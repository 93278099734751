// React
import { useState, useEffect } from "react";
import "./IndexHomePage.scss";
import ClausePage from "./ClausePage";
import { getUserRole } from "../../../utils/token";
import { protectedResources } from "../../../authConfig";
import jwt_decode from "jwt-decode";

interface IPROPS {
  indicationId?: any;
  arrData?: any;
  onAPiLoad?: any;
  scrollId?: any;
  documentId?: any;
  fromIssueRecap?: any;
  checkNegotiationStatus?: any;
  checkWriteAccess?: any;
  isEditing?: any;
  mainInfoData?: any;
  isPrefilledCondition?:any;
  extendedContractIDBCP?:any;
}

const EditorPage = ({
  indicationId,
  arrData,
  onAPiLoad,
  scrollId,
  documentId,
  fromIssueRecap,
  checkNegotiationStatus,
  checkWriteAccess,
  isEditing,
  mainInfoData,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const [userRole, setUserRole] = useState();
  const [bcpSectionData, setBcpSectionData] = useState<any>([]);
  const scrollTo = (id: any) => {
    if (id !== 0) {
      const scroll: any = document.getElementById(`scroll${id}`);
      // window.scrollTo({
      //   top: scroll?.offsetTop ? scroll?.offsetTop - 100 : 100,
      //   left: 0,
      //   behavior: "smooth",
      // });
      scroll?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const onClauseAPiLoad = () => {
    onAPiLoad();
  };

  const fetchToken = async () => {
    const role = await getUserRole();
    setUserRole(role);
  };
  useEffect(() => {
    scrollTo(scrollId);
  }, [scrollId]);
  useEffect(() => {
    setBcpSectionData(arrData);
  }, [arrData]);
  useEffect(() => {
    fetchToken();
  });
  const handleRevertCheck = (e, itemData) => {
    console.log(e, itemData, bcpSectionData, arrData);
    if (bcpSectionData?.newSub && bcpSectionData?.newSub?.length > 0) {
      bcpSectionData?.newSub?.map((item) => {
        item.baseCharterPartyMainClauseParaGraphs.map((subItem) => {
          console.log(subItem.paragraphId, itemData.paragraphId);
          if (subItem.paragraphId === itemData.paragraphId) {
            subItem.checked = e;
          }
        });
      });
      if (bcpSectionData?.baseCharterPartyMainClauseParaGraphs?.length > 0) {
        bcpSectionData?.baseCharterPartyMainClauseParaGraphs?.map((item) => {
          console.log(item.paragraphId, itemData.paragraphId);
          if (item.paragraphId === itemData.paragraphId) {
            item.checked = e;
          }
        });
      }
    } else {
      bcpSectionData?.baseCharterPartyMainClauseParaGraphs?.map((item) => {
        if (item.paragraphId === itemData.paragraphId) {
          item.checked = e;
        }
      });
    }
  };

  return (
    <div className="cluaseDiv">
      <h3 className="heading-line" id={`scroll${arrData?.id}`}>
      {arrData?.title == null ? arrData?.parentTitle : arrData?.title}
        {/* {arrData?.title == null ? arrData?.parentTitle : arrData?.newSub[0].parentTitle} */}
        {/* {arrData.parentTitle} */}
      </h3>
      {arrData?.baseCharterPartyMainClauseParaGraphs?.map((item: any, index: any) => (
        <div className="eachSection">
          <ClausePage
            indicationId={indicationId}
            titleId={arrData?.paragraphId}
            onAPiLoadNew={onClauseAPiLoad}
            cluaseData={item}
            isTitle={arrData?.title}
            userRole={userRole}
            documentId={documentId}
            fromIssueRecap={fromIssueRecap}
            checkNegotiationStatus={checkNegotiationStatus}
            isWriteAccess={checkWriteAccess}
            isEditing={isEditing}
            handleRevertCheck={handleRevertCheck}
            mainInfoData={mainInfoData}
            index={index}
            isPrefilledCondition={isPrefilledCondition}
            extendedContractIDBCP={extendedContractIDBCP}
          ></ClausePage>
        </div>
      ))}

      {arrData?.newSub?.map((arritem: any) =>
        arritem?.baseCharterPartyMainClauseParaGraphs?.map((item: any, index: any) => (
          <div className="eachSection">
            <ClausePage
              indicationId={indicationId}
              titleId={arritem?.id}
              isTitle={arritem?.title}
              onAPiLoadNew={onClauseAPiLoad}
              cluaseData={item}
              userRole={userRole}
              documentId={documentId}
              fromIssueRecap={fromIssueRecap}
              checkNegotiationStatus={checkNegotiationStatus}
              isWriteAccess={checkWriteAccess}
              isEditing={isEditing}
              handleRevertCheck={handleRevertCheck}
              mainInfoData={mainInfoData}
              index={index}
              isPrefilledCondition={isPrefilledCondition}
              extendedContractIDBCP={extendedContractIDBCP}
            ></ClausePage>
          </div>
        ))
      )}
    </div>
  );
};
export default EditorPage;
