import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  McModal,
  McButton,
  McCheckbox,
  McIcon,
  McInputDate,
} from "@maersk-global/mds-react-wrapper";
import Select from "react-select";
import Checkbox from "react-custom-checkbox";
import moment from "moment";
import "./EditModal.scss";
interface IPROPS {
  OpenModal?: boolean;
  data?: any;
  onModalClose?: any;
  statusOption?: any;
  currencyOption?: any;
  buyerOption?: any;
  sourceOption?: any;
}
const EditModal = ({
  OpenModal,
  data,
  onModalClose,
  statusOption,
  currencyOption,
  buyerOption,
  sourceOption,
}: IPROPS) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const dataValue = data;
  const [defaultStatus, setDefualtStatus] = useState<any | undefined>([]);
  const [defultCurrency, setDefualtCurrency] = useState<any | undefined>([]);
  const [defaultBuyer, setDefaultBuyer] = useState<any | undefined>([]);
  const [defualtSource, setDefualtSource] = useState<any | undefined>([]);
  const [isModalDisabled, setIsModalDisabled] = useState(false);
  const [todaysDate, setTodaysDate] = useState<any>();
  const [date, setDate] = useState<any>();
  const [priceField, setPriceDisabled] = useState(true);
  const closeFunction = () => {
    onModalClose("close", null);
  };
  const intailSetValue = () => {
    if (dataValue) {
      let ststusObj = statusOption?.find(
        (o: any) => o.label === dataValue?.status
      );
      setValue("price", dataValue?.price);
      setValue("priceGuidance", dataValue?.priceGuidance);
      setIsModalDisabled(false);
      setDefualtStatus([ststusObj]);
      let currencyObj = currencyOption?.find(
        (o: any) => o.label === dataValue?.currency
      );
      setDefualtCurrency([currencyObj]);
      let buyerObj = buyerOption?.find(
        (o: any) => o.label === dataValue?.buyer
      );
      setDefaultBuyer([buyerObj]);
      if (dataValue?.source) {
        let sourceObj = sourceOption?.find(
          (o: any) => o.label === dataValue?.source
        );
        setDefualtSource([sourceObj]);
      } else {
        setDefualtSource([]);
      }
      if (dataValue?.date)
        setTodaysDate(moment(dataValue?.date).format("DD/MM/YYYY"));
    }
  };
  const setPriceFieldDisabled = (val: any) => {
    if (val.label == "Sold") {
      setPriceDisabled(false);
    } else {
      setPriceDisabled(true);
    }
  };
  const populateDateOfStatusToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();

    let newToday = dd + "/" + mm + "/" + yyyy;
    setDate(newToday);
    setTodaysDate(newToday);
    setValue("date", newToday);
    console.log(getValues());
  };
  useEffect(() => {
    intailSetValue();
  }, [OpenModal]);
  const onSubmit = (data: any) => {
    console.log(dataValue.buyer);
    console.log(dataValue.currency);
    const dataObj = {
      id: dataValue.id,
      imo: dataValue.imo,
      status: data.status ? data.status.label : dataValue.status,
      date: data.date
        ? moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD[T]HH:mm:ss")
        : dataValue.date,
      owner: dataValue.owner,
      buyer: data.buyer ? data.buyer.label : dataValue.buyer,
      currency: data.currency ? data.currency.label : dataValue.currency,
      currencyId: data.currency ? data.currency.value : dataValue.currencyId,
      price: data.price ? data.price : dataValue.price,
      enBlock: data.enBlock !== undefined ? data.enBlock : dataValue.enBlock,
      priceGuidance: data.priceGuidance
        ? data.priceGuidance
        : dataValue.priceGuidance,
      uid: dataValue.uid,
      source: data.source ? data.source.label : dataValue.source,
      sourceId: data.source ? data.source.value : dataValue.sourceId,
      updateDate: dataValue.updateDate,
      comments: data.comments ? data.comments : dataValue.comments,
      typeId: dataValue.typeId,
      statusId: data.status ? data.status.value : dataValue.statusId,
      chartererId: dataValue.chartererId,
    };
    onModalClose("submit", dataObj);
    reset();
    setIsModalDisabled(true);
  };
  return (
    <>
      <form role="submit" data-testid="form" onSubmit={handleSubmit(onSubmit)}>
        <McModal
          open={OpenModal}
          height="70vh"
          backdropcloseactiondisabled
          heading={`Update Vessel S&P Details - ${dataValue?.vesselName}`}
        >
          <span className="mds-text--medium-normal">
            <div className="update-vessels">
              <div className="row">
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Vessel Name</p>
                    <h6 className="heading" data-testid="snp-vessel-name">
                      {dataValue?.vesselName}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>IMO</p>
                    <h6 className="heading" data-testid="snp-imo">
                      {dataValue?.imo}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Build</p>
                    <h6 className="heading" data-testid="snp-build-">
                      {dataValue?.build}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Design</p>
                    <h6
                      title={dataValue?.design}
                      className="heading"
                      data-testid="snp-design"
                    >
                      {dataValue?.design}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="data-div">
                    <p>TEU</p>
                    <h6 className="heading" data-testid="snp-teu">
                      {dataValue?.teu}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>UID</p>
                    <h6 className="heading" data-testid="snp-uid">
                      {dataValue?.uid}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Owner</p>
                    <h6
                      title={dataValue?.owner}
                      className="heading"
                      data-testid="snp-owner"
                    >
                      {dataValue?.owner}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Charterer</p>
                    <h6 className="heading" data-testid="snp-charterer-name">
                      {dataValue?.chartererName}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="data-div">
                    <p>Last Update Date</p>
                    <h6 className="heading" data-testid="snp-last-update-date">
                      {moment(dataValue?.updateDate).format("DD/MM/YYYY")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="data-div">
                    <label>
                      <span className="mandetory">*</span>Status
                    </label>
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange } }) => (
                          <Select
                            id="snp-status"
                            defaultValue={{
                              value: String(dataValue?.statusId),
                              label: dataValue?.status,
                            }}
                            name="status"
                            onChange={(val) => {
                              onChange(val);
                              populateDateOfStatusToday();
                              setPriceFieldDisabled(val);
                            }}
                            options={statusOption}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="data-div">
                    <label>
                      <span className="mandetory">*</span>Date of Status
                    </label>
                    <McInputDate
                      label=""
                      {...register("date")}
                      value={todaysDate ? todaysDate : ""}
                      input={(e: any) => setTodaysDate(e.target.value)}
                      startofweek={1}
                      format="DD/MM/YYYY"
                      nextlabel="Next month"
                      previouslabel="Previous month"
                      ariaPlaceholder="DD/MM/YYYY"
                      type={undefined}
                      icon="calendar"
                      data-testid="snp-date-of-status"
                    ></McInputDate>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="data-div">
                    <label>Buyer</label>
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="buyer"
                        render={({ field: { onChange } }) => (
                          <Select
                            defaultValue={{
                              value: String(dataValue?.buyer),
                              label: dataValue?.buyer,
                            }}
                            name="buyer"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={buyerOption}
                            data-testid="snp-buyer"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="data-div">
                    <label>Price Guidance</label>
                    <input
                      className="inputText"
                      {...register("priceGuidance")}
                      type="text"
                      data-testid="snp-price-guidance"
                    ></input>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="data-div">
                        <label>Currency</label>
                        {OpenModal && (
                          <Controller
                            control={control}
                            name="currency"
                            render={({ field: { onChange } }) => (
                              <Select
                                id="snp-currency"
                                defaultValue={{
                                  value: String(dataValue?.currencyId),
                                  label: dataValue?.currency,
                                }}
                                name="currency"
                                onChange={(val) => {
                                  onChange(val);
                                }}
                                options={currencyOption}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="data-div">
                        <label>Price</label>
                        <input
                          id="snp-price"
                          className="inputText"
                          {...register("price")}
                          type="text"
                          disabled={priceField}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="data-div enblockValue">
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="enBlock"
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            checked={dataValue?.enBlock}
                            icon={
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  backgroundColor: "#42b0d5",
                                }}
                                data-testid="snp-enblock"
                              >
                                <McIcon icon="check" color="#fff"></McIcon>
                              </div>
                            }
                            onChange={(value: any) => {
                              onChange(value);
                            }}
                            borderColor="#ddd"
                            borderRadius={1}
                            size={18}
                            label="EnBlock"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="data-div">
                    <label>Source</label>
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="source"
                        render={({ field: { onChange } }) => (
                          <Select
                            defaultValue={{
                              value: String(dataValue?.sourceId),
                              label: dataValue?.source,
                            }}
                            name="source"
                            data-testid="snp-source"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={sourceOption}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="data-div">
                    <label>Comments</label>
                    <textarea
                      className="commentArea"
                      {...register("comments")}
                      defaultValue={
                        dataValue?.comments !== null ? dataValue?.comments : ""
                      }
                      placeholder="comments"
                      rows={1}
                      id="snp-comments"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row"></div>
            </div>
          </span>
          <McButton
            slot="primaryAction"
            variant="primary"
            type="submit"
            disabled={isModalDisabled}
            label="Save and update"
            data-testid="snp-save-update"
          ></McButton>
          <McButton
            slot="secondaryAction"
            appearance="default"
            variant="outlined"
            dialogAction="cancel"
            click={closeFunction}
            role="cancel"
            data-testid="cancep-button"
          >
            Cancel
          </McButton>
        </McModal>
      </form>
    </>
  );
};
export default EditModal;
