//React
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";

//MDS
import {
  McSelect,
  McInput,
  McButton,
  McIcon,
  McModal,
  McInputDate,
  McCTypeahead,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import DatePicker from "react-datepicker";

//Components
import { getAPI } from "../../services/ApiServices";
import { InputDropdown } from "../../components/inputDropdown/inputDropdown";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
//Config
import { getUID } from "../../utils/token";

// Styles
import "../../pages/charter-market/CharterMarket.scss";
import "../../pages/charter-market-filters/CharterMarketFilters.scss";
import "./AddCharterMarket.scss";
import { min } from "moment";

interface IPROPS {
  onAddDataFunc?: any;
  onOpen?: boolean;
  onAddClose?: (close: boolean) => void;
}
const AddModalCharter = ({ onAddDataFunc, onOpen, onAddClose }: IPROPS) => {
  const UID = getUID();
  const {
    register,
    handleSubmit,
    control,
    trigger,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [newRR, setNewRR] = useState<any>([]);
  const [fuelClass, setFuelClass] = useState([]);
  const [vesselIMO, setVesselIMOFilters] = useState([]);
  const [managingOwner, setManagingOwner] = useState([]);
  const [imo, setIMO] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [buildTEUDesign, setBuildTEUDesignFilters] = useState<any>([]);
  const [charterer, setChartererFilters] = useState([]);
  const [charterType, setCharterTypeFilters] = useState([]);
  const [currency, setCurrencyFilters] = useState([]);

  const [deliveryDateVal, setDeliveryDateVal] = useState("");
  const [minDate, setMindate] = useState("");
  const [minDateVal, setMinDateVal] = useState("");
  const [maxDate, setMaxdate] = useState({ maxDate: "" });
  const [maxDateVal, setMaxDateVal] = useState("");
  const [estDate, setEstdate] = useState("");

  const [redeliveryRange, setRedeliveryRangeFilters] = useState<any[]>([]);
  const [redeliveryPort, setRedeliveryPortFilters] = useState([]);
  const [sourcesData, setSourcesDataFilters] = useState([]);
  const [sourceId, setSourceId] = useState(0);
  const [redeliveryRangeId, setRedeliveryRangeId] = useState([]);
  let newArr:any[] = [];
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  let maxDateCal = "";
  const purchaseObligation:any[] = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
    { value: null, label: "None" },
  ];

  const fixtureOptionPeriodType = [
    { value: "Day", label: "Day" },
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
  ];

  const onAddModalClose = () => {
    if(onAddClose){
      onAddClose(true);
    }
    
  };
  useEffect(() => {
    if (onOpen) {
      setDisableBtn(false);
    }
  }, [onOpen]);

  const onSubmit = (data) => {
    data["imo"] = Number(imo);
    data["redeliveryRanges"] = newRR;

    data.deliveryDate = moment(data?.deliveryDate).format("YYYY-MM-DD");
    data.minDate = moment(data?.minDate).format("YYYY-MM-DD");
    data.maxDate = moment(data?.maxDate).format("YYYY-MM-DD");
    data.estRedeliveryDate = data.estRedeliveryDate ? moment(data?.estRedeliveryDate).format("YYYY-MM-DD") : null;
    if (data) {
      onAddDataFunc(data, "submit");
      setDisableBtn(true);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const fetchAddVesselDetailsData = async (): Promise<void> => {
    const vesselIMOData = await getAPI(`Filter/GetAllVesselAndImo`);
    setVesselIMOFilters(vesselIMOData);

    const chartererData = await getAPI(`Filter/GetAllCharterer`);
    setChartererFilters(chartererData);

    const charterTypeData = await getAPI(`Filter/GetAllCharterType`);
    setCharterTypeFilters(charterTypeData);

    const currencyData = await getAPI(`Filter/GetAllCurrency`);
    setCurrencyFilters(currencyData);

    const redeliveryRangeData = await getAPI(`Filter/GetAllRedeliveryRange/0`);
    setRedeliveryRangeFilters(redeliveryRangeData);

    const sourcesData = await getAPI(`Filter/GetAllSources`);
    setSourcesDataFilters(sourcesData);

    const redeliveryPortData = await getAPI(`Filter/GetAllRedeliveryPort`);
    setRedeliveryPortFilters(redeliveryPortData);
  };

  useEffect(() => {
    fetchAddVesselDetailsData();
  }, []);

  const getManagingOwner = async (imoNum) => {
    // await getAPI(`Charter/GetManagingOwner/${imoNum}/0`).then((result) => {
    //   setManagingOwner(result);
    // });
    await getAPI(`Charter/GetManagingOwner`).then((result) => {
      setManagingOwner(result);
    });
  };
  const getFuelClass = async (val): Promise<void> => {
    await getAPI(`Charter/GetFuelClassAsync/` + val).then((result) => {
      setFuelClass(result);
    });
  };

  const onVslIMO = async (val): Promise<void> => {
    await getAPI(`Vessel/GetVesselByImoOrName/` + val).then((result) => {
      setIMO(result.imoNumber);
      setBuildTEUDesignFilters(result);
      getManagingOwner(result.imoNumber);
      getFuelClass(result.imoNumber);
    });
  };

  const getTodayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    const todayDate = dd + "/" + mm + "/" + yyyy;
    return todayDate;
  };
  const deliveryDateChangeHandler = (date) => {
    const newDate: any = moment(
      moment(date).add(1, "d").format("YYYY/MM/DD")
    );
    setMindate(newDate);
    setMinDateVal("");
    setMaxDateVal("");
    setEstdate("");
    setValue("minDate", "");
    setValue("maxDate", "");
    setValue("estRedeliveryDate", "");
  };
  const minDateChangeHandler = (date) => {
    const newDate: any = moment(date).format("YYYY/MM/DD")

    setMaxdate({ maxDate: newDate });
    setMaxDateVal("");
    maxDateCal = newDate;
    setValue("maxDate", "");
    setValue("estRedeliveryDate", "");
  };

  const onChangeRange = (currentNode, selectedNodes) => {
    let arr:any[] = [];
    selectedNodes.forEach((element: any) => {
      if (element?._depth !== 0) {
        if (element?._depth == 1) {
          element?._children?.forEach((j) => {
            arr.push(j);
          });
        } else {
          arr.push(element.id);
        }
      } else {
        const selectedRange = redeliveryRange.filter(
          (e:any) => e.label == element.label
        );
        selectedRange.forEach((e:any) => {
          e.children.forEach((i) => {
            i.children.forEach((j) => {
              arr.push(j.id);
            });
          });
        });
      }
    });
    setNewRR(arr);
    newArr = arr;
    const updatedRedeliveryRange = redeliveryRange.map((range:any) => {
      const allChildrenChecked = range.children.every(subRange =>
        subRange.children.every(item => arr.includes(item.id))
      );
      return {
        ...range,
        checked: allChildrenChecked || arr.includes(range.id),
        children: range.children.map((subRange) => ({
          ...subRange,
          checked: subRange.children.every(item => arr.includes(item.id)) || arr.includes(subRange.id),
          children: subRange.children.map((item) => ({
            ...item,
            checked: arr.includes(item.id),
          })),
        })),
      };
    });
    setRedeliveryRangeFilters(updatedRedeliveryRange);

    const checkedItem = updatedRedeliveryRange.filter((range) => range.checked === true);
    setCheckedItems(checkedItem );
  };

  return (
    <>
      <form data-testid="form" className="charterMarketAddModal" onSubmit={handleSubmit(onSubmit, onError)}>
        <McModal
          open={onOpen}
          height="70vh"
          heading="Add Vessel Details"
          backdropcloseactiondisabled
        >
          <div className="row">
            <div className="col-md-14">
              <div className="modal-section">
                <div className="subColoum">
                  <div className="row">
                    <div className="col-md-6 row-divider">
                      <div className="input-section">
                        <Controller
                          name="vslImo"
                          rules={{ required: true }}
                          control={control}
                          defaultValue=""
                          
                          render={({ field: { onChange } }) => (
                            <McCTypeahead
                              ariaPlaceholder="Search vessel or IMO"
                              data-testid="charter-market-search-vessel"
                              fit="medium"
                              name="vslImo"
                              label="Vessel Name"
                              icon="magnifying-glass"
                              iconposition="right"
                              optionselected={(e) => {
                                onChange(e.detail.option.value);
                                onVslIMO(e.detail.option.value);
                              }}
                              options={vesselIMO}
                            ></McCTypeahead>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="data-section">
                    <div className="header-section">
                      <div className="row">
                        <div className="col-md-3">
                          <h5>Vessel Name</h5>
                        </div>
                        <div className="col-md-3">
                          <h5>IMO</h5>
                        </div>
                        <div className="col-md-3">
                          <h5>Build</h5>
                        </div>
                        <div className="col-md-3">
                          <h5>Design</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-3 text-highlight" data-testid="cm-vessel-name">
                        {buildTEUDesign.vesselName}
                      </div>
                      <div className="col-md-3 text-highlight" data-testid="cm-imo">
                        {buildTEUDesign.imoNumber}
                      </div>
                      <div className="col-md-3 text-highlight" data-testid="cm-yob">
                        {buildTEUDesign.yob}
                      </div>
                      <div className="col-md-3 text-highlight" data-testid="cm-design">
                        {buildTEUDesign.design}
                      </div>
                    </div>
                    <div className="header-section">
                      <div className="row">
                        <div className="col-md-3">
                          <h5>UID </h5>
                        </div>
                        <div className="col-md-3">
                          <h5>Source</h5>
                        </div>
                        <div className="col-md-3">
                          <h5>Status</h5>
                        </div>
                        <div className="col-md-3">
                          <h5>Date of Status</h5>
                        </div>
                      </div>
                    </div>
                    <div className="data-section  row-divider">
                      <div className="row">
                        <div className="col-md-3 text-highlight" data-testid="cm-uid">
                          {UID}
                          <div></div>
                        </div>
                        <div className="col-md-3 text-highlight">
                          {"Manual"}
                        </div>
                        <div className="col-md-3 dull-text">
                          {"System Populated"}{" "}
                        </div>
                        <div className="col-md-3 text-highlight">
                          {getTodayDate()}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="header-section">
                      <div className="row">
                        <div className="col">
                          <h5>TEU </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-highlight" data-testid="cm-teu">
                          <h5>{buildTEUDesign.teu} </h5>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="row">
                        <div className="col-md-6 row-divider">
                          <div className="input-section">
                            <label>
                              <span className="asterisk">*</span>
                              Charterer
                            </label>
                            <McSelect
                              {...register("chartererId", { required: true })}
                              name="chartererId"
                              selectedindex={[0]}
                              fit="medium"
                              options={charterer}
                              label=""
                              data-testid="charterer"
                            ></McSelect>
                          </div>
                        </div>
                        <div className="col-md-6 row-divider">
                          <div className="input-section">
                            <label>
                              <span className="asterisk">*</span>
                              Charter Type
                            </label>
                            <McSelect
                              {...register("chartererType", { required: true })}
                              name="chartererType"
                              selectedindex={[0]}
                              ariaPlaceholder="Charter Type"
                              fit="medium"
                              label=""
                              options={charterType}
                              data-testid="charterer-type"
                            ></McSelect>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3 row-divider">
                          <div className="input-section">
                            <label>
                              <span className="asterisk">*</span>
                              Delivery
                            </label>
                            <div className="date-div">
                              <span className="date-icon">
                                <McIcon icon="calendar"></McIcon>
                              </span>
                              <Controller
                                control={control}
                                // name="deliveryDate"
                                data-testid="delivery-date"
                                {...register("deliveryDate", { required: true })}
                                render={({ field: { onChange } }) => (
                                  <DatePicker
                                    id="delivery-date"
                                    placeholderText="dd/mm/yyyy"
                                    className="inputText"
                                    dateFormat="dd/MM/yyyy"
                                    selected={deliveryDateVal}
                                    onChange={(date:any) => {
                                      onChange(date);
                                      setDeliveryDateVal(date);
                                      deliveryDateChangeHandler(date);
                                    }}
                                    required
                                  ></DatePicker>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 row-divider">
                          <div className="input-section">
                            <label>
                              <span className="asterisk">*</span>
                              Min
                            </label>
                            <div className="date-div" data-testid='minDeliveryDate'>
                              <span className="date-icon">
                                <McIcon icon="calendar"></McIcon>
                              </span>
                              <Controller
                                control={control}
                                // name="minDate"
                                data-testid="min-delivery-date"
                                {...register("minDate", { required: true })}
                                render={({ field: { onChange } }) => (
                                  <DatePicker
                                    id="min-delivery-date"
                                    placeholderText="dd/mm/yyyy"
                                    className="inputText"
                                    dateFormat="dd/MM/yyyy"
                                    selected={minDateVal}
                                    minDate={new Date(minDate)}
                                    onChange={(date:any) => {
                                      onChange(date);
                                      setMinDateVal(date);
                                      minDateChangeHandler(date);
                                    }}
                                    required
                                  ></DatePicker>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 row-divider">
                          <div className="input-section">
                            <label>
                              <span className="asterisk">*</span>
                              Max
                            </label>
                            <div className="date-div">
                              <span className="date-icon">
                                <McIcon icon="calendar"></McIcon>
                              </span>
                              <Controller
                                control={control}
                                // name="maxDate"
                                data-testid="max-delivery-date"
                                {...register("maxDate")}
                                render={({ field: { onChange } }) => (
                                  <DatePicker
                                    id="max-delivery-date"
                                    placeholderText="dd/mm/yyyy"
                                    className="inputText"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date(minDateVal)}
                                    selected={maxDateVal}
                                    onChange={(date:any) => {
                                      onChange(date);
                                      setMaxDateVal(date);
                                    }}
                                    required
                                  ></DatePicker>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 row-divider">
                          <div className="input-section est-redelivery-range">
                            <label>Est. Redelivery Date</label>
                            <div className="date-div">
                              <span className="date-icon">
                                <McIcon icon="calendar"></McIcon>
                              </span>
                              <Controller
                                control={control}
                                // name="estRedeliveryDate"
                                data-testid="est-delivery-date"
                                {...register("estRedeliveryDate")}
                                render={({ field: { onChange } }) => (
                                  <DatePicker
                                    placeholderText="dd/mm/yyyy"
                                    className="inputText"
                                    dateFormat="dd/MM/yyyy"
                                    selected={estDate}
                                    minDate={new Date(minDateVal)}
                                    onChange={(date:any) => {
                                      onChange(date);
                                      setEstdate(date);
                                    }}
                                  ></DatePicker>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3 row-divider">
                          <div className="input-section">
                            <label>Currency</label>
                            <McSelect
                              {...register("currencysItems")}
                              name="currencysItems"
                              ariaPlaceholder="Select"
                              fit="medium"
                              label=""
                              options={currency}
                              data-testid="currency"
                            ></McSelect>
                          </div>
                        </div>
                        <div className="col-md-3 row-divider">
                          <div className="input-section">
                            <label>Rate</label>
                            <McInput
                              {...register("rate")}
                              name="rate"
                              fit="medium"
                              label=""
                              // invalid={errors?.rate?.type == "required"}
                              ariaPlaceholder="Rate"
                              data-testid="rate"
                            ></McInput>
                          </div>
                        </div>
                        <div className="col-md-3 row-divider">
                          <div className="input-section redl-range">
                            <label>Redelivery range</label>
                            <DropdownTreeSelect
                              className="custom-treeselect"
                              data={redeliveryRange}
                              onChange={(currentNode, selectedNodes) =>
                                onChangeRange(currentNode, selectedNodes)
                              }
                              data-testid="redelivery-range"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 row-divider">
                          <div className="input-section redl-port">
                            <label>Redelivery port</label>
                            <McSelect
                              {...register("redeliveryPortId")}
                              name="redeliveryPortId"
                              label=""
                              ariaPlaceholder="Select"
                              fit="medium"
                              options={redeliveryPort}
                              data-testid="redelivery-port"
                            ></McSelect>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="row">
                        <div className="col-md-4 row-divider">
                          <div className="input-section">
                            <McSelect
                              {...register("purchaseObligation")}
                              name="purchaseObligation"
                              ariaPlaceholder="Select"
                              label="Purchase Obligation"
                              options={purchaseObligation}
                              fit="medium"
                              data-testid="purchase-obligation"
                            ></McSelect>
                          </div>
                        </div>
                        <div className="col-md-4 row-divider">
                          <div className="input-section">
                            <McSelect
                              {...register("purchaseOption")}
                              name="purchaseOption"
                              ariaPlaceholder="Select"
                              label="Purchase Option"
                              options={purchaseObligation}
                              fit="medium"
                              data-testid="purchase-option"
                            ></McSelect>
                          </div>
                        </div>
                        <div className="col-md-4 row-divider">
                          <div className="input-section">
                            <label>Purchase Option Value</label>
                            <div className="row">
                              <div className="col custom-input">
                                <McInput
                                  {...register("purchaseOptionValue")}
                                  name="purchaseOptionValue"
                                  fit="medium"
                                  label=""
                                  ariaPlaceholder="Type"
                                  data-testid="purchase-option-value"
                                ></McInput>
                              </div>
                              <div className="col custom-input-right">
                                <McSelect
                                  {...register("purchaseOptionCurrencyId")}
                                  name="purchaseOptionCurrencyId"
                                  ariaPlaceholder="Select"
                                  label=""
                                  options={currency}
                                  fit="medium"
                                  data-testid="purchase-option-currency-id"
                                ></McSelect>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="row">
                            <div className="col-md-4 row-divider">
                              <div className="input-section">
                                <McSelect
                                  {...register("fixtureOption")}
                                  name="fixtureOption"
                                  ariaPlaceholder="Select"
                                  label="Fixture Option"
                                  options={purchaseObligation}
                                  fit="medium"
                                  data-testid="fixture-option"
                                ></McSelect>
                              </div>
                            </div>

                            <div className="col-md-4 row-divider">
                              <div className="input-section">
                                <label>Fixture Option Period</label>
                                <div className="row">
                                  <div className="col custom-input">
                                    <McInput
                                      {...register("fixtureOptionPeriod")}
                                      name="fixtureOptionPeriod"
                                      fit="medium"
                                      label=""
                                      ariaPlaceholder="Type"
                                      data-testid="fixture-option-period"
                                    ></McInput>
                                  </div>
                                  <div className="col custom-input-right">
                                    <McSelect
                                      {...register("fixtureOptionPeriodType")}
                                      name="fixtureOptionPeriodType"
                                      ariaPlaceholder="Select"
                                      label=""
                                      options={fixtureOptionPeriodType}
                                      fit="medium"
                                      data-testid="fixture-option-period-type"
                                    ></McSelect>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 row-divider">
                              <div className="input-section">
                                <label>Fixture Option Value</label>
                                <div className="row">
                                  <div className="col custom-input">
                                    <McInput
                                      {...register("fixtureOptionValue")}
                                      name="fixtureOptionValue"
                                      fit="medium"
                                      label=""
                                      ariaPlaceholder="Type"
                                      data-testid="fixture-option-value"
                                    ></McInput>
                                  </div>
                                  <div className="col custom-input-right">
                                    <McSelect
                                      {...register("fixtureOptionCurrencyId")}
                                      name="fixtureOptionCurrencyId"
                                      ariaPlaceholder="Select"
                                      label=""
                                      options={currency}
                                      fit="medium"
                                      data-testid="fixture-option-currency-id"
                                    ></McSelect>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="row">
                            <div className="col-md-4 row-divider">
                              <div className="input-section">
                                <div className="data-div">
                                  <label>
                                    <span className="mandetory"></span>Managing
                                    owner
                                  </label>
                                </div>
                                <Controller
                                  control={control}
                                  name="ManagingOwner"
                                  render={({ field: { onChange } }) => (
                                    <InputDropdown
                                      onDdlChange={onChange}
                                      optionList={managingOwner}
                                      data-testid="managing-owner"
                                    ></InputDropdown>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-4 row-divider">
                              <div className="input-section">
                                <div className="data-div">
                                  <label>Fuel Class</label>
                                  <McSelect
                                    {...register("fuelClass")}
                                    name="fuelClass"
                                    ariaPlaceholder="Select"
                                    fit="medium"
                                    label=""
                                    options={fuelClass}
                                    data-testid="fuel-class"
                                  ></McSelect>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 row-divider">
                              <div className="input-section">
                                <McTextarea
                                  {...register("comments")}
                                  name="comments"
                                  label="Comments"
                                  ariaPlaceholder="Type"
                                  rows={2}
                                  fit="medium"
                                  data-testid="comments"
                                ></McTextarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <McButton
            // focusendanchor
            slot="secondaryAction"
            variant="secondary"
            click={() => onAddModalClose()}
            dialogAction="cancel"
            data-testid="cancel-button"
          >
            Cancel
          </McButton>

          <McButton
            // focusstartanchor
            slot="primaryAction"
            variant="primary"
            type="submit"
            disabled={disableBtn}
            data-testid="save-button"
          >
            Save details
          </McButton>
        </McModal>
      </form>
    </>
  );
};

export default AddModalCharter;
