import React, { useState, useEffect } from "react";
import {
  deleteAPI,
  downloadReportDocuments,
  fileApiAxios,
} from "../../../../services/CepApiServices";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import "../AdditionalDocuments.scss";
import { getUserRole } from "../../../../utils/token";
import UploadFiles from "../../../../IndicationRequest/IndicationRequestPages/Details/UploadFiles";
import UploadDocConfirmationModal from "./UploadDocConfirmationModal";
import { IconPdfDL } from "../../../../components/Icons/pdfIcon";
import ConfirmationModal from "../../../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import { AppString } from "../../../../utils/AppString";
import { toast } from "react-toastify";
import UploadDocumentLibraryVO from "./UploadDocumentLibraryVO";

interface IPROPS {
  indicationId?: any;
  accessStatus?: any;
  getAllDocumentFunc?: any;
  indicationDocs?: any;
}
const UploadedDocumentSection = ({
  indicationId,
  accessStatus,
  getAllDocumentFunc,
  indicationDocs,
}: IPROPS) => {
  const [isWriteAccess, setIsWriteAccess] = useState<any>(false);
  const [accessRole, setAccessRole] = useState(false);
  const [contentLoader, setContentLoader] = useState(true);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [uploadModalValue, setUploadModalValue] = useState(false);
  const [uploadConfirmModalValue, setUploadConfirmModalValue] = useState(false);
  const [uploadModalForVODoc, setUploadModalForVODoc] = useState(false);
  const [requestedDocuments, setRequestedDocuments] = useState<any>([]);
  const [fileUploaded, setFileUploaded] = useState<File>();
  const [fileUploadedVO, setFileUploadedVO] = useState<File>();
  const [progressStatus, setProgressStatus] = useState("");
  const [selectedDocument, setSelectedDocument] = useState<any>({});
  const [isConfrmModal, setIsConfrmModal] = useState(false);
  const [fileNameForDelete, setFileNameForDelete] = useState();
  const [fileIdForDelete, setFileIdForDelete] = useState(0);
  const [pageLoadOnDelete, setPageLoadOnDelete] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);

  useEffect(() => {
    const filteredDocuments = indicationDocs.filter((data) => data.uploadedBy);
    setUploadedDocuments(filteredDocuments);
    const filteredRequestedDocuments = indicationDocs.filter(
      (data) => !data.uploadedBy
    );
    setRequestedDocuments(filteredRequestedDocuments);
    setContentLoader(false);

    setPageLoad(false);
    setPageLoadOnDelete(false);
  }, [indicationDocs]);
  const DownloadUploadedDocument = async (id: any, fileName: string) => {
    let filesName = id + "_" + fileName;
    await downloadReportDocuments(
      `IndicationRequest/DownlaodIndicationRequestDocument/${filesName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        var name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("Could not download the file");
      });
  };
  const handleUploadPopUp = (requestedDocuments: any) => {
    if (requestedDocuments > 0) {
      setUploadConfirmModalValue(true);
    } else {
      setUploadModalForVODoc(true);
    }
  };
  const closingUpload = (act: any, data: any, typeValue: any) => {
    setUploadModalValue(false);
  };
  const closingVOUpload = () => {
    setUploadModalForVODoc(false);
    getAllDocumentFunc();
  };
  const onConfirmationModalClose = (data: any) => {
    if (data == "true") {
      setUploadModalValue(true);
      setUploadConfirmModalValue(false);
      setUploadModalForVODoc(false);
    } else if (data == "false") {
      setUploadModalForVODoc(true);
      setUploadModalValue(false);
      setUploadConfirmModalValue(false);
    } else {
      setUploadConfirmModalValue(false);
    }
  };
  const receiveUploadedFile = (file: File, source: any) => {
    setFileUploaded(file);
    setSelectedDocument(source);
  };
  const receiveUploadedFileFromVO = (file: File) => {
    setFileUploadedVO(file);
  };
  const saveUploadedFile = async () => {
    const indicationRequestDocObj = [
      {
        id: 0,
        documentId: selectedDocument?.documentId,
        formFile: fileUploaded,
        indicationRequestId: indicationId,
        documentName: fileUploaded?.name,
        createdBy: "",
        modifiedBy: "",
      },
    ];
    let formData: any = new FormData();
    let index = 0;
    for (let pair of indicationRequestDocObj) {
      formData.append(
        "indicationRequestDocuments[" + index + "].formFile",
        pair.formFile
      );
      formData.append("indicationRequestDocuments[" + index + "].id", pair.id);
      formData.append(
        "indicationRequestDocuments[" + index + "].documentId",
        pair.documentId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].indicationRequestId",
        pair.indicationRequestId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].documentName",
        pair.documentName
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].createdBy",
        pair.createdBy
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].modifiedBy",
        pair.modifiedBy
      );
    }
    if (fileUploaded) {
      await fileApiAxios(
        `IndicationRequest/UploadFilesFromNegotiation?indicationId=${indicationId}`,
        formData
      ).then((result) => {
        if (result.data == true) {
          setProgressStatus("success");
          setPageLoadOnDelete(true);
          setTimeout(function () {
            setUploadModalValue(false);
          }, 1500);
          getAllDocumentFunc();
        } else {
          setProgressStatus("danger");
          setTimeout(function () {}, 1000);
        }
      });
    }
    setContentLoader(true);
    setPageLoad(true);
  };

  const saveOwnersUploadedFile = async () => {
    const indicationRequestDocObj = [
      {
        id: 0,
        documentId: 75,
        formFile: fileUploadedVO,
        indicationRequestId: indicationId,
        documentName: fileUploadedVO?.name,
        createdBy: "",
        modifiedBy: "",
      },
    ];
    let formData: any = new FormData();
    let index = 0;
    for (let pair of indicationRequestDocObj) {
      formData.append(
        "indicationRequestDocuments[" + index + "].formFile",
        pair.formFile
      );
      formData.append("indicationRequestDocuments[" + index + "].id", pair.id);
      formData.append(
        "indicationRequestDocuments[" + index + "].documentId",
        pair.documentId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].indicationRequestId",
        pair.indicationRequestId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].documentName",
        pair.documentName
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].createdBy",
        pair.createdBy
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].modifiedBy",
        pair.modifiedBy
      );
    }
    if (fileUploadedVO) {
      await fileApiAxios(
        `IndicationRequest/UploadFilesFromNegotiation?indicationId=${indicationId}`,
        formData
      ).then((result) => {
        if (result.data == true) {
          setProgressStatus("success");
          setPageLoadOnDelete(true);
          setTimeout(function () {
            setUploadModalValue(false);
            setUploadModalForVODoc(false);
          }, 1500);
          getAllDocumentFunc();
        } else {
          setProgressStatus("danger");
          setTimeout(function () {}, 1000);
          getAllDocumentFunc();
        }
      });
    }
    setContentLoader(true);
    setPageLoad(true);
  };

  const deleteFilesHandler = async (id: any, fileName: any) => {
    setIsConfrmModal(true);
    setFileNameForDelete(fileName);
    setFileIdForDelete(id);
  };
  const deleteFun = async (response: string) => {
    if (response == "Yes") {
      var res = await deleteAPI(
        `IndicationRequest/DeleteIndicationRequestDocument/` +
          fileIdForDelete +
          `?fileName=${fileNameForDelete}`
      );
      if (res === true) {
        notify("File Deleted Successfully", true);
      } else {
        notify("File Deletion Failed", false);
      }
      setIsConfrmModal(false);
      setPageLoadOnDelete(true);
      setContentLoader(true);
      setPageLoad(true);
      getAllDocumentFunc();
    } else {
      setIsConfrmModal(false);
    }
  };
  const getUserRoleAsync = async () => {
    setIsWriteAccess(accessStatus);
    const userRole: any = await getUserRole();
    if (userRole === "VesselOwner" || userRole === "OwnerRepresentative") {
      setAccessRole(true);
    }
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  useEffect(() => {
    getUserRoleAsync();
  }, []);

  useEffect(() => {
    saveUploadedFile();
  }, [fileUploaded]);

  useEffect(() => {
    saveOwnersUploadedFile();
  }, [fileUploadedVO]);
  return (
    <div>
      <div className="col-md-12">
        <div className="uploaded-documents">
          <div className="header-div">
            <div className="left-content">
              <span className="headingIcon">
                <McIcon icon="file"></McIcon>
              </span>
              <span className="icon-text">
                Documents uploaded by Vessel Owner
              </span>
            </div>
            {accessRole && isWriteAccess && (
              <div className="right-content">
                <McButton
                  fit="small"
                  icon="tray-arrow-up"
                  data-testid="upload-document"
                  onClick={() => handleUploadPopUp(requestedDocuments.length)}
                  // disabled={requestedDocuments.length === 0}
                >
                  Upload Document
                </McButton>
              </div>
            )}
          </div>
          <div className="body-div">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    {!contentLoader && uploadedDocuments?.length == 0 && (
                      <div className="no-documents" data-testid="no-files">
                        <span>No Files Found</span>
                      </div>
                    )}
                  </div>
                  {!contentLoader &&
                    !pageLoad &&
                    uploadedDocuments?.map((data: any) => (
                      <div className="col-md-4">
                        <div className="uploaded-doc-div">
                          {accessRole && isWriteAccess && (
                            <div
                              className="delete-icon"
                              onClick={() => {
                                deleteFilesHandler(
                                  data?.id,
                                  data?.uploadedFileName
                                );
                              }}
                            >
                              <McIcon
                                icon="trash"
                                color="#c00"
                                data-testid="delete-button"
                                onClick={() => {
                                  deleteFilesHandler(
                                    data?.id,
                                    data?.uploadedFileName
                                  );
                                }}
                              ></McIcon>
                            </div>
                          )}
                          <div
                            className="download-icon"
                            onClick={() => {
                              DownloadUploadedDocument(
                                data?.id,
                                data?.uploadedFileName
                              );
                            }}
                          >
                            <McIcon icon="tray-arrow-down"></McIcon>
                          </div>
                          <p className="file-name" data-testid="file-name">
                            <IconPdfDL
                              nameOfDoc={
                                data?.uploadedFileName.split(".").slice(-1)[0]
                              }
                            ></IconPdfDL>{" "}
                            {data?.uploadedFileName
                              .split(".")
                              .slice(0, -1)
                              .join(".")}
                          </p>
                          <div
                            className="uploaded-by"
                            data-testid="uploaded-by"
                          >
                            Uploaded By: {data?.uploadedBy}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {accessRole && isWriteAccess && (
        <>
          <UploadDocumentLibraryVO
            onOpen={uploadModalForVODoc}
            closeUpload={closingVOUpload}
            fileUpload={receiveUploadedFileFromVO}
            progressStatusRes={progressStatus}
            documentList={requestedDocuments}
          />
          <UploadFiles
            onOpen={uploadModalValue}
            closeUpload={closingUpload}
            fileUpload={receiveUploadedFile}
            availableDocumentList={requestedDocuments}
            progressStatusRes={progressStatus}
          />
          <UploadDocConfirmationModal
            onOpen={uploadConfirmModalValue}
            closeConfirmation={onConfirmationModalClose}
          />
        </>
      )}
      <ConfirmationModal
        onModalOpen={isConfrmModal}
        onModalClose={deleteFun}
        modalTxt={AppString.deleteModalTxt}
        width={"400px"}
        height={"130px"}
      />
    </div>
  );
};

export default UploadedDocumentSection;
