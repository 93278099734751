// React
import React, { useState, useEffect } from "react";

// Service
import { VesselDetailsService } from "../../services/VesselDetailsService";

// Model
import { VslDetailsModel, Vesseldetails } from "./model";
import { McCCard } from "@maersk-global/mds-react-wrapper";
interface IPROPS {
  selectedRow?: any;
  data?: any;
}
const VesselDetailsTab = ({ selectedRow, data }: IPROPS) => {
  const [vsldetails, setVslDetailsData] = React.useState<VslDetailsModel>();
  const [vsldetailsNestedData, setVslDetailsNestedData] =
    React.useState<Vesseldetails>();

  const fetchVesselDetails = async (data: any) => {
    setVslDetailsData(data);
    setVslDetailsNestedData(data?.vesselDetail);
  };
  useEffect(() => {
    fetchVesselDetails(data);
  }, [data]);

  return (
    <div className="vsl-details-overview">
      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header">Vessel name</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-vessel-name"
          >
            {vsldetails?.vesselName}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">IMO No.</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-imo-no"
          >
            {vsldetails?.imoNumber}
          </div>
        </div>
        <div className="col ">
          <label className="vsl-details-header">Managing Owner</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-managing-owner"
          >
            {vsldetails?.managingOwner}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Operator</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-operator"
          >
            {vsldetails?.operator}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header ">Trade</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-trade"
          >
            {vsldetails?.trade}
          </div>
        </div>
      </div>
      <br />
      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header">Flag</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-flag"
          >
            {vsldetailsNestedData?.flag}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">TEU</label>
          <div className="vsl-details-value font-bold" data-testid="vessel-teu">
            {vsldetailsNestedData?.teu}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">TEU 14 Tons</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-teu-14-tons"
          >
            {vsldetailsNestedData?.teU14}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">DWT</label>
          <div className="vsl-details-value font-bold" data-testid="vessel-dwt">
            {vsldetailsNestedData?.dwtTonnage}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">LOA</label>
          <div className="vsl-details-value font-bold" data-testid="vessel-loa">
            {vsldetailsNestedData?.loa}
          </div>
        </div>
      </div>
      <br />
      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header">Beam</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-beam"
          >
            {vsldetailsNestedData?.beam}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Draft</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-draft"
          >
            {vsldetailsNestedData?.draft}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Gross Tonnage</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-gross-tonnage"
          >
            {vsldetailsNestedData?.grossTonnage}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Speed/Con</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-speed-con"
          >
            {vsldetailsNestedData?.speed}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Net Tonnage</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-net-tonnage"
          >
            {vsldetailsNestedData?.netTonnage}
          </div>
        </div>
      </div>

      <br />

      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header">Gear</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-gear"
          >
            {vsldetailsNestedData?.gear}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Reefer Plugs</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-reefer-plugs"
          >
            {vsldetailsNestedData?.reeferPlugs}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Ice Class</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-ice-class"
          >
            {vsldetailsNestedData?.iceClass}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Length Overall</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-length-overall"
          >
            {vsldetailsNestedData?.lengthOverall}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Speed</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-speed"
          >
            {vsldetailsNestedData?.speed}
          </div>
        </div>
      </div>

      <br />

      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header ">
            Consumption at max speed
          </label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-consumption"
          >
            {vsldetailsNestedData?.consumptionAtMaxSpeed}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Shaft Alternate</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-shaft-alternate"
          >
            {vsldetailsNestedData?.shaftAlternator}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Bow thrust</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-bow-thrust"
          >
            {vsldetailsNestedData?.bowThrust}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Gear Profile</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-gear-profile"
          >
            {vsldetailsNestedData?.gearProfile}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Stern Thrust</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-stern-thrust"
          >
            {vsldetailsNestedData?.sternThrust}
          </div>
        </div>
      </div>
      <div className="div-liner"></div>
      <br />

      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header">Owner ship status</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-owner-ship-status"
          >
            {vsldetailsNestedData?.ownershipStatus}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Owner Country</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-owner-country"
          >
            {vsldetailsNestedData?.ownerCountry}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Technical Manager</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-technical-manager"
          >
            {vsldetailsNestedData?.techincalManager}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Design</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-design"
          >
            {vsldetailsNestedData?.design}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Order Book</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-order-book"
          >
            {vsldetailsNestedData?.orderBook}
          </div>
        </div>
      </div>
      <br />

      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header">Year Built</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-year-built"
          >
            {vsldetailsNestedData?.yob}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Build Shipyard</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-build-shipyard"
          >
            {vsldetailsNestedData?.buildShipyward}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Build Ship Country</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-build-ship-country"
          >
            {vsldetailsNestedData?.buildShipCountry}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">Hull Number</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-hull-number"
          >
            {vsldetailsNestedData?.hullNumber}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header">S AND P Status</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-snp-status"
          >
            {vsldetailsNestedData?.sAndPStatus == "Sold" ||
            vsldetailsNestedData?.sAndPStatus == "sold"
              ? "-"
              : vsldetailsNestedData?.sAndPStatus}
          </div>
        </div>
      </div>
      <br />

      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header warm-grey">Segment</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-segment"
          >
            {vsldetailsNestedData?.segment}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header warm-grey">Planning Group</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-planning-group"
          >
            {vsldetailsNestedData?.planningGroup}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header warm-grey">
            Main Engine Type
          </label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-main-engine-type"
          >
            {vsldetailsNestedData?.mainEngineType}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header warm-grey vsl-details-currency">
            Power(MCR)
          </label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-power"
          >
            {vsldetailsNestedData?.power}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header warm-grey vsl-details-currency">
            Generator Sets
          </label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-generator-sets"
          >
            {vsldetailsNestedData?.generatorSet}
          </div>
        </div>
      </div>
      <br />

      <div className="row vsl-details-data">
        <div className="col">
          <label className="vsl-details-header warm-grey">Electric Power</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-electric-power"
          >
            {vsldetailsNestedData?.electicPower}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header warm-grey">
            Main Engine Fuel Type
          </label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-main-engine-fuel-type"
          >
            {vsldetailsNestedData?.mainEngineFuelType}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header warm-grey">
            Alternative Fuel Type
          </label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-alternative fuel-type"
          >
            {vsldetailsNestedData?.alternativeFuelType}
          </div>
        </div>
        <div className="col">
          <label className="vsl-details-header warm-grey vsl-details-currency">
            Scrubber Fitted
          </label>
          <div
            className="vsl-details-value font-bold"
            data-testid="vessel-scrubber-fitted"
          >
            {vsldetailsNestedData?.scrubberFitted}
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-sm-4 col-md-6">
              <label className="vsl-details-header warm-grey vsl-details-currency">
                AMP
              </label>
              <div
                className="vsl-details-value font-bold"
                data-testid="vessel-amp"
              >
                {vsldetailsNestedData?.amp}
              </div>
            </div>
            <div className="col-sm-4 col-md-6">
              <label className="vsl-details-header warm-grey vsl-details-currency">
                CII
              </label>
              <div
                className="vsl-details-value font-bold"
                data-testid="vessel-cii"
              >
                {vsldetailsNestedData?.cii}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </McCCard>
  );
};

export default VesselDetailsTab;
