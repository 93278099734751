// React
import { useState, useEffect } from "react";
import "./RiderClausePage.scss";
import { getToken } from "../../../../utils/token";
import { protectedResources } from "../../../../authConfig";
import jwt_decode from "jwt-decode";
import { getUserRole } from "../../../../utils/token";
import RiderClause from "./RiderClause";
import { McCheckbox, McIcon } from "@maersk-global/mds-react-wrapper";
import EditNewRiderClause from "./EditNewRiderClause";
import DeleteModal from "./DeleteModal";
interface IPROPS {
  indicationId?: any;
  arrData?: any;
  onAPiLoad?: any;
  scrollId?: any;
  checkNegotiation?: any;
  isWriteAccess?: any;
  isEditing?: any;
  mainInfoData?: any;
  fromIssueRecap?: any;
  isPrefilledCondition?:any;
  extendedContractIDBCP?:any;
}

const RiderClauseEditor = ({
  indicationId,
  arrData,
  onAPiLoad,
  scrollId,
  checkNegotiation,
  isWriteAccess,
  isEditing,
  mainInfoData,
  fromIssueRecap,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const [userRole, setUserRole] = useState();
  const [isEditnewClause, setIsEditnewClause] = useState(false);
  const [isDeleteClause, setIsDeleteClause] = useState(false);
  const [rcSectionData, setRcSectionData] = useState<any>([]);
  const scrollTo = (id: any) => {
    if (id !== 0) {
      const scroll = document.getElementById(`scroll${id}`);
      scroll?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const onRiderClauseAPiLoad = () => {
    onAPiLoad();
  };

  const fetchToken = async () => {
    // const accessToken = await getToken(
    //   protectedResources.apiTodoList.scopes.read
    // );
    // let decoded: any = [];
    // decoded = jwt_decode(accessToken);
    // setUserRole(decoded?.roles[0]);
    const role = await getUserRole();
    setUserRole(role);
  };
  useEffect(() => {
    scrollTo(scrollId);
  }, [scrollId]);
  useEffect(()=>{
    setRcSectionData(arrData);
  }, [arrData])
  useEffect(() => {
    fetchToken();
  });
  const handleRevertCheck = (e, itemData) => {
      if (rcSectionData.titleId === itemData.titleId) {
        rcSectionData.checked = e;
      }
  }
  const closeNewEdit = (submittype:any) =>{
    setIsEditnewClause(false)
    if(submittype !== 'close'){
      onRiderClauseAPiLoad()
    }
  }
  const editClause = () => {
    setIsEditnewClause(true)
  }
  const deleteCluase = () =>{
    setIsDeleteClause(true)
  }
  const closeDeleteRider = (submitType:any) =>{
    if(submitType === 'Submit'){
      onRiderClauseAPiLoad()
    }
    setIsDeleteClause(false)
  }
  return (
    <div className="riderClauseDiv">
      <h3 className="heading-line" id={`scroll${arrData?.titleId}`}>
        {arrData?.title}  {arrData?.newRiderClauseAddedButNotSubmitted && <div className="editSection"><span onClick={editClause}><McIcon icon="pencil"></McIcon></span><span onClick={deleteCluase}><McIcon icon="trash" color="red"></McIcon></span></div>}
      </h3>
      {isWriteAccess && isEditing && arrData?.newRiderClauseAddedButNotSubmitted && <EditNewRiderClause indicateRequestId={indicationId} onCloseFunc={closeNewEdit} onLoadData={arrData} isModalOpen={isEditnewClause}/> }
      {isWriteAccess && isEditing && arrData?.newRiderClauseAddedButNotSubmitted && <DeleteModal
      onLoadData={arrData}
        isModalOpen={isDeleteClause}
        indicationId={indicationId}
        onCanceled={closeDeleteRider}/> }
      <div className="eachSection">
        <RiderClause
          fromIssueRecap={fromIssueRecap}
          indicationId={indicationId}
          onAPiLoadNew={onRiderClauseAPiLoad}
          cluaseData={arrData}
          userRole={userRole}
          checkNegotiation={checkNegotiation}
          isWriteAccess={isWriteAccess}
          isEditing={isEditing}
          mainInfoData={mainInfoData}
          handleRevertCheck={handleRevertCheck}
          isPrefilledCondition={isPrefilledCondition}
          extendedContractIDBCP={extendedContractIDBCP}
        ></RiderClause>
      </div>
    </div>
  );
};
export default RiderClauseEditor;
