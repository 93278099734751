// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components
import { MdsTable } from "../../components/table/MdsTable";
import {
  CharterDetailsColumns,
  SalesPurchaseColumns,
} from "../../pages/commercial_details_tab/Columns";

// Model
import {
  CommercialDetailsModel,
  CharterMarketDetailsModel,
  SandPDetailsModel,
} from "./model";

// Service
import { CommercialDetailsService } from "../../services/CommercialDetailsService";

// Utils
import { extractDateOnly } from "../../utils/customDateTime";

function CommercialDetails({
  selectedRow,
  data,
}: {
  selectedRow?: any;
  data?: any;
}) {
  const [cmrlDetails, setVslDetailsData] =
    React.useState<CommercialDetailsModel>();

  const [charterMarketDetails, setcharterMarketData] = React.useState<
    CharterMarketDetailsModel[]
  >([]);

  const [salesAndPurchaseDetails, setSalesAndPurchaseData] = React.useState<
    SandPDetailsModel[]
  >([]);

  const [tableData, setDataSource] = useState<CommercialDetailsModel[]>([]);

  useEffect(() => {
    fetchCommercialDetails(data);
  }, [data]);

  const columnSortHandler = (event: CustomEvent): void => {
    event.preventDefault();
  };

  const fetchCommercialDetails = async (data: any) => {
    setcharterMarketData(data?.commercialHistory?.charterDetails);
    setSalesAndPurchaseData(data?.commercialHistory?.salesAndPurchaseDetails);
    setVslDetailsData(data);
  };

  return (
    <div className="vsl-details-overview">
      <div className="row vsl-details-data" style={{ marginRight: -150 }}>
        <div className="col-sm-12 col-md-2">
          <label className="vsl-details-header">Vessel name</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="commercial-vessel-name"
          >
            {cmrlDetails?.vesselName}
          </div>
        </div>
        <div className="col-sm-12 col-md-2">
          <label className="vsl-details-header">IMO No.</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="commercial-imo-no"
          >
            {cmrlDetails?.imoNumber}
          </div>
        </div>
        <div className="col-sm-12 col-md-2 ">
          <label className="vsl-details-header">Managing Owner</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="commercial-managing-owner"
          >
            {cmrlDetails?.managingOwner}
          </div>
        </div>
        <div className="col-sm-12 col-md-2">
          <label className="vsl-details-header">Operator</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="commercial-operator"
          >
            {cmrlDetails?.operator}
          </div>
        </div>
        <div className="col-sm-12 col-md-2">
          <label className="vsl-details-header ">Trade</label>
          <div
            className="vsl-details-value font-bold"
            data-testid="commercial-trade"
          >
            {cmrlDetails?.trade}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="charter-details-heading">Charter Details</div>
      <br />
      <div className="row" style={{ marginRight: 35 }}>
        <MdsTable
          data={charterMarketDetails}
          columns={CharterDetailsColumns}
          onSorting={columnSortHandler}
          // selectedIndex={[0]}
        />
      </div>
      <br />
      <br />
      <div className="sales-purchase-details-heading">
        Sales and Purchase Details
      </div>
      <br />
      <div className="row" style={{ marginRight: 35 }}>
        <MdsTable
          data={salesAndPurchaseDetails}
          columns={SalesPurchaseColumns}
          // selectedIndex={[0]}
        />
      </div>
    </div>
  );
}

export default CommercialDetails;
