import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { postAPI } from "../../../services/CepApiServices";
import DataTable from "react-data-table-component";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import columns from "./CharterRequestTableColumns";

import "./CharterRequestTable.scss";
interface IPROPS {
  pageData?:object
}
const CharterRequestTable = ({pageData}:IPROPS) => {
  const history = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  
  const onLoadDataFetch = async (
    payload: any
  ) => {
    setIsTableLoader(true)
    const getCharterRequest = await postAPI(
      `CharterRequest/Get`,
      payload
    );
    setTableData(getCharterRequest);
    setIsTableLoader(false)
  };
 
  const tableRowClick = (row:any) =>{
    history(`/CharterDetails?id=${row.id}`)
  }

  useEffect(() => {
    onLoadDataFetch(pageData);
  }, [pageData]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };

  return (
    <div className="ir-table-main table-margin1 bg-white charterRequestTable">
      <DataTable
        columns={columns}
        data={tableData}
        progressPending={isTableLoader}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        onRowClicked={tableRowClick}
        progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
      />
    </div>
  );
};
export default CharterRequestTable;
