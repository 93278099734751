import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { checkIfUserLoggedIn } from "./components/Protected/AuthHandler";

// Pages
import { Home } from "./pages/Home";
import VesselSupplyDataList from "./pages/vessel_supply_data_list/VesselSupplyDataList";
import VesselDetails from "./pages/vesseldetails/VesselDetails";
import CommercialDetails from "./pages/commercial_details_tab/CommercialDetails";
import ForgeRockLoginPage from "./pages/forge-rock/ForgeRockLoginPage";
import { NavigationBar } from "../src/components/navigationBar/NavigationBar";
import { SideMenu } from "./components/side-menu/SideMenu";
import CharterRequest from "./CharterRequest/CharterRequest";
import DetailsTab from "./CharterRequest/RequestPages/DetailsTab/DetailsTab";
import VesselCompare from "./CharterRequest/RequestPages/VesselCompare/VesselCompare";
import IndicationInvite from "./CharterRequest/RequestPages/IndicationInvite/IndicationInvite";
import AccessDenied from "./components/AccessDenied/AccessDenied";
// Styles
import "./App.scss";
import "./CommonTable.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentVerificationPage from "./DocumentVerification/DocumentVerificationPages/DocumentVerificationPage";
import DocumentVerificationHome from "./DocumentVerification/DocumentVerificationHome";
import IndicationRequestDetails from "./IndicationRequest/IndicationRequestPages/Details/IndicationRequestDetails";
import IndicationRequest from "./IndicationRequest/IndicationRequest";
import IndicationRequestVO from "./IndicationRequest/IndicationRequestVO";
import IndicationRequestVODetails from "./IndicationRequest/IndicationRequestVOPages/IndicationDetailsVOPage/IndicationRequestVODetails";

import NegotiationList from "./Negotiation/NegotiationList";
import IndexHomePage from "./Negotiation/NegotiationOfferPages/BaseCharterParty/IndexHomePage";

import DocumentLibraryCM from "./DocumentLibraryCM/DocumentLibraryCM";
import DocumentLibraryVO from "./DocumentLibraryVO/DocumentLibraryVO";
import Dashboard from "./Dashboard/Dashboard";
import InitiateOfferDetails from "./Negotiation/NegotiationOfferPages/OfferDetails/InitiateOfferDetails";
import IndicationDocumentsDownload from "./IndicationDocumentsDownload/IndicationDocumentsDownload";
import { getUserRole } from "./utils/token";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = checkIfUserLoggedIn();
  if (!isLoggedIn) {
    localStorage.setItem("redirectUri", window.location.href);
    //localStorage.setItem("redirectUri", process.env.REACT_APP_REDIRECT_URI as string);
    return <Navigate to={"/"} />;
  }
  return children;
};

const Pages = () => {
  return (
    <>
      <Routes>
        <Route
          path="/vessel-supply-datalist"
          element={<VesselSupplyDataList />}
        />
        <Route path="/vessel-details" element={<VesselDetails />} />
        <Route path="/commercial-details" element={<CommercialDetails />} />
        <Route path="/" element={<Home />} />
        <Route path="/CharterDetails" element={<DetailsTab />} />
        <Route path="/CharterRequest" element={<CharterRequest />} />
        <Route path="/IndicationRequestPage" element={<IndicationInvite />} />
        <Route path="/AccessDenied" element={<AccessDenied />} />
        <Route
          path="/DocumentVerification"
          element={<DocumentVerificationHome />}
        />
        <Route
          path="/DocumentVerificationPage"
          element={<DocumentVerificationPage />}
        />
        <Route
          path="/InitiateOfferDetails"
          element={<InitiateOfferDetails />}
        />
        <Route
          path="/DocumentVerification/:id"
          element={<DocumentVerificationPage />}
        />
        <Route path="/Negotiation" element={<NegotiationList />} />
        <Route path="/IndexPage" element={<IndexHomePage />} />
        <Route path="/VesselCompare" element={<VesselCompare />} />
        <Route path="/IndicationRequest" element={<IndicationRequest />} />
        <Route
          path="/GetIndicationRequestDataAsync"
          element={<IndicationRequestDetails />}
        />
        <Route path="/IndicationRequestVO" element={<IndicationRequestVO />} />
        <Route
          path="/GetIndicationRequestDataAsyncVO"
          element={<IndicationRequestVODetails />}
        />
        <Route path="/DocumentLibrary" element={<DocumentLibraryCM />} />
        <Route path="/DocumentLibraryVO" element={<DocumentLibraryVO />} />
        <Route
          path="/IndicationRequestDocumentsDownloads"
          element={<IndicationDocumentsDownload />}
        />
        <Route path="/Dashboard" element={<Dashboard />} />
      </Routes>
      <ToastContainer
        autoClose={3000}
        pauseOnHover={false}
        hideProgressBar={true}
        limit={3}
      />
    </>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: { instance: any }) => {
  const [isSideNav, setIsSideNav] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  const sideNavClicck = (val: any) => {
    setIsSideNav(!val);
  };
  if (
    window.location.href.includes(process.env.REACT_APP_HOST_URL as string) ||
    window.location.href.includes(
      process.env.REACT_APP_MAERSK_HOST as string
    ) ||
    window.location.href.includes(process.env.REACT_APP_HOST_URL as string)
  ) {
    document.title = "Availability List";
    return (
      <Router>
        <MsalProvider instance={instance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <div className="side">
              <SideMenu onSideClick={sideNavClicck}></SideMenu>
            </div>
            <div className={`mainPages ${isSideNav && "Stechactive"}`}>
              <NavigationBar sidebarActive={isSideNav}></NavigationBar>
              <Pages />
            </div>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </Router>
    );
  } else {
    document.title = "Chartering Execution Platform";
    const isLoggedIn = checkIfUserLoggedIn();
    return (
      <Router>
        <Routes>
          <Route path="/" element={<ForgeRockLoginPage />} />
          <Route
            path="/Dashboard"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <Dashboard />
                    {/* <AccessDenied /> */}
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/CharterRequest"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <CharterRequest />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/AccessDenied"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu
                      onSideClick={sideNavClicck}
                      accessDenied={true}
                    ></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <AccessDenied />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/CharterDetails"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <DetailsTab />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/IndicationRequest"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <IndicationRequest />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/InitiateOfferDetails"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <InitiateOfferDetails />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/GetIndicationRequestDataAsync"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <IndicationRequestDetails />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/IndicationRequestVO"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <IndicationRequestVO />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/IndicationRequestPage"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <IndicationInvite />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/GetIndicationRequestDataAsyncVO"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <IndicationRequestVODetails />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/DocumentVerification"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <DocumentVerificationHome />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/DocumentVerificationPage"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <DocumentVerificationPage />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/DocumentVerification/:id"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <DocumentVerificationPage />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/Negotiation"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <NegotiationList />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/DocumentLibrary"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <DocumentLibraryCM />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/DocumentLibraryVO"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <DocumentLibraryVO />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/IndicationRequestDocumentsDownloads"
            element={
              <>
                <ProtectedRoute>
                  <div className="side">
                    <SideMenu onSideClick={sideNavClicck}></SideMenu>
                  </div>
                  <div className={`mainPages ${isSideNav && "Stechactive"}`}>
                    <NavigationBar sidebarActive={isSideNav}></NavigationBar>
                    <IndicationDocumentsDownload />
                  </div>
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <ToastContainer
          autoClose={3000}
          pauseOnHover={false}
          hideProgressBar={true}
          limit={3}
        />
      </Router>
    );
  }
};
export default App;
