import React, { useEffect } from "react";
import { useState } from "react";
import {
  McModal,
  McSelect,
  McIcon,
} from "@maersk-global/mds-react-wrapper";
import { FileUploader } from "react-drag-drop-files";

interface IPROPS {
  onOpen?: boolean;
  closeUpload?: any;
  fileUpload?: any;
  availableDocumentList?: any;
  progressStatusRes?: any;
}

const MultipleFileUpload = ({ onOpen, closeUpload, fileUpload, availableDocumentList, progressStatusRes }: IPROPS) => {
  const fileTypes = ["xls", "xlsx","pdf","txt","doc","docx"];
  const [loaderDiv, setLoaderDiv] = useState(false);
  const [modalHieght, setModalHieght] = useState("55vh");
  const [progressStatus, setProgressStatus] = useState("success");
  const [fileValue, setFile] = useState<any>();
  const [source, setSource] = useState<any>();
  const [selectedDocument, setSelectedDocument] = useState<any>({
    id: "",
    documentId: "",
    documentName: "",
    displayName: "",
  });
  const [isUploadSection, setIsUploadSection] = useState(true);
  const [progress, setProgress] = useState(0);
  const [fileUploaded, setFileValue] = useState<any>();
  const uploadRef = React.useRef();
  const statusRef:any = React.useRef();
  const loadTotalRef:any = React.useRef();
  const progressRef:any = React.useRef();
  const [sourceType, setSourceType] = useState([]);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [disableUpload,setDisableUpload] = useState(false);

  function formatBytes(bytes: any) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;

    while (bytes >= 1024 && i < units.length - 1) {
      bytes /= 1024;
      i++;
    }

    return bytes.toFixed(2) + units[i];
  }
  const handleChange = (file: any) => {
    setFileTypeError(false);
    const fileName = file.name.replace(/\s+/g, '_');
    const fileExtension = file.name.split('.').slice(-1)[0];
    if(selectedDocument?.fullName.split('.').slice(-1)[0] === fileExtension){
      const modifiedFile:any = new File([file], fileName, {type: file.type});
      setFile(modifiedFile);
      setIsUploadSection(false);
      setModalHieght("55vh");
    } else{
      return setFileTypeError(true);
    }

    var xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", ProgressHandler, false);
  };
  const handleSubmit = async () => {
    setFileValue(fileValue);
    if(fileUpload){
      fileUpload(fileValue,selectedDocument);
    }
  };
  const ProgressHandler = (e) => {
    loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
    var percent = (e.loaded / e.total) * 100;
    progressRef.current.value = Math.round(percent);
    statusRef.current.innerHTML = Math.round(percent) + "% uploaded...";
  };
  const onCancel = (act: any, data: any) => {
    setSource("");
    closeUpload(act, data);
    setLoaderDiv(false);
    setProgressStatus("info");
    setIsUploadSection(true);
    setProgress(0);
    setModalHieght("55vh");
    setFileTypeError(false);
    setDisableUpload(false);
  };

  const sourceSelected = (e: any) => {
    setSelectedDocument({
      id: e.detail?.selectedOptions[0].id,
      documentId: e.detail?.selectedOptions[0].value,
      documentName: e.detail?.selectedOptions[0].label,
      fullName: e.detail?.selectedOptions[0].fullName,
    });
    setSource(e.detail?.selectedOptions[0].label);
    setFileTypeError(false);
  };
 
  const onReload = () => {
    setIsUploadSection(true);
    setProgressStatus("info");
    setModalHieght("48vh");
    setProgress(0);
  };
  const fetchAllDocumentTypes = async() =>{
    if(onOpen==false){
      setSourceType([]);
      setIsUploadSection(true);
      setSource("")
      setFileTypeError(false)
    }else{
      setSourceType(availableDocumentList.map((item:any) => ({
        id: item.id,
        label: item.documentName.split('.').slice(0, -1).join('.'), 
        fullName: item.documentName,
        value: item.documentId,
      })));
    }
  }
  useEffect(() => {
      handleSubmit();
  }, [fileValue]);
  useEffect(()=>{
    fetchAllDocumentTypes();
    setSelectedDocument({
      id: "",
      documentId: "",
      documentName: "",
      displayName: "",
    })
  },[onOpen])
  return (
    <McModal
      open={onOpen}
      heading="Upload Documents"
      data-testid="upload-document-modal"
      width="560px"
      backdropcloseactiondisabled
      height={modalHieght}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="modal-section">
            <div className="subColoum">
              <div className="header-section">
                <div
                  className="closeIcon"
                  onClick={() => onCancel({ type: "close" }, null)}
                  data-testid="close-icon"
                >
                  <McIcon icon="times"></McIcon>
                </div>
                <div className="row">
                  <div className="col-md-12 row-divider">
                    <div className="input-section">
                      <McSelect
                        name="indicationId"
                        fit="medium"
                        label=""
                        options={sourceType}
                        change={sourceSelected}
                        value={source}
                        required
                        data-testid='source-select'
                        ariaPlaceholder="Select a File"
                      ></McSelect>
                    </div>
                  </div>
                </div>
                {disableUpload == false && <div className="row">
                  <div className="col-md-12">
                    {selectedDocument.documentId && isUploadSection && availableDocumentList.length > 0 && (
                      <div className="uploader-div indication-request-cm-uploader" data-testid='fileuploader'>
                        {fileTypeError && (
                            <div className="error-message">
                                <p>Uploaded a wrong document</p>
                            </div>
                        )}
                        <FileUploader
                          handleChange={handleChange}
                          name="File"
                          types={fileTypes}
                        >
                          <div className="dropZone" data-testid="file-input">
                            <div className="drop-text">
                              <p>
                                <McIcon icon="folder-arrow-up"></McIcon>
                                <span>Select file </span> or Drag here
                              </p>
                            </div>
                          </div>
                        </FileUploader>
                        
                      </div>
                    )}
                    {availableDocumentList?.length === 0 && isUploadSection && (
                        <div className="uploader-div" data-testid='fileuploader'>
                            <div className="dropZone" data-testid="file-input">
                                <div className="drop-text">
                                <p>
                                    <McIcon icon="folder-arrow-up"></McIcon>
                                    <p>No files to upload </p> 
                                </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {(!selectedDocument?.documentId || selectedDocument === undefined) && availableDocumentList?.length !== 0 && isUploadSection && (
                        <div className="uploader-div" data-testid='fileuploader'>
                                <div className="dropZone" data-testid="file-input">
                                    <div className="drop-text">
                                    <p>
                                        <McIcon icon="folder-arrow-up"></McIcon>
                                        <p>Select Document to upload </p> 
                                    </p>
                                    </div>
                                </div>
                        </div>
                    )}
                    {!isUploadSection && (
                      <div className="attach-div">
                        <p className="file-name" data-testid="file-name">
                          {fileValue?.name}
                        </p>
                        <p className="file-size" data-testid="file-size">
                          File size :{" "}
                          {fileValue ? formatBytes(fileValue?.size) : "N/A"}{" "}
                        </p>
                        <div className="progress-section">
                          {/* {progressStatus == "info" && (
                            <span
                              className="status info"
                              data-testid="progress-status"
                            >
                              {progress < 100 && progress}{" "}
                              {progress < 100 ? (
                                " % remaining"
                              ) : (
                                <span className="loading-text">
                                  Record Is being validated
                                </span>
                              )}
                            </span>
                          )} */}
                          {progressStatusRes == "success" && (
                            <span
                              className="status success"
                              data-testid="progress-status"
                            >
                              <span className="icon">
                                <McIcon icon="check-circle"></McIcon>
                              </span>{" "}
                              Upload success
                            </span>
                          )}
                          {progressStatusRes == "danger" && (
                            <span
                              className="status danger"
                              data-testid="progress-status"
                            >
                              <span className="icon">
                                {" "}
                                <McIcon icon="exclamation-circle"></McIcon>
                              </span>{" "}
                              Upload failed
                              <span
                                className="reload"
                                data-testid="reload-button"
                                onClick={onReload}
                              >
                                <McIcon icon="arrow-clockwise"></McIcon>
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </McModal>
  );
};

export default MultipleFileUpload;
