import { useState, useEffect } from "react";
import { McModal, McButton } from "@maersk-global/mds-react-wrapper";
import { getAPI } from "../../services/ApiServices";
import moment from "moment";
import "./ChangeLogModal.scss";
import ChangeLogDetail from "./ChangeLogDetail";

interface IPROPS {
  onChangeLogOpen?: boolean;
  logId?: any;
  closeChangeLogFunc?: any;
  onChangeLogOpenSnP?: boolean;
  logIdSnP?: any;
  onClose?: any;
}
const ChangeLogModal = ({
  onChangeLogOpen,
  logId,
  closeChangeLogFunc,
  onChangeLogOpenSnP,
  logIdSnP,
  onClose,
}: IPROPS) => {
  const [changeLogResp, setChangeLogResp] = useState<any>([]);
  const [changeLogRespSnP, setChangeLogRespSnP] = useState<any>([]);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [isChangeSnPModalOpen, setIsSnPChangeModalOpen] = useState(false);

  const clickOnClose = () => {
    closeChangeLogFunc();
    setIsChangeModalOpen(false);
  };
  const onSnPClose = () => {
    onClose();
    setIsSnPChangeModalOpen(false);
  };
  const changeLogDetailsFunc = async () => {
    const changeLogResponse = await getAPI("Audit/GetCharterAuditLog/" + logId);
    if (changeLogResponse) {
      setChangeLogResp(changeLogResponse);
      setIsChangeModalOpen(true);
    }
  };

  const changeLogDetailsFuncSnP = async () => {
    const changeLogResponse = await getAPI("Audit/GetSnPAuditLog/" + logIdSnP);
    if (changeLogResponse) {
      setChangeLogRespSnP(changeLogResponse);
      setIsSnPChangeModalOpen(true);
    }
  };

  useEffect(() => {
    if (onChangeLogOpen) changeLogDetailsFunc();
  }, [logId]);

  useEffect(() => {
    if (onChangeLogOpenSnP) changeLogDetailsFuncSnP();
  }, [logIdSnP]);

  return (
    <div>
      <McModal
        height="650px"
        className="header-section changeModalsection"
        open={isChangeModalOpen}
        heading="Initial Value"
        backdropcloseactiondisabled
      >
        <div className="ininital-data">
          <div className="row">
            <div className="col-md-12">
              <div className="heading">
                Created by {changeLogResp?.charterInitialData?.createdBy} on{" "}
                {moment(changeLogResp?.charterInitialData?.createdOn).format(
                  "DD/MM/YYYY"
                )}{" "}
                UTC{" "}
              </div>
              <div className="table-div">
                <table className="table">
                  <tr>
                    <th>Charterer</th>
                    <th>Type</th>
                    <th>Currency</th>
                    <th>Delivery</th>
                    <th>Min</th>
                    <th>Max</th>
                    <th>Est.Redelivery</th>
                    <th>Managing owner</th>
                    <th>Rate</th>
                    <th>Purchase Obg.</th>
                    <th>Fixture Option</th>
                    <th>Fixture Option Value</th>
                    <th>Purchase Option</th>
                    <th>Purchase Option Value</th>
                    <th>Purchase Option Period</th>
                    <th>Comments</th>
                  </tr>
                  <tr>
                    <td>{changeLogResp?.charterInitialData?.chartererName}</td>
                    <td>
                      {changeLogResp?.charterInitialData?.charterTypeName}
                    </td>
                    <td>{changeLogResp?.charterInitialData?.currency}</td>
                    <td>
                      {changeLogResp?.charterInitialData?.delivery
                        ? moment(
                            changeLogResp?.charterInitialData?.delivery
                          ).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>
                      {changeLogResp?.charterInitialData?.min
                        ? moment(changeLogResp?.charterInitialData?.min).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </td>
                    <td>
                      {changeLogResp?.charterInitialData?.max
                        ? moment(changeLogResp?.charterInitialData?.max).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </td>
                    <td>{changeLogResp?.charterInitialData?.estRedelivery
                        ? moment(changeLogResp?.charterInitialData?.estRedelivery).format(
                            "DD/MM/YYYY"
                          )
                        : ""}</td>
                    <td>{changeLogResp?.charterInitialData?.managingOwner}</td>
                    <td>{changeLogResp?.charterInitialData?.rateValue}</td>
                    <td>
                      {
                        changeLogResp?.charterInitialData
                          ?.purchaseObligationValue
                      }
                    </td>
                    <td>
                      {
                        changeLogResp?.charterInitialData
                          ?.fixtureOptionTypeValue
                      }
                    </td>
                    <td>
                      {
                        changeLogResp?.charterInitialData
                          ?.fixtureOptionDataValue
                      }{" "}
                      {
                        changeLogResp?.charterInitialData
                          ?.fixtureOptionCurrencyIdValue
                      }
                    </td>
                    <td>
                      {
                        changeLogResp?.charterInitialData
                          ?.purchaseOptionValueData
                      }
                    </td>
                    <td>
                      {
                        changeLogResp?.charterInitialData
                          ?.purchaseOptionDataValue
                      }{" "}
                      {
                        changeLogResp?.charterInitialData
                          ?.purchaseOptionCurrencyIdValue
                      }
                    </td>
                    <td>
                      {
                        changeLogResp?.charterInitialData
                          ?.fixtureOptionPeriodTypeValue
                      }{" "}
                      {
                        changeLogResp?.charterInitialData
                          ?.fixtureOptionPeriodTypeDataValue
                      }
                    </td>
                    <td>{changeLogResp?.charterInitialData?.comment}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="heading">Change Log</div>
        <div className="line"></div>
        <div className="">
          {changeLogResp?.charterUpdatedData?.length == 0 && (
            <div>No changelog details</div>
          )}
          {changeLogResp?.charterUpdatedData?.map((item : any ) => (
            <div>
              <div className="heading">
                Updated by {item.updatedBy} on {item.updatedDate} UTC
              </div>
              <div className="row">
                <div className="col-md-2 val-text">Time</div>
                <div className="col-md-4 val-text">Column</div>
                <div className="col-md-3 val-text">Current Value</div>
                <div className="col-md-3 val-text">Last Value</div>
              </div>
              <ChangeLogDetail data={item.audits} />
              <div className="line"></div>
            </div>
          ))}
        </div>
        <McButton
          // focusendanchor
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
          click={clickOnClose}
          role ='cancel-cp'
        >
          Cancel
        </McButton>
      </McModal>

      <McModal
        height="650px"
        className="main-heading changeModalsection"
        open={isChangeSnPModalOpen}
        heading="Initial Value"
      >
        <div className="ininital-data">
          <div className="row">
            <div className="col-md-12">
              <div className="heading">
                Created by{" "}
                {changeLogRespSnP?.salesAndPurchaseInitialData?.createdBy} on{" "}
                {moment(
                  changeLogRespSnP?.salesAndPurchaseInitialData?.createdOn
                ).format("DD/MM/YYYY")}{" "}
                UTC{" "}
              </div>
              <div className="table-div">
                <table className="table">
                  <tr>
                    <th>Status</th>

                    <th>Date of Status</th>
                    <th>Currency</th>
                    <th>Source</th>
                    <th>Buyer</th>
                    <th>EnBlock</th>
                    <th>Price</th>
                    <th>Price Guidance</th>
                    <th>Comments</th>
                  </tr>
                  <tr>
                    <td>
                      {changeLogRespSnP?.salesAndPurchaseInitialData?.status}
                    </td>
                    <td>
                      {changeLogRespSnP?.salesAndPurchaseInitialData?.date !=
                      null
                        ? moment(
                            changeLogRespSnP?.salesAndPurchaseInitialData?.date
                          ).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>
                      {changeLogRespSnP?.salesAndPurchaseInitialData?.currency}
                    </td>

                    <td>
                      {changeLogRespSnP?.salesAndPurchaseInitialData?.source}
                    </td>
                    <td>
                      {changeLogRespSnP?.salesAndPurchaseInitialData?.buyer}
                    </td>
                    <td>
                    {changeLogRespSnP?.salesAndPurchaseInitialData?.enBlockValue}
                    </td>
                    <td>
                      {
                        changeLogRespSnP?.salesAndPurchaseInitialData
                          ?.priceValue
                      }
                    </td>
                    <td>
                      {
                        changeLogRespSnP?.salesAndPurchaseInitialData
                          ?.priceGuidance
                      }
                    </td>
                    <td>
                      {changeLogRespSnP?.salesAndPurchaseInitialData?.comments}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="heading">Change Log</div>
        <div className="header-line"></div>
        <div className="container">
          {changeLogRespSnP?.salesAndPurchaseUpdatedData?.length == 0 && (
            <div>No changelog details</div>
          )}
          {changeLogRespSnP?.salesAndPurchaseUpdatedData?.map((item : any) => (
            <div>
              <div className="heading">
                Updated by {item.updatedBy} on {item.updatedDate} UTA
              </div>
              <div className="row">
                <div className="col-md-2 val-text">Time</div>
                <div className="col-md-4 val-text">Column</div>
                <div className="col-md-3 val-text">Current Value</div>
                <div className="col-md-3 val-text">Last Value</div>
              </div>
              <ChangeLogDetail data={item.audits} />
              <div className="line"></div>
            </div>
          ))}
        </div>
        <McButton
          // focusendanchor 
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
          click={onSnPClose}
          role="cancel-sp"
        >
          Cancel
        </McButton>
      </McModal>
    </div>
  );
};

export default ChangeLogModal;
