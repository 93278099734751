// React
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment"

//MDS
import "@maersk-global/mds-components-core";
import {
  McSelect,
  McCAccordionItem,
  McCheckboxGroup,
  McCheckbox,
  McButton,
  McInputDate,
  McIcon,
} from "@maersk-global/mds-react-wrapper";

//Service
import { getAPI } from "../../../services/CepApiServices";
import Multiselect from "multiselect-react-dropdown";
//CSS
import "./CharterRequestFilter.scss";
import "../../FilterCss.scss";
import RangeSlider from "../../../components/rangeSlider/rangeSlider";
interface IPROPS {
  closeFilterFunc?: any;
  onDataFunc?: any;
  clearFilter?: any;
}
function CharterRequestFilter({
  closeFilterFunc,
  onDataFunc,
  clearFilter,
}: IPROPS) {
  const [filters, setFilters] = useState<any | { requestStatusItems: any }>({});
  const [statusData, setStatusData] = useState([]);
  const [requestName, setRequestName] = useState([]);
  const [startPort, setStartPort] = useState([]);
  const [toDate, settoDate] = useState({toDate: ""});
  const gearOption: any = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const { register, handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      statusList: "",
      startPort: "",
      requestName: "",
      teu: [],
      gear: [],
      plugs: [],
      fromDate: "",
      toDate: "",
    },
  });
  const onSubmit = (data: any) => {
    onDataFunc(data);
    closeFilterFunc();
  };
  useEffect(() => {
    fetchFiltersForCharterMrkt();
  }, []);

  const fetchFiltersForCharterMrkt = async () => {
    const filtersData = await getAPI(`CharterRequest/CharterFilter`);
    setFilters(filtersData);
    setStatusData(
      filtersData?.requestStatusItems.map((item: any, index: number) => ({
        value: index,
        label: item,
      }))
    );
    setRequestName(filtersData?.charterRequestNamesItems);
    setStartPort(filtersData?.startPortItems);
  };
  const closeFilter = () => {
    closeFilterFunc();
  };
  const clearFunction = () => {
    reset();
    clearFilter();
    closeFilterFunc();
  };

  const minDateChangeHandler = (e) => {
    const newDate: any = moment(e.target.value).format("YYYY/MM/DD")
    settoDate({ toDate: newDate});
    setValue("toDate", "");
  }

  return (
    <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="filterModal" data-testid="charter-request-filter">
        <div className="filter-section">
          <div className="charter-request-details-filter">
            <div className="input-mainSection">
              <div className="header-section">
                <h5 className="header-text">Apply Filter</h5>
                <div
                  className="closeIcon"
                  data-testid="close"
                  onClick={closeFilter}
                >
                  <McIcon icon="times"></McIcon>
                </div>
              </div>
              <div className="filters-subsection">
                <McCAccordionItem
                  title="Status"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <Controller
                    name="statusList"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <McCheckboxGroup
                        name="statusList"
                        legend=""
                        change={(e) => onChange(e)}
                      >
                        {statusData?.map((item: any) => (
                          <McCheckbox
                            name="statusList"
                            value={item.label}
                            label={item.label}
                          ></McCheckbox>
                        ))}
                      </McCheckboxGroup>
                    )}
                  />
                </McCAccordionItem>
              </div>
              <div className="filters-subsection">
                <McCAccordionItem
                  title="Additional Filters"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <div className="input-section">
                    <label>Request Name</label>
                    <Controller
                      name="requestName"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          placeholder="Request Name"
                          options={requestName}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <label>Phase in port</label>
                    <Controller
                      name="startPort"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          placeholder="Phase in port"
                          options={startPort}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <McSelect
                      {...register("gear")}
                      name="gear"
                      ariaPlaceholder="Select"
                      label="Gear"
                      options={gearOption}
                      fit="medium"
                    ></McSelect>
                  </div>
                  {filters?.minimumTEU && (
                    <div className="input-section">
                      <label>TEU</label>
                      <Controller
                        name="teu"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <RangeSlider
                            min={filters?.minimumTEU}
                            max={filters?.maximumTEU}
                            intialMin={filters?.minimumTEU}
                            intialMax={filters?.maximumTEU}
                            onRRChange={onChange}
                          />
                        )}
                      />
                    </div>
                  )}
                  {filters?.maximumReeferPlugs && (
                    <div className="input-section">
                      <label>Reefer Plugs</label>
                      <Controller
                        name="plugs"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <RangeSlider
                            min={filters?.minimumReeferPlugs}
                            max={filters?.maximumReeferPlugs}
                            intialMin={filters?.minimumReeferPlugs}
                            intialMax={filters?.maximumReeferPlugs}
                            onRRChange={onChange}
                          />
                        )}
                      />
                    </div>
                  )}
                </McCAccordionItem>
                <McCAccordionItem
                  title="Date"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <div className="input-section">
                    <McInputDate
                      {...register("fromDate")}
                      name="fromDate"
                      label="From Date"
                      ariaPlaceholder="yyyy-mm-dd"
                      oninput={minDateChangeHandler}
                    ></McInputDate>
                  </div>
                  <div className="input-section">
                    <McInputDate
                      {...register("toDate")}
                      name="toDate"
                      label="To Date"
                      ariaPlaceholder="yyyy-mm-dd"
                      min={toDate.toDate}
                    ></McInputDate>
                  </div>
                </McCAccordionItem>
              </div>
            </div>
            <div className="footer-fixed">
              <div className="button-apply">
                <div className="input-section">
                  <McButton
                    type="submit"
                    label="Apply filter"
                    width="full-width"
                    fit="small"
                  ></McButton>
                </div>
              </div>
              <div className="button-apply">
                <div className="input-section">
                  <button
                    data-testid="clear"
                    className="reset-btn"
                    type="button"
                    onClick={() => {
                      clearFunction();
                    }}
                  >
                    Clear filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CharterRequestFilter;
