import { McButton, McIcon, McTooltip } from "@maersk-global/mds-react-wrapper";
export const StatusList = (
  statusId: any,
  statusName: any,
  comments?: any,
  userType?: any
) => {
  switch (statusId) {
    case 1:
      return <span className="status-sent status-label" data-testid="status-1">{statusName}</span>;
    case 2:
      return <span className="status-sent status-label" data-testid="status-2">{statusName}</span>;
    case 3:
      return <span className="status-cancel status-label" data-testid="status-3">{statusName}</span>;
    case 4:
      if (comments == "") {
        return <span className="status-reject status-label" data-testid="status-4">{statusName}</span>;
      } else {
        return (
          <div>
            <McTooltip position="right-top">
              <span className="status-reject reject-class status-label" data-testid="status-4">
                <span className="status-text">{statusName}</span>
                <span className="status-icon">
                  <McIcon icon="info-circle" color="black" size="20"></McIcon>
                </span>
              </span>
              <span slot="content" className="tooltip-text">
                {comments}
              </span>
            </McTooltip>
          </div>
        );
      }
    case 5:
      return <span className="status-success status-label" data-testid="status-5">{statusName}</span>;
    case 6:
      return <span className="status-accepted status-label" data-testid="status-6">{statusName}</span>;
    case 7:
      return (
        <span
          className={
            userType
              ? "status-accepted status-label"
              : "status-reject status-label"
          }
          data-testid="status-7"
        >
          {statusName}
        </span>
      );
    case 8:
      return <span className="status-sent status-label" data-testid="status-8">{statusName}</span>;
    case 9:
      return (
        <span className="status-negotiation status-label" data-testid="status-9">{statusName}</span>
      );
    case 10:
      return (
        <span className="status-negotiation status-label" data-testid="status-10">{statusName}</span>
      );
    case 11:
      return (
        <span className="status-negotiation status-label" data-testid="status-11">{statusName}</span>
      );
    case 12:
      return (
        <span className="status-negotiation status-label" data-testid="status-12">{statusName}</span>
      );
    case 13:
      return (
        <span className="status-negotiation status-label" data-testid="status-13">{statusName}</span>
      );
    case 14:
      return (
        <span className="status-negotiation status-label" data-testid="status-14">{statusName}</span>
      );
    case 15:
      return (
        <span className="status-negotiation status-label" data-testid="status-15">{statusName}</span>
      );
    case 16:
      return (
        <span className="status-terminated status-label" data-testid="status-16">{statusName}</span>
      );
    case 17:
      return <span className="status-success status-label" data-testid="status-17">{statusName}</span>;
    default:
      return <span className="status-success status-label" data-testid="status-other">{statusName}</span>;
  }
};
