//React
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

//MDS
import {
  McSelect,
  McIcon,
  McInput,
  McButton,
  McModal,
  McCheckbox,
  McCTypeahead,
} from "@maersk-global/mds-react-wrapper";
import { McTextarea } from "@maersk-global/mds-react-wrapper";
import DatePicker from "react-datepicker";

//Components
import { getAPI } from "../../services/ApiServices";

//Config
import { getUID } from "../../utils/token";

// Styles
import "../../pages/charter-market/CharterMarket.scss";
import "../../pages/charter-market-filters/CharterMarketFilters.scss";
import "../add-charter-market/AddCharterMarket.scss";
import moment from "moment";

interface IPROPS {
  onAddSPDataFunc?: (data: any) => void;
  onSPOpen?: boolean;
  onAddSnPClose?: (close: any) => void;
}

function SPModal({ onAddSPDataFunc, onSPOpen, onAddSnPClose }: IPROPS) {
  const UID = getUID();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState = { },
  } = useForm();
  const [vesselIMO, setVesselIMOFilters] = useState<any>([]);
  const [imo, setIMO] = useState();
  const [buildTEUDesign, setBuildTEUDesignFilters] = useState<any>([]);
  const [currency, setCurrencyFilters] = useState([]);
  const [buyer, setBuyerFilters] = useState([]);
  const [sourcesData, setSourcesDataFilters] = useState([]);
  const [statusData, setStatusDataFilters] = useState([]);
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [dateOfStatus, setDateOfStatus] = useState<any>("");
  const [priceField, setPriceDisabled] = useState(false);

  const onSubmit = (data:any) => {
    data["vslImo"] = imo;
    data["dateOfStatus"] = moment(data?.dateOfStatus).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    if (onAddSPDataFunc) {
      onAddSPDataFunc(data);
      setIsDisableBtn(true);
    }
  };
  useEffect(() => {
    if (onSPOpen) {
      setIsDisableBtn(false);
    }
  }, [onSPOpen]);
  const onError = (errors:any, e:any) => console.log(errors, e);

  const fetchSalesPurchseDetailsData = async () => {
    const vesselIMOData = await getAPI(`Filter/GetAllVesselAndImo`);
    if(vesselIMOData){
    setVesselIMOFilters(vesselIMOData);
    }

    const currencyData = await getAPI(`Filter/GetAllCurrency`);
    if(currencyData){
    setCurrencyFilters(currencyData);
    }

    const buyerData = await getAPI(`Charter/GetManagingOwner`);
    if(buyerData){
      setBuyerFilters(buyerData);
    }
    
    const sourcesData = await getAPI(`Filter/GetSNPSources`);
    if(sourcesData){
    setSourcesDataFilters(sourcesData);
    }

    const statusData = await getAPI(`Filter/GetAllSNPStatus`);
    if(statusData){
      setStatusDataFilters(statusData);
    }
  };

  useEffect(() => {
    fetchSalesPurchseDetailsData();
  }, []);

  const onVslIMO = async (val:any): Promise<void> => {
    const buildTEUDesignData = await getAPI(
      `Vessel/GetVesselByImoOrName/` + val
    );
    setIMO(buildTEUDesignData?.imoNumber);
    setBuildTEUDesignFilters(buildTEUDesignData);
  };

  const populateDateOfStatus = (val:any) => {
    var today = new Date();
    // var dd = String(today.getDate()).padStart(2, "0");
    // var mm = String(today.getMonth() + 1).padStart(2, "0");
    // var yyyy = today.getFullYear();

    // let todayDate = dd + "/" + mm + "/" + yyyy;
    setDateOfStatus(today);

    if (val.detail?.selectedOptions[0].label == "Sold") {
      setPriceDisabled(false);
    } else {
      setPriceDisabled(true);
    }
  };

  return (
    <form data-testid="form" onSubmit={handleSubmit(onSubmit, onError)}>
      <McModal
        open={onSPOpen}
        height="auto"
        heading="Add Vessel S & P Details"
        backdropcloseactiondisabled
      >
        <div className="row">
          <div className="col-md-14">
            <div className="modal-section">
              <div className="subColoum">
                <div className="row">
                  <div className="col-md-6 row-divider">
                    <div className="input-section">
                      <Controller
                        name="vslImo"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange } }) => (
                          <McCTypeahead
                            data-testid="vessel-name"
                            ariaPlaceholder="Search vessel or IMO"
                            fit="medium"
                            name="vslImo"
                            label="Vessel Name"
                            icon="magnifying-glass"
                            iconposition="right"
                            required
                            optionselected={(e:any) => {
                              onChange(e?.detail?.option?.value);
                              onVslIMO(e?.detail?.option?.value);
                            }}
                            options={vesselIMO}
                          ></McCTypeahead>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="header-section">
                  <div className="row">
                    <div className="col-md-3">
                      <h5>Vessel Name</h5>
                    </div>
                    <div className="col-md-3">
                      <h5>IMO</h5>
                    </div>
                    <div className="col-md-3">
                      <h5>Build</h5>
                    </div>
                    <div className="col-md-3">
                      <h5>Design</h5>
                    </div>
                  </div>
                </div>
                
                <div className="row margin-bottom">
                  <div className="col-md-3 text-highlight" data-testid="snp-vessel-name">
                    {buildTEUDesign?.vesselName}
                  </div>
                  <div className="col-md-3 text-highlight" data-testid="snp-ino">
                    {buildTEUDesign?.imoNumber}
                  </div>
                  <div className="col-md-3 text-highlight" data-testid="snp-yob">
                    {buildTEUDesign?.yob}
                  </div>
                  <div className="col-md-3 text-highlight" data-testid="snp-design">
                    {buildTEUDesign?.design}
                  </div>
                </div>
                
                <div className="header-section">
                  <div className="row">
                    <div className="col-md-3">
                      <h5>TEU</h5>
                    </div>
                    <div className="col-md-3">
                      <h5>UID</h5>
                    </div>
                    <div className="col-md-3">
                      <h5>Owner </h5>
                    </div>
                    <div className="col-md-3">
                      <h5>Charterer</h5>
                    </div>
                  </div>
                </div>
                <div className="data-section  row-divider">
                
                <div className="row">
                    <div className="col-md-3 text-highlight" data-testid="snp-teu">
                      {buildTEUDesign?.teu}
                    </div>
                    <div className="col-md-3 text-highlight" data-testid="snp-uid">{UID} </div>
                    <div className="col-md-3 text-highlight" data-testid="snp-managing-owner">
                      {buildTEUDesign?.managingOwner}
                    </div>
                    <div className="col-md-3 text-highlight" data-testid="snp-chjarterer-name">
                      {buildTEUDesign?.chartererName}
                    </div>
                  </div>
                </div>
                <div className="header-section">
                  <div className="row">
                    <div className="col-md-4 row-divider">
                      <div className="input-section">
                        <label>
                          <span className="asterisk">*</span>
                          Status
                        </label>
                        <McSelect
                          {...register("status", { required: true })}
                          name="status"
                          selectedindex={statusData ? [0]: []}
                          fit="medium"
                          options={statusData}
                          label=""
                          change={populateDateOfStatus}
                          data-testid="status"
                        ></McSelect>
                      </div>
                    </div>
                    <div className="col-md-4 row-divider">
                      <div className="input-section">
                        <label>
                          <span className="asterisk">*</span>
                          Date of Status
                        </label>

                        <div className="date-div">
                          <span className="date-icon">
                            <McIcon icon="calendar"></McIcon>
                          </span>
                          <Controller
                            control={control}
                            data-testid="date-of-status"
                            {...register("dateOfStatus")}
                            render={({ field: { onChange } }) => (
                              <DatePicker
                                id="date-of-status"
                                placeholderText="dd/mm/yyyy"
                                className="inputText"
                                dateFormat="dd/MM/yyyy"
                                selected={dateOfStatus}
                                onChange={(date:any) => {
                                  onChange(date);
                                }}
                              ></DatePicker>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 row-divider">
                      <div className="input-section">
                        <label>Buyer</label>
                        <McSelect
                          {...register("buyer")}
                          name="buyer"
                          ariaPlaceholder="Select"
                          fit="medium"
                          label=""
                          options={buyer}
                          data-testid="buyer"
                        ></McSelect>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-section">
                  <div className="row">
                    <div className="col-md-4 row-divider">
                      <div className="input-section">
                        <McInput
                          {...register("priceGuidance")}
                          name="priceGuidance"
                          label="Price Guidance"
                          ariaPlaceholder="Type"
                          fit="medium"
                          data-testid="price-guidance"
                        ></McInput>
                      </div>
                    </div>
                    <div className="col-md-4 row-divider">
                      <div className="input-section">
                        <label>Currency</label>
                        <McSelect
                          {...register("currency")}
                          name="currency"
                          ariaPlaceholder="Select"
                          fit="medium"
                          label=""
                          options={currency}
                          data-testid="currency"
                        ></McSelect>
                      </div>
                    </div>
                    <div className="col-md-2 row-divider">
                      <div className="input-section">
                        <label>Price</label>
                        <input
                          className="inputText"
                          {...register("price")}
                          type="text"
                          name="price"
                          placeholder="0.00"
                          disabled={priceField}
                          data-testid="price"
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-2 row-divider">
                      <div className="input-section enblockValue">
                        <Controller
                          name="enBlock"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange } }) => (
                            <McCheckbox
                              name="enBlock"
                              label="EnBlock"
                              change={(e: any) => {
                                onChange(e?.target?.checked);
                              }}
                              data-testid="enBlock"
                            ></McCheckbox>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 row-divider">
                      <div className="input-section">
                        <McSelect
                          {...register("source")}
                          name="source"
                          label="Source"
                          ariaPlaceholder="Select"
                          fit="medium"
                          options={sourcesData}
                          data-testid="source"
                        ></McSelect>
                      </div>
                    </div>
                    <div className="col-md-8 ">
                      <div className="input-section">
                        <McTextarea
                          {...register("comments")}
                          name="comments"
                          label="Comments"
                          ariaPlaceholder="Type"
                          rows={1}
                          fit="medium"
                          data-testid="comments"
                        ></McTextarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="header-section">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <McButton
          data-testid="cancel-button"
          slot="secondaryAction"
          variant="secondary"
          click={() => onAddSnPClose && onAddSnPClose(true)}
          dialogAction="cancel"
        >
          Cancel
        </McButton>

        <McButton
          data-testid="save-button"
          slot="primaryAction"
          variant="primary"
          type="submit"
          disabled={isDisableBtn}
          role='submit'
        >
          Save details
        </McButton>
      </McModal>
    </form>
  );
}

export default SPModal;
