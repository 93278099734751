import { McModal, McButton } from "@maersk-global/mds-react-wrapper";
import { useEffect, useState } from "react";
import { postAPI } from "../../../../services/CepApiServices";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
import moment from "moment";
import "../../OfferDetails/InitialOfferDetails.scss";
interface IPROPS {
  isModalOpen?: boolean;
  onLoadData?: any;
  onCloseFunc?: any;
  termsAcceptTimeline?: any;
  negotiationType?: any;
  indicationId?: any;
  reRenderApiFunc?: any;
  liftSubTimeForExtension?: any;
}
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
const ExtensionModal = ({
  isModalOpen,
  onLoadData,
  onCloseFunc,
  termsAcceptTimeline,
  negotiationType,
  indicationId,
  reRenderApiFunc,
  liftSubTimeForExtension,
}: IPROPS) => {
  const [valueTime, onChangeDateTime] = useState(
    new Date(moment(termsAcceptTimeline).format("YYYY-MM-DDTHH:mm:ss"))
  );
  const [error, setError] = useState("");
  const handleSubmit = async () => {
    const timeValue = moment(valueTime).format("YYYY-MM-DDTHH:mm");
    const payload = {
      indicationId: indicationId,
      timeline: timeValue,
      negotiationType: "",
    };
    const payloadLiftSubs = {
      indicationId: Number(indicationId),
      timeline: timeValue,
    };

    let response: any;
    if (negotiationType == "Lift Subs") {
      if(liftSubTimeForExtension.userType === "CM"){
        if( valueTime < new Date(liftSubTimeForExtension.timeValue) 
          || moment(valueTime).format("YYYY-MM-DDTHH:mm") === moment(liftSubTimeForExtension.timeValue).format("YYYY-MM-DDTHH:mm") ) {
          setError(`Please provide a date greater than the lift sub date requested by Vessel Owner.`);
          return;
        }
      } else if(liftSubTimeForExtension.userType === "VO"){
        if(valueTime > new Date(liftSubTimeForExtension.timeValue) ||
          moment(valueTime).format("YYYY-MM-DDTHH:mm") === moment(liftSubTimeForExtension.timeValue).format("YYYY-MM-DDTHH:mm")
        ){
          setError(`Please provide a date lesser than the lift sub date requested by Chartering Manager.`);
          return;
        }
      }
      response = await postAPI(
        `Negotiation/LiftSubTimelineExtenstionRequest`,
        payloadLiftSubs
      );
    } else {
      response = await postAPI(
        `InitialOffer/ProcessTimelineExtensionRequest`,
        payload
      );
    }

    if (response === true) {
      toast.success(`Extension Requested successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      onCloseFunc();
      reRenderApiFunc();
    } else {
      toast.error(`Extension of Request failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
  };
  const handleDateChange = (e: any) => {
    setError("");
    if(e < new Date()){
      onChangeDateTime(new Date());
    }else{
      onChangeDateTime(e);
    }
  };
  const onCancelData = () => {
    onCloseFunc();
  };
  useEffect(() => {
    onChangeDateTime(termsAcceptTimeline);
  }, [isModalOpen]);
  return (
    <McModal
      height="480px"
      width="500px"
      className="extensionRequest-modal"
      heading={`Request For Extension`}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="date-section">
        <label>Please Select The Extension Date for Submission</label>
        <DateTimePicker
          format="MM/dd/yyyy HH:mm"
          onChange={handleDateChange}
          value={valueTime}
          minDate={new Date()}
        />
        {error && (<span className="liftsub-error-msg">{error}<span className="date-display">({moment(liftSubTimeForExtension.timeValue).format("DD-MMM-YYYY HH:mm")})</span></span>)}
      </div>
      <McButton
        slot="primaryAction"
        variant="primary"
        fit="small"
        label="Request"
        onClick={handleSubmit}
        data-testid="save-and-update"
      ></McButton>
      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        onClick={onCancelData}
        role="cancel-cp"
        fit="small"
      >
        Cancel
      </McButton>
    </McModal>
  );
};
export default ExtensionModal;
