import { getToken } from "../utils/token";
import { protectedResources } from "../authConfig";
import axios from "axios";
import React from "react";
import { Navigate } from "react-router-dom";
import { al } from "../auth/al-login-config";
//import { logoutApp } from "../components/Protected/AuthHandler";

const handleTokenExpiry = () => {
  localStorage.setItem("redirectUri", window.location.href);
  al.logoutRedirect({ post_logout_uri: process.env.REACT_APP_REDIRECT_URI });
  // logoutApp();
  // return window.location.href = '/';
};
const handleStatusCode = async (response: any) => {
  if (
    response?.status === 403 ||
    response?.status === 400 ||
    response?.StatusCode === 403 ||
    response?.StatusCode === 400
  ) {
    const keyPrefix = `${process.env.REACT_APP_AUTHORITY}.${process.env.REACT_APP_FORGEROCKCLIENT_ID}`;
    const tokenExpiry = localStorage.getItem(`al.${keyPrefix}.expiry_date`);
    const currentTimeStamp = new Date().getTime();
    if (
      tokenExpiry &&
      parseInt(tokenExpiry) > currentTimeStamp &&
      (response?.status === 403 || response?.StatusCode === 403)
    ) {
      return (window.location.href = "/AccessDenied");
    } else if (
      tokenExpiry &&
      parseInt(tokenExpiry) > currentTimeStamp &&
      (response?.status === 400 || response?.StatusCode === 400)
    ) {
      // const awaitResponse = await response.json();
      // if(awaitResponse?.Message.includes("Access denied")){
      //   return window.location.href = "/AccessDenied";
      // }else{
      //   return response.json();
      // }
      return response.json();
    } else {
      return handleTokenExpiry();
    }
  }
  return response.json();
};
export const getAPI = async (url: any, responseType?: string) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
      options
    )
      .then((response: any) =>
        responseType == "text" ? response.text() : handleStatusCode(response)
      )
      .then((data: any) => data)
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const postAPI = async (url: any, postData?: any) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      console.log("Test", protectedResources.apiTodoList.isAzure);
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      console.log("Test", protectedResources.apiTodoList.isAzure);
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(postData),
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
      options
    )
      .then((response) => handleStatusCode(response))
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const putAPI = async (url: any, postData?: any) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(postData),
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
      options
    )
      .then((response) => handleStatusCode(response))
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const postAPIForFormData = async (url: any, postData?: any) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const options = {
      method: "POST",
      headers: {
        Authorization: bearer,
      },
      body: postData,
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const deleteAPI = async (url: any) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "DELETE",
      headers: headers,
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const patchAPI = async (url: any, postData?: any) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(postData),
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const fileApiAxios = async (url: any, postData?: any) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const options = {
      method: "POST",
      headers: {
        Authorization: bearer,
      },
      body: postData,
    };
    return axios
      .post(
        protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
        postData,
        {
          headers: {
            Authorization: bearer,
          },
        }
      )
      .then((response) => response)
      .catch((response) => response.response);
  } catch (error) {
    console.log("error is ", error);
  }
};
export const downloadReportDocuments = async (url: any) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon + `${url}`,
      options
    );
  } catch (error) {
    console.log("error is ", error);
  }
};

export const downloadReport = async (fileName: string) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon +
        `IndicationRequest/DownlaodIndicationRequestDocument/${fileName}`,
      options
    );
  } catch (error) {
    console.log("error is ", error);
  }
};

export const downloadReportDocumentLibrary = async (
  fileName: any,
  docCategoryType: any
) => {
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    };
    return fetch(
      protectedResources.apiTodoList.endPointCEPCommon +
        `DocumentLibrary/DownlaodDocument/${fileName}/${docCategoryType}`,
      options
    );
  } catch (error) {
    console.log("error is ", error);
  }
};

export const deleteAPIForDocument = async (url: any, postData?: any) => {
  console.log(postData);
  let accessToken: any;
  try {
    if (protectedResources.apiTodoList.isAzure) {
      accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
    } else {
      accessToken = al.storage.id_token;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "DELETE",
      headers: headers,
      body: postData,
    };
    return axios
      .delete(protectedResources.apiTodoList.endPointCEPCommon + `${url}`, {
        headers: {
          Authorization: bearer,
        },
        data: postData,
      })
      .then((response) => response)
      .catch((response) => response.response);
  } catch (error) {
    console.log("error is ", error);
  }
};
