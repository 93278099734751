// React
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import pageName from "./PageName";
import { ifAzureFlow } from "../../utils/token";
import { al } from "../../auth/al-login-config";
import { getUserName } from "../../utils/token";
// Styles
import "./NavigationBar.scss";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import moment from "moment";

interface IPROPS {
  sidebarActive?: any;
}
export const NavigationBar = ({ sidebarActive }: IPROPS) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userPhoto, setUserPhoto] = useState<any>("assets/mc-c-avatar.png");
  const [activeUserName, setActiveUserName] = useState("");
  const [dt, setDt] = useState(new Date().toISOString().slice(0, -1));

  useEffect(() => {
    let secTimer = setInterval(() => {
      setDt(new Date(new Date().toISOString().slice(0, -1)).toLocaleString());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);
  const { instance } = useMsal();
  const location = useLocation();
  let activeAccount;
  let account;

  // console.log("from navigationbar.tsx" + window.location.href);
  // console.log("from navigationbar.tsx" + process.env.REACT_APP_HOST_URL);
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    const getProfilePhoto = async () => {
      if (activeAccount) {
        const response = await instance.acquireTokenSilent({
          account: activeAccount,
          scopes: ["user.read"],
        });

        const graphResponse = await fetch(
          "https://graph.microsoft.com/v1.0/me/photo/$value",
          {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          }
        );
        if (graphResponse.ok) {
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(await graphResponse.blob());
          setUserPhoto(blobUrl);
        }
      }

      if (ifAzureFlow()) {
        account = instance.getActiveAccount(); //azure login
        setActiveUserName(account?.name);
      } else {
        account = await getUserName(); //forgeRock login
        if (
          account.name === null ||
          account.name === "" ||
          account.name === undefined
        )
          setActiveUserName(account.username);
        else setActiveUserName(account.name);
      }
    };

    getProfilePhoto();
  }, []);

  const handleLogoutRedirect = () => {
    if (!ifAzureFlow()) {
      al.logoutRedirect({
        post_logout_uri: process.env.REACT_APP_REDIRECT_URI,
      });
    } else {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
      });
    }
  };

  const toggle = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <div className={`topbar  ${sidebarActive && "strechMenu"}`}>
      <div className="top-bar-container">
        <a href={location.pathname} className="logo-home">
          <div className="logo-top-text">{pageName(location)}</div>
        </a>

        {activeAccount && <div className="nav-container"></div>}
        <div className="utility-container">
          <span className="timezone"> UTC Time {dt}</span>

          <div className="it-help-section">
            <a
              href="https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=af24786afb668a90c360fe57aeefdc8a"
              target="_blank"
              rel="noopener noreferrer"
            >
              IT Help
            </a>
          </div>
          {/* <img
            src="assets/bell-icon.png"
            className="bell-allignment"
            width="19"
            height="20"
          /> */}
          <br />
          <img
            data-testid="your-photo-element"
            src={userPhoto}
            className="imgallignment user-image-style"
            width="20"
            height="20"
          />
          <span className="currentusername">
            {activeUserName ? activeUserName : ""}
          </span>
          <span className="sign-out-icon">
            <McIcon
              data-testid="toggle"
              icon="chevron-down"
              className="chevron-down-icon"
              onClick={toggle}
            ></McIcon>
          </span>

          {showDropdown && (
            <div className="dropdown-content">
              <span
                className="signout"
                data-testid="sign-out"
                onClick={handleLogoutRedirect}
              >
                Sign out
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
