import React, { useEffect } from "react";
import { useState } from "react";
import {
  McModal,
  McSelect,
  McIcon,
  McInput,
} from "@maersk-global/mds-react-wrapper";
import { FileUploader } from "react-drag-drop-files";
import { getAPI } from "./../services/CepApiServices";
import "./DocumentLibraryCm.scss";
import ProgressBar from "react-bootstrap/ProgressBar";

interface IPROPS {
  onOpen?: boolean;
  closeUpload?: any;
  fileUpload?: any;
  progressStatusRes?: any;
  documentList?: any;
}

const UploadDocumentLibrary = ({
  onOpen,
  closeUpload,
  fileUpload,
  progressStatusRes,
  documentList,
}: IPROPS) => {
  // const fileTypes = ["pdf"];

  const [fileTypes, setFileTypes] = useState<any>();
  const [loaderDiv, setLoaderDiv] = useState(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [modalHieght, setModalHieght] = useState("55vh");
  const [progressStatus, setProgressStatus] = useState("success");
  const [fileValue, setFile] = useState<any>();
  const [source, setSource] = useState<any>();
  const [isUploadSection, setIsUploadSection] = useState(true);
  const [progress, setProgress] = useState(0);
  const [fileUploaded, setFileValue] = useState<any>();
  const uploadRef = React.useRef();
  const statusRef: any = React.useRef();
  const loadTotalRef: any = React.useRef();
  const progressRef: any = React.useRef();
  const [sourceType, setSourceType] = useState([]);
  const [ErrorValue, setError] = useState(false);
  const [pageNumberError, setPageNumberErr] = useState(false);
  const [errorValueForFile, setErrorValueForFile] = useState(false);
  const [sendUploadValue, setSendUploadValue] = useState(false);
  const [startPage, setStartPage] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [docTypeSelected, setDocTypeSelected] = useState(false);

  function formatBytes(bytes: any) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;

    while (bytes >= 1024 && i < units.length - 1) {
      bytes /= 1024;
      i++;
    }

    return bytes.toFixed(2) + units[i];
  }
  const handleChange = (file: any) => {
    setIsLoader(false);
    setFile(file);
    let checkDocumentExist = documentList?.some(function (item: any) {
      return item["documentName"] === file?.name;
    });
    setSendUploadValue(checkDocumentExist);
    if (checkDocumentExist) {
      setErrorValueForFile(true);
    } else {
      setErrorValueForFile(false);
    }
    setIsUploadSection(false);
    setModalHieght("55vh");

    let xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", ProgressHandler, false);
    setIsLoader(true);
  };
  const handleSubmit = async () => {
    setFileValue(fileValue);
    if (fileUpload && source) {
      if (source?.value == 67 && startPage == "") {
        setPageNumberErr(true);
      } else {
        setPageNumberErr(false);
        fileUpload(fileValue, source, startPage);
      }
    } else if (fileValue && source == "") {
      setError(true);
    }
  };
  const ProgressHandler = (e) => {
    loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
    let percent = (e.loaded / e.total) * 100;
    progressRef.current.value = Math.round(percent);
    statusRef.current.innerHTML = Math.round(percent) + "% uploaded...";
  };
  const onCancel = (act: any, data: any) => {
    setSource("");
    closeUpload(act, data);
    setLoaderDiv(false);
    setProgressStatus("info");
    setIsUploadSection(true);
    setProgress(0);
    setModalHieght("55vh");
    setError(false);
    setDocTypeSelected(false);
  };

  const sourceSelected = (e?: any) => {
    const sourceValue = e?.detail?.selectedOptions[0];
    if (sourceValue?.value == 67) {
      setFileTypes(["pdf"]);
      setModalHieght("65vh");
    } else if (sourceValue?.value == 68) {
      setFileTypes(["xls", "xlsx", "doc", "docx", "pdf"]);
      setModalHieght("55vh");
    }

    setSource(e?.detail?.selectedOptions[0]);
    if (sourceValue) {
      setError(false);
      setIsUploadDisabled(false);
      //disable upload when start page is not provided
      if (sourceValue?.value == 68) {
        setDocTypeSelected(true);
      } else if (sourceValue?.value == 67 && startPage !== "") {
        setDocTypeSelected(true);
      } else {
        setDocTypeSelected(false);
      }
    } else {
      setError(true);
      setIsUploadDisabled(true);
      setDocTypeSelected(false);
    }
  };

  const onReload = () => {
    setIsUploadSection(true);
    setProgressStatus("info");
    setModalHieght("48vh");
    setProgress(0);
  };
  const fetchAllDocumentTypes = async () => {
    if (onOpen == false) {
      setSourceType([]);
      setIsUploadSection(true);
      setSource("");
      setError(false);
    } else {
      const vessels = await getAPI(`DocumentLibrary/GetDocumentCategoryTypes/`);
      setSourceType(vessels);
      setIsLoader(false);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartPage(e.target.value);
    setPageNumberErr(false);
    //disable upload when start page is not provided
    e.target.value ? setDocTypeSelected(true) : setDocTypeSelected(false);
  };
  useEffect(() => {
    handleSubmit();
  }, [fileValue, source, startPage]);
  useEffect(() => {
    fetchAllDocumentTypes();
  }, [onOpen]);

  return (
    <McModal
      open={onOpen}
      heading="Upload Documents"
      width="560px"
      backdropcloseactiondisabled
      height={modalHieght}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="modal-section">
            <div className="subColoum">
              <div className="header-section">
                <div
                  className="closeIcon"
                  onClick={() => onCancel({ type: "close" }, null)}
                  data-testid="close-icon"
                >
                  <McIcon icon="times"></McIcon>
                </div>
                <div className="row">
                  <div className="col-md-12 row-divider">
                    <div className="input-section">
                      <label>Documents Type</label>
                      <McSelect
                        name="indicationId"
                        fit="medium"
                        label=""
                        options={sourceType}
                        change={sourceSelected}
                        invalid={ErrorValue}
                        value={source}
                        errormessage="Please select the document type"
                        required={true}
                        data-testid="source-select"
                      ></McSelect>
                    </div>
                  </div>
                </div>
                {source?.value == 67 && (
                  <div className="row">
                    <div className="col-md-12 row-divider">
                      <div className="input-section">
                        <McInput
                          label="Extraction Start Page"
                          invalid={pageNumberError}
                          errormessage="Please enter the page from which pdf extraction should start"
                          onKeyDown={(event) => {
                            if (
                              !/[0-9]/.test(event.key) &&
                              event.key !== "Backspace" &&
                              event.key !== "Delete"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          input={(e: any) => handleInputChange(e)}
                        ></McInput>
                      </div>
                    </div>
                  </div>
                )}
                {
                  <div className="row">
                    <div className="col-md-12">
                      {isUploadSection && docTypeSelected && (
                        <div
                          className="uploader-div"
                          data-testid="fileuploader"
                        >
                          <FileUploader
                            handleChange={handleChange}
                            name="File"
                            disabled={isUploadDisabled}
                            types={fileTypes}
                          >
                            <div className="dropZone" data-testid="file-input">
                              <div className="drop-text">
                                <p>
                                  <McIcon icon="folder-arrow-up"></McIcon>
                                  <span>Select file </span> or Drag here
                                </p>
                              </div>
                            </div>
                          </FileUploader>
                        </div>
                      )}

                      {isUploadSection && !docTypeSelected && (
                        <div
                          className="uploader-div"
                          data-testid="fileuploader"
                        >
                          <div className="dropZone" data-testid="file-input">
                            <div className="drop-text">
                              <p>
                                <McIcon icon="folder-arrow-up"></McIcon>
                                <div>Select file or Drag here</div>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isUploadSection && (
                        <div className="attach-div">
                          <p className="file-name" data-testid="file-name">
                            {fileValue?.name}
                          </p>
                          <p className="file-size" data-testid="file-size">
                            File size :{" "}
                            {fileValue ? formatBytes(fileValue.size) : "N/A"}{" "}
                          </p>
                          <div className="progress-section">
                            {errorValueForFile && (
                              <span
                                className="status danger"
                                data-testid="progress-status"
                              >
                                <span className="icon">
                                  {" "}
                                  <McIcon icon="exclamation-circle"></McIcon>
                                </span>{" "}
                                Document already exist please choose something
                                else
                                <span
                                  className="reload"
                                  data-testid="reload-button"
                                  onClick={onReload}
                                >
                                  <McIcon icon="arrow-clockwise"></McIcon>
                                </span>
                              </span>
                            )}
                            {progressStatusRes == "success" && (
                              <span
                                className="status success"
                                data-testid="progress-status"
                              >
                                <span className="icon">
                                  <McIcon icon="check-circle"></McIcon>
                                </span>{" "}
                                Upload success
                              </span>
                            )}
                            {progressStatusRes == "danger" && (
                              <span
                                className="status danger"
                                data-testid="progress-status"
                              >
                                <span className="icon">
                                  {" "}
                                  <McIcon icon="exclamation-circle"></McIcon>
                                </span>{" "}
                                Upload failed
                                <span
                                  className="reload"
                                  data-testid="reload-button"
                                  onClick={onReload}
                                >
                                  <McIcon icon="arrow-clockwise"></McIcon>
                                </span>
                              </span>
                            )}
                            <ProgressBar
                              variant={progressStatus}
                              now={progress}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoader && progressStatusRes !== "danger" ? (
        <div className="template-repo-loader-div" data-testid="loader">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </McModal>
  );
};

export default UploadDocumentLibrary;
