import React, { useState } from "react";
import "./CharterRequest.scss";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import CharterRequestTable from "./RequestPages/CharterRequestTable/CharterRequestTable";
import CharterRequestFilter from "./RequestPages/CharterRequestFilter/CharterRequestFilter";

const CharterRequest = () => {
  const [isFilter, setIsFilter] = useState(false);
  const onloadObj = {
    requestName: null,
    startDate: null,
    endDate: null,
    startPort: null,
    teuFrom: 0,
    teuTo: 0,
    gear: null,
    reeferPlugsFrom: 0,
    reeferPlugsTo: 0,
    requestStatus: null,
  };
  const [dataObj, setDataObj] = useState<any>(onloadObj);
  const onFilterClick = () => {
    setIsFilter(true);
  };
  const onFilterClose = () => {
    setIsFilter(false);
  };
  const clearFilterFunc = () => {
    setDataObj(onloadObj);
  };
  const filterDataFunc = (obj: any) => {
    const formData = {
      requestId:
        obj?.requestName !== "" ? obj?.requestName.map((e) => e.value) : null,
      startPort:
        obj?.startPort !== "" ? obj?.startPort.map((e) => e.label) : null,
      teuFrom: obj?.teu.length !== 0 ? obj?.teu[0] : 0,
      teuTo: obj?.teu.length !== 0 ? obj?.teu[1] : 0,
      gear: obj?.gear.length !== 0 ? obj?.gear[0].value : null,
      reeferPlugsFrom: obj?.plugs.length !== 0 ? obj?.plugs[0] : 0,
      reeferPlugsTo: obj?.plugs.length !== 0 ? obj?.plugs[1] : 0,
      startDate: obj?.fromDate !== "" ? obj?.fromDate : null,
      endDate: obj?.toDate !== "" ? obj?.toDate : null,
      requestStatus: obj?.statusList ? obj?.statusList : null,
    };
    setDataObj(formData);
  };
  return (
    <div className="charter-requestMain">
      <div className="container-fluid">
        <div className="header-div">
          <div className="row">
            <div className="col-md-3">
              <h5>Charter requests</h5>
            </div>
          </div>
          <div className="col-md-7"></div>
          <div className="col-md-2 filter-buttons">
            <button className="clear-button" onClick={clearFilterFunc}>
              Clear
            </button>
            <button
              onClick={onFilterClick}
              data-testid="filter"
              className="filter-button"
            >
              <span>
                <McIcon icon="sliders-horizontal"></McIcon>
              </span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <div className="table-section">
          <CharterRequestTable pageData={dataObj} />
        </div>
        {isFilter && (
          <CharterRequestFilter
            closeFilterFunc={onFilterClose}
            onDataFunc={filterDataFunc}
            clearFilter={clearFilterFunc}
          />
        )}
      </div>
    </div>
  );
};
export default CharterRequest;
