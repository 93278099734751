import { UserAgentPublicApp } from "@maersk/iam-al";

const loginConfig = {
    /**
     * @description: the uri that's whitelisted in AM for OAuth2
     */
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    /**
     * @description: Your application client id
     */
    client_id: process.env.REACT_APP_FORGEROCKCLIENT_ID,
    /**
      * @description: The url of the authority, e.g https://accounts.maersk.com/developer
      */
    authority: process.env.REACT_APP_AUTHORITY,
    storage: {
        /**
         * @default: "localStorage"
         * @description: the type of storage you want to utilize to store the tokens
         * choices are sessionStoragesession lived tokens, and localStorage, for permanent storing (until deleted)
         */
        type: "localStorage"
    },
    /**
     * default: "iamAL"
     * this will give the AL instance name in the global scope (window.iamAL)
     */
    window_namespace: 'maerskAL',
    post_logout_uri: window.location.origin
}
export const al = new UserAgentPublicApp(loginConfig); 
