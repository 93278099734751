import { McModal, McButton } from "@maersk-global/mds-react-wrapper";
import { getAPI } from "../../../../services/CepApiServices";
import { toast } from "react-toastify";
interface IPROPS {
  isModalOpen?: boolean;
  indicationId?: any;
  onCanceled?: any;
  onLoadData?: any;
}

const DeleteModal = ({
  isModalOpen,
  indicationId,
  onCanceled,
  onLoadData,
}: IPROPS) => {
  const onOKClick = async () => {
    const response = await getAPI(
      `Negotiation/DeleteNewriderclauseAsync?id=${onLoadData?.titleId}&indicationId=${indicationId}`
    );
    if (response === true) {
      toast.success(`New Rider clause Deleted`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      onCanceled("Submit");
    } else {
      toast.error(`Deleting Failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      onCanceled("Cancel");
    }
  };

  const closeAcceptFunc = () => {
    onCanceled("Cancel");
  };

  return (
    <div>
      <McModal
        width="620px"
        height="180px"
        open={isModalOpen}
        heading="Confirm Action"
        className="accept-clause-modal"
      >
        <p className="accept-alert">
          Do you want to Delete the New Rider Clause ?
        </p>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          click={closeAcceptFunc}
          dialogAction="cancel"
          data-testid="cancel"
        >
          Cancel
        </McButton>
        <McButton
          slot="primaryAction"
          variant="primary"
          fit="small"
          label={"Delete"}
          onClick={onOKClick}
          data-testid="submit-button"
        ></McButton>
      </McModal>
    </div>
  );
};

export default DeleteModal;
