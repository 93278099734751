// React
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../NegotiationOfferPages/BaseCharterParty/RiderClausePage/RiderClausePage.scss";
import RiderClauseEditor from "../../NegotiationOfferPages/BaseCharterParty/RiderClausePage/RiderClauseEditor";
import Nodata from "../../../components/NoData/NoData";
interface IPROPS {
  indicationId?: any;
  reRenderApiFunc?: any;
  data?: any;
  checkNegotiationStatus?: any;
  isAccessType?: any;
  isPrefilledCondition?:any;
  extendedRiderClauseResponse?:any;
  extendedContractIDBCP?:any;
}

const IssueRecapBaseRiderClause = ({
  indicationId,
  reRenderApiFunc,
  data,
  checkNegotiationStatus,
  isAccessType,
  isPrefilledCondition,
  extendedRiderClauseResponse,
  extendedContractIDBCP,
}: IPROPS) => {
  const [scrollID, setscrollID] = useState(0);
  const [contentData, setContentData] = useState([]);
  const [newdataList, setNewDataList] = useState([]);
  const location = useLocation();
  const documentId = location?.state?.documentId;

  const [isAddRiderClasueOpen, setIsAddRiderClasueOpen] = useState(false);

  useEffect(() => {
    getRiderClauseData(data);
  }, [data]);

  const reRenderApiRiderClause = () => {
    getRiderClauseData(data);
  };
  const getRiderClauseData = async (responseValue) => {
    setNewDataList(responseValue?.riderClauseIndices);
    setContentData(responseValue?.riderClauseContractDocumentContents);
  };

  const scrollTo = (id: any) => {
    setscrollID(id);
  };

  const handleAddRiderClause = () => {
    setIsAddRiderClasueOpen(true);
  };
  return (
    <>
      {/* {!isPrefilledCondition && ( */}
        <div className="rider-clause-main-page">
        <div className="rider-clause-page-sub-section">
          <div className="indexPage-riderClausePage">
            <div className="frame">
              {newdataList?.length == 0 ? (
                <Nodata text={"No Recap Data Found.."} />
              ) : (
                <div className="data-section">
                  <div className="left-dataSection-riderClausePage">
                    <div className="main-heading">
                      <span>Rider Clauses</span>
                      {checkNegotiationStatus && isAccessType && (
                        <span className="right-btn-add">
                          {" "}
                          <button
                            className="rider-clause-btn"
                            onClick={handleAddRiderClause}
                          >
                            Add Clause
                          </button>
                        </span>
                      )}
                    </div>
                    <ul>
                      {newdataList?.map((item: any, index: any) => (
                        <li
                          data-testid='list-item'
                          className={`${item?.id == scrollID && "active"}`}
                          onClick={() => scrollTo(item?.id)}
                          key={item?.id}
                        >
                          {item?.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="right-dataSection-riderClausePage">
                    <div className="each-dataSection">
                      {contentData?.map((item: any) => (
                        <RiderClauseEditor
                          fromIssueRecap={true}
                          arrData={item}
                          scrollId={scrollID}
                          onAPiLoad={reRenderApiRiderClause}
                          key={item?.titleId}
                          checkNegotiation={checkNegotiationStatus}
                          isWriteAccess={isAccessType}
                          isPrefilledCondition={isPrefilledCondition}
                          extendedContractIDBCP={extendedContractIDBCP}
                        ></RiderClauseEditor>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueRecapBaseRiderClause;
