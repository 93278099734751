import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { getAPI } from "../../../services/CepApiServices";
import moment from "moment";
import "../../IndicationRequestPages/IndicationRequestTable/IndicationRequestTable.scss";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import { MdsConstants } from "../../../utils/Constants";
import { StatusList } from "../../../utils/StatusList";

import { IndicationRequestModelVO } from "./IndicationRequestVOModel";
import { McButton, McIcon, McTooltip } from "@maersk-global/mds-react-wrapper";
import WriteAccessModal from "../../IndicationRequestPages/IndicationRequestTable/WriteAccessModal";

const IndicationRequestTableVO = () => {
  const history = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dDlOptions, setTableSizeDdl] = useState<any>([]);
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);
  const [rejectPopUp, setRejectpopUp] = useState(false);
  const [hoverValue, setHoverValue] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [isWriteAccess, SetIsWriteAccess] = useState(false);
  const [rowData, setRowData] = useState<any>();
  let numOfRecords = 0;

  const onLoadDataFetch = async () => {
    setIsTableLoader(true);
    setTableSizeDdl(MdsConstants.ItemsPerPageDDl);

    const getAllVOIndicationRequest = await getAPI(
      `IndicationRequest/GetVesselOwnerIndicationRequest`
    );
    setTableData(getAllVOIndicationRequest);
    setIsTableLoader(false);
    numOfRecords = getAllVOIndicationRequest
      ? getAllVOIndicationRequest?.length
      : 0;
    setTotalPages(numOfRecords / 10);
  };

  const handleWriteAssigneeFun = (value: any) => {
    SetIsWriteAccess(true);
    setRowData(value);
  };
  const handleAssignee = (row: any) => {
    if (
      row?.statusId != 4 &&
      row?.statusId != 3 &&
      row?.statusId != 7 &&
      row?.statusId !== 16 && //Terminated requests
      row.accessType == "Write"
    ) {
      return (
        <span
          className="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  };
  const closeFunc = () => {
    SetIsWriteAccess(false);
  };
  const handleLongText = (str: any) => {
    return str.split(",").map((subStr: any) => {
      return (
        <div className="long-text">
          {subStr}
          <br />
        </div>
      );
    });
  };
  const accessTypeSetter = (accessType: any) => {
    if (accessType == "Read") {
      return (
        <span className="read-access">
          <McIcon icon="eye" className="read-access-icon"></McIcon>
          {accessType}
        </span>
      );
    } else if (accessType == "Write") {
      return (
        <span className="write-access">
          <McIcon icon="pencil" className="write-access-icon"></McIcon>
          {accessType}
        </span>
      );
    }
  };
  const columns: TableColumn<IndicationRequestModelVO>[] = [
    {
      name: "Status",
      selector: (row) => row.indicationStatus,
      cell: (row) =>
        StatusList(row.statusId, row.indicationStatus, row.comments, "VO"),
      sortable: true,
      width: "9%",
    },
    {
      name: "Vessel Name",
      selector: (row) =>
        row.vesselName !== null ? row.vesselName.toLowerCase() : "",
      cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
      sortable: true,
      width: "15%",
    },
    {
      name: "IMO",
      selector: (row) => (row.imo !== null ? row.imo : ""),
      sortable: true,
      width: "8%",
    },
    {
      name: "Chartering Manager",
      selector: (row) =>
        row.createdBy !== null ? row.createdBy.toLowerCase() : "",
      cell: (row) => (row.createdBy !== null ? row.createdBy : ""),
      sortable: true,
      width: "17%",
    },
    {
      name: "VO/Owner representative",
      selector: (row) => handleLongText(row.requestAssigneeEmail),
      sortable: true,
      width: "15%",
    },
    {
      name: "Created Date",
      selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
      cell: (row) => moment(row.createdOn).format("DD-MMM-YYYY"),
      sortable: true,
      width: "11%",
    },
    {
      name: "Access Type",
      selector: (row) => (row.accessType !== null ? row.accessType : ""),
      cell: (row) => accessTypeSetter(row.accessType),
      sortable: true,
      width: "11%",
    },
    {
      name: "Access",
      cell: (row) => handleAssignee(row),
      width: "5%",
    },
  ];
  const tableRowClick = (row: any) => {
    if (row.isDirectNegotiation !== "No") return false;
    if (row.statusId == 2) {
      window.open(`/IndicationRequestPage?id=${row.id}`);
    } else if (row.statusId >= 5) {
      history(`/GetIndicationRequestDataAsyncVO?id=${row.id}`);
    } else if (row.statusId == 4) {
      setRejectpopUp(true);
    }
  };

  useEffect(() => {
    onLoadDataFetch();
  }, [pageLoad]);
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const cursorPointerStyle = { cursor: "pointer" };
  const cursorDefaultStyle = { cursor: "default" };
  const conditionalRowStyles = [
    {
      when: (row) => row.isDirectNegotiation === "No",
      style: cursorPointerStyle,
    },
    {
      when: (row) => row.isDirectNegotiation !== "No"|| 
                     row.indicationStatus === "VO Rejected" || 
                     row.indicationStatus === "Indication Cancelled",
      style: cursorDefaultStyle,
    },
  ];

  return (
    <div className="ir-table-main table-margin2 bg-white">
      <DataTable
        columns={columns}
        data={tableData}
        progressPending={isTableLoader}
        onRowClicked={tableRowClick}
        progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        conditionalRowStyles={conditionalRowStyles}
      />
      <WriteAccessModal
        isModalOpen={isWriteAccess}
        onCloseFunc={closeFunc}
        rowData={rowData}
        isCm={false}
        reloadPage={onLoadDataFetch}
      />
    </div>
  );
};
export default IndicationRequestTableVO;
