// React
import { useState, useEffect } from "react";
import { McTab, McTabBar, McIcon } from "@maersk-global/mds-react-wrapper";
// MDS
import { McInput } from "@maersk-global/mds-react-wrapper";

// Pages
import LandingPage from "../landingPage/LandingPage";
import CharterMarket from "../charter-market/CharterMarket";

// Config
import { getToken } from "../../utils/token";
import { protectedResources } from "../../authConfig";
import "./HomePage.scss";
import { toast } from "react-toastify";
import { DownloadVesselSupplyDataListService } from "../../services/VesselSupplyDataListService";
import {
  DownloadDataConstant,
  DownloadColumnsConstant,
} from "../vessel_supply_data_list/DownloadDataColumns";
import { excelDownload } from "../../utils/excelDownload";

const HomePage = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [results, setResults] = useState([]);
  const [tab, setTab] = useState<number>(0);
  const [downloadClicked, setDownloadClicked] = useState(false);
  let delay: any;
  let returnedField1 = localStorage.getItem("item");
  const page = localStorage.getItem("vslSupplyPage");
  console.log("Environment -" + process.env.REACT_APP_ENVIRONMENT);

  console.log("Home Page -" + process.env.REACT_APP_SCOPE_ID);

  console.log("React Base Url -" + process.env.REACT_APP_BASE_URL);

  const callApi = async (val: any) => {
    const response = await getVesselSupplyDataList(val);
    setResults(response);
  };

  const getVesselSupplyDataList = async (searchText: any) => {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };
    return fetch(
      protectedResources.apiTodoList.endpoint + `${searchText}`,
      options
    )
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    delay = setTimeout(() => {
      // if (searchText) callApi(searchText);
      // else {
      if (page === "true" && returnedField1 !== null) {
        callApi(returnedField1);
        localStorage.removeItem("vslSupplyPage");
      }
      // }
    });

    return () => clearTimeout(delay);
  }, [searchText]);

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      clearTimeout(delay);
      callApi(e.target.value);
      setSearchText(e.target.value);
      localStorage.setItem("item", e.target.value);
      setTab(0);
    }
  };
  const yourChangeHandler = (event: any) => {
    setTab(event.detail);
  };
  const downloadVslSupplyList = async () => {
    notify("File download started", true);
    setDownloadClicked(true);
    // Global download implementation
    let vslSupplyData = [];
    if (!searchText) {
      vslSupplyData = await DownloadVesselSupplyDataListService(null);

      const modified_rows = DownloadDataConstant(vslSupplyData);
      const header = DownloadColumnsConstant();
      excelDownload("Vessel_Supply_Data_List", modified_rows, header);
      notify("File downloaded successfully", true);
      resetIsExport();
    }
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const setSearchTextValue = (e: any) => {
    setSearchText(e.target.value);
  };
  const resetIsExport = () => {
    setDownloadClicked(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <McTabBar
            data-testid="tab-changer"
            currentindex={tab}
            variant="stretched"
            change={yourChangeHandler}
            style={{ marginRight: 35, width: 60 }}
          >
            <McTab
              className="custom-height"
              slot="tab"
              label="Global Vessel List"
              icon="vessel-front"
              data-testid={"globalVessel1"}
            >
              <span>
                <img
                  className="vesselSupplyDataList-img"
                  src={require("../../assets/vessel.png")}
                  alt="vessels-img"
                />
              </span>
            </McTab>
            <div className="tab-row" slot="panel"></div>
            <McTab
              className="custom-height"
              slot="tab"
              data-testid={"globalVessel2"}
              label="Charter Market Place"
              icon="vessel-side"
            ></McTab>
            <div className="tab-row" slot="panel"></div>
          </McTabBar>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              {tab === 0 && (
                <McInput
                  className="search-box globalSearch VSDmainSearch"
                  value={searchText}
                  onChange={setSearchTextValue}
                  onKeyDown={keyDown}
                  icon="magnifying-glass"
                  iconposition="left"
                  label=""
                  data-testid="global-search"
                  ariaPlaceholder="Search by IMO, Vessel Name or Hull No."
                  fit="small"
                ></McInput>
              )}
              {tab === 0 && results && (
                <div className="subColoum">
                  <div className="edit-options">
                    <ul className="options-list">
                      <li
                        className={`${
                          downloadClicked && "disabled"
                        } global-download`}
                      >
                        <McIcon
                          data-testid="global-download"
                          icon="tray-arrow-down"
                          onClick={downloadVslSupplyList}
                        ></McIcon>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="data-section">
            {tab === 1 && <CharterMarket />}
            {tab === 0 && (
              <LandingPage
                data={results}
                searchText={searchText}
                selectedTab={tab}
                dwnldVslSupplyList={downloadClicked}
                resetDownloadVslSuppluList={resetIsExport}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
