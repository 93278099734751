// React
import React, { useState, useEffect, useCallback } from "react";
// Styles
import "./CharterMarket.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//MDS
import { MdsPaginantion } from "../../components/pagination/MdsPaginantion";
import { MdsTableItemPerPageDdl } from "../../components/dropdown/MdsTableSizeDropdown";
import { MdsConstants } from "../../utils/Constants";
import { McIcon } from "@maersk-global/mds-react-wrapper/components-core";
import { McModal, McButton, McInput } from "@maersk-global/mds-react-wrapper";
import { SkeletonLoader } from "../../components/skeletonLoader/SkeletonLoader";
//Pages
import SalesAndPurchase from "../SalesAndPurchase/SalesAndPurchase";
import CharterMarketFilters from "../charter-market-filters/CharterMarketFilters";
import CharterMarketFiltersSNP from "../charter-market-filters/CharterMarketFiltersSNP";
import EditModal from "./EditModal";
import DeleteModal from "../delete-modal/DeleteModal";
import ChangeLogModal from "../change-log/ChangeLogModal";
import UploadModal from "../upload/UploadModal";
import ValidateModal from "../upload/ValidateModal";
import OnholdModal from "../upload/OnholdModal";
// Services
import { CharterMarketService } from "../../services/VesselSupplyDataListService";

// Model
import { VslCharterMarketModel } from "./model";
import moment from "moment";

//Columns
import columns from "./Columns";

//Component
import AddModalCharter from "../add-charter-market/AddCharterMarket";
import SPModal from "../../pages/sp-modal/SPModal";
import { getAPI, postAPI, deleteAPI } from "../../services/ApiServices";

import "./CharterMarket.scss";
import DataTable from "react-data-table-component";

//Config
import { getUID } from "../../utils/token";
import { excelDownload } from "../../utils/excelDownload";

//Utils
import { McSelectOptions } from "@maersk-global/mds-components-core/mc-select/types";
interface IPROPS {
  data?: any;
  searchText?: any;
}
const CharterMarket = ({ data, searchText }: IPROPS) => {
  const [globalSearch, setGlobalSearchCommercial] = useState(false);
  const [globalSearchSnP, setGlobalSearchSnP] = useState(false);
  const [globalsearchText, setGlobalSearchText] = useState<string>('');
  const [tableData, setDataSource] = useState<VslCharterMarketModel[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [chartererOption, setChartererOption] = useState();
  const [currency, setCurrency] = useState([]);
  const [reDelivaryRange, setReDelivaryRange] = useState([]);
  const [chartererType, setChartererType] = useState();
  const [isCommercialTab, setIsCommercialTab] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [dDlOptions, setTableSizeDdl] = useState<McSelectOptions>([]);
  const [hasError, setHasError] = useState<boolean>();
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);
  const [selectedNoOfRecords, setSelectedNoOfRecords] = useState(10);
  const [modal, setModal] = useState(false);
  const [spmodal, setSPModal] = useState(false);
  const [uploadmodal, setUploadModal] = useState(false);
  const [validateModal, setValidateModal] = useState(false);
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [isFilterChangeSNP, setIsFilterChangeSNP] = useState(false);
  let [isOnloadFilter, setIsOnloadFilter] = useState(0);
  const [showAddError, setShowAddError] = useState(false);
  const [showAddSuccess, setShowAddSuccess] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editData, setEditData] = useState();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [downloadData, setDownloadData] = useState([]);
  const [isdownloadSnP, setDownloadSnP] = useState<boolean>();
  const [changeLogModal, setChangeLogModal] = useState(false);
  const [changeLogData, setChangeLogData] = useState(0);
  const [isDownloadClicked, setisDownloadClicked] = useState<boolean>();
  const [uploadCharterData, setUploadCharterData] = useState();
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [uploadType, setUploadType] = useState();
  const [onHoldModal, setOnHoldModal] = useState(false);
  const UID = getUID();
  let sID = 0;
  const [filtersForDownld, setFiltersForDownload] = useState<any>({
    id: 0,
    statusList: null,
    imo: null,
    design: null,
    chartererId: null,
    charterTypeId: null,
    rate: null,
    redeliveryPortId: 0,
    redeliveryRangeId: 0,
    purchaseObligation: null,
    fixtureOption: 0,
    purchaseOption: 0,
    deliveryDate: null,
    estRedeliveryDate: null,
    currencyId: 0,
    teuFrom: 0,
    teuTo: 0,
    managingOwner: null,
    isLocked: null,
  });
  const [isSNPfilterdata, setIsSNPfilterdata] = useState({
    id: 0,
    statusids: null,
    owner: null,
    buyer: null,
    chartererId: 0,
    imo: null,
    fromDate: null,
    FromTeu:0,
    ToTeu:0
  });
  let formData: any = {
    id: 0,
    statusList: "Current,Next",
    imo: null,
    design: null,
    chartererId: 0,
    charterTypeId: 0,
    // rate: null,
    redeliveryPortId: 0,
    redeliveryRangeId: 0,
    purchaseObligation: 0,
    purchaseOption: 0,
    fixtureOption: 0,
    deliveryDate: null,
    estRedeliveryDate: null,
    currencyId: 0,
    isLocked: null,
    comment: "",
    sourceId: null,
    managingOwner: null,
    uid: 0,
    min: 0,
    max: 0,
    teuFrom: 0,
    teuTo: 0,
  };
  let addformData: any = {
    id: 0,
    imo: 0,
    chartererId: 0,
    charterTypeId: 0,
    delivery: null,
    min: null,
    max: null,
    managingOwner: null,
    estRedelivery: null,
    redeliveryPortId: 0,
    redeliveryRanges: [],
    purchaseObligation: 0,
    isLocked: null,
    currencyId: 0,
    rate: null,
    uid: 0,
    sourceId: 0,
    comment: null,
    fixtureOption: null,
    fixtureOptionPeriod: null,
    fixtureOptionPeriodType: null,
    fixtureOptionCurrencyId: null,
    purchaseOption: null,
    purchaseOptionValue: null,
    purchaseOptionCurrencyId: null,
    fuelClassId: null,
    fixtureOptionValue: null,
  };
  let [addSNPformData, setAddSNPDetailsObject] = useState<any>({
    id: 0,
    imo: 0,
    status: 0,
    date: null,
    owner: null,
    buyer: null,
    currency: null,
    currencyId: 0,
    price: 0,
    priceGuidance: null,
    uid: null,
    source: null,
    sourceId: 0,
    updateDate: null,
    comments: null,
    typeId: 0,
    statusId: 0,
    chartererId: 0,
  });
  // let [addSNPformData, setAddSNPDetailsObject] = useState<any>([]);
  let numOfRecords = 0;

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      setGlobalSearchText(e.target.value);
      if(isCommercialTab)
      {
        setGlobalSearchCommercial(true)
      }
      else{
        setGlobalSearchSnP(true)
      }
      if (isCommercialTab) {
        fetchGlobalSearchCharterMrkt(1, 10, e.target.value);
      }
    }
  };

  const fetchGlobalSearchCharterMrkt = async (
    pageNo: any,
    recordCount: any,
    input: any
  ) => {
    if (input) {
      setIsTableLoader(true)
      const globalSearchRes = await getAPI(
        `Charter/GetCharterDetailByVesselAsync?searchInput=` + input
      );
      if (
        globalSearchRes.Errors ||
        globalSearchRes.length == 0
      ) {
        setDataSource([]);
        setIsTableLoader(false)
      } else {
        numOfRecords = globalSearchRes[0].recordCount
          ? globalSearchRes[0].recordCount
          : 0;
        setTotalPages(numOfRecords / 10);

        // const startIdx = 1 * 10 - 10;
        // const endIdx = 1 * 10 - 1;
        // const recordsToRead = globalSearchRes.slice(startIdx, endIdx);
        setIsLoading(false);
        setDataSource(globalSearchRes);
        setIsTableLoader(false)
      }
    }
  };
  const onFilterClick = () => {
    isCommercialTab
      ? setIsFilterChange(!isFilterChange)
      : setIsFilterChangeSNP(!isFilterChangeSNP);
  };
  const onFilterClose = (close: any) => {
    setIsFilterChange(false);
    setIsFilterChangeSNP(false);
  };

  const onChangeLogClose = (close: any) => {
    setChangeLogModal(false);
    setChangeLogData(0);
  };
  const fetchCharterMrktData = async (
    page: number,
    itemsPerPage: number,
    data?: any
  ): Promise<void> => {
    setIsTableLoader(true);
    setTableSizeDdl(MdsConstants.ItemsPerPageDDl);
    let charterMkrtData: any = [];
    const postData = {
      id: 0,
      statusList: "Current,Next",
      imo: null,
      design: null,
      chartererId: null,
      charterTypeId: null,
      rate: null,
      redeliveryPortId: 0,
      redeliveryRangeId: 0,
      purchaseObligation: 0,
      fixtureOption: 0,
      purchaseOption: 0,
      deliveryDate: null,
      estRedeliveryDate: null,
      currencyId: 0,
      teuFrom: 0,
      teuTo: 0,
      managingOwner: null,
      isLocked: null,
    };
    if (data) {
      charterMkrtData = await CharterMarketService(page, itemsPerPage, data);
    } else {
      charterMkrtData = await CharterMarketService(
        page,
        itemsPerPage,
        postData
      );
    }
    setIsLoading(false);
    if (charterMkrtData?.Errors) {
      setDataSource([]);
    } else {
      if (charterMkrtData?.length > 0) {
        numOfRecords = charterMkrtData[0].recordCount
          ? charterMkrtData[0].recordCount
          : 0;
        setTotalPages(numOfRecords / itemsPerPage);

        // const startIdx = page * itemsPerPage - itemsPerPage;
        // const endIdx = page * itemsPerPage - 1;
        // const recordsToRead = charterMkrtData.slice(startIdx, endIdx);
        setIsLoading(false);
        setDataSource(charterMkrtData);
        setIsTableLoader(false);
        // setDownloadData(charterMkrtData);
      } else {
        setDataSource([]);
        setIsTableLoader(false);
      }
    }
  };

  // const columnSortHandler = (event: CustomEvent): void => {
  //   event.preventDefault();
  // };

  const closingUpload = (act: any, data: any, typeValue: any) => {
    setUploadModal(false);

    if (act.type === "submit") {
      setUploadCharterData(data);
      setValidateModal(true);
      setUploadType(act.source);
    }
  };
  const closeValidMsg = (val: any) => {
    setValidateModal(false);
    fetchCharterMrktData(1, 10, null);
  };
  const closeHoldModal = () => {
    setOnHoldModal(false);
    fetchCharterMrktData(1, 10, null);
  };
  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    setDataSource([]);
    if (data) setDataSource(data);
    else {
      setDataSource([]);
      setIsLoading(false);
      setHasError(true);
    }
  }, []);

  useEffect(() => {
    sourceID();
  }, []);

  const getPurchaseObj = (obj: any) => {
    if (obj == true) return "Yes";
    else if (obj == false) return "No";
    else return "None";
  };

  const getFixtureOptionValue = (val1: any, val2: any) => {
    if (val1 && val2) {
      let labelName: any = currency.filter((e: any) => e.value == val2);
      const result = val1 + labelName[0].label;
      return result;
    }
  };

  const getFixtureOptionPeriod = (val1: any, val2: any) => {
    if (val1 !== null && val2 !== null) {
      return val1 + "" + val2;
    } else {
      return " ";
    }
  };
  const downloadCM = async (e: any) => {
    // const postData1 = {
    //   id: 0,
    //   statusList: "Current,Next",
    //   imo: null,
    //   design: null,
    //   chartererId: null,
    //   charterTypeId: null,
    //   rate: null,
    //   redeliveryPortId: 0,
    //   redeliveryRangeId: 0,
    //   purchaseObligation: 0,
    //   fixtureOption: 0,
    //   purchaseOption: 0,
    //   deliveryDate: null,
    //   estRedeliveryDate: null,
    //   currencyId: 0,
    //   teuFrom: 0,
    //   teuTo: 0,
    //   managingOwner: null,
    //   isLocked: null,
    // };
    notify("File download started", true);
    if (isCommercialTab == false && e.type == "click") {
      setisDownloadClicked(true);
      setDownloadSnP(true);
    } else if (isCommercialTab == true && e.type == "click") {
      setDownloadSnP(false);
      let charterMkrtDownloadData = [];
      charterMkrtDownloadData = await CharterMarketService(
        0,
        0,
        filtersForDownld
      );

      setDownloadData(charterMkrtDownloadData);
      if (isCommercialTab) {
        let modified_rows: any = [];
        if (globalSearch) {
          modified_rows = tableData.map((e: any) => {
            return {
              "Vessel Name": e.vesselName,
              IMO: e.imo,
              TEU: e.teu,
              Design: e.design,
              Status: e.status,
              Charterer: e.chartererName,
              Type: e.charterTypeName,
              Rate: e.rate,
              Delivery:
                e.delivery != null
                  ? moment(e.delivery).format("DD-MMM-YYYY")
                  : e.delivery,
              Min: e.min != null ? moment(e.min).format("DD-MMM-YYYY") : e.min,
              Max: e.max != null ? moment(e.max).format("DD-MMM-YYYY") : e.max,
              Source: e.source,
              "Est.Redelivery": e.estRedelivery,
              "Redelivery Port": e.redeliveryPortName,
              "Redelivery Range": e.redeliveryRangeName,
              "Purchase Obligation": e.purchaseObligation,
              "Purchase Option": e.purchaseObligation,
              "Purchase Option Value": getFixtureOptionValue(
                e.purchaseOptionValue,
                e.purchaseOptionCurrencyId
              ),
              "Fixture Option": e.purchaseObligation,
              "Fixture Option Value": getFixtureOptionValue(
                e.fixtureOptionValue,
                e.fixtureOptionCurrencyId
              ),
              "Fixture Option Period": getFixtureOptionPeriod(
                e.fixtureOptionPeriod,
                e.fixtureOptionPeriodType
              ),
              UID: e.uid,
              Updated: moment(e.updateDate).format("DD-MMM-YYYY"),
              Comments: e.comment,
            };
          });
        } else {
          modified_rows = charterMkrtDownloadData?.map((e: any) => {
            return {
              "Vessel Name": e.vesselName,
              IMO: e.imo,
              TEU: e.teu,
              Design: e.design,
              Status: e.status,
              Charterer: e.chartererName,
              Type: e.charterTypeName,
              Rate: e.rate,
              Delivery:
                e.delivery != null
                  ? moment(e.delivery).format("DD-MMM-YYYY")
                  : e.delivery,
              Min: e.min != null ? moment(e.min).format("DD-MMM-YYYY") : e.min,
              Max: e.max != null ? moment(e.max).format("DD-MMM-YYYY") : e.max,
              Source: e.source,
              "Est.Redelivery": e.estRedelivery,
              "Redelivery Port": e.redeliveryPortName,
              "Redelivery Range": e.redeliveryRangeName,
              "Purchase Obligation": e.purchaseObligation,
              "Purchase Option": e.purchaseOption,
              "Purchase Option Value": getFixtureOptionValue(
                e.purchaseOptionValue,
                e.purchaseOptionCurrencyId
              ),
              "Fixture Option": e.fixtureOption,
              "Fixture Option Value": getFixtureOptionValue(
                e.fixtureOptionValue,
                e.fixtureOptionCurrencyId
              ),
              "Fixture Option Period": getFixtureOptionPeriod(
                e.fixtureOptionPeriod,
                e.fixtureOptionPeriodType
              ),
              UID: e.uid,
              Updated: moment(e.updateDate).format("DD-MMM-YYYY"),
              Comments: e.comment,
            };
          });
        }
        const header = [
          "Vessel Name",
          "IMO",
          "TEU",
          "Design",
          "Status",
          "Charterer",
          "Type",
          "Rate",
          "Delivery",
          "Min",
          "Max",
          "Source",
          "Est.Redelivery",
          "Redelivery Port",
          "Redelivery Range",
          "Purchase Obligation",
          "Purchase Option",
          "Purchase option Value",
          "Fixture Option",
          "Fixture Option Value",
          "Fixture Option Period",
          "UID",
          "Updated",
          "Comments",
        ];

        excelDownload("Commercial_Data", modified_rows, header)
        setDownloadSnP(false);
        setFiltersForDownload({});
        notify("File downloaded successfully", true);
      } else {
      }
    }
  };

  const snpDownloaded = () => {
    setDownloadSnP(false);
  };
  const openAddChrtMrktModal = () => {
    isCommercialTab ? setModal(true) : setSPModal(true);
  };
  const openOnholdDatatModal = () => {
    setOnHoldModal(true);
  };
  const openUploadModal = () => {
    setUploadModal(true);
  };

  const formObjFunc = (type, data: any) => {
    setFormObject(type,data);
    fetchCharterMrktData(1, 10, formData);
  };
  /* S &p filter object*/
  const formObjFuncSNP = (data: any) => {
    let formInitialDataSNP = {
      id: 0,
      statusids: data.statusList ? data.statusList.toString() : null,
      owner: data.owner ? data.owner : null,
      buyer: data.buyer ? data.buyer : null,
      chartererId: data.chartererId ? data.chartererId[0].value : 0,
      ToTeu: data.ToTeu ? Number(data.ToTeu) : 0,
      FromTeu: data.FromTeu ? Number(data.FromTeu) : 0,
      imo: null,
      fromDate: data.fromDate ? data.fromDate : null,
      toDate: data.toDate ? data.toDate : null,
    };
    setIsSNPfilterdata(formInitialDataSNP);
    setIsOnloadFilter(isOnloadFilter + 1);
  };
  const formAddObjFunc = (data: any, type: any) => {
    if (type == "submit") {
      setAddVslDetailsObject(data);
      validateCharterStatus(addformData);
    } else {
      setModal(false);
    }
  };

  /* S & P add object */
  const formAddSPObjFunc = (data: any) => {
    let addSNPformDataobj = {
      id: 0,
      imo: data.vslImo ? data.vslImo : 0,
      status: data.status ? data.status[0].label : null,
      date: data.dateOfStatus ? data.dateOfStatus : new Date(),
      owner: data.owner ? data.owner[0].label : null,
      buyer: data.buyer ? data.buyer[0].label : null,
      currency: data.currency ? data.currency[0].label : null,
      currencyId: data.currency ? data.currency[0].value : 0,
      price: data.price ? data.price.toString() : null,
      priceGuidance: data.priceGuidance ? data.priceGuidance : null,
      uid: data.uid ? data.uid : UID,
      source: data.source ? data.source[0].label : null,
      // enBlock: data.enBlock != "" ? data.enBlock : false,
      sourceId: data.source ? data.source[0].value : 0,
      updateDate: data.updateDate ? data.updateDate : null,
      comments: data.comments ? data.comments : null,
      typeId: 0,
      statusId: data.status ? data.status[0].value : 0,
      chartererId: data.chartererId ? data.chartererId[0].value : 0,
    };
    setAddSNPDetailsObject(addSNPformDataobj);
  };

  const validateCharterStatus = async (obj: any): Promise<void> => {
    const status = await postAPI(`Charter/ValidateCharterStatus`, obj);
    //validate API failure, display same error message
    if (status.StatusCode == 400 || status.message) {
      setTimeout(() => {
        setShowAddError(true);
      }, 1000);
      status.Message
        ? notify(status.Message, false)
        : notify(status.message, false);

      setShowAddError(false);

      setAddVslDetailsObject([]);
      setModal(false);
    }
    //validate message with empty response, call Add API API
    else {
      if (status.message == "" || (!status.message && !status.errors)) {
        setShowAddError(false);
        const addCharterMarket = await postAPI(`Charter/Add`, obj);
        //Add API returning true
        if (addCharterMarket) {
          setTimeout(() => {
            setShowAddSuccess(true);
          }, 1000);
          notify("Vessel details added successfully", true);
          fetchCharterMrktData(1, 10, null);
          setAddVslDetailsObject([]);
          setModal(false);
        }
        //Add API returning false or error
        else {
          if (addCharterMarket.StatusCode == 400) {
            setShowAddError(true);
          }
        }
      }
    }
  };

  const setDesignObj = (objArr: any, itemName: any) => {
    let arr: any = [];
    objArr.forEach((element: any) => {
      arr.push(element[itemName]);
    });
    return arr;
  };

  /* filters commercial data object*/
  const setFormObject = (type:any,data: any) => {
    formData = {
      id: 0,
      statusList: data.statusList ? data.statusList.toString() : type === 'submit' ? null : 'Current,Next',
      imo: data.imo ? data.imo : null,
      design: data.design ? setDesignObj(data.design, "label") : null,
      chartererId: data.chartererId
        ? setDesignObj(data.chartererId, "value")
        : null,
      charterTypeId: data.charterTypeId
        ? setDesignObj(data.charterTypeId, "value")
        : null,
      redeliveryPortId: data.redeliveryPortId
        ? data.redeliveryPortId[0].value
        : 0,
      redeliveryRangeId: data.redeliveryRangeId
        ? data.redeliveryRangeId[0].value
        : 0,
      purchaseObligation: data.purchaseObligation
        ? data.purchaseObligation[0].value
        : null,
      purchaseOption: data.purchaseOption ? data.purchaseOption[0].value : null,
      fixtureOption: data.fixtureOption ? data.fixtureOption[0].value : null,
      delivery: data.deliveryDate ? data.deliveryDate : null,
      estRedelivery: data.estRedeliveryDate ? data.estRedeliveryDate : null,
      currencyId: data.currencysItems ? data.currencysItems[0].value : 0,
      isLocked:
        data.isLocked !== undefined &&
        data.isLocked !== "undefined" &&
        data.isLocked !== null
          ? data.isLocked === "true" ? true : false
          : null,
      comment: data.comment ? data.comment : null,
      sourceId: data.source ? setDesignObj(data.source, "value") : null,
      managingOwner: data.managingOwner
        ? setDesignObj(data.managingOwner, "label")
        : null,
      uid: data.uid ? data.uid : 0,
      min: data.min ? data.min : 0,
      max: data.max ? data.max : 0,
      teuFrom: data.teuFrom ? data.teuFrom : 0,
      teuTo: data.teuTo ? data.teuTo : 0,
    };
    setFiltersForDownload(formData);
  };

  /*add commercial data object*/
  const setAddVslDetailsObject = (data: any) => {
    addformData = {
      id: 0,
      imo: data.imo ? data.imo : 0,
      chartererId: data.chartererId ? data.chartererId[0].value : 0,
      charterTypeId: data.chartererType ? data.chartererType[0].value : 0,
      delivery: data.deliveryDate ? data.deliveryDate : null,
      min: data.minDate ? data.minDate : null,
      max: data.maxDate ? data.maxDate : null,
      managingOwner: data?.ManagingOwner ? data.ManagingOwner : "",
      estRedelivery: data.estRedeliveryDate ? data.estRedeliveryDate : null,
      redeliveryPortId: data.redeliveryPortId
        ? data.redeliveryPortId[0].value
        : 0,
      redeliveryRanges: data.redeliveryRanges ? data.redeliveryRanges : [],
      purchaseObligation: data.purchaseObligation
        ? getPurchaseObligationValue(data.purchaseObligation[0].label)
        : null,
      isLocked: true,
      currencyId:
        data.currencysItems !== undefined &&
        data.currencysItems !== "undefined" &&
        data.currencysItems !== null
          ? data.currencysItems[0].value
          : null,
      rate:
        data.rate !== undefined &&
        data.rate !== "undefined" &&
        data.rate !== null &&
        data.rate !== ""
          ? data.rate
          : null,
      uid: data.uid ? data.uid : UID,
      // sourceId: sID,
      sourceId: 9,
      comment: data.comments ? data.comments : null,
      fixtureOption: data.fixtureOption
        ? getPurchaseObligationValue(data.fixtureOption[0].label)
        : null,
      fixtureOptionPeriod: data.fixtureOptionPeriod
        ? Number(data.fixtureOptionPeriod)
        : null,
      fixtureOptionPeriodType: data.fixtureOptionPeriodType
        ? data.fixtureOptionPeriodType[0].label
        : null,
      fixtureOptionValue: data.fixtureOptionValue
        ? Number(data.fixtureOptionValue)
        : null,
      fixtureOptionCurrencyId: data.fixtureOptionCurrencyId
        ? data.fixtureOptionCurrencyId[0].value
        : null,
      purchaseOption: data.purchaseOption
        ? getPurchaseObligationValue(data.purchaseOption[0].label)
        : null,
      purchaseOptionValue: data.purchaseOptionValue
        ? Number(data.purchaseOptionValue)
        : null,
      purchaseOptionCurrencyId: data.purchaseOptionCurrencyId
        ? data.purchaseOptionCurrencyId[0].value
        : null,
      fuelClassId: data.fuelClass ? data.fuelClass[0].value : null,
    };
  };

  const sourceID = async () => {
    const sourcesData = await getAPI(`Filter/GetAllSources`);
    const sourceId = sourcesData?.filter((e: any) => {
      if (e.label == "Manual") {
        return e.value;
      }
    });
    sID = sourceId?.[0]?.value;
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const isSNPAddModalClose = (val: any) => {
    setSPModal(!val);
  };
  const charterMarketAddClose = (close: any) => {
    setAddVslDetailsObject([]);
    setModal(false);
  };

  const charterMarketAddCloseSnP = (close: any) => {
    setAddSNPDetailsObject([]);
    setSPModal(false);
  };
  const getPurchaseObligationValue = (val: any) => {
    if (val == "Yes") {
      return true;
    } else if (val == "No") {
      return false;
    } else {
      return null;
    }
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const deleteCMFunc = (type: string) => {
    if (type === "delete") {
      deleteAPI(`Charter/Delete` + `/` + deleteData).then((resp) => {
        fetchCharterMrktData(1, 10, null);
        setIsDeleteModal(false);
        if (resp === true) {
          toast.success("Deleted successfully", {
            theme: "colored",
            icon: false,
          });
        } else {
          toast.error("Delete Failed", {
            theme: "colored",
            icon: false,
          });
        }
      });
    } else {
      setIsDeleteModal(false);
    }
  };
  const modalCloseFuc = (type: string, data: any) => {
    if (type === "submit") {
      const validateCharterMarketEdit = postAPI(
        `Charter/ValidateCharterOnEdit`,
        data
      );
      postAPI(`Charter/Update`, data).then((resp) => {
        fetchCharterMrktData(1, 10, null);
        setIsEditModal(false);
        if (resp === true) {
          toast.success("Updated successfully", {
            theme: "colored",
            icon: false,
          });
        } else {
          toast.error("Update Failed", {
            theme: "colored",
            icon: false,
          });
        }
      });
    } else {
      setIsEditModal(false);
    }
  };
  const onClearFilter = () =>{
    if(isCommercialTab){
      fetchCharterMrktData(1, 10, null);
      setGlobalSearchText('')
    }else{
      formObjFuncSNP([])
      setGlobalSearchText('')
    }
  }
  const fetchOnLoadEditData = async () => {
    await getAPI("Filter/GetAllCharterer").then((result) => {
      setChartererOption(result);
    });
    await getAPI("Filter/GetAllCharterType").then((result) => {
      setChartererType(result);
    });
    await getAPI("Filter/GetAllCurrency").then((result) => {
      setCurrency(result);
    });
  };
  React.useEffect(() => {
    fetchCharterMrktData(1, 10, null);
    fetchOnLoadEditData();
  }, []);
  const ExpandedComponent = ({ data }: any) => {
    const onHandleEditChange = (data: any) => {
      setEditData(data);
      setIsEditModal(true);
    };

    const onDelete = (data: any) => {
      setDeleteData(data.id);
      setIsDeleteModal(true);
    };
    const onChangeLog = (data: any) => {
      setChangeLogData(data.id);
      setChangeLogModal(true);
    };

    return (
      <div className="subColoum">
        <div className="edit-options">
          <ul className="icons-list">
            <li>
              <McIcon
                data-testid="charter-market-edit"
                icon="pencil"
                onClick={() => onHandleEditChange(data)}
              ></McIcon>
            </li>
            <li>
              <McIcon
                data-testid="charter-market-change-log"
                icon="arrow-clockwise-clock"
                onClick={() => onChangeLog(data)}
              ></McIcon>
            </li>
            <li>
              <McIcon icon="trash" data-testid="chater-marktet delete" onClick={() => onDelete(data)}></McIcon>
            </li>
          </ul>
        </div>
        <div className="header-section">
          <div className="row">
            <div className="col">
              <h5>Comments</h5>
            </div>
            <div className="col">
              <h5>Updated</h5>
            </div>
            <div className="col">
              <h5>UID</h5>
            </div>
            <div className="col">
              <h5>Managing Owner</h5>
            </div>
            <div className="col">
              <h5>Source</h5>
            </div>
            <div className="col">
              <h5>Type</h5>
            </div>
            <div className="col">
              <h5>Design</h5>
            </div>
          </div>
        </div>
        <div className="data-section">
          <div className="row">
            <div className="col" data-testid="charter-market-comment">{data.comment ? data.comment : ""}</div>
            <div className="col ltr" data-testid="charter-market-update-date">
              {data.updateDate !== null
                ? moment(data.updateDate).format("DD-MMM-YYYY")
                : ""}
            </div>
            <div className="col" data-testid="charter-market-uid">{data.uid}</div>
            <div className="col" data-testid="charter-market-managing-owner">{data.managingOwner}</div>
            <div className="col" data-testid="charter-market-source">{data.source}</div>
            <div className="col" data-testid="charter-market-charter-type-name">{data.charterTypeName}</div>
            <div className="col" data-testid="charter-market-design">{data.design}</div>
          </div>
        </div>

        <div className="header-section">
          <div className="row">
            <div className="col">
              <h5>Fixture Option Value</h5>
            </div>
            <div className="col">
              <h5>Fixture Option Period</h5>
            </div>
            <div className="col">
              <h5>Fixture Option</h5>
            </div>
            <div className="col">
              <h5>Purchase Option Value</h5>
            </div>
            <div className="col">
              <h5>Purchase Option</h5>
            </div>
            <div className="col">
              <h5>Purchase</h5>
            </div>
            <div className="col">
              <h5>Fuel Class</h5>
            </div>
          </div>
        </div>
        <div className="data-section2">
          <div className="row">
            <div className="col" data-testid="fixture-option-value">
              {data.fixtureOptionValue && data.fixtureOptionCurrencyId
                ? getFixtureOptionValue(
                    data.fixtureOptionValue,
                    data.fixtureOptionCurrencyId
                  )
                : ""}
            </div>
            <div className="col" data-testid="fixture-option-period">
              {data.fixtureOptionPeriod && data.fixtureOptionPeriodType
                ? getFixtureOptionPeriod(
                    data.fixtureOptionPeriod,
                    data.fixtureOptionPeriodType
                  )
                : ""}
            </div>
            <div className="col" data-testid="fixture-option">{getPurchaseObj(data.fixtureOption)}</div>
            <div className="col" data-testid="purchase-option-value">
              {data.purchaseOptionValue && data.purchaseOptionCurrencyId
                ? getFixtureOptionValue(
                    data.purchaseOptionValue,
                    data.purchaseOptionCurrencyId
                  )
                : ""}
            </div>
            <div className="col" data-testid="purchase-option">{getPurchaseObj(data.purchaseOption)}</div>
            <div className="col" data-testid="purchase">{getPurchaseObj(data.purchaseObligation)}</div>
            <div className="col" data-testid="fuel-class">{data.fuelClassName}</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="sub-section">
          <div className="col-md-4">
            <div className="global-search">
              <McInput
                data-testid="charter-market-search-box"
                className="search-box"
                onChange={(e: any) => setGlobalSearchText(e.target.value)}
                onKeyDown={keyDown}
                icon="magnifying-glass"
                iconposition="left"
                value={globalsearchText}
                label=""
                ariaPlaceholder="Search by vessel name, ex-name or IMO"
                fit="small"
              ></McInput>
            </div>
          </div>
          <div className="col-md-2">
            <button role='filter-button' data-testid="charter-market-filter-button" onClick={onFilterClick} className="filter-button">
              <span>
                <McIcon icon="sliders-horizontal"></McIcon>
              </span>
              <span>Filter</span>
            </button>
            <button data-testid="charter-market-clear-filter-button" onClick={onClearFilter} className="clearfilter-button">
              <span>Clear</span>
            </button>
          </div>
          {isFilterChange && (
            <CharterMarketFilters
              closeFilterFunc={onFilterClose}
              onDataFunc={formObjFunc}
            />
          )}
          {isFilterChangeSNP && (
            <CharterMarketFiltersSNP
              closeFilterFunc={onFilterClose}
              onDataFunc={formObjFuncSNP}
            />
          )}
          <div className="subColoum">
            <div className="tab-components">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  data-testid="charter-data"
                  type="button"
                  onClick={() => {
                    setIsCommercialTab(true);
                    setGlobalSearchText('');
                    fetchCharterMrktData(1, 10, null);
                  }}
                  className={`btn btn-primary ${isCommercialTab && "active"}`}
                >
                  Charter Data
                </button>
                <button
                  data-testid="snp-data"
                  type="button"
                  onClick={() => {
                    setIsCommercialTab(false);
                    setAddSNPDetailsObject([]);
                    setGlobalSearchText('')
                  }}
                  className={`btn btn-primary ${!isCommercialTab && "active"}`}
                >
                  Sales and Purchase Data
                </button>
              </div>
            </div>
            <div className="edit-options">
              <ul className="options-list">
                {isCommercialTab && (
                  <li title={"MisMatched Record"}>
                    <McIcon
                      data-testid="open-onhold-data-modal"
                      icon="list-bullets"
                      onclick={openOnholdDatatModal}
                      color="#fccb4e"
                    ></McIcon>
                  </li>
                )}
                <li>
                  <McIcon
                    data-testid="open-add-charter-market"
                    title={"Add"}
                    icon="plus"
                    onClick={openAddChrtMrktModal}
                  ></McIcon>
                </li>
                {isCommercialTab && (
                  <li title={"Upload"}>
                    <McIcon
                      data-testid="open-upload-modal"
                      icon="tray-arrow-up"
                      onClick={openUploadModal}
                    ></McIcon>
                  </li>
                )}
                <li title={"Download"}>
                  <McIcon icon="tray-arrow-down" data-testid="open-download" onClick={downloadCM}></McIcon>
                </li>
              </ul>
            </div>
          </div>
          {isEditModal && (
            <EditModal
              onModalClose={modalCloseFuc}
              charterOption={chartererOption}
              charterType={chartererType}
              currencyOption={currency}
              // reDeliveryRangeOption={reDelivaryRange}
              OpenModal={isEditModal}
              data={editData}
            />
          )}
          <DeleteModal
            onDeleteOpen={isDeleteModal}
            data={deleteData}
            onDeleteModalClose={deleteCMFunc}
          />
          <ChangeLogModal
            onChangeLogOpen={changeLogModal}
            logId={changeLogData}
            closeChangeLogFunc={onChangeLogClose}
          />
          {modal && (
            <AddModalCharter
              onAddDataFunc={formAddObjFunc}
              onOpen={modal}
              onAddClose={charterMarketAddClose}
            />
          )}
          {spmodal && (
            <SPModal
              onAddSPDataFunc={formAddSPObjFunc}
              onSPOpen={spmodal}
              onAddSnPClose={charterMarketAddCloseSnP}
            />
          )}
          <UploadModal onOpen={uploadmodal} closeUpload={closingUpload} />
          <OnholdModal
            onOpen={onHoldModal}
            closeValid={closeHoldModal}
          ></OnholdModal>
          <ValidateModal
            onOpen={validateModal}
            closeValid={closeValidMsg}
            uploadFile={uploadType}
            pageData={uploadCharterData}
          />
          {isCommercialTab && (
            <div className="panel  panelCommercial bg-white">
              <div slot="panel" style={{ margin: "0px" }}>
                <DataTable
                  columns={columns}
                  data={tableData}
                  expandableRows
                  progressPending={isTableLoader}
                  progressComponent={
                    <SkeletonLoader isLoader={true}></SkeletonLoader>
                  }
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  expandableRowsComponent={ExpandedComponent}
                />
              </div>
            </div>
          )}
          {!isCommercialTab && (
            <div className="panel  panelSalesandPurchase">
              <div slot="panel">
                <SalesAndPurchase
                  ondownloadSnP={isdownloadSnP}
                  snpAfterDownload={snpDownloaded}
                  isDownloadClicked={isDownloadClicked}
                  isFilterload={isOnloadFilter}
                  dataObject={isSNPfilterdata}
                  addObj={addSNPformData}
                  onAddModalClose={isSNPAddModalClose}
                  globalSearchSelected={globalSearchSnP}
                  onSearch={globalsearchText}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CharterMarket;
