import { useState, useEffect } from "react";
import { McIcon, McButton } from "@maersk-global/mds-react-wrapper";
import "../InitiateOfferAccept.scss";
import InputSectionVO from "./InputSectionVO";
import "react-datepicker/dist/react-datepicker.css";
import BunkerQty from "./BunkerQtyModal";
import AdditionalDocumentModal from "../AdditionalDocuments/AdditionalDocumentModal";
import revertIcon from "../../../../assets/delay.png";
import direction from "../../../../assets/direction.png";
interface IPROPS {
  data?: any;
  submitData?: any;
  indicationId?: any;
  reRenderApiFunc?: any;
  accessType?: any;
  stageType?: any;
  isEditing?: any;
  mainInfoData?: any;
  dataBack?: any;
  standardDataLoaded?: any;
  isPrefilledCondition?: any;
}
const StandardOfferVO = ({
  data,
  submitData,
  indicationId,
  reRenderApiFunc,
  accessType,
  stageType,
  isEditing,
  mainInfoData,
  dataBack,
  standardDataLoaded,
  isPrefilledCondition,
}: IPROPS) => {
  //mainData used for handle all the data filtering
  const [mainData, setMainData] = useState<any>([]);
  const [termData, setTermData] = useState<any>([]);
  //keyData is for filterArray and display in JSX
  const [keyData, setKeyData] = useState<any>();
  const [isBunkerQty, setIsBunkerQty] = useState<any>(false);
  const [selectedId, setSelectedId] = useState<any>(1);
  const [leftList, setLeftList] = useState<any>([]);
  const [addDocOpen, setAddDocOpen] = useState<boolean>(false);
  const [contentPosition, setContentPosition] = useState<any>(0);
  const [displayContent, setDisplayContent] = useState<any>("");

  const checkWriteAccess =
    accessType === "Write" ? (stageType ? false : true) : false;
  const handleLeftMenu = (id: any, response: any, leftMenu: any) => {
    const updatedList = leftMenu.map((item, index) => {
      if (item.id === id) {
        item.active = true;
        setContentPosition(index + 1);
      } else {
        item.active = false;
      }
      return item;
    });
    setLeftList(updatedList);
    setKeyData(response?.filter((item: any) => item.termId === id));
    setSelectedId(id);
    setDisplayContent("StandardTerms");
  };

  const handleOnTextchange = (value: any, itemValue: any) => {
    const newArray = mainData.map((val: any) => {
      if (val.termId === itemValue.termId) {
        val.subTerms.map((subtermData: any) => {
          if (subtermData.subTermId === itemValue.subTermId) {
            subtermData.details.map((detailsData: any) => {
              if (itemValue.standardTermDetailId == 56) {
                detailsData.inputs.map((inputData: any) => {
                  if (inputData.standardTermDetailId == 57) {
                    inputData.value = "";
                  }
                });
              }
              if (detailsData.detailId === itemValue.detailId) {
                detailsData.inputs.map((inputData: any) => {
                  if (
                    inputData.standardTermDetailId ===
                    itemValue.standardTermDetailId
                  ) {
                    inputData.value = value;
                  }
                  return inputData;
                });
              }
              return detailsData;
            });
          }
          return subtermData;
        });
      }
      return val;
    });
    setKeyData(newArray?.filter((item: any) => item.termId === selectedId));
    setMainData(newArray);
    if (dataBack) {
      dataBack(newArray);
    }
  };

  // const updateBunker = () =>{
  //   setIsBunkerQty(true)
  // }
  const handleRevertCheck = (e, itemData) => {
    const newArray = mainData.map((item: any) => {
      if (item.termId === itemData.termId) {
        item.subTerms.map((subtermData: any) => {
          if (subtermData.subTermId === itemData.subTermId) {
            subtermData.details.map((detailsData: any) => {
              if (detailsData.detailId === itemData.detailId) {
                detailsData.revertLater = e.target.checked;
              }
              return detailsData;
            });
          }
          return subtermData;
        });
      }
      return item;
    });
    setMainData(newArray);
    if (dataBack) {
      dataBack(newArray);
    }
  };
  const handleAdditionalDocument = () => {
    setDisplayContent("AdditionalDocuments");
    const updatedList = leftList.map((item) => ({
      ...item,
      active: false,
    }));
    setLeftList(updatedList);
    setAddDocOpen(true);
  };
  useEffect(() => {
    if (data) {
      let getMainId =
        selectedId > 1
          ? selectedId
          : data[0]?.termId
          ? data[0]?.termId
          : selectedId;
      const leftData = data?.map((item: any, index: any) => ({
        id: item.termId,
        name: item.termName,
        mainTermAccepted: item.mainTermAccepted,
        isReverted: item.revertLaterCounterEnabled,
        active: item.termId === getMainId ? true : false,
      }));
      setLeftList(leftData);
      setMainData(data);
      if (dataBack) {
        dataBack(data);
      }
      handleLeftMenu(getMainId, data, leftData);
    }
  }, [data]);
  useEffect(() => {
    setDisplayContent("StandardTerms");
  }, []);
  useEffect(() => {
    if (dataBack) {
      dataBack(mainData);
    }
  }, [mainData]);
  return (
    <div className="standardOffer-section">
      {/* <div className="standOffer-header-section"> */}
      {/* <div className="left-content">
          <span className="icon">
            {" "}
            <McIcon icon="info-circle" />
          </span>
          <span className="text">
            Fill out all the questions to generate your contract.
          </span>
        </div> */}
      {/* <div className="right-content">
           {submitData?.isStandardTermsSent && checkWriteAccess && isEditing &&  <McButton fit="small" className="additional-document" data-testid="additional-document" onClick={updateBunker}>Update Bunker Qty</McButton>}
            <BunkerQty isModalOpen={isBunkerQty} indicationId={indicationId} reRender={reRenderApiFunc} onAddModalClose={()=>setIsBunkerQty(false)}></BunkerQty>
        </div> */}
      {/* </div> */}
      <div className="data-section">
        <div className="leftSection">
          <div className="left-dataSection">
            <p
              className="main-heading"
              onClick={() => {
                setDisplayContent("StandardTerms");
              }}
            >
              Main Terms
            </p>
            <ul>
              {leftList.map((item) => (
                <li
                  className={`${item.active && "active"}`}
                  data-testid="left-menu-sov"
                  onClick={() => handleLeftMenu(item?.id, mainData, leftList)}
                >
                  {/* <span className="termsForAccpet"></span> */}
                  {item?.name}{" "}
                  {item?.mainTermAccepted && (
                    <span className="termAccpeted">
                      <McIcon icon="check-circle" color="#31ca31"></McIcon>
                    </span>
                  )}
                  {item.isReverted && (
                    <img
                      src={revertIcon}
                      className="revertClass"
                      alt="pencil-icon"
                    ></img>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="left-documentSection">
            <p
              className="additional-doc-heading"
              data-testid="additional-doc"
              onClick={handleAdditionalDocument}
            >
              <span>Additional Documents</span>
              <br />
              <span className="extra-text">(TCD and other document)</span>
              <McIcon
                icon="chevron-right"
                className="chevron-right-icon"
              ></McIcon>
            </p>
          </div>
        </div>

        <div className="right-dataSection">
          {standardDataLoaded && (
            <div className="each-dataSection">
              {displayContent === "StandardTerms" && keyData?.length !== 0 && (
                <>
                  <div className="header-div">
                    <span className="icon"> {keyData && contentPosition} </span>
                    <span className="text">
                      {keyData && keyData[0]?.termName} Main Terms
                    </span>
                  </div>
                  <div className="data-div">
                    {keyData &&
                      keyData[0]?.subTerms.map((subTermData: any, index) => (
                        <InputSectionVO
                          key={subTermData.subTermId}
                          subTermData={subTermData}
                          renderFunc={reRenderApiFunc}
                          revertChange={handleRevertCheck}
                          indicationId={indicationId}
                          submitData={submitData}
                          handleChangeFunc={handleOnTextchange}
                          checkWriteAccess={checkWriteAccess}
                          isEditing={isEditing && checkWriteAccess}
                          mainInfoData={mainInfoData}
                          isPrefilledCondition={isPrefilledCondition}
                        />
                      ))}
                  </div>
                </>
              )}
              {displayContent === "AdditionalDocuments" && (
                <>
                  <div className="header-div">
                    <span className="text">Additional Document Section</span>
                  </div>
                  <div className="data-div">
                    <AdditionalDocumentModal
                      userDetails={"VO"}
                      indicationId={indicationId}
                      accessType={checkWriteAccess}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {standardDataLoaded &&
            data?.length == 0 &&
            displayContent !== "AdditionalDocuments" && (
              <>
                <div className="no-mainterms">
                  <span className="image">
                    <img src={direction} />
                  </span>{" "}
                  <span>
                    "Please be advised that all main terms have been moved to
                    the running recap. Kindly refer to the running recap tab for
                    further details and to proceed with the next steps."
                  </span>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};
export default StandardOfferVO;