import { useState, useEffect } from "react";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import "./IndexHomePage.scss";
import EditorPage from "./EditorPage";
import { useLocation } from "react-router-dom";
import OfferTimeModal from "../StandardOffer/OfferNegotiationCM/offerTimeModal";
import RiderClausePage from "./RiderClausePage/RiderClausePage";
import editIcon from "../../../assets/edit-pencil.png";
import revertIcon from "../../../assets/delay.png";

interface IPROPS {
  indicationRequestId?: any;
  reRenderApiFunc?: any;
  BCPData?: any;
  SOData?: any;
  accessType?: any;
  userType?: any;
  stageType?: any;
  isEditing?: any;
  mainInfoData?: any;
  isPrefilledCondition?:any;
  extendedContractIDBCP?:any;
}

const IndexHomePage = ({
  indicationRequestId,
  reRenderApiFunc,
  BCPData,
  SOData,
  userType,
  accessType,
  stageType,
  isEditing,
  mainInfoData,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const [scrollID, setscrollID] = useState(0);
  const [newdataList, setNewDataList] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [offerTimeModal, setOfferTimeModal] = useState(false);
  const [bcpcontent, setBcpContent] = useState(null);
  const location = useLocation();
  const documentId = location?.state?.documentId;
  const [isIconUp, setIsIconUp] = useState<any>(true);
  const [id, setId] = useState(0);
  const [checkStartNegotiation, setCheckNegotiation] = useState(false);
  const [isBaseCharterParty, setIsBaseCharterParty] = useState(true);
  const checkWriteAccess =
    accessType === "Write" ? (stageType ? false : true) : false;
  useEffect(() => {
    getBaseCharterPartyDetails(BCPData);
    setIsBaseCharterParty(true);
  }, [BCPData]);

  const getBaseCharterPartyDetails = async (responseValue) => {
    setBcpContent(responseValue?.negotiationTimelineDto);
    setCheckNegotiation(
      responseValue?.negotiationTimelineDto === null ? false : true
    );
    // Grouping as per parentTitle
    let result: any = [];
    if (responseValue?.negotiationDocumentIndexes) {
      result = Object.values(
        responseValue?.negotiationDocumentIndexes?.reduce(
          (
            c,
            { title, parentTitle, id, isEdited, revertNextCounter, bcpStarted }
          ) => {
            c[title] = c[title] || {
              title,
              parentTitle: [],
              id: [],
              isEdited,
              bcpStarted,
              revertNextCounter,
            };
            c[title].parentTitle.push(parentTitle);
            c[title].id.push(id);
            return c;
          },
          {}
        )
      );
    }

    // Added as per new object baseCharterPartySideMenus
    let result1: any = [];
    if (responseValue?.baseCharterPartySideMenus) {
      setNewDataList(responseValue?.baseCharterPartySideMenus);
    }
    // Commenting below code as per addition of new object baseCharterPartySideMenus
    // Only headers
    // let arr: any = [];
    // result?.filter((n: any) => {
    //   // Added hire condition because Hire is not in uppercase but its a main title
    //   if (n.title == n.title.toUpperCase() || n.title == "Hire") {
    //     arr.push(n);
    //   }
    // });
    // setNewDataList(arr);

    const mainHeading = responseValue?.baseCharterPartyMainClauses?.filter(
      (ele: any, ind: any) => ele.parentId === null
    );
    // console.log(mainHeading);
    const subHeading = responseValue?.baseCharterPartyMainClauses?.filter(
      (ele: any, ind: any) => ele.parentId !== null
    );

    const missingMainHeadings = subHeading?.filter(
      (sub) => !mainHeading.some((main) => main.id === sub.parentId)
    );
    // console.log(missingMainHeadings, "missingMainHeadings")

    const newMainHeadings = missingMainHeadings?.reduce((item, sub) => {
      if (!item.some((main) => main.id === sub.parentId)) {
        item.push({
          id: sub.parentId,
          parentId: null,
          title: null,
          parentTitle: sub.parentTitle,
        });
      }
      return item;
    }, []);
    // console.log(newMainHeadings, "newMainHeadings")
    let allMainHeadings;
    if(mainHeading && newMainHeadings){
      allMainHeadings = [...mainHeading, ...newMainHeadings].sort(
        (a:any, b:any) => a.id - b.id
      );
    }
    // console.log(allMainHeadings, "allMainHeadings")

    const newArrayList: any = allMainHeadings?.map((main) => ({
      ...main,
      newSub: subHeading.filter((sub) => sub.parentId === main.id),
    }));

    // const newArrayList = mainHeading?.map((k: any) => ({
    //   ...k,
    //   newSub: subHeading.filter((ele) => ele.parentId == k.id),
    // }));

    setContentData(newArrayList);
  };

  const scrollTo = (id: any) => {
    setscrollID(id);
  };

  const closeSubmitModal = () => {
    setOfferTimeModal(false);
  };
  const CheckForDropdown = (id: any) => {
    setId(id);
    setIsIconUp("up");
  };
  const CheckForDropdownUp = (id: any) => {
    setId(id);
    setIsIconUp("down");
  };
  useEffect(() => {
    if (isBaseCharterParty) {
      // if(!isPrefilledCondition){
        reRenderApiFunc();
      // }
    }
  }, [isBaseCharterParty]);
  return (
    <>
      <div className="main bcpSection-main">
        <div className="bcp-header-section">
          <div className="left-content">
            <span className="icon">
              {" "}
              <McIcon icon="info-circle" />
            </span>
            <span className="text">
              Change the clause of the{" "}
              {isBaseCharterParty ? "Charter Party" : "Rider Clauses"}
            </span>
          </div>
        </div>
        <div className="subColoum">
          <div className="tab-components" style={{ marginBottom: "15px" }}>
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                data-testid="main-body-tab"
                type="button"
                onClick={() => {
                  setIsBaseCharterParty(true);
                }}
                className={`btn btn-primary bcp-subsection ${
                  isBaseCharterParty && "active"
                }`}
              >
                Main Body
              </button>
              <button
                data-testid="rider-clause-tab"
                type="button"
                onClick={() => {
                  setIsBaseCharterParty(false);
                }}
                className={`btn btn-primary rc-subsection ${
                  !isBaseCharterParty && "active"
                }`}
              >
                Rider Clauses
              </button>
            </div>
          </div>
          <>
            <div className="indexPage-BaseCharterParty">
              {!isBaseCharterParty && (
                <RiderClausePage
                  fromIssueRecap={false}
                  indicationRequestId={indicationRequestId}
                  checkNegotiationStatus={checkStartNegotiation}
                  isAccessType={checkWriteAccess}
                  isEditing={isEditing}
                  mainInfoData={mainInfoData}
                  isPrefilledCondition={isPrefilledCondition}
                  extendedContractIDBCP={extendedContractIDBCP}
                />
              )}
              {isBaseCharterParty && (
                <div className="frame">
                  <div className="data-section">
                    <div className="left-dataSection-baseCharterParty">
                      <p className="main-heading">Main Body</p>
                      <ul>
                        {newdataList?.map((item: any, index: any) => (
                          <li
                            data-testid="scrollTo"
                            className={`${item?.id == scrollID && "active"}`}
                            onClick={() => scrollTo(item?.id)}
                            key={item?.id}
                          >
                            {item?.title}
                            {item?.mainRevertNextCounter && (
                              <img
                                src={revertIcon}
                                className="revertClass"
                                alt="pencil-icon"
                              ></img>
                            )}
                            {item?.mainStatus == "Awaiting" && (
                              <img
                                src={editIcon}
                                className="pencilClass"
                                alt="pencil-icon"
                              ></img>
                            )}
                            {(item?.mainStatus == "Accept" || item?.mainStatus == "NegotiatedAndAccepted") && (
                              <span className="acceptedIcon">
                                <McIcon
                                  icon="check-circle"
                                  color="#31ca31"
                                ></McIcon>
                              </span>
                            )}
                            {item?.subHeadings?.length > 1 &&
                              (isIconUp == "up" && item?.id == id ? (
                                <McIcon
                                  data-testid="CheckForDropdownUp"
                                  icon="chevron-up"
                                  color="black"
                                  className="imageClass"
                                  onClick={() => CheckForDropdownUp(item?.id)}
                                ></McIcon>
                              ) : (
                                <McIcon
                                  data-testid="CheckForDropdown"
                                  icon="chevron-down"
                                  color="black"
                                  className="imageClass"
                                  onClick={() => CheckForDropdown(item?.id)}
                                ></McIcon>
                              ))}
                            {item?.subHeadings?.length > 1 &&
                              isIconUp == "up" &&
                              item?.id == id && (
                                <ul className="ul-list">
                                  {item?.subHeadings?.map(
                                    (data: any, index: any) =>
                                      data !== null ? (
                                        <div
                                          className="nested-list"
                                          key={index}
                                        >
                                          {data}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}
                                </ul>
                              )}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="right-dataSection-baseCharterParty">
                      <div className="each-dataSection">
                        {contentData?.map((item: any) => (
                          <EditorPage
                            indicationId={indicationRequestId}
                            fromIssueRecap={false}
                            arrData={item}
                            scrollId={scrollID}
                            onAPiLoad={reRenderApiFunc}
                            key={item.id}
                            documentId={documentId}
                            checkNegotiationStatus={checkStartNegotiation}
                            checkWriteAccess={checkWriteAccess}
                            isEditing={isEditing}
                            mainInfoData={mainInfoData}
                            isPrefilledCondition={isPrefilledCondition}
                            extendedContractIDBCP={extendedContractIDBCP}
                          ></EditorPage>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <></>
          </>
        </div>
      </div>
      <OfferTimeModal
        isModalOpen={offerTimeModal}
        onCloseFunc={closeSubmitModal}
        SOData={SOData}
        renderFunc={reRenderApiFunc}
        indicationId={indicationRequestId}
        checkWriteAccess={checkWriteAccess}
        negotiationType={[
          "Contract Document",
          "Submit the TimeLine",
          "Submit TimeLine",
        ]}
      />
    </>
  );
};

export default IndexHomePage;
