import DocumentLibraryTableVO from "./DocumentLibraryTableVO/DocumentLibraryTableVO";
import "./DocumentLibraryVO.scss";
import { McButton } from "@maersk-global/mds-react-wrapper";
import UploadDocumentLibraryVO from "../Negotiation/NegotiationOfferPages/StandardOffer/AdditionalDocuments/UploadDocumentLibraryVO";
import { useEffect, useRef, useState } from "react";
import { fileApiAxios, getAPI } from "../services/CepApiServices";
import { getUserName } from "../utils/token";
import moment from "moment";
const DocumentLibraryVO = () => {
  const [fileUploaded, setFileUploaded] = useState<File>();
  const [uploadModalValueDl, setUploadModalValueDl] = useState(false);
  const [progressStatus, setProgressStatus] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [pageReload, setPageReload] = useState(false);
  const [userName, setUserName] = useState<any>("");

  const receiveUploadedFile = (file: File) => {
    setFileUploaded(file);
  };

  const onLoadDataFetchDocumentLibrary = async () => {
    setIsTableLoader(true);
    const getDocumentLibraryList = await getAPI(
      `DocumentLibrary/GetOwnersAllDocuments`
    );
    setTableData(getDocumentLibraryList);
    setIsTableLoader(false);
  };

  useEffect(() => {
    const getUserNameAsync = async () => {
      const UID = await getUserName();
      setUserName(UID);
    };
    getUserNameAsync();
  }, []);

  const saveUploadedDocuments = async () => {
    const currentDateTime = new Date();
    const timeValue = moment(currentDateTime).format("YYYY-MM-DDTHH:mm:ss");
    const UploadedDocumentsFiles = {
      DocumentId: 0,
      DocumentName: fileUploaded?.name,
      createdOn: timeValue,
      createdBy: userName.username,
      Status: null,
      FormFile: fileUploaded,
    };
    let formData: any = new FormData();
    let index = 0;
    formData.append("document_id", UploadedDocumentsFiles.DocumentId);
    formData.append("DocumentName", UploadedDocumentsFiles.DocumentName);
    formData.append("CreatedOn", UploadedDocumentsFiles.createdOn);
    formData.append("CreatedBy", UploadedDocumentsFiles.createdBy);
    formData.append("ErrorMessage", UploadedDocumentsFiles.Status);
    formData.append("FormFile", UploadedDocumentsFiles.FormFile);

    if (fileUploaded) {
      await fileApiAxios("DocumentLibrary/SaveOwnersDocument", formData).then(
        (result) => {
          if (result.data == true) {
            setProgressStatus("success");
            setTimeout(function () {
              setUploadModalValueDl(false);
            }, 1500);
            onLoadDataFetchDocumentLibrary();
          } else {
            setProgressStatus("danger");
            setTimeout(function () {}, 1000);
          }
        }
      );
      setPageReload(true);
    }
  };

  const DocumentListChanges = (res: any) => {
    if (res === "No") {
      setPageReload(false);
    }
  };

  const UploadFun = () => {
    setUploadModalValueDl(true);
  };

  const closingUpload = () => {
    setUploadModalValueDl(false);
  };

  useEffect(() => {
    saveUploadedDocuments();
  }, [fileUploaded]);

  useEffect(() => {
    onLoadDataFetchDocumentLibrary();
  }, [pageReload]);

  return (
    <div className="document-library-cm">
      <div className="container-fluid">
        <div className="header-div-dl">
          <div className="row">
            <div className="col-md-3">
              <h5>Document Library</h5>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2 filter-buttons">
              <div className="uploadButtonDocumentLibraryVO">
                <McButton
                  fit="small"
                  icon="tray-arrow-up"
                  data-testid="upload-button"
                  onClick={UploadFun}
                >
                  Upload Document
                </McButton>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DocumentLibraryTableVO
            tableData={tableData}
            isTableLoader={isTableLoader}
            isDocumentListChanges={DocumentListChanges}
            rerenderFunc={onLoadDataFetchDocumentLibrary}
          />
        </div>
        {/* <div className="row">
          <div className="col-md-12">
            <span className="user-info-div">
              ** Please do not upload PDFs with images in Content..
            </span>
          </div>
        </div> */}

        <div data-testid="upload-file">
          <UploadDocumentLibraryVO
            onOpen={uploadModalValueDl}
            closeUpload={closingUpload}
            fileUpload={receiveUploadedFile}
            progressStatusRes={progressStatus}
            documentList={tableData}
          />
        </div>
      </div>
    </div>
  );
};
export default DocumentLibraryVO;
