// MDS
import { TableColumn } from "@maersk-global/mds-components-community/mc-c-table/types";
import { html, TemplateResult } from "lit";
import { TableCellTemplates } from "@maersk-global/mds-components-community/mc-c-table/table-cell-templates";
import moment from "moment";

export const CharterDetailsColumns: Array<TableColumn> = [
  {
    id: "status",
    label: "Status",
    width: "auto",
  },
  {
    id: "chartererName",
    label: "Charterer",
    width: "auto",
  },
  {
    id: "charterTypeName",
    label: "Charter Type",
    align: "left",
  },
  {
    id: "delivery",
    label: "Delivery",
    align: "center",
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span
          >${moment(details.value).format("DD-MMM-YYYY")}</span
        >`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "min",
    label: "Min",
    width: "auto",
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span
          >${moment(details.value).format("DD-MMM-YYYY")}</span
        >`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "max",
    label: "Max",
    width: "auto",
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span
          >${moment(details.value).format("DD-MMM-YYYY")}</span
        >`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "estRedelivery",
    label: "Est. Redelivery ",
    width: "auto",
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span
          >${moment(details.value).format("DD-MMM-YYYY")}</span
        >`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "redeliveryPortName",
    label: "Port",
    width: "auto",
  },
  {
    id: "currency",
    label: "Currency",
    width: "auto",
  },
  {
    id: "rate",
    label: "Rate",
    width: "auto",
  },
  {
    id: "uid",
    label: "UID",
    width: "auto",
  },
];

export const SalesPurchaseColumns: Array<TableColumn> = [
  {
    id: "type",
    label: "Type",
    width: "auto",
  },
  {
    id: "status",
    label: "Status",
    width: "auto",
  },
  {
    id: "date",
    label: "Date",
    align: "right",
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span
          >${moment(details.value).format("DD-MMM-YYYY")}</span
        >`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "owner",
    label: "Owner",
    align: "center",
  },
  {
    id: "buyer",
    label: "Buyer",
    width: "auto",
  },
  {
    id: "currency",
    label: "Currency",
    width: "auto",
  },
  {
    id: "currentRate",
    label: "Price Guide",
    width: "auto",
  },
  {
    id: "source",
    label: "Source",
    width: "auto",
  },
  {
    id: "uid",
    label: "UID",
    width: "auto",
  },
  {
    id: "updateDate",
    label: "Updated on",
    width: "auto",
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span
          >${moment(details.value).format("DD-MMM-YYYY")}</span
        >`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "comments",
    label: "Comments",
    width: "auto",
  },
];
