// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { McTab, McTabBar } from "@maersk-global/mds-react-wrapper";

// Styles
import "./VesselDetails.scss";
// Service
import { VesselDetailsService } from "../../services/VesselDetailsService";
import { CommercialDetailsService } from "../../services/CommercialDetailsService";
import { getAPI } from "../../services/ApiServices";

// Pages
import VesselDetailsTab from "../../pages/vessel_details_tab/VesselDetailsTab";
import CommercialDetails from "../../pages/commercial_details_tab/CommercialDetails";
import AISDetails from "../../pages/aisDetails/AISDetails";
import VesselSupplyDataList from "../../pages/vessel_supply_data_list/VesselSupplyDataList";

function VesselDetails(imo: any) {
  const [tabNo, setActiveTab] = useState();
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const [vslDetailsData, setVslDetailsData] = useState<any>([]);
  const [commercialDetailsData, setCommercialDetailsData] = useState<any>([]);
  const [aisDetailsData, setAISDetailsData] = useState<any>([]);
  const imoNumber = location?.state?.imo;
  const searchedField = location?.state?.searchText;
  let selectTab = false;
  const navigate = useNavigate();
  const yourChangeHandler = (event: any) => {
    document.getElementById("mydiv")!.innerHTML =
      event.target.tabs[event.target.currentindex].__label;
    if (event.detail !== undefined) {
      if (event.detail !== null) {
        setTab(event.detail);
      }
    }
    if (event.detail === 0) {
      fetchVesselDetails(imoNumber);
    }
    if (event.detail === 1) {
      fetchCommercialDetails(imoNumber);
    }
    if (event.detail == 2) {
      fetchAISDetails(imoNumber);
    }
  };

  const fetchVesselDetails = async (selectedRow: number) => {
    const response = await VesselDetailsService(selectedRow);
    if (response) {
      setVslDetailsData(response);
    }
  };

  const fetchCommercialDetails = async (selectedRow: number) => {
    const response = await CommercialDetailsService(selectedRow);
    if (response) {
      setCommercialDetailsData(response);
    }
  };

  const fetchAISDetails = async (selectedRow: number) => {
    const response = await getAPI(
      `LocationServiceDetails/GetLocationServiceDetails/` + selectedRow
    );
    if (response) {
      setAISDetailsData(response);
    }
  };

  useEffect(() => {
    fetchVesselDetails(imoNumber);
  }, []);

  const navigateToVslSupplyData = (): void => {
    localStorage.setItem("vslSupplyPage", "true");
    navigate("/");
  };

  return (
    <>
      <span className="tab-text breadcrumbs">
        <a className="highlight-link" onClick={navigateToVslSupplyData}>
          Global Vessel List
        </a>
        /<span id="mydiv">Vessel Details</span>
      </span>

      <div className="rectangle">
        <McTabBar
          currentindex={tab}
          change={yourChangeHandler}
          variant="stretched"
        >
          <McTab
            className="custom-height"
            slot="tab"
            label="Vessel Details"
            data-testid="vessel-details-tab"
          ></McTab>
          <div className="tab-row tab-section" slot="panel">
            <VesselDetailsTab selectedRow={imoNumber} data={vslDetailsData} />
          </div>
          <McTab
            slot="tab"
            label="Commercial Details"
            data-testid="commercial-details-tab"
          ></McTab>
          <div className="tab-row" slot="panel">
            <CommercialDetails
              selectedRow={imoNumber}
              data={commercialDetailsData}
            />
          </div>
          <McTab
            slot="tab"
            label="AIS Details"
            data-testid="ais-details-tab"
          ></McTab>
          <div className="tab-row" slot="panel">
            <AISDetails selectedRow={imoNumber} data={aisDetailsData} />
          </div>
        </McTabBar>
      </div>
    </>
  );
}

export default VesselDetails;
