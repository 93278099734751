import { useState, useEffect } from "react";
import { McIcon, McButton } from "@maersk-global/mds-react-wrapper";
import "../InitiateOfferAccept.scss";
import "react-datepicker/dist/react-datepicker.css";
import OfferTimeModal from "./offerTimeModal";
import InputSection from "./InputSection";
import AdditionalDocumentModal from "../AdditionalDocuments/AdditionalDocumentModal";
import revertIcon from "../../../../assets/delay.png";
import MoveToIssueRecapModal from "./MoveToIssueRecapModal";
import { postAPI } from "../../../../services/CepApiServices";
import direction from "../../../../assets/direction.png";
import AddNewTermsModal from "../AddNewTermsModal";
interface IPROPS {
  data?: any;
  submitData?: any;
  indicationId?: any;
  reRenderApiFunc?: any;
  leftmenu?: any;
  accessType?: any;
  stageType?: any;
  isEditing?: any;
  dataBack?: any;
  mainInfoData?: any;
  standardDataLoaded?: any;
  reRenderNegotiationDetail?: any;
  isPrefilledCondition?: any;
  recapStatus?:any;
}
const StandardOfferCM = ({
  data,
  submitData,
  indicationId,
  reRenderApiFunc,
  accessType,
  stageType,
  isEditing,
  dataBack,
  mainInfoData,
  standardDataLoaded,
  reRenderNegotiationDetail,
  isPrefilledCondition,
  recapStatus,
}: IPROPS) => {
  const [mainData, setMainData] = useState<any>([]);
  const [keyData, setKeyData] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>(1);
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [leftList, setLeftList] = useState<any>([]);
  const [isSendError, setIsSendError] = useState<any>(false);
  const [addDocOpen, setAddDocOpen] = useState<boolean>(false);
  const [displayContent, setDisplayContent] = useState<any>("");
  const [contentPosition, setContentPosition] = useState<any>(0);
  const [isStandardTermsSending, setIsStandardTermsSending] =
    useState<any>(false);
  const [moveToIssueRecapModal, setMoveToIssueRecapModal] =
    useState<boolean>(false);
    const [isAddNewTermsOpen, setIsAddNewTermsOpen] = useState(false);

  const isWriteAccess =
    accessType === "Write" ? (stageType ? false : true) : false;
  const handleLeftMenu = (id: any, response: any, leftMenu: any) => {
    const updatedList = leftMenu.map((item, index) => {
      if (item.id === id) {
        item.active = true;
        setContentPosition(index + 1);
      } else {
        item.active = false;
      }
      return item;
    });
    setLeftList(updatedList);
    setKeyData(response?.filter((item: any) => item.termId === id));
    setSelectedId(id);
    setDisplayContent("StandardTerms");
  };

  const handleOnTextchange = (value: any, itemValue: any) => {
    const newArray = mainData.map((val: any) => {
      if (val.termId === itemValue.termId) {
        val.subTerms.map((subtermData: any) => {
          if (subtermData.subTermId === itemValue.subTermId) {
            subtermData.details.map((detailsData: any) => {
              if (itemValue.standardTermDetailId == 56) {
                detailsData.inputs.map((inputData: any) => {
                  if (inputData.standardTermDetailId == 57) {
                    inputData.value = "";
                  }
                });
              }
              if (detailsData.detailId === itemValue.detailId) {
                detailsData.inputs.map((inputData: any) => {
                  if (
                    inputData.standardTermDetailId ===
                    itemValue.standardTermDetailId
                  ) {
                    inputData.value = value;
                  }
                  return inputData;
                });
              }
              return detailsData;
            });
          }
          return subtermData;
        });
      }
      return val;
    });
    setKeyData(newArray?.filter((item: any) => item.termId === selectedId));
    setMainData(newArray);
    if (dataBack) dataBack(newArray);
  };

  const radioChange = (checkedVal: any, itemValue: any) => {
    const newArray = mainData.map((val: any) => {
      if (val.termId === itemValue.termId) {
        val.subTerms.map((subtermData: any) => {
          if (subtermData.subTermId === itemValue.subTermId) {
            subtermData.details.map((detailsData: any) => {
              const otherInputObj = detailsData?.inputs?.filter(
                (inputData: any) =>
                  inputData.dataType === "SelectedCheckbox" &&
                  inputData.detailId !== itemValue.detailId
              )[0];

              if (detailsData.detailId === itemValue.detailId) {
                detailsData.inputs.map((inputData: any) => {
                  if (
                    inputData.standardTermDetailId ===
                    itemValue.standardTermDetailId
                  ) {
                    inputData.value = String(checkedVal.target.checked);
                  }
                  return inputData;
                });
              }
              if (otherInputObj) {
                if (detailsData.detailId === otherInputObj?.detailId) {
                  detailsData.inputs.map((inputData: any) => {
                    if (
                      inputData.standardTermDetailId ===
                      otherInputObj.standardTermDetailId
                    ) {
                      inputData.value = String(!checkedVal.target.checked);
                    }
                    return inputData;
                  });
                }
              }
              return detailsData;
            });
          }
          return subtermData;
        });
      }
      return val;
    });
    setKeyData(newArray?.filter((item: any) => item.termId === selectedId));
    setMainData(newArray);
    if (dataBack) dataBack(newArray);
  };
  const closeSubmitModal = () => {
    setIsSubmit(false);
    setIsStandardTermsSending(false);
  };
  const handleAdditionalDocument = () => {
    setDisplayContent("AdditionalDocuments");
    const updatedList = leftList.map((item) => ({
      ...item,
      active: false,
    }));
    setLeftList(updatedList);
    setAddDocOpen(true);
  };
  useEffect(() => {
    if (data) {
      let getMainId =
        selectedId > 1
          ? selectedId
          : data[0]?.termId
          ? data[0]?.termId
          : selectedId;
      const leftData = data?.map((item: any, index: any) => ({
        id: item.termId,
        name: item.termName,
        mainTermAccepted: item.mainTermAccepted,
        isReverted: item.revertLaterCounterEnabled,
        active: item.termId === getMainId ? true : false,
      }));
      setLeftList(leftData);
      setMainData(data);
      if (dataBack) dataBack(data);
      handleLeftMenu(getMainId, data, leftData);
    }
  }, [data]);
  useEffect(() => {
    setDisplayContent("StandardTerms");
  }, []);
  const handleMoveBtnClick = () => {
    setMoveToIssueRecapModal(true);
  };

  const fetchRunningRecapForMainTerms = async (data) => {
    const termIds = String(data);
    const response = await postAPI(
      `InitialOffer/EnableRunningRecapForMainTerms?indicationId=${indicationId}&termIds=${termIds}`
    );
  };

  const closeMoveToIssueRecapModal = (type: string, data: any) => {
    if (type === "OK") {
      setMoveToIssueRecapModal(false);
      const arr: any = [];
      for (let item of data) {
        arr.push(item?.termId);
      }
      fetchRunningRecapForMainTerms(arr);
      reRenderApiFunc();
      reRenderNegotiationDetail();
      setSelectedId(1);
    } else {
      setMoveToIssueRecapModal(false);
    }
  };

  const handleAddNewTerms = () => {
    setIsAddNewTermsOpen(true);
  };
  
  const closeAddNewTerms = () => {
    setIsAddNewTermsOpen(false);
  };

  const reRenderApiMainTerms = () => {
    reRenderNegotiationDetail();
    // if(mainInfoData.isExtended) {
    //   getPrefilledRiderClauseData();
    // } else {
    //   getRiderClauseData();
    // }
  };

  return (
    <div className="standardOffer-section">
      {accessType === "Write" &&
        mainInfoData.counterNumber !== 0 &&
        data?.length !== 0 && (
          <div className="standOffer-header-section">
            <div className="right-ir-button">
              <McButton
                data-testid="recap-button"
                fit="small"
                label="Move to Running Recap"
                className="edit-button"
                onClick={handleMoveBtnClick}
              ></McButton>
            </div>
          </div>
        )}
      <div className="data-section">
        <div className="leftSection">
          <div className="left-dataSection">
            <p
              className="main-heading"
              onClick={() => {
                setDisplayContent("StandardTerms");
              }}
            >
              Main Terms
              {accessType && isEditing && !recapStatus?.isCmInitiatedIssueRecap &&  (
                      <span className="right-btn-add">
                        <button
                          className="main-terms-add-btn add-clause"
                          onClick={handleAddNewTerms}
                        >
                          Add Term
                        </button>
                      </span>
                    )}
            </p>
            <ul>
              {leftList.map((item) => (
                <li
                  className={`${item.active && "active"}`}
                  onClick={() => handleLeftMenu(item?.id, mainData, leftList)}
                >
                  {item?.name}{" "}
                  {item?.mainTermAccepted && (
                    <span className="termAccpeted">
                      <McIcon icon="check-circle" color="#31ca31"></McIcon>
                    </span>
                  )}
                  {item.isReverted && (
                    <img
                      src={revertIcon}
                      className="revertClass"
                      alt="pencil-icon"
                    ></img>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="left-documentSection">
            <p
              className="additional-doc-heading"
              onClick={handleAdditionalDocument}
              data-testid="handleAdditionalDocument"
            >
              <span>Additional Documents</span>
              <br />
              <span className="extra-text">(TCD and other document)</span>
              <McIcon
                icon="chevron-right"
                className="chevron-right-icon"
              ></McIcon>
            </p>
          </div>
        </div>

        <div className="right-dataSection">
          {standardDataLoaded && (
            <div className="each-dataSection">
              {displayContent === "StandardTerms" && keyData?.length !== 0 && (
                <>
                  <div className="header-div">
                    <span className="icon"> {keyData && contentPosition} </span>
                    <span className="text">
                      {keyData && keyData[0]?.termName} Main Terms
                    </span>
                  </div>
                  <div className="data-div">
                    {keyData &&
                      keyData[0]?.subTerms?.map((subTermData: any, index) => (
                        <InputSection
                          key={subTermData.subTermId}
                          subTermData={subTermData}
                          handleChangeRadio={radioChange}
                          renderFunc={reRenderApiFunc}
                          indicationId={indicationId}
                          submitData={submitData}
                          handleChangeFunc={handleOnTextchange}
                          checkWriteAccess={isWriteAccess}
                          isEditing={isEditing && isWriteAccess}
                          mainInfoData={mainInfoData}
                          isPrefilledCondition={isPrefilledCondition}
                        />
                      ))}
                  </div>
                </>
              )}
              {displayContent === "AdditionalDocuments" && (
                <>
                  <div className="header-div">
                    <span className="text">Additional Document Section</span>
                  </div>
                  <div className="data-div">
                    <AdditionalDocumentModal
                      userDetails={"CM"}
                      indicationId={indicationId}
                      accessType={isWriteAccess}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {standardDataLoaded &&
            data?.length == 0 &&
            displayContent !== "AdditionalDocuments" && (
              <>
                <div className="no-mainterms">
                  <span className="image">
                    <img src={direction} />
                  </span>{" "}
                  <span>
                    "Please be advised that all main terms have been moved to
                    the running recap. Kindly refer to the running recap tab for
                    further details and to proceed with the next steps."
                  </span>
                </div>
              </>
            )}
        </div>
      </div>
      {/* <div className="submit-section">
        <div className="btn-div" data-testid="submit-save-button">
          {!submitData?.isStandardTermsSent && (
            <span className="note-div">
              {" "}
              Note: <b>Bunker Period</b> and <b>Hire payment optional period</b> will
              not be considered if not selected
            </span>
          )}
        </div>
      </div> */}
      <OfferTimeModal
        isModalOpen={isSubmit}
        onCloseFunc={closeSubmitModal}
        onLoadData={mainData}
        submitData={submitData}
        renderFunc={reRenderApiFunc}
        indicationId={indicationId}
        checkWriteAccess={isWriteAccess}
        negotiationType={[
          "Standard Offer Document",
          "Submit the Standard Offer Terms",
          "Submit Standard Terms",
        ]}
      />
      {moveToIssueRecapModal && (
        <MoveToIssueRecapModal
          isModalOpen={moveToIssueRecapModal}
          indicationId={indicationId}
          onCanceled={closeMoveToIssueRecapModal}
          isPrefilledCondition={isPrefilledCondition}
        ></MoveToIssueRecapModal>
      )}

    <AddNewTermsModal
      indicateRequestId={indicationId}
      reloadApi={reRenderApiMainTerms}
      isModalOpen={isAddNewTermsOpen}
      onCloseFunc={closeAddNewTerms}
    />
      
    </div>
  );
};
export default StandardOfferCM;