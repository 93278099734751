import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  onModalOpen?: boolean;
  data?: any;
  onModalClose?: any;
  modalTxt:any;
  width?:any;
  height?:any;
}

const WarningModalPopup = ({ onModalOpen, onModalClose,modalTxt,width, height }: IPROPS) => {
  const finalWidth = width ? width : "auto";
  const finalHeight = height ? height : "auto";

  const closeModalFunc = () => {
    onModalClose();
  };

  return (
    <div>
      <McModal width={finalWidth} height={finalHeight} open={onModalOpen} heading="" backdropcloseactiondisabled>
        <p>{modalTxt}</p>
        <McButton
          slot="primaryAction"
          variant="primary"
          click={closeModalFunc}
          dialogAction="ok-btn"
          role="okay-btn"
          fit={"small"}
        >
          Ok
        </McButton>
      </McModal>
    </div>
  );
};

export default WarningModalPopup;
