// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import "./LandingPage.scss";

// Config
import { msalInstance } from "../../index";
import { getToken } from "../../utils/token";
import { protectedResources } from "../../authConfig";

// Pages
import VesselSupplyDataList from "../vessel_supply_data_list/VesselSupplyDataList";
import CharterMarket from "../charter-market/CharterMarket";

// Image
import shipImage from "../../assets/ship-image.png";

function LandingPage(props: any) {
  const [data, setData] = useState([]);
  const [chrMrktSelected, setChrtMrktSelected] = useState(false);

  if (props?.data?.length == 0 && props.searchText) {
    return (
      <div className="background-img">
        <span className="img-text">No searched data..</span>
        <br />
        <img
          className="ship-img"
          src={shipImage}
          alt="vessels-img"
        />
      </div>
    );
  }
  if (props?.data?.length >= 1 && props.selectedTab == 0) {
    return (
      <VesselSupplyDataList
        data={props.data}
        searchText={props.searchText}
        isExport={props.dwnldVslSupplyList}
        resetExport={props.resetDownloadVslSuppluList}
      />
    );
  } else {
    if (props?.data?.length >= 1 && props.selectedTab == 1) {
      return <CharterMarket data={undefined} searchText={undefined} />;
    }
  }

  return (
    <div className="background-img">
      <span className="img-text">
        Hi there! Search to find the relevant data related to specific vessel
        details.
      </span>
      <br />
      <img
        className="ship-img"
        src={shipImage}
        alt="vessels-img"
      />
    </div>
  );
}

export default LandingPage;
