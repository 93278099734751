export interface ContractLines {
    lineNumber: number;
    content: string;
    isContractLine?: boolean;
  }


  export type TContractLine = {
    lineNumber: number;
    content: string;
    isContractLine?: boolean;
  }

  export type TRiderClause = {
    id: number;
    content: string|null;
    contentType: contentType;
  }

  export enum contentType {
    Title,
    Content,
    History
  }

export type TDocument = TContractLine | TRiderClause;
