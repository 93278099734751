import moment from "moment";

export function extractDateOnly(dateTime: any) {
  if (dateTime) {
    const date = dateTime.split("T");
    return date[0];
  } else {
    return null;
  }
}

export const fetchTimeFormat = (dateTime: any) => {
  const date = dateTime.split("T");
  let newDate = date[1].split(":");
  return moment(dateTime).format("DD-MMM-YYYY") + " " + date[1];
};

export const fetchDateFormat = (dateTime: any) => {
  return moment(dateTime).format("DD-MMM-YYYY");
};
export const fetchYearMonthDateFormat = (dateTime: any) => {
  return moment(dateTime,'DD-M-YYYY').format("YYYY-MM-DD");
}
