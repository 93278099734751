import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import moment from "moment";
interface IPROPS {
  isModalOpen?: boolean;
  isCloseModal?: any;
  infoData?: any;
}
const VesselInfoModal = ({ isModalOpen, isCloseModal, infoData }: IPROPS) => {
  console.log("isModalOpen", infoData);
  return (
    <McModal
      height="500px"
      width="900px"
      className="vessel-info-modal"
      heading={`Vessel Based On AIS Simulation`}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="vessel-data">
        <div className="vessel-data-io input-section">
          <div className="row section-title">Output</div>
          <div className="row">
            <div className="col-md-4">
              <div className="header">Port Name</div>
              <div className="value">{infoData?.portInfo?.portName}</div>
            </div>
            <div className="col-md-4">
              <div className="header">Range</div>
              <div className="value">{infoData?.portInfo?.range}</div>
            </div>
            <div className="col-md-4">
              <div className="header">Entry Date</div>
              <div className="value">
                {moment(infoData?.portInfo?.entryDate).format("DD-MMM-YYYY")}
              </div>
            </div>
          </div>
        </div>

        <div className="vessel-data-io output-section">
          <div className="row">
            <div className="col-md-4">
              <div className="header">Port Rotaion</div>
              <div className="value">{infoData?.portRotaion}</div>
            </div>
            <div className="col-md-4">
              <div className="header">Contract Rate</div>
              <div className="value">{infoData?.contractRate}</div>
            </div>
            <div className="col-md-4">
              <div className="header">Rate Tobe Compared</div>
              <div className="value">{infoData?.rateTobeCompared}</div>
            </div>
          </div>
          <div className="row row2">
            <div className="col-md-4">
              <div className="header">New Start Date</div>
              <div className="value">
                {moment(infoData?.startDate).format("DD-MMM-YYYY")}
              </div>
            </div>
            <div className="col-md-4">
              <div className="header">New End Date</div>
              <div className="value">
                {moment(infoData?.endDate).format("DD-MMM-YYYY")}
              </div>
            </div>
          </div>
        </div>
        <div className="table-section">
          <div className="header">Initial Rotation Ports</div>
          <div className="table-div">
            <table className="table">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Entry Date</th>
                </tr>
              </thead>
              <tbody>
                {infoData?.initialRotationPortsForSimulation?.map((item: any) => {
                  return (
                    <>
                      <tr>
                        <td>{item?.portName}</td>
                        <td>{moment(item?.startPort).format("DD-MMM-YYYY")}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-section">
          <div className="header">Simulated Ports
            <span className="notes-div">
              <span className="note-part">{" "}Note :</span>
              <span className="content-part">{" "}Future Rotation ports Entry Date are calculated by adding rotation days to entry date</span>
            </span>
          </div>
          <div className="table-div">
            <table className="table">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Rotation</th>
                  <th>Entry Date</th>
                </tr>
              </thead>
              <tbody>
              {infoData?.simulatedPorts
                ?.sort((a, b) => {
                  const rotationComparison = a.rotation - b.rotation;
                  if (rotationComparison !== 0) {
                    return rotationComparison;
                  }
                  const dateComparator = new Date(b.startPort).getTime() - new Date(a.startPort).getTime();
                  return dateComparator;
                })
                .map((item: any) => (
                  <tr key={item.portName}>
                    <td>{item.portName}</td>
                    <td>{item.rotation}</td>
                    <td>{moment(item.startPort).format("DD-MMM-YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-section">
          <div className="header">Simulated Ports By NewDate Range <span className="from-to-dates">(from {moment(infoData?.startDate).format("DD-MMM-YYYY")} to {moment(infoData?.endDate).format("DD-MMM-YYYY")})</span></div>
          <div className="table-div">
            <table className="table">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Entry Date</th>
                  <th>Range Name</th>
                </tr>
              </thead>
              <tbody>
                {infoData?.simulatedPortsByNewDateRange?.map((item: any) => {
                  return (
                    <>
                      <tr>
                        <td>{item?.portName}</td>
                        <td>{moment(item?.startPort).format("DD-MMM-YYYY")}</td>
                        <td>{item?.rangeName}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        onClick={isCloseModal}
        role="cancel-cp"
        fit="small"
      >
        Cancel
      </McButton>
    </McModal>
  );
};
export default VesselInfoModal;
