import React, { useEffect, useRef, useState } from "react";
import "./inputDropdown.scss";

interface IPROPS {
  Defaultval?: string;
  optionList?: any;
  onDdlChange?: any;
}

export const InputDropdown = ({
  Defaultval,
  optionList,
  onDdlChange,
}: IPROPS) => {
  const [isDropDown, setIsDropDown] = useState(false);
  const [dropDownOption, setDropDownOption] = useState([]);
  const [textvalue, setTextvalue] = useState();

  const wrapperRef = useRef(null);
  useEffect(() => {
    setDropDownOption(optionList);
  }, [optionList]);
  function useOutsideAlerter(ref: any) {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDropDown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }
  useOutsideAlerter(wrapperRef);
  const changeKeyPress = (e: any) => {
    setIsDropDown(true);
    const filtered = optionList.filter((entry: any) =>
      entry.label.toLowerCase().includes(e.target.value.toLowerCase())
    );
    filtered.length == 0 ? setIsDropDown(false) : setIsDropDown(true);
    setDropDownOption(filtered);
    onDdlChange(e.target.value);
  };
  const selectDropdownvalue = (e: any) => {
    setTextvalue(e.target.outerText);
    const inputTextCheck = document.getElementById(
      "inputTextId"
    ) as HTMLInputElement;
    if (inputTextCheck) {
      inputTextCheck.value = e.target.outerText;
    }
    setIsDropDown(false);
    onDdlChange(e.target.outerText);
  };
  return (
    <div className="inputDropdown">
      <input
        data-testid="dropdown-input"
        type="text"
        className="inputText"
        id="inputTextId"
        defaultValue={Defaultval}
        onChange={changeKeyPress}
        autoComplete="off"
      ></input>
      {isDropDown && (
        <div ref={wrapperRef} className="suggestedValue">
          <ul>
            {dropDownOption?.map((item: any) => (
              <li key={item.label} onClick={selectDropdownvalue}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
