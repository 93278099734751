// React
import React, { useState, useEffect } from "react";
import { useForm, Controller, useFormContext } from "react-hook-form";

//MDS
import "@maersk-global/mds-components-core";
import {
  McSelect,
  McCAccordionItem,
  McCheckboxGroup,
  McCheckbox,
  McButton,
  McIcon,
} from "@maersk-global/mds-react-wrapper";

//Service
import { getAPI } from "../../../services/CepApiServices";
//CSS
import "./IndicationRequestFilter.scss";
import Multiselect from "multiselect-react-dropdown";


interface IPROPS {
  closeFilterFunc?: any;
  onDataFunc?: any;
  clearFilter?: any;
}
function IndicationRequestFilter({
  closeFilterFunc,
  onDataFunc,
  clearFilter,
}: IPROPS) {
  const [filters, setFilters] = useState<any | { requestStatusItems: any }>({});
  const [statusData, setStatusData] = useState([]);
  const [requestItemsData, setRequestItemsData] = useState([]);
  
  
  const {
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      requestItems:[],
      requestStatus:[],
    },
  });
  const onSubmit = (data: any) => {
    onDataFunc(data);
    closeFilterFunc();
  };
  useEffect(() => {
    fetchFiltersForIndicationRequest();
  }, []);

  const fetchFiltersForIndicationRequest = async () => {
    const filtersData = await getAPI(`IndicationRequest/GetIndicationRequestFilter`);
    setFilters(filtersData);
    setStatusData(
      filtersData?.requestStatus
      )
    
      setRequestItemsData(
      filtersData?.requestItems.map((a: any) => a)
    );
   
  };
  const closeFilter = () => {
    closeFilterFunc();
  };
  const clearFunction = () => {
    reset();
    clearFilter();
    closeFilterFunc();
  };
  return (
    <form data-testid='filter-form' onSubmit={handleSubmit(onSubmit)}>
      <div className="filterModal">
        <div className="filter-section">
          <div className="input-mainSection">
            <div className="header-section">
              <h5 className="header-text">Apply Filter</h5>
              <div className="closeIcon" data-testid="close" onClick={closeFilter}>
                <McIcon icon="times"></McIcon>
              </div>
            </div>
            <div className="filters-subsection">
              <McCAccordionItem
                title="Status"
                id="1"
                expanded={true}
                fit="medium"
              >
                <Controller
                  name="requestStatus"
                  control={control}
                
                  render={({ field: { onChange } }) => (
                    <McCheckboxGroup
                      name="requestStatus"
                      legend=""
                      change={(e) => onChange(e)}
                    >
                      {statusData?.map((item: any) => (
                        <McCheckbox
                          name="requestStatus"
                          value={item}
                          label={item}
                        ></McCheckbox>
                      ))}
                    </McCheckboxGroup>
                  )}
                />
              </McCAccordionItem>
            </div>
            <div className="filters-subsection">
              <McCAccordionItem
                title="Additional filters"
                id="1"
                expanded={true}
                fit="medium"
              >
                <div className="input-section" style={{height:"180px"}}>
                  <Controller
                  name="requestItems"
                  control={control}
                
                  render={({ field: { onChange } }) => (
                <Multiselect
                          placeholder="Requested items "
                          options={requestItemsData}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                  )}
                  />
                </div>
              </McCAccordionItem>
              
                
                
            </div>
          </div>
          <div className="footer-fixed">
            <div className="button-apply">
              <div className="input-section">
                <McButton
                  data-testid="apply-filter"
                  type="submit"
                  label="Apply filter"
                  width="full-width"
                  fit="small"
                ></McButton>
              </div>
            </div>
            <div className="button-apply">
              <div className="input-section">
                <button
                  data-testid="clear-filter"
                  className="reset-btn"
                  type="button"
                  onClick={() => {
                    clearFunction();
                  }}
                >
                  Clear filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default IndicationRequestFilter;
