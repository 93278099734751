import { useState, useEffect } from "react";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import EditorPage from "../../NegotiationOfferPages/BaseCharterParty/EditorPage";
import { useLocation } from "react-router-dom";
import Nodata from "../../../components/NoData/NoData";
interface IPROPS {
  data?: any;
  indicationId?: any;
  reRenderApiFunc?: any;
  extendedContractIDBCP?: any;
  isPrefilledCondition?:any;
}

const IssueRecapBaseCharterParty = ({
  data,
  indicationId,
  reRenderApiFunc,
  extendedContractIDBCP,
  isPrefilledCondition,
}: IPROPS) => {
  const [newdataList, setNewDataList] = useState([]);
  const [scrollID, setscrollID] = useState(0);
  const [isIconUp, setIsIconUp] = useState<any>(true);
  const [contentData, setContentData] = useState([]);
  const [id, setId] = useState(0);
  const location = useLocation();
  const documentId = location?.state?.documentId;
  useEffect(() => {
    if (data) {
      let result: any = [];
      if (data?.baseCharterPartySideMenus?.length > 0) {
        result = Object.values(
          data?.baseCharterPartySideMenus?.reduce(
            (c, { title, parentTitle, id }) => {
              c[title] = c[title] || { title, parentTitle: [], id: [] };
              c[title].parentTitle.push(parentTitle);
              c[title].id.push(id);
              return c;
            },
            {}
          )
        );
      }

      // Only headers
      let arr: any = [];
      result?.filter((n: any) => {
        if (n.title == n.title.toUpperCase() || n.title == "Hire") {
          arr.push(n);
        }
      });

      // Logic added to handle side menu object change
    //   console.log("result is"+JSON.stringify(result));
    //   let arr1 :any=[];
    //   let arr2:any=[];
    //   result?.forEach((m:any)=>{
    //     if(m.title !== m.title.toUpperCase()) {
    //       arr1= data?.baseCharterPartyMainClauses?.filter((l:any)=>{
    //         if(l.title == m.title){
    //           return m.parentTitle;
    //         }
    //       });
    //       console.log("ARR"+JSON.stringify(arr1[0].parentTitle));
    //       let obj = {
    //         title: [arr1[0].parentTitle],
    //         parentTitle: [null],
    //         id: m.id
    //     };
    //     console.log("obj is"+JSON.stringify(obj));
    //     arr2.push(obj);
    //     console.log("arr2"+JSON.stringify(arr2));
    //     }
    //   })
    //   let resArr: any=[];
    //   let arr3 = arr2.reduce((unique, o) => {
    //     if(!unique.some(obj => obj.title === o.title && obj.parentTitle === o.parentTitle && obj.id === o.id)) {
    //       unique.push(o);
    //     }
    //     return unique;
    //   },[]);
    // console.log("arr3"+JSON.stringify(arr3));
    // resArr = arr.concat(arr3);

    // resArr= resArr.filter((value, index, self) =>
    //   index === self.findIndex((t) => (
    //     t.title === value.title && t.parentTitle === value.parentTitle && t.id === value.id
    //   ))
    // );
    // console.log(Object.values(resArr.reduce((acc,cur)=>Object.assign(acc,{[cur.title]:cur}),{})))
    //   console.log("arr is"+JSON.stringify(resArr));
      setNewDataList(arr); //original
      // setNewDataList(resArr);

      const mainHeading = data?.baseCharterPartyMainClauses?.filter(
        (ele: any, ind: any) => ele.parentId === null
      );

      const subHeading = data?.baseCharterPartyMainClauses?.filter(
        (ele: any, ind: any) => ele.parentId !== null
      );

      let newArrayList = mainHeading?.map((k: any) => ({
        ...k,
        newSub: subHeading.filter((ele) => ele.parentId == k.id),
      }));

      //One's without parentId row, mapping using index object
      const extraArrayList = data?.baseCharterPartySideMenus?.map(
        (k: any) => ({
          ...k,
          newSub: subHeading.filter((ele) => ele.parentId == k.id),
        })
      );

      let aaArr = extraArrayList?.filter(
        (item) =>
          !newArrayList.some(
            (itemToBeRemoved) => itemToBeRemoved.id === item.id
          )
      );
      aaArr = aaArr?.filter((ele) => ele.parentTitle == null);

      for (let i = 0; i < aaArr?.length; i++) {
        newArrayList.push(aaArr[i]);
      }

      let sortedListNewArrayList = newArrayList?.sort(function (a, b) {
        return a.id - b.id;
      });
    
      setContentData(sortedListNewArrayList);
    }
  }, [data]);

  const CheckForDropdown = (id: any) => {
    setId(id);
    setIsIconUp("up");
  };
  const CheckForDropdownUp = (id: any) => {
    setId(id);
    setIsIconUp("down");
  };

  const scrollTo = (id: any) => {
    setscrollID(id);
  };

  // const scrolltoTop = () => {
  //   window.scrollTo(0, 0);
  // };

  return (
    <div className="indexPage-IssueRecap">
      <div className="frame">
        {newdataList?.length == 0 ? (
          <Nodata text={"No Recap Data Found.."} />
        ) : (
          <div className="data-section">
            <div className="left-dataSection-IssueRecap">
              <p className="main-heading">Main Body</p>
              <ul>
                {newdataList?.map((item: any, index: any) => (
                  <li
                    className={`${item?.id[0] == scrollID && "active"}`}
                    onClick={() => scrollTo(item?.id[0])}
                    key={item?.id[0]}
                  >
                    {item?.title}
                    {item?.parentTitle.length > 1 &&
                      (isIconUp == "up" && item?.id[0] == id ? (
                        <McIcon
                          icon="chevron-up"
                          color="black"
                          className="imageClass"
                          onClick={() => CheckForDropdownUp(item?.id[0])}
                        ></McIcon>
                      ) : (
                        <McIcon
                          icon="chevron-down"
                          color="black"
                          className="imageClass"
                          onClick={() => CheckForDropdown(item?.id[0])}
                        ></McIcon>
                      ))}
                    {item?.parentTitle.length > 1 &&
                      isIconUp == "up" &&
                      item?.id[0] == id && (
                        <ul className="ul-list">
                          {item?.parentTitle.map((data: any, index: any) =>
                            data !== null ? (
                              <div className="nested-list" key={index}>
                                {data}
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="right-dataSection-IssueRecap">
              <div className="each-dataSection">
                {contentData?.map((item: any) => (
                  <EditorPage
                    fromIssueRecap={true}
                    arrData={item}
                    scrollId={scrollID}
                    onAPiLoad={reRenderApiFunc}
                    key={item?.id}
                    documentId={documentId}
                    isPrefilledCondition={isPrefilledCondition}
                    extendedContractIDBCP={extendedContractIDBCP}
                  ></EditorPage>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IssueRecapBaseCharterParty;
