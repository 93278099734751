import {
  McModal,
  McButton,
  McInput,
  McIcon,
} from "@maersk-global/mds-react-wrapper";
import react, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { postAPI } from "../../../services/CepApiServices";
import "./IndicationRequestTable.scss";
import { useForm } from "react-hook-form";
import { getUserRole } from "../../../utils/token";
interface IPROPS {
  isModalOpen?: boolean;
  rowData?: any;
  onCloseFunc?: any;
  isCm?: any;
  reloadPage?: any;
}
const WriteAccessModal = ({
  isModalOpen,
  rowData,
  onCloseFunc,
  isCm,
  reloadPage,
}: IPROPS) => {
  const [assigneeEmail, setAssigneeEmail] = useState<string>("");
  const [loggedUserRole, setLoggedUserRole] = useState<any>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;
  const handleSubmitEmail = async () => {
    setIsSubmitting(true);
    const payLoadData = {
      indicationId: rowData?.id,
      email: assigneeEmail,
      isCM: isCm,
    };
    const res = await postAPI(
      `IndicationRequest/AssignWriteAccess`,
      payLoadData
    );
    if (res.StatusCode) {
      toast.error(<div>Failed to provide Write Access</div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsSubmitting(false);
    } else {
      toast.success(<div>Assignee email has got Write Access</div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsSubmitting(false);
    }
    setAssigneeEmail("");
    onCloseFunc();
    reloadPage();
  };

  const onCancelData = () => {
    setAssigneeEmail("");
    onCloseFunc();
  };
  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      setLoggedUserRole(userRole);
    };
    getUserRoleAsync();
  }, [isModalOpen]);

  return (
    <form data-testid="form" onSubmit={handleSubmit(handleSubmitEmail)}>
      <McModal
        height="400px"
        width="490px"
        heading={"Give Write Access"}
        open={isModalOpen}
        backdropcloseactiondisabled
      >
        <div className="header-modal">
          <span>
            {"Email"}
            <span className="manage-access-icon">
              <McIcon icon="envelope"></McIcon>
            </span>
            <span></span>
          </span>
          <McInput
            data-testid="email-id"
            ariaPlaceholder="Email"
            role="owner-email-address"
            autocomplete={"nope"}
            fit="small"
            value={assigneeEmail}
            input={(e: any) => setAssigneeEmail(e.target.value)}
            {...register(`email`, {
              required: true,
              pattern: {
                value:
                  loggedUserRole === "CharteringManager"
                    ? /^[A-Z0-9._%+-]+@maersk\.com$/i
                    : /^(?!.*@(?:maersk\.com|MAERSK\.COM)$)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Enter a valid email address",
              },
            })}
            label=""
          ></McInput>
          {errors.email && (
            <p className="error-message" data-testid="owner-email-error">
              {"Enter a valid email address"}
            </p>
          )}
        </div>
        <div>
          <span>
            <span className="warning-msg-for-writeAccess">
              <McIcon icon="info-circle" color="#f00"></McIcon>Once you grant 'Write-Access' to the Assignee, you will only have 'Read- Access'.
            </span>
          </span>
          {/* <span className="warning-msg-for-writeAccess"></span> */}
        </div>
        <McButton
          slot="primaryAction"
          variant="primary"
          fit="small"
          label={"Grant Access"}
          type="submit"
          data-testid="submit-button"
          disabled={isSubmitting}
        ></McButton>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
          onClick={onCancelData}
          role="cancel-button"
          fit="small"
        >
          Cancel
        </McButton>
      </McModal>
    </form>
  );
};
export default WriteAccessModal;
