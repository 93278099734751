// React
import { useState, useEffect } from "react";
import { getAPI } from "../../../../services/CepApiServices";
import { useLocation } from "react-router-dom";
import RiderClauseEditor from "./RiderClauseEditor";
import "./RiderClausePage.scss";
import AddNewRiderClause from "./AddNewRiderClause";
import laderImage from "../../../../assets/loader-image.png";
import editIcon from "../../../../assets/edit-pencil.png";
import revertIcon from "../../../../assets/delay.png";
import { McIcon } from "@maersk-global/mds-react-wrapper";
interface IPROPS {
  indicationRequestId?: any;
  checkNegotiationStatus?: any;
  isAccessType?: any;
  isEditing?: any;
  mainInfoData?: any;
  fromIssueRecap?: any;
  isPrefilledCondition?:any;
  extendedContractIDBCP?:any;
}

const RiderClausePage = ({
  indicationRequestId,
  checkNegotiationStatus,
  isAccessType,
  isEditing,
  mainInfoData,
  fromIssueRecap,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const [scrollID, setscrollID] = useState(0);
  const [contentData, setContentData] = useState([]);
  const [newdataList, setNewDataList] = useState([]);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const location = useLocation();
  const documentId = location?.state?.documentId;

  const [isAddRiderClasueOpen, setIsAddRiderClasueOpen] = useState(false);
  useEffect(() => {
    // if(mainInfoData.isExtended && mainInfoData.initiatedNegotiation){
    //   //Extended & after submission of counter 0
    //   getRiderClauseData(); 
    //   return;
    // } if(!mainInfoData.isExtended) {
    //   //Normal condition
    //   getRiderClauseData();
    //   return;
    // } else {
    //   getPrefilledRiderClauseData();
    //   return;
    // }
    if(mainInfoData.isExtended) {
      getPrefilledRiderClauseData();
    } else {
      getRiderClauseData();
    }
  }, []);

  const reRenderApiRiderClause = () => {
    // getRiderClauseData();
    if(mainInfoData.isExtended) {
      getPrefilledRiderClauseData();
    } else {
      getRiderClauseData();
    }
  };
  const getRiderClauseData = async () => {
    setIsModalLoading(true);
    const responseValue = await getAPI(
      `Negotiation/GetBasGetRiderClauseContractDocument/` + indicationRequestId + '/'+true+'/'+false+'/'+false+'/'+false
    );
    setNewDataList(responseValue?.riderClauseIndices);
    setContentData(responseValue?.riderClauseContractDocumentContents);
    setIsModalLoading(false);
  };

  const getPrefilledRiderClauseData = async () => {
    setIsModalLoading(true);
    const responseValue = await getAPI(
    `Negotiation/GetBasGetRiderClauseContractDocument/` + extendedContractIDBCP + '/'+false+'/'+false+'/'+true+'/'+false
    );
    setNewDataList(responseValue?.riderClauseIndices);
    setContentData(responseValue?.riderClauseContractDocumentContents);
    setIsModalLoading(false);
  }
  const scrollTo = (id: any) => {
    setscrollID(id);
  };

  const handleAddRiderClause = () => {
    setIsAddRiderClasueOpen(true);
  };
  const closeAddRiderClause = () => {
    setIsAddRiderClasueOpen(false);
  };
  return (
    <>
      {isModalLoading && (
        <div className="loader-div rider-clause">
          <div className="width-div">
            <div className="image-div shipLoader" id="pot">
              <img src={laderImage} alt="" />
            </div>
          </div>
        </div>
      )}
      <div className="rider-clause-main-page">
        <div className="rider-clause-page-sub-section">
          <div className="indexPage-riderClausePage">
            <div className="frame">
              <div className="data-section">
                <div className="left-dataSection-riderClausePage">
                  <div className="main-heading">
                    <span>Rider Clauses</span>
                    {/* <span><McButton label="Add" fit="small"></McButton></span> */}
                    {isAccessType && isEditing && (
                      <span className="right-btn-add">
                        <button
                          className="rider-clause-btn add-clause"
                          onClick={handleAddRiderClause}
                        >
                          Add Clause
                        </button>
                      </span>
                    )}
                  </div>
                  <ul>
                    {newdataList?.map((item: any, index: any) => (
                      <li
                        className={`${
                          item?.id == scrollID && "active"
                        } rider-clause-li`}
                        onClick={() => scrollTo(item?.id)}
                        key={item?.id}
                      >
                        {item?.title}
                        {item?.revertNextCounter && (
                          <img
                            src={revertIcon}
                            className="revertClass"
                            alt="pencil-icon"
                          ></img>
                        )}
                        {/* {item?.isEdited &&  item?.bcpStarted && (
                          <img
                            src={editIcon}
                            className="pencilClass"
                            alt="pencil-icon"
                          ></img>
                        )} */}
                        {item?.status == "Awaiting" && (
                          <img
                            src={editIcon}
                            className="pencilClass"
                            alt="pencil-icon"
                          ></img>
                        )}
                        {/* {mainInfoData?.bcpEdit &&
                          mainInfoData?.counterNumber > 0 &&
                          !item.revertNextCounter &&
                          !item?.isEdited &&  item?.bcpStarted && (
                            <span className="acceptedIcon">
                              <McIcon
                                icon="check-circle"
                                color="#31ca31"
                              ></McIcon>
                            </span>
                          )} */}
                           {item?.status =="Accept" && (
                            <span className="acceptedIcon">
                              <McIcon
                                icon="check-circle"
                                color="#31ca31"
                              ></McIcon>
                            </span>
                          )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="right-dataSection-riderClausePage">
                  <div className="each-dataSection">
                    {contentData?.map((item: any) => (
                      <RiderClauseEditor
                        fromIssueRecap={fromIssueRecap}
                        indicationId={indicationRequestId}
                        arrData={item}
                        scrollId={scrollID}
                        onAPiLoad={reRenderApiRiderClause}
                        key={item?.titleId}
                        checkNegotiation={checkNegotiationStatus}
                        isWriteAccess={isAccessType}
                        isEditing={isEditing}
                        mainInfoData={mainInfoData}
                        isPrefilledCondition={isPrefilledCondition}
                        extendedContractIDBCP={extendedContractIDBCP}
                      ></RiderClauseEditor>
                    ))}
                  </div>
                </div>
                <AddNewRiderClause
                  indicateRequestId={indicationRequestId}
                  reloadApi={reRenderApiRiderClause}
                  isModalOpen={isAddRiderClasueOpen}
                  onCloseFunc={closeAddRiderClause}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiderClausePage;
