import React from "react";
import ReactDOM from "react-dom/client";

import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { msalConfig } from "./authConfig";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LogLevel } from "@grafana/faro-web-sdk";
import { RumInit } from "@maersk-global/telemetry-web-sdk";
import { ReactIntegration, ReactRouterVersion } from "@grafana/faro-react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

export const msalInstance = new PublicClientApplication(msalConfig);
// if (!window.location.href.includes(process.env.REACT_APP_HOST_URL)) {

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

window.addEventListener("DOMContentLoaded", function (e) {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <App instance={msalInstance} />
  );
});

const faro = RumInit({
  app: {
    name: `${process.env.REACT_APP_CEP_NAME}`,
    version: "1.0.0",
    environment: `${process.env.REACT_APP_ENVIRONMENT}`,
  },
  apiKey: `${process.env.REACT_APP_RUM_API_KEY}`,

  instrumentations: {
    interactions: true,
    xhr: false,
    console: {
      disabledLevels: [LogLevel.TRACE, LogLevel.INFO],
    },
  },
  performanceInstrument: {
    ignoreEvents: ["msal.*"], // To ignore certain events pass a list of exact events names or regex
  },
  isolate: true,
  spanLimitInTrace: 3,
  useSendBeacon: true,
  transportBufferSize: 50,
  rateLimitBackoffMs: 5000,
  propagateTraceHeaderCorsUrls: [new RegExp(".*api.*")],
  debug: false, // false: Prevent showing log,error,etc captured in browser console
  traceLog: false, //  Sends Traces to Loki ( For easy querying of traces)
  // sessionSampler:{
  //   ratio:0.2 // only 50% of the sessions are captured
  // },
  batching: {
    // enable/disable batch sending of events
    enabled: true, // default
    // The interval in which to send event batches
    sendTimeout: 1000, // default
    // The size of the signal buffer before the batch is sent (overrides interval)
    itemLimit: 4,
  },
});
console.log("REACT_APP_CEP_NAME>>>>>>>" + process.env.REACT_APP_CEP_NAME);
faro.instrumentations.add(
  new ReactIntegration({
    // If the version of React Router is V6
    router: {
      version: ReactRouterVersion.V6,
      dependencies: {
        createRoutesFromChildren,
        matchRoutes,
        Routes,
        useLocation,
        useNavigationType,
      },
    },
  })
);

// root.render(
//   <>
//     <App instance={msalInstance} />
//   </>
// );
// } else {
//   console.log("forge rock login ");
//   console.log("For cep users forge rock login is coming soon..... ");
//   <ForgeRockLoginPage />;
// }
