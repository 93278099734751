import { useEffect, useState } from "react";
import { McModal, McSelect, McIcon } from "@maersk-global/mds-react-wrapper";
import { FileUploader } from "react-drag-drop-files";
import { set } from "react-hook-form";

interface IPROPS {
  onOpen?: boolean;
  closeUpload?: any;
  fileUpload?: any;
  availableDocumentList?: any;
  selectedDocumentDetails?: any;
}
const DocumentUpload = ({
  onOpen,
  closeUpload,
  fileUpload,
  availableDocumentList,
  selectedDocumentDetails,
}: IPROPS) => {
  const fileTypes = ["xls", "xlsx", "pdf", "txt", "doc"];
  const [modalHieght, setModalHeight] = useState("52vh");
  const [selectedDocument, setSelectedDocument] = useState<any>({
    id: "",
    documentId: "",
    documentName: "",
    displayName: "",
  });
  const [source, setSource] = useState<any>();
  const [isUploadSection, setIsUploadSection] = useState(true);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [progress, setProgress] = useState(0);
  const [fileTypeErr, setFileTypeError] = useState(false);

  const onCancel = (act: any, data: any) => {
    closeUpload();
    setIsUploadSection(true);
    setProgress(0);
    setModalHeight("52vh");
    setFileTypeError(false);
  };
  const documentSelected = (e: any) => {
    setSelectedDocument({
      id: e?.detail?.selectedOptions[0].id,
      documentId: e?.detail?.selectedOptions[0].value,
      documentName: e?.detail?.selectedOptions[0].label,
      fullName: e?.detail?.selectedOptions[0].fullName,
    });
    setSource(e?.detail?.selectedOptions[0]?.label);
    setFileTypeError(false);
  };

  function formatBytes(bytes: any) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;
    while (bytes >= 1024 && i < units.length - 1) {
      bytes /= 1024;
      i++;
    }
    return bytes.toFixed(2) + units[i];
  }
  const handleFileUpload = (file: any) => {
    setFileTypeError(false);
    const fileName = file.name.replace(/\s+/g, "_");
    const fileExtension = file.name.split(".").slice(-1)[0];
    if (selectedDocument.fullName.split(".").slice(-1)[0] === fileExtension) {
      const modifiedFile: any = new File([file], fileName, { type: file.type });
      setSelectedFile(modifiedFile);
      setIsUploadSection(false);
      setModalHeight("34vh");
      setTimeout(() => {
        closeUpload();
        setIsUploadSection(true);
        setProgress(0);
        setModalHeight("52vh");
      }, 1200);
    } else {
      setFileTypeError(true);
    }
  };
  const handleSubmit = async () => {
    if (fileUpload) {
      fileUpload(selectedFile, selectedDocument);
    }
  };
  useEffect(() => {
    if (!fileTypeErr) {
      handleSubmit();
    }
  }, [selectedFile]);

  useEffect(() => {
    setSource("");
    setSelectedDocument({
      id: "",
      documentId: "",
      documentName: "",
      displayName: "",
    });
  }, [onOpen]);
  return (
    <McModal
      open={onOpen}
      heading="Upload Document"
      width="540px"
      height={modalHieght}
      backdropcloseactiondisabled
    >
      <div className="row indication-invite-upload-section">
        <div className="col-md-12">
          <div className="modal-section">
            <div className="subColumn">
              <div className="header-section">
                <div
                  className="closeIcon"
                  onClick={() => onCancel({ type: "close" }, null)}
                  data-testid="close-icon"
                >
                  <McIcon icon="times"></McIcon>
                </div>
                <div className="row">
                  <div className="col-md-12 row-divider">
                    <div className="input-section document_name_select">
                      <McSelect
                        name="documentId"
                        fit="medium"
                        label=""
                        options={availableDocumentList?.map(
                          (document: any) => ({
                            id: document.id,
                            label: document.documentName
                              .split(".")
                              .slice(0, -1)
                              .join("."),
                            fullName: document.documentName,
                            value: document.documentId,
                          })
                        )}
                        change={documentSelected}
                        value={source}
                        data-testid="source-select"
                        ariaPlaceholder="Select a File"
                      ></McSelect>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {selectedDocument.documentId &&
                      isUploadSection &&
                      availableDocumentList.length > 0 && (
                        <div
                          className="uploader-div indication-invite-uploader"
                          data-testid="fileuploader"
                        >
                          {fileTypeErr && (
                            <div className="error-message">
                              <p>Uploaded a wrong document</p>
                            </div>
                          )}
                          <FileUploader
                            handleChange={handleFileUpload}
                            name="File"
                            types={[
                              "doc",
                              "docx",
                              "xls",
                              "xlsx",
                              "ppt",
                              "pptx",
                              "txt",
                            ]}
                          >
                            <div className="dropZone" data-testid="file-input">
                              <div className="drop-text">
                                <p>
                                  <McIcon icon="folder-arrow-up"></McIcon>
                                  <span>Select file </span> or Drag here
                                </p>
                              </div>
                            </div>
                          </FileUploader>
                        </div>
                      )}
                    {availableDocumentList.length === 0 && isUploadSection && (
                      <div className="uploader-div" data-testid="fileuploader">
                        <div className="dropZone" data-testid="file-input">
                          <div className="drop-text">
                            <p>
                              <McIcon icon="folder-arrow-up"></McIcon>
                              <p>No files to upload </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {(!selectedDocument.documentId ||
                      selectedDocument === undefined) &&
                      availableDocumentList.length !== 0 &&
                      isUploadSection && (
                        <div
                          className="uploader-div"
                          data-testid="fileuploader"
                        >
                          <div className="dropZone" data-testid="file-input">
                            <div className="drop-text">
                              <p>
                                <McIcon icon="folder-arrow-up"></McIcon>
                                <p>Select Document to upload </p>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {!isUploadSection && !fileTypeErr && (
                  <div className="attach-div">
                    <p className="file-name" data-testid="file-name">
                      {selectedFile?.name}
                    </p>
                    <p className="file-size" data-testid="file-size">
                      File size :{" "}
                      {selectedFile ? formatBytes(selectedFile.size) : "N/A"}{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </McModal>
  );
};

export default DocumentUpload;
