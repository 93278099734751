import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import Select from "react-select";
import moment from "moment";
import "../SalesAndPurchase/EditModal.scss";
import { getAPI } from "../../services/ApiServices";
import { getUID } from "../../utils/token";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputDropdown } from "../../components/inputDropdown/inputDropdown";
import MismatchPopup from "../mismatch-warning-popup/MismatchPopup";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
interface IPROPS {
  OpenModal?: boolean;
  data?: any;
  onModalClose?: any;
  charterOption?: any;
  charterType?: any;
  currencyOption?: any;
}
const EditModal = ({
  OpenModal,
  data,
  onModalClose,
  charterOption,
  charterType,
  currencyOption,
}: IPROPS) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const dataValue = data;
  const isMismatchedCheck = data?.isMismatched;
  const UID = getUID();
  const [newArr, setNewArr] = useState<any>([]);
  const [redeliveryPortOption, setredeliveryPortOption] = useState();
  const [reDelivaryRange, setReDelivaryRange] = useState([]);
  const [isModalSubmitted, setisModalSubmitted] = useState(false);
  const [managingOwner, setManagingOwner] = useState([]);
  const [fuelClass, setFuelClass] = useState([]);
  const [isMismatched, setIsMismatched] = useState(false);
  const [openMismatchPopup, setOpenMismatchPopup] = useState(false);
  const [dataEditObj, setdataObjEdit] = useState({});
  const [minsetDate, setMinsetDate] = useState("");
  const [maxsetDate, setMaxsetDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(
    dataValue?.delivery && new Date(dataValue?.delivery)
  );
  const [minDate, setMinDate] = useState(
    dataValue?.min && new Date(dataValue?.min)
  );
  const [maxDate, setMaxDate] = useState(
    dataValue?.max && new Date(dataValue?.max)
  );
  const [redeliveryDate, setRedeliveryDate] = useState(
    dataValue?.estRedelivery && new Date(dataValue?.estRedelivery)
  );
  let purchaseOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
    { label: "None", value: null },
  ];
  const fixtureOptionPeriodType = [
    { value: "Day", label: "Day" },
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
  ];

  const rangeOnchange = async (e: any) => {
    const portValue = e.value;
  };
  useEffect(() => {
    if (dataValue) {
      getAPI(`Charter/GetManagingOwner`).then((result) => {
        setManagingOwner(result);
      });
      getAPI(`Charter/GetFuelClassAsync/` + dataValue.imo).then((result) => {
        setFuelClass(result);
      });
      getAPI(`Filter/GetAllRedeliveryRange/` + dataValue.id).then((result) => {
        setReDelivaryRange(result);

        const initialRedeliveryRangeState = result?.map((range) => {
          const allChildrenChecked = range?.children?.every((child) => {
            const allSubChildrenChecked = child?.children?.every(
              (subChild) => subChild.checked
            );
            return allSubChildrenChecked || child.checked;
          });

          return {
            ...range,
            checked: allChildrenChecked || range.checked,
            children: range?.children?.map((subRange) => ({
              ...subRange,
              checked:
                subRange?.children?.every((item) => item.checked) ||
                subRange.checked,
              children: subRange?.children?.map((item) => ({
                ...item,
                checked: item.checked,
              })),
            })),
          };
        });

        setReDelivaryRange(initialRedeliveryRangeState);
      });
      getAPI(`Filter/GetAllRedeliveryPort`).then((result) => {
        setredeliveryPortOption(result);
      });
    }
  }, [dataValue]);
  const getInputFieldValueForEdit = (val: any) => {
    if (val) return Number(val);
    else return null;
  };
  const onSubmit = (data: any) => {
    setisModalSubmitted(true);
    const dataObj = {
      id: dataValue?.id,
      imo: dataValue?.imo,
      chartererId: data.chartererName
        ? data.chartererName
        : dataValue.chartererId,
      charterTypeId: data?.chartererType
        ? data.chartererType
        : dataValue.charterTypeId,
      delivery: data.delivery
        ? moment(data.delivery, "DD/MM/YYYY").format("YYYY-MM-DD")
        : moment(dataValue.delivery).format("YYYY-MM-DD"),
      min: data.min
        ? moment(data.min, "DD/MM/YYYY").format("YYYY-MM-DD")
        : moment(dataValue.min).format("YYYY-MM-DD"),
      max: data.max
        ? moment(data.max, "DD/MM/YYYY").format("YYYY-MM-DD")
        : moment(dataValue.max).format("YYYY-MM-DD"),
      estRedelivery: data.estRedelivery
        ? moment(data.estRedelivery, "DD/MM/YYYY").format("YYYY-MM-DD")
        : dataValue.estRedelivery,
      ManagingOwner: data?.ManagingOwner
        ? data.ManagingOwner
        : dataValue.managingOwner,
      redeliveryPortId: data?.redeliveryPortName
        ? data.redeliveryPortName
        : dataValue.redeliveryPortId,
      redeliveryRanges:
        newArr.length > 0
          ? newArr
          : getSelectedRedeliveryRanges(dataValue.redeliveryRangeName),
      purchaseObligation:
        data?.purchaseObl !== undefined
          ? data.purchaseObl
          : dataValue.purchaseObligation,
      isLocked: true,
      isMismatched: dataValue.isMismatched,
      currencyId: data?.currency ? data.currency : dataValue.currencyId,
      rate:
        data.rate || data.fixtureOptionPeriod == ""
          ? getInputFieldValueForEdit(data.rate)
          : dataValue.rate,
      uid: dataValue.uid ? dataValue.uid : UID,
      sourceId: dataValue.sourceId,
      comment: data.comments ? data.comments : dataValue.comments,
      fixtureOption:
        data.fixtureOption !== null && data.fixtureOption !== undefined
          ? data.fixtureOption
          : dataValue.fixtureOption,

      fixtureOptionPeriod:
        data.fixtureOptionPeriod || data.fixtureOptionPeriod == ""
          ? getInputFieldValueForEdit(data.fixtureOptionPeriod)
          : dataValue.fixtureOptionPeriod,

      fixtureOptionPeriodType: data.fixtureOptionPeriodType
        ? data.fixtureOptionPeriodType
        : dataValue.fixtureOptionPeriodType,

      fixtureOptionCurrencyId: data.fixtureOptionCurrencyId
        ? data.fixtureOptionCurrencyId
        : dataValue.fixtureOptionCurrencyId,

      fixtureOptionValue:
        data.fixtureOptionValue || data.fixtureOptionValue == ""
          ? getInputFieldValueForEdit(data.fixtureOptionValue)
          : dataValue.fixtureOptionValue,

      purchaseOption:
        data.purchaseOption !== null && data.purchaseOption !== undefined
          ? data.purchaseOption
          : dataValue.purchaseOption,

      purchaseOptionCurrencyId: data.purchaseOptionCurrencyId
        ? data.purchaseOptionCurrencyId
        : dataValue.purchaseOptionCurrencyId,

      purchaseOptionValue:
        data.purchaseOptionValue || data.purchaseOptionValue == ""
          ? getInputFieldValueForEdit(data.purchaseOptionValue)
          : dataValue.purchaseOptionValue,
      fuelClassId: data.fuelClass ? data.fuelClass : dataValue.fuelClassId,
    };
    if (dataValue.isMismatched == true && isMismatched == true) {
      //open warning modal
      const newObj = {
        id: dataValue?.id,
        imo: dataValue?.imo,
        chartererId: data.chartererName
          ? data.chartererName
          : dataValue.chartererId,
        charterTypeId: data?.chartererType
          ? data.chartererType
          : dataValue.charterTypeId,
        delivery: data.delivery
          ? moment(data.delivery, "DD/MM/YYYY").format("YYYY-MM-DD")
          : moment(dataValue.delivery).format("YYYY-MM-DD"),
        min: data.min
          ? moment(data.min, "DD/MM/YYYY").format("YYYY-MM-DD")
          : moment(dataValue.min).format("YYYY-MM-DD"),
        max: data.max
          ? moment(data.max, "DD/MM/YYYY").format("YYYY-MM-DD")
          : moment(dataValue.max).format("YYYY-MM-DD"),
        estRedelivery: data.estRedelivery
          ? moment(data.estRedelivery, "DD/MM/YYYY").format("YYYY-MM-DD")
          : dataValue.estRedelivery,
        ManagingOwner: data?.ManagingOwner
          ? data.ManagingOwner
          : dataValue.managingOwner,
        redeliveryRanges:
          newArr.length > 0
            ? newArr
            : getSelectedRedeliveryRanges(dataValue.redeliveryRangeName),
        purchaseObligation:
          data?.purchaseObl !== undefined
            ? data.purchaseObl
            : dataValue.purchaseObligation,
        isLocked: true,
        isMismatched: false,
        currencyId: data?.currency ? data.currency : dataValue.currencyId,
        rate:
          data.rate || data.fixtureOptionPeriod == ""
            ? getInputFieldValueForEdit(data.rate)
            : dataValue.rate,
        uid: dataValue.uid ? dataValue.uid : UID,
        sourceId: dataValue.sourceId,
        comment: data.comments ? data.comments : dataValue.comments,
        fixtureOption:
          data.fixtureOption !== null && data.fixtureOption !== undefined
            ? data.fixtureOption
            : dataValue.fixtureOption,

        fixtureOptionPeriod:
          data.fixtureOptionPeriod || data.fixtureOptionPeriod == ""
            ? getInputFieldValueForEdit(data.fixtureOptionPeriod)
            : dataValue.fixtureOptionPeriod,

        fixtureOptionPeriodType: data.fixtureOptionPeriodType
          ? data.fixtureOptionPeriodType
          : dataValue.fixtureOptionPeriodType,

        fixtureOptionCurrencyId: data.fixtureOptionCurrencyId
          ? data.fixtureOptionCurrencyId
          : dataValue.fixtureOptionCurrencyId,

        fixtureOptionValue:
          data.fixtureOptionValue || data.fixtureOptionValue == ""
            ? getInputFieldValueForEdit(data.fixtureOptionValue)
            : dataValue.fixtureOptionValue,

        purchaseOption:
          data.purchaseOption !== null && data.purchaseOption !== undefined
            ? data.purchaseOption
            : dataValue.purchaseOption,

        purchaseOptionCurrencyId: data.purchaseOptionCurrencyId
          ? data.purchaseOptionCurrencyId
          : dataValue.purchaseOptionCurrencyId,

        purchaseOptionValue:
          data.purchaseOptionValue || data.purchaseOptionValue == ""
            ? getInputFieldValueForEdit(data.purchaseOptionValue)
            : dataValue.purchaseOptionValue,
        fuelClassId: data.fuelClass ? data.fuelClass : dataValue.fuelClassId,
      };
      setdataObjEdit(newObj);
      setOpenMismatchPopup(true);
    } else {
      onModalClose("submit", dataObj);
    }
  };
  const addDate = (dateString: any) => {
    let startDate = new Date(dateString);
    let day = 60 * 60 * 24 * 1000;
    let endDate = new Date(startDate.getTime() + day);
    return endDate;
  };
  const chkValueChanged = (e: any, label: string) => {
    if (label == "Charterer") {
      if (dataValue.chartererId !== e) {
        setIsMismatched(true);
      }
    }
    if (label == "chartererType") {
      if (dataValue.charterTypeId !== e) {
        setIsMismatched(true);
      }
    }
    if (label == "delivery") {
      if (dataValue.delivery !== e) {
        setIsMismatched(true);
      }
      const newDate: any = addDate(e);
      setMinsetDate(newDate);
      setMinDate("");
      setMaxDate("");
      setRedeliveryDate("");
      setValue("min", "");
      setValue("max", "");
      setValue("estRedelivery", "");
    }
    if (label == "min") {
      if (dataValue.min !== e) {
        setIsMismatched(true);
      }
      const newDate: any = e;
      setMaxsetDate(newDate);
      setMaxDate("");
      setValue("max", "");
    }
    if (label == "max") {
      if (dataValue.max !== e) {
        setIsMismatched(true);
      }
    }
    if (label == "estRedelivery") {
      if (dataValue.estRedelivery !== e) {
        setIsMismatched(true);
      }
    }
    if (label == "currency") {
      if (dataValue.currencyId !== e) {
        setIsMismatched(true);
      }
    }
    if (label == "rate") {
      if (dataValue.rate !== e) {
        setIsMismatched(true);
      }
    }
  };
  const closeFunction = () => {
    onModalClose("close", null);
  };

  const closeMismatchFunc = (type: string, data: any) => {
    if (type == "cancel") {
      onModalClose("close", null);
      setOpenMismatchPopup(false);
    } else {
      onModalClose("submit", data);
      setOpenMismatchPopup(false);
    }
  };
  const getPurchaseObj = (obj: any) => {
    if (obj == true) return "Yes";
    else if (obj == false) return "No";
    else return "None";
  };

  const getCurrencyMapping = (val: any) => {
    if (val) {
      let labelName = currencyOption.filter((e: any) => e.value == val);
      return labelName[0].label;
    }
  };
  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 48,
      minHeight: 38,
    }),
  };

  const onChangeRange = (currentNode: any, selectedNodes: any) => {
    let arr: any[] = [];
    selectedNodes.forEach((element: any) => {
      if (element?._depth !== 0) {
        if (element?._depth == 1) {
          element?._children?.forEach((j: any) => {
            arr.push(j);
          });
        } else {
          arr.push(element.id);
        }
      } else {
        const selectedRange = reDelivaryRange.filter(
          (e: any) => e.label == element.label
        );
        selectedRange.forEach((e: any) => {
          e.children.forEach((i: any) => {
            i.children.forEach((j: any) => {
              arr.push(j.id);
            });
          });
        });
      }
    });
    setNewArr(arr);

    const updatedRedeliveryRange: any = reDelivaryRange.map((range: any) => {
      const allChildrenChecked = range.children.every((subRange) =>
        subRange.children.every((item) => arr.includes(item.id))
      );
      return {
        ...range,
        checked: allChildrenChecked || arr.includes(range.id),
        children: range.children.map((subRange) => ({
          ...subRange,
          checked:
            subRange.children.every((item) => arr.includes(item.id)) ||
            arr.includes(subRange.id),
          children: subRange.children.map((item) => ({
            ...item,
            checked: arr.includes(item.id),
          })),
        })),
      };
    });
    setReDelivaryRange(updatedRedeliveryRange);
  };
  const getSelectedRedeliveryRanges = (val: any) => {
    if (val == null) {
      setNewArr([]);
      return [];
    }
    let arr1: any[] = [];
    reDelivaryRange.forEach((l: any) => {
      l.children.forEach((m: any) => {
        m.children.forEach((n: any) => {
          if (n.checked == true) {
            arr1.push(n.id);
          }
        });
      });
    });
    setNewArr(arr1);
    return arr1;
  };
  return (
    <>
      <form
        data-testid="form"
        className="charterMarketEditModal"
        onSubmit={handleSubmit(onSubmit)}
      >
        <McModal
          open={OpenModal}
          height="70vh"
          backdropcloseactiondisabled
          heading={`Update Vessel Commercial Details - ${dataValue?.vesselName}`}
        >
          <span className="mds-text--medium-normal">
            <div className="update-vessels">
              <div className="row">
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Vessel Name</p>
                    <h6 className="heading" data-testid="cm-vessel-name">
                      {dataValue?.vesselName}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>IMO</p>
                    <h6 className="heading" data-testid="cm-imo">
                      {dataValue?.imo}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>TEU</p>
                    <h6 className="heading" data-testid="cm-teu">
                      {dataValue?.teu}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>UID</p>
                    <h6 className="heading" data-testid="cm-uid">
                      {dataValue?.uid}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Design</p>
                    <h6 className="heading" data-testid="cm-design">
                      {dataValue?.design}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Source</p>
                    <h6 className="heading">
                      <span className="icon-div" data-testid="cm-source">
                        {dataValue?.source}{" "}
                        {dataValue?.isLocked && (
                          <McIcon data-testid="cm-lock" icon="lock"></McIcon>
                        )}
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Status</p>
                    <h6 className="heading" data-testid="cm-status">
                      {dataValue?.status}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <p>Date of Status</p>
                    <h6 className="heading" data-testid="cm-date-of-status">
                      {moment(dataValue?.updateDate).format("DD/MM/YYYY")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="data-div">
                    <label>
                      <span className="mandetory">*</span>Charterer
                    </label>
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="chartererName"
                        data-testid="charterer"
                        render={({ field: { onChange } }) => (
                          <Select
                            styles={customStyles}
                            defaultValue={{
                              value: String(dataValue?.chartererId),
                              label: dataValue?.chartererName,
                            }}
                            name="chartererName"
                            onChange={(val: any) => {
                              onChange(val.value);
                              chkValueChanged(val.value, "Charterer");
                            }}
                            options={charterOption}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="data-div">
                    <label>
                      <span className="mandetory">*</span>Charter Type
                    </label>
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="chartererType"
                        render={({ field: { onChange } }) => (
                          <Select
                            styles={customStyles}
                            defaultValue={{
                              value: String(dataValue?.charterTypeId),
                              label: dataValue?.charterTypeName,
                            }}
                            name="chartererType"
                            data-testid="charterType"
                            onChange={(val: any) => {
                              onChange(val.value);
                              chkValueChanged(val.value, "chartererType");
                            }}
                            options={charterType}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="data-div">
                    <label>
                      <span className="mandetory">*</span>Delivery
                    </label>
                    <div className="date-div">
                      <span className="date-icon">
                        <McIcon icon="calendar"></McIcon>
                      </span>
                      <Controller
                        control={control}
                        name="delivery"
                        data-testid="cm-delivery"
                        render={({ field: { onChange } }) => (
                          <DatePicker
                            placeholderText="dd/mm/yyyy"
                            className="inputText"
                            dateFormat="dd/MM/yyyy"
                            selected={deliveryDate}
                            onChange={(date: any) => {
                              onChange(date);
                              setDeliveryDate(date);
                              chkValueChanged(date, "delivery");
                            }}
                          ></DatePicker>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <label>
                      <span className="mandetory">*</span>Min
                    </label>
                    <div className="date-div" data-testid="cm-edit-min">
                      <span className="date-icon">
                        <McIcon icon="calendar"></McIcon>
                      </span>
                      <Controller
                        control={control}
                        name="min"
                        data-testid="cm-min"
                        render={({ field: { onChange } }) => (
                          <DatePicker
                            placeholderText="dd/mm/yyyy"
                            className="inputText"
                            dateFormat="dd/MM/yyyy"
                            selected={minDate}
                            minDate={new Date(minsetDate)}
                            onChange={(date: any) => {
                              onChange(date);
                              setMinDate(date);
                              chkValueChanged(date, "min");
                            }}
                          ></DatePicker>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="data-div">
                    <label>
                      <span className="mandetory">*</span>Max
                    </label>
                    <div className="date-div">
                      <span className="date-icon">
                        <McIcon icon="calendar"></McIcon>
                      </span>
                      <Controller
                        control={control}
                        name="max"
                        data-testid="cm-max"
                        render={({ field: { onChange } }) => (
                          <DatePicker
                            placeholderText="dd/mm/yyyy"
                            className="inputText"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(maxsetDate)}
                            selected={maxDate}
                            onChange={(date: any) => {
                              onChange(date);
                              setMaxDate(date);
                              chkValueChanged(date, "max");
                            }}
                          ></DatePicker>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="data-div est-redelivery-range"
                    data-testid="cm-edit-est"
                  >
                    <label>Est. Redelivery</label>
                    <div className="date-div">
                      <span className="date-icon">
                        <McIcon icon="calendar"></McIcon>
                      </span>
                      <Controller
                        control={control}
                        name="estRedelivery"
                        data-testid="cm-est-redelivery"
                        render={({ field: { onChange } }) => (
                          <DatePicker
                            placeholderText="dd/mm/yyyy"
                            className="inputText"
                            dateFormat="dd/MM/yyyy"
                            selected={redeliveryDate}
                            minDate={new Date(minsetDate)}
                            onChange={(date: any) => {
                              onChange(date);
                              setRedeliveryDate(date);
                              chkValueChanged(date, "estRedelivery");
                            }}
                          ></DatePicker>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col">
                      <div className="data-div">
                        <label>Currency</label>
                        {OpenModal && (
                          <Controller
                            control={control}
                            name="currency"
                            data-testid="cm-currency"
                            render={({ field: { onChange } }) => (
                              <Select
                                styles={customStyles}
                                defaultValue={{
                                  value: String(dataValue?.currencyId),
                                  label: dataValue?.currency,
                                }}
                                options={currencyOption}
                                name="currency"
                                onChange={(val: any) => {
                                  onChange(val.value);
                                  chkValueChanged(val.value, "currency");
                                }}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="data-div">
                        <label>Rates</label>
                        <Controller
                          name="vslImo"
                          control={control}
                          defaultValue=""
                          data-testid="cm-vsl-imo"
                          render={({ field: { onChange } }) => (
                            <input
                              className="inputText"
                              defaultValue={dataValue?.rate}
                              {...register("rate")}
                              type="text"
                              role="rate"
                              onChange={(val) => {
                                chkValueChanged(val, "rate");
                              }}
                            ></input>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col">
                      <div className="data-div">
                        <label>Redelivery Range</label>
                        {OpenModal && (
                          <DropdownTreeSelect
                            className="custom-treeselect"
                            data={reDelivaryRange}
                            data-testid="cm-redelivery-range"
                            onChange={(currentNode, selectedNodes) =>
                              onChangeRange(currentNode, selectedNodes)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="data-div">
                        <label>Redelivery Port</label>

                        {OpenModal && (
                          <Controller
                            control={control}
                            name="redeliveryPortName"
                            data-testid="cm-redelivery-port-name"
                            render={({ field: { onChange } }) => (
                              <Select
                                styles={customStyles}
                                defaultValue={{
                                  value: String(dataValue?.redeliveryPortId),
                                  label: dataValue?.redeliveryPortName,
                                }}
                                options={redeliveryPortOption}
                                name="redeliveryPortName"
                                onChange={(val: any) => {
                                  onChange(val.value);
                                  rangeOnchange(val);
                                }}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 row-divider">
                  <div className="data-div">
                    <label>Purchase Obligation</label>
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="purchaseObl"
                        data-testid="cm-purchase-oblidation"
                        render={({ field: { onChange } }) => (
                          <Select
                            styles={customStyles}
                            defaultValue={{
                              value: dataValue?.purchaseObligation,
                              label: getPurchaseObj(
                                dataValue?.purchaseObligation
                              ),
                            }}
                            options={purchaseOptions}
                            name="purchaseObl"
                            onChange={(val: any) => onChange(val.value)}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-4 row-divider">
                  <div className="data-div">
                    <label>Purchase Option</label>
                    {OpenModal && (
                      <Controller
                        control={control}
                        name="purchaseOption"
                        data-testid="cm-purchase-option"
                        render={({ field: { onChange } }) => (
                          <Select
                            styles={customStyles}
                            defaultValue={{
                              value: dataValue?.purchaseOption,
                              label: getPurchaseObj(dataValue?.purchaseOption),
                            }}
                            options={purchaseOptions}
                            name="purchaseOption"
                            onChange={(val: any) => onChange(val.value)}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-4 row-divider">
                  <div className="data-div">
                    <label>Purchase Option Value</label>
                    <div className="row">
                      <div className="col custom-input">
                        <input
                          id="cm-purchase-option-value"
                          className="inputText"
                          defaultValue={dataValue?.purchaseOptionValue}
                          {...register("purchaseOptionValue")}
                          type="text"
                        ></input>
                      </div>
                      <div className="col custom-input-right">
                        {OpenModal && (
                          <Controller
                            control={control}
                            name="purchaseOptionCurrencyId"
                            data-testid="cm-purchase-option-currency-id"
                            render={({ field: { onChange } }) => (
                              <Select
                                styles={customStyles}
                                defaultValue={{
                                  value: dataValue?.purchaseOptionCurrencyId,
                                  label: getCurrencyMapping(
                                    dataValue?.purchaseOptionCurrencyId
                                  ),
                                }}
                                options={currencyOption}
                                name="purchaseOptionCurrencyId"
                                onChange={(val: any) => onChange(val.value)}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 row-divider">
                    <div className="input-section">
                      <label>Fixture Option</label>
                      {OpenModal && (
                        <Controller
                          control={control}
                          name="fixtureOption"
                          data-testid="cm-fixture-option"
                          render={({ field: { onChange } }) => (
                            <Select
                              styles={customStyles}
                              defaultValue={{
                                value: dataValue?.fixtureOption,
                                label: getPurchaseObj(dataValue?.fixtureOption),
                              }}
                              options={purchaseOptions}
                              name="fixtureOption"
                              onChange={(val: any) => onChange(val.value)}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 row-divider">
                    <div className="input-section">
                      <label>Fixture Option Period</label>
                      <div className="row">
                        <div className="col custom-input">
                          <input
                            id="cm-fixture-option-period"
                            className="inputText"
                            defaultValue={dataValue?.fixtureOptionPeriod}
                            {...register("fixtureOptionPeriod")}
                            type="text"
                          ></input>
                        </div>
                        <div className="col custom-input-right">
                          {OpenModal && (
                            <Controller
                              control={control}
                              name="fixtureOptionPeriodType"
                              data-testid="cm-fixture-option-period-type"
                              render={({ field: { onChange } }) => (
                                <Select
                                  styles={customStyles}
                                  defaultValue={{
                                    value: dataValue?.fixtureOptionPeriodType,
                                    label: dataValue?.fixtureOptionPeriodType,
                                  }}
                                  options={fixtureOptionPeriodType}
                                  name="fixtureOptionPeriodType"
                                  onChange={(val: any) => onChange(val.label)}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 row-divider">
                    <div className="input-section">
                      <label>Fixture Option Value</label>
                      <div className="row">
                        <div className="col custom-input">
                          <input
                            id="cm-fixture-option-value"
                            className="inputText"
                            defaultValue={dataValue?.fixtureOptionValue}
                            {...register("fixtureOptionValue")}
                            type="text"
                          ></input>
                        </div>
                        <div className="col custom-input-right">
                          {OpenModal && (
                            <Controller
                              control={control}
                              name="fixtureOptionCurrencyId"
                              data-testid="cm-fixture-option-currency-id"
                              render={({ field: { onChange } }) => (
                                <Select
                                  styles={customStyles}
                                  defaultValue={{
                                    value: dataValue?.fixtureOptionCurrencyId,
                                    label: getCurrencyMapping(
                                      dataValue?.fixtureOptionCurrencyId
                                    ),
                                  }}
                                  options={currencyOption}
                                  name="fixtureOptionCurrencyId"
                                  onChange={(val: any) => onChange(val.value)}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 row-divider">
                    <div className="input-section">
                      <label>Managing Owner</label>
                      {OpenModal && (
                        <Controller
                          control={control}
                          name="ManagingOwner"
                          data-testid="cm-managing-onwer"
                          render={({ field: { onChange } }) => (
                            <InputDropdown
                              Defaultval={dataValue?.managingOwner}
                              onDdlChange={onChange}
                              optionList={managingOwner}
                            ></InputDropdown>
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 row-divider">
                    <div className="input-section">
                      <label>Fuel Class</label>
                      {OpenModal && (
                        <Controller
                          control={control}
                          name="fuelClass"
                          data-testid="cm-fuel-class"
                          render={({ field: { onChange } }) => (
                            <Select
                              styles={customStyles}
                              defaultValue={{
                                value: String(dataValue?.fuelClassId),
                                label: dataValue?.fuelClassName,
                              }}
                              options={fuelClass}
                              name="fuelClass"
                              onChange={(val: any) => onChange(val.value)}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 row-divider">
                    <label>Comments</label>
                    <textarea
                      data-testid="cm-comments"
                      className="commentArea"
                      {...register("comments")}
                      defaultValue={
                        dataValue?.comment !== null ? dataValue?.comment : ""
                      }
                      placeholder="comments"
                      rows={2}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <McButton
            slot="primaryAction"
            variant="primary"
            type="submit"
            disabled={isModalSubmitted}
            label="Save and update"
            data-testid="save-and-update"
          ></McButton>
          <McButton
            slot="secondaryAction"
            appearance="default"
            variant="outlined"
            dialogAction="cancel"
            click={closeFunction}
            data-testid="cancel"
          >
            Cancel
          </McButton>
          {openMismatchPopup && (
            <MismatchPopup
              onMismatchPopupOpen={true}
              onMismatchedPopupClose={closeMismatchFunc}
              data={dataEditObj}
              data-testid="closeMismatchFunc"
            />
          )}
        </McModal>
      </form>
    </>
  );
};
export default EditModal;
