import { getToken } from "../utils/token";
import { protectedResources } from "../authConfig";
import axios from "axios";

export const getAPI = async (url: any) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      protectedResources.apiTodoList.endPointCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const postAPI = async (url: any, postData?: any) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(postData),
    };

    return fetch(
      protectedResources.apiTodoList.endPointCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const deleteAPI = async (url: any) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "DELETE",
      headers: headers,
    };

    return fetch(
      protectedResources.apiTodoList.endPointCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const fileApiAxios = async (
  url: any,
  postData?: any,
  updateProgress?: any | undefined
) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    const options = {
      method: "POST",
      headers: {
        Authorization: bearer,
      },
      body: postData,
    };
    return axios
      .post(
        protectedResources.apiTodoList.endPointCommon + `${url}`,
        postData,
        {
          headers: {
            Authorization: bearer,
          },
          onUploadProgress: (progressEvent: any) => {
            let percentComplete : any = progressEvent.loaded / progressEvent?.total;
            let percentCompleteFinal: any = parseInt(String(percentComplete * 100));
            updateProgress(percentCompleteFinal);
          },
        }
      )
      .then((response) => response)
      .catch((response) => response.response);
  } catch (error) {
    console.log("error is ", error);
  }
};

export const fileAPI = async (url: any, postData?: any) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: bearer,
      },
      body: postData,
    };

    return fetch(
      protectedResources.apiTodoList.endPointCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};
