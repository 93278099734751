// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
// Styles
import "./VesselSupplyDataList.scss";

// MDS
import { MdsConstants } from "../../utils/Constants";
// Pages
import {} from "../../pages/aisDetails/Columns";
// Services
import {
  VesselSupplyDataListService,
  DownloadVesselSupplyDataListService,
} from "../../services/VesselSupplyDataListService";
import { excelDownload } from "../../utils/excelDownload";

// Model
import { VslSupplyDataListModel } from "./model";
import { McSelectOptions } from "@maersk-global/mds-components-core/mc-select/types";
import { toast } from "react-toastify";
import {
  DownloadDataConstant,
  DownloadColumnsConstant,
} from "./DownloadDataColumns";

interface IPROPS {
  data?: any;
  searchText?: any;
  isExport?: any;
  resetExport?: any;
}
function VesselSupplyDataList({
  data,
  searchText,
  isExport,
  resetExport,
}: IPROPS) {
  const [tableData, setDataSource] = useState<VslSupplyDataListModel[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [dDlOptions, setTableSizeDdl] = useState<McSelectOptions>([]);
  const [hasError, setHasError] = useState<boolean>();
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);
  const [selectedNoOfRecords, setSelectedNoOfRecords] = useState(10);
  const [selectedIMO, setSelectedIMO] = useState();
  const [vslSupplyDataList, setVslSupplyDataList] = useState([]);
  const [download, setDownloaded] = useState(isExport);
  const navigate = useNavigate();
  const location = useLocation();
  let numOfRecordsPerPage = 10;
  let numOfRecords = 0;

  let returnedField = localStorage.getItem("item");

  const downloadVslSupplyDataList = async () => {
    let vslSupplyData = [];
    if (searchText) {
      vslSupplyData = await DownloadVesselSupplyDataListService(
        searchText.toString()
      );
    } else {
      vslSupplyData = await DownloadVesselSupplyDataListService(returnedField);
    }
    if (!download) {
      setDownloaded(true);
      const modified_rows = DownloadDataConstant(vslSupplyData);
      const header = DownloadColumnsConstant();
      excelDownload("Vessel_Supply_Data_List", modified_rows, header);
      resetExport();
      notify("File downloaded successfully", true);
    }
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  useEffect(() => {
    setDownloaded(isExport);
  }, [isExport]);
  useEffect(() => {
    fetchVslSupplyData(1, 10);
  }, [searchText]);
  const fetchVslSupplyData = async (
    page: number,
    itemsPerPage: number
  ): Promise<void> => {
    setIsLoading(true);
    setTableSizeDdl(MdsConstants.ItemsPerPageDDl);
    let vesselData: any = [];
    if (searchText) {
      vesselData = await VesselSupplyDataListService(searchText);
    } else {
      vesselData = await VesselSupplyDataListService(returnedField);
    }
    setIsLoading(false);

    numOfRecords = vesselData ? vesselData[0].count : 0;
    setTotalPages(numOfRecords / numOfRecordsPerPage);

    const startIdx = page * numOfRecordsPerPage - numOfRecordsPerPage;
    const endIdx = page * numOfRecordsPerPage - 1;
    const recordsToRead = vesselData?.slice(startIdx, endIdx);
    setIsLoading(false);
    setDataSource(vesselData);
    setVslSupplyDataList(vesselData);
  };

  useEffect(() => {
    if (isExport) downloadVslSupplyDataList();
  }, [isExport]);

  const rowclickhandler = (event: any): void => {
    const rowVal = event;
    setSelectedIMO(rowVal.imoNumber);
    navigate("/vessel-details", {
      state: {
        imo: rowVal.imoNumber,
        searchText: searchText,
      },
    });
  };

  // Fetch vessel overview table data
  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    setDataSource([]);
    if (data) setDataSource(data);
    else {
      setDataSource([]);
      setIsLoading(false);
      setHasError(true);
    }
  }, []);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  return (
    <div
      className="global-table ir-table-main"
      // style={{ marginTop: 0, cursor: "pointer", border: 'solid 1px #d5d5d5' }}
    >
      <DataTable
        columns={columns}
        data={tableData}
        onRowClicked={(e) => rowclickhandler(e)}
        pagination
        paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
}
const columns: TableColumn<VslSupplyDataListModel>[] = [
  {
    name: "Earliest Date",
    selector: (row) => (row.earliestRdel !== null ? row.earliestRdel : ""),
    sortable: true,
    width: "13%",
  },
  {
    name: "Market Rate",
    selector: (row) => (row.marketRate !== null ? row.marketRate : ""),
    sortable: true,
    width: "10%",
  },
  {
    name: "Current Rate",
    selector: (row) => (row.currentRate !== null ? row.currentRate : ""),
    sortable: true,
    width: "10%",
  },
  {
    name: "Current Service",
    selector: (row) => (row.currentService !== null ? row.currentService : ""),
    sortable: true,
    width: "17%",
  },
  {
    name: "Operator",
    selector: (row) =>
      row.operator !== null ? row.operator.toLowerCase() : "",
    cell: (row) => (row.operator !== null ? row.operator : ""),
    sortable: true,
    width: "10%",
  },
  {
    name: "Owner",
    selector: (row) =>
      row.managingOwner !== null ? row.managingOwner.toLowerCase() : "",
    cell: (row) => (row.managingOwner !== null ? row.managingOwner : ""),
    sortable: true,
    width: "11%",
  },
  {
    name: "Hull No.",
    selector: (row) => (row.hullNumber !== null ? row.hullNumber : ""),
    sortable: true,
    width: "8%",
  },
  {
    name: "IMO",
    selector: (row) => (row.imoNumber !== null ? row.imoNumber : ""),
    sortable: true,
    width: "7%",
  },
  {
    name: "Vessel Name",
    selector: (row) =>
      row.vesselName !== null ? row.vesselName.toLowerCase() : "",
    cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
    sortable: true,
    width: "12%",
  },
];
export default VesselSupplyDataList;
