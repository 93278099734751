export const DownloadDataConstant = (data) => {
  const modified_rows = data?.map((e: any) => {
    return {
      "Vessel Name": e.vesselName,
      IMO: e.imoNumber,
      "Vessel Status": e.status,
      "Hull No.": e.hullNumber,
      "Managing Owner": e.managingOwner,
      Operator: e.operator,
      "Current Service": e.currentService,
      "Alternative Fuel Type": e.alternativeFuelTypeValue,
      Beam: e.beam,
      "Beneficial Owner Capital": e.beneficialOwnerCapital,
      "Beneficial Owner Country": e.beneficialOwnerCountry,
      "Build Ship Country": e.buildShipCountry,
      "Build Ship Ward": e.buildShipyward,
      "Built Month": e.builtMonth,
      "Consumption At Max Speed": e.consumptionAtMaxSpeed,
      Design: e.design,
      Draft: e.draft,
      "DWT Tonnage": e.dwtTonnage,
      "Electric Power": e.electicPower,
      "Engine Builder": e.engineBuilder,
      "Engine Design": e.engineDesign,
      "Engine Hp": e.engineHp,
      "Engine Model": e.engineModel,
      "Engines No": e.enginesNumber,
      "Ex Name": e.exName,
      Flag: e.flag,
      Gear: e.gear,
      "Gross Tonnage": e.grossTonnage,
      "Ice Class": e.iceClass,
      lbp: e.lbp,
      LengthOverall: e.lengthOverall,
      "Market Status": e.marketStatus,
      "Net Tonnage": e.netTonnage,
      OrderBook: e.orderBook,
      OwnerCountry: e.ownerCountry,
      "Ownership Status": e.ownershipStatus,
      Power: e.power,
      ReeferPlugs: e.reeferPlugs,
      "Service Id": e.serviceId,
      "Service Name": e.serviceName,
      Speed: e.speed,
      TEU14: e.teU14,
      "Technical Manager": e.techincalManager,
      TEU: e.teu,
      TPC: e.tpc,
      Trade: e.trade,
      Type: e.type,
      Typeshort: e.typeShort,
      "VesselDate Modified": e.vesselDateModified,
      "Vessel Geared": e.vesselGeared,
      "Vessel MMSI": e.vesselMMSI,
      "Vessel Reg Owner Country": e.vesselRegOwnerCountry,
      VesselTOP: e.vesselTOP,
      YOB: e.yob,
    };
  });
  return modified_rows;
};

export const DownloadColumnsConstant = () => {
  const header = [
    "Vessel Name",
    "IMO",
    "Vessel Status",
    "Hull No.",
    "Managing Owner",
    "Operator",
    "Current Service",
    "Alternative Fuel Type",
    "Beam",
    "Beneficial Owner Capital",
    "Beneficial Owner Country",
    "Build Ship Country",
    "Build Ship Ward",
    "Built Month",
    "Consumption At Max Speed",
    "Design",
    "Draft",
    "DWT Tonnage",
    "Electric Power",
    "Engine Builder",
    "Engine Design",
    "Engine Hp",
    "Engine Model",
    "Engines No",
    "Ex Name",
    "Flag",
    "Gear",
    "Gross Tonnage",
    "Ice Class",
    "lbp",
    "LengthOverall",
    "Market Status",
    "Net Tonnage",
    "OrderBook",
    "OwnerCountry",
    "Ownership Status",
    "Power",
    "ReeferPlugs",
    "Service Id",
    "Service Name",
    "Speed",
    "TEU14",
    "Technical Manager",
    "TEU",
    "TPC",
    "Trade",
    "Type",
    "Typeshort",
    "VesselDate Modified",
    "Vessel Geared",
    "Vessel MMSI",
    "Vessel Reg Owner Country",
    "VesselTOP",
    "YOB",
  ];
  return header;
};
