
export const MdsConstants = {
    'ItemsPerPageDDl': [{ value: '10', label: '10' },
                        { value: '15', label: '15' },
                        { value: '20', label: '20' },
                        { value: '25', label: '25' },
                        { value: '30', label: '30' }
                    ],
    'PaginatorVisiblePages': 5                
}

//DEV
export const SUS_FORM_ID = "1235457882618511360";
export const NPS_FORM_ID = "1237325098687393792";

//SIT
export const SUS_FORM_ID_SIT = "1235457959244251136";
export const NPS_FORM_ID_SIT = "1240252291503616000";

//PROD
export const SUS_FORM_ID_PROD = "1235458086755287040";
export const NPS_FORM_ID_PROD = "1240252402799472640";

// Local storage key for storing the last feedback timestamp
export const LAST_FEEDBACK_TIMESTAMP_KEY = 'last_feedback_timestamp'; 