import { McModal, McButton, McTextarea } from "@maersk-global/mds-react-wrapper";
import react, { useState } from "react";
import "./InitialOfferDetails.scss";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
interface IPROPS {
  isModalOpen?: boolean;
  onLoadData?: any;
  onCloseFunc?: any;
  submitData?: any;
  liftSubExtensionTime?: any;
  negotiationType?: any;
}
const ChangeOfferTimeModal = ({
  isModalOpen,
  onLoadData,
  onCloseFunc,
  submitData,
  liftSubExtensionTime,
  negotiationType,
}: IPROPS) => {

  const [valueTime, onChangeDateTime] = useState<any>((onLoadData));
  const [commentValue, setCommentValue] = useState<string>("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if(negotiationType === "Lift Subs"){
      if(liftSubExtensionTime.userType === "CM"){
        if( valueTime > new Date(liftSubExtensionTime.timeValue) 
          || moment(valueTime).format("YYYY-MM-DDTHH:mm") === moment(liftSubExtensionTime.timeValue).format("YYYY-MM-DDTHH:mm") ) {
            setError(`Please provide a date lesser than the lift sub date requested by Chartering Manager.`);
          return;
        }
      } else if(liftSubExtensionTime.userType === "VO"){
        if(valueTime < new Date(liftSubExtensionTime.timeValue) ||
          moment(valueTime).format("YYYY-MM-DDTHH:mm") === moment(liftSubExtensionTime.timeValue).format("YYYY-MM-DDTHH:mm")
        ){
          setError(`Please provide a date greater than the lift sub date requested by Vessel Owner.`);
          return;
        }
      }
    }
    onCloseFunc();
    setCommentValue("");
    const sendValueTime = valueTime == undefined?  onLoadData: valueTime;
    submitData(moment(sendValueTime).format("YYYY-MM-DDTHH:mm"),commentValue, "yes");
  }
  const handleDateChange = (date: any) => {
    setError("");
    onChangeDateTime(date);
  }
  const onCancelData = () => {
    setCommentValue("");
    onCloseFunc();
  };
  return (
    <McModal
      height="400px"
      width="500px"
      className="offer-submission-modal"
      heading={"Change Offer Time"}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="date-value">
        <div className="date-section">
          <label>Please Select Acceptance Date</label>
          <div>
            <DateTimePicker format="MM/dd/yyyy HH:mm" onChange={handleDateChange} value={valueTime == undefined?  onLoadData: valueTime} minDate={new Date()}/>
            {error && (<span className="liftsub-error-msg">{error}<span className="date-display">({moment(liftSubExtensionTime.timeValue).format("DD-MMM-YYYY HH:mm")})</span></span>)}
          </div>
          <div>
          <McTextarea label="Comment" data-testid='comment' input={(e: any) => setCommentValue(e.target.value)} value={commentValue}></McTextarea>
          </div>
        </div>
      </div>

      <McButton
        slot="primaryAction"
        variant="primary"
        fit="small"
        label={"Submit"}
        onClick={handleSubmit}
        data-testid="submit-button"
      ></McButton>
      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        onClick={onCancelData}
        role="cancel-button"
        fit="small"
      >
        Cancel
      </McButton>
    </McModal>
  );
};
export default ChangeOfferTimeModal;
