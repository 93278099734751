

interface IPROPS {
    onZoomIn: () => void;
    onZoomOut: () => void;
}

const Zoom = ({
    onZoomIn,
    onZoomOut
}: IPROPS) => {

    return (
        <div className="zoom-btn">
            <button className="btn-plus" onClick={onZoomIn}>+</button>
            <button className="btn-minus" onClick={onZoomOut}>-</button>
        </div>
    );
}

export default Zoom;