import { useState } from "react";

import DataTable, { TableColumn } from "react-data-table-component";
import {
  downloadReportDocuments,
  postAPI,
} from "../../services/CepApiServices";

import "./DocumentLibraryTableVO.scss";
import { SkeletonLoader } from "../../components/skeletonLoader/SkeletonLoader";
import { DocumentLibraryModelVO } from "./model";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import { IconPdfDL } from "../../components/Icons/pdfIcon";
import moment from "moment";
import ConfirmationModal from "../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import { AppString } from "../../utils/AppString";
import { toast } from "react-toastify";
interface IPROPS {
  tableData: any;
  isTableLoader?: any;
  isDocumentListChanges?: any;
  rerenderFunc?: any;
}
const DocumentLibraryTableVO = ({
  tableData,
  isTableLoader,
  isDocumentListChanges,
  rerenderFunc,
}: IPROPS) => {
  const [modalValue, setModalValue] = useState(false);
  const [rowValue, setRowValue] = useState<DocumentLibraryModelVO>();

  const downloadReportDL = async (fileName: string) => {
    await downloadReportDocuments(
      `DocumentLibrary/DownloadOwnerDocument/${fileName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("Data is not loading");
      });
  };
  const deleteFun = (row: any) => {
    setRowValue(row);
    setModalValue(true);
  };
  const deleteReport = async (response: any) => {
    console.log("rowValue" + JSON.stringify(rowValue));
    if (response == "Yes") {
      const deletedDocumentDetails = {
        documentId: rowValue?.documentId,
        documentName: rowValue?.documentName,
        createdOn: rowValue?.createdOn,
        createdBy: rowValue?.createdBy,
        errorMessage: rowValue?.errorMessage,
        formFile: rowValue?.formFile,
      };
      const res = await postAPI(
        `DocumentLibrary/DeleteOwnerDocument`,
        deletedDocumentDetails
      );
      setModalValue(false);
      if (res === true) {
        notify("Document Deleted Successfully", true);
        rerenderFunc();
      } else {
        notify("Document is referenced and deletion failed", false);
      }
    } else {
      setModalValue(false);
      isDocumentListChanges("No");
    }
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const documentNameWithIcon = (docName: any) => {
    const ext = docName.substr(docName.lastIndexOf(".") + 1);
    return (
      <span>
        <span style={{ paddingRight: "8px" }}>
          <IconPdfDL nameOfDoc={ext} />
        </span>
        <span>{docName}</span>
      </span>
    );
  };

  const columns: TableColumn<DocumentLibraryModelVO>[] = [
    {
      name: "Document Name",
      cell: (row) => documentNameWithIcon(row.documentName),
      selector: (row) => row.documentName,
      sortable: true,
      width: "29%",
    },
    {
      name: "Uploaded By",
      selector: (row) =>
        row.createdBy !== null ? row.createdBy.toLowerCase() : "",
      cell: (row) => (row.createdBy !== null ? row.createdBy : ""),
      sortable: true,
      width: "22%",
    },
    {
      name: "Uploaded On",
      selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
      cell: (row) => moment(row.createdOn).format("DD-MMM-YYYY"),
      sortable: true,
      width: "20%",
    },
    {
      name: "Download",
      width: "16%",
      cell: (row) => (
        <span className="download-dl">
          <McIcon
            icon="tray-arrow-down"
            data-testid="download-document-library-data"
            onClick={() => downloadReportDL(row.documentName)}
          ></McIcon>
        </span>
      ),
    },
    {
      name: "Action",
      width: "9%",
      cell: (row) => (
        <span>
          <div
            data-testid="delete-document-library-data"
            className="delete-icon-library-table"
            onClick={() => deleteFun(row)}
          >
            <McIcon icon="trash" color="#f00"></McIcon>
          </div>
        </span>
      ),
    },
  ];

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  return (
    <div className="document-library-table-main">
      <div className="ir-table-main table-margin1 bg-white">
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={isTableLoader}
          progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />

        <ConfirmationModal
          onModalOpen={modalValue}
          onModalClose={deleteReport}
          modalTxt={AppString.deleteModalTxt}
          width={"400px"}
          height={"130px"}
        />
      </div>
    </div>
  );
};
export default DocumentLibraryTableVO;
