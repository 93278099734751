import React, { useEffect } from "react";
import { useState } from "react";
import { McModal, McIcon } from "@maersk-global/mds-react-wrapper";
import { FileUploader } from "react-drag-drop-files";
import ProgressBar from "react-bootstrap/ProgressBar";

interface IPROPS {
  onOpen?: boolean;
  closeUpload?: any;
  fileUpload?: any;
  progressStatusRes?: any;
  documentList?: any;
}

const UploadDocumentLibraryVO = ({
  onOpen,
  closeUpload,
  fileUpload,
  progressStatusRes,
  documentList,
}: IPROPS) => {
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [modalHieght, setModalHieght] = useState("55vh");
  const [progressStatus, setProgressStatus] = useState("success");
  const [fileValue, setFile] = useState<any>();
  const [isUploadSection, setIsUploadSection] = useState(true);
  const [progress, setProgress] = useState(0);
  const statusRef: any = React.useRef();
  const loadTotalRef: any = React.useRef();
  const progressRef: any = React.useRef();
  const [errorValueForFile, setErrorValueForFile] = useState(false);

  function formatBytes(bytes: any) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;

    while (bytes >= 1024 && i < units.length - 1) {
      bytes /= 1024;
      i++;
    }

    return bytes.toFixed(2) + units[i];
  }

  const handleFileChange = (file: any) => {
    setFile(file);
    let checkDocumentExist = documentList?.some(function (item: any) {
      return item["documentName"] === file?.name;
    });
    if (checkDocumentExist) {
      setErrorValueForFile(true);
    } else {
      setErrorValueForFile(false);
    }
    setIsUploadSection(false);
    setModalHieght("55vh");

    let xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", ProgressHandler, false);
  };

  const handleSubmit = async () => {
    if (fileUpload) {
      fileUpload(fileValue);
    }
  };

  const ProgressHandler = (e) => {
    loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
    let percent = (e.loaded / e.total) * 100;
    progressRef.current.value = Math.round(percent);
    statusRef.current.innerHTML = Math.round(percent) + "% uploaded...";
  };

  const onCancel = (data: any) => {
    closeUpload(data);
    setProgressStatus("info");
    setIsUploadSection(true);
    setProgress(0);
    setModalHieght("55vh");
  };

  const onReload = () => {
    setIsUploadSection(true);
    setProgressStatus("info");
    setModalHieght("48vh");
    setProgress(0);
  };

  useEffect(() => {
    handleSubmit();
  }, [fileValue]);

  useEffect(() => {
    setIsUploadSection(true);
  }, [onOpen]);

  return (
    <McModal
      open={onOpen}
      heading="Upload Documents"
      width="560px"
      backdropcloseactiondisabled
      height={modalHieght}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="modal-section">
            <div className="subColoum">
              <div className="header-section">
                <div
                  className="closeIcon"
                  onClick={() => onCancel(null)}
                  data-testid="close-icon"
                >
                  <McIcon icon="times"></McIcon>
                </div>
                {
                  <div className="row">
                    <div className="col-md-12">
                      {isUploadSection && (
                        <div
                          className="uploader-div"
                          data-testid="fileuploader"
                        >
                          <FileUploader
                            handleChange={handleFileChange}
                            name="File"
                            disabled={isUploadDisabled}
                          >
                            <div className="dropZone" data-testid="file-input">
                              <div className="drop-text">
                                <p>
                                  <McIcon icon="folder-arrow-up"></McIcon>
                                  <span>Select file </span> or Drag here
                                </p>
                              </div>
                            </div>
                          </FileUploader>
                        </div>
                      )}
                      {!isUploadSection && (
                        <div className="attach-div">
                          <p className="file-name" data-testid="file-name">
                            {fileValue?.name}
                          </p>
                          <p className="file-size" data-testid="file-size">
                            File size :{" "}
                            {fileValue ? formatBytes(fileValue.size) : "N/A"}{" "}
                          </p>
                          <div className="progress-section">
                            {errorValueForFile && (
                              <span
                                className="status danger"
                                data-testid="progress-status"
                              >
                                <span className="icon">
                                  {" "}
                                  <McIcon icon="exclamation-circle"></McIcon>
                                </span>{" "}
                                Document already exist please choose something
                                else
                                <span
                                  className="reload"
                                  data-testid="reload-button"
                                  onClick={onReload}
                                >
                                  <McIcon icon="arrow-clockwise"></McIcon>
                                </span>
                              </span>
                            )}
                            {progressStatusRes == "success" && (
                              <span
                                className="status success"
                                data-testid="progress-status"
                              >
                                <span className="icon">
                                  <McIcon icon="check-circle"></McIcon>
                                </span>{" "}
                                Upload success
                              </span>
                            )}
                            {progressStatusRes == "danger" && (
                              <span
                                className="status danger"
                                data-testid="progress-status"
                              >
                                <span className="icon">
                                  {" "}
                                  <McIcon icon="exclamation-circle"></McIcon>
                                </span>{" "}
                                Upload failed
                                <span
                                  className="reload"
                                  data-testid="reload-button"
                                  onClick={onReload}
                                >
                                  <McIcon icon="arrow-clockwise"></McIcon>
                                </span>
                              </span>
                            )}
                            <ProgressBar
                              variant={progressStatus}
                              now={progress}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </McModal>
  );
};

export default UploadDocumentLibraryVO;
