// To check for normal case CM side: All the given inputs should be filled
export const getAllTermsFilledCM = (termsData: any, isExtended:any) => {
  const otherItems: any = [];
  termsData.forEach((terms) => {
    if (terms.subTerms) {
      terms.subTerms.forEach((subTerm) => {
        if (subTerm.isNegotiable === true) {
          subTerm.details.forEach((detail) => {
            if (detail.lineType !== "Description") {
              detail.inputs.forEach((item) => {
                if (
                  item.cmDisabled !== true &&
                  item.isRequired &&
                  (item.value === null || item.value === "") &&
                  item.dataType !== "SelectedCheckbox" &&
                  item.dataType !== "Checkbox"
                ) {
                  // otherItems.push({
                  //   label: detail.label,
                  //   type: detail.lineType,
                  // });
                  if(isExtended && item.standardTermDetailId == 57) {
                    console.log("Added condition");
                  } else {
                    otherItems.push({
                      label: detail.label,
                      type: detail.lineType,
                    });
                  }
                }
              });
            }
          });
        }
      });
    }
  });
  return otherItems.length > 0 ? true : false;
};

// To check for normal case VO side: All the given inputs should be filled
export const getAllTermsFilledVO = (termsData: any, isExtended:any) => {
  const otherItems: any = [];
  termsData.forEach((terms) => {
    if (terms.subTerms) {
      terms.subTerms.forEach((subTerm) => {
        if (subTerm.isNegotiable === true) {
          subTerm.details.forEach((detail) => {
            if (detail.lineType !== "Description") {
              detail.inputs.forEach((item) => {
                if (
                  (item.value === null || item.value === "") &&
                  item.isRequired &&
                  item.dataType !== "SelectedCheckbox" &&
                  item.dataType !== "Checkbox"
                ) {
                  if(isExtended && item.standardTermDetailId == 57) {
                    console.log("Added condition");
                  } else {
                    otherItems.push({
                      label: detail.label,
                      type: detail.lineType,
                    });
                  }
                }
              });
            }
          });
        }
      });
    }
  });
  return otherItems.length > 0 ? true : false;
};

// To check for initial case CM side : All the given inputs should be filled and condition for SelectedCheckbox and Checkbox
export const getInitialNegotiationTermsFilledCM = (termsData: any) => {
  const otherItems: any = [];
  termsData.forEach((terms) => {
    if (terms.subTerms) {
      terms.subTerms.forEach((subTerm) => {
        if (subTerm.isNegotiable === true) {
          subTerm.details.forEach((detail) => {
            if (detail.lineType !== "Description") {
              // checking the presence of "SelectedCheckbox"
              const checkBoxPresent = detail.inputs.find(
                (item) =>
                  item.dataType === "SelectedCheckbox" &&
                  item.cmDisabled !== true
              );
              if (checkBoxPresent) {
                // if SelectedCheckbox present and its checked, check for the value should not be null, else no need to check
                const selectedCheckboxSibling = detail.inputs.find(
                  (item) =>
                    item.dataType === "SelectedCheckbox" &&
                    item.value === "true" &&
                    item.cmDisabled !== true
                );
                if (selectedCheckboxSibling) {
                  detail.inputs.forEach((item) => {
                    if (
                      item.cmDisabled !== true &&
                      item.isRequired &&
                      (item.value === null || item.value === "") &&
                      item.dataType !== "SelectedCheckbox" &&
                      item.dataType !== "Checkbox"
                    ) {
                      otherItems.push({
                        label: detail.label,
                        type: detail.lineType,
                      });
                    }
                  });
                }
              } else {
                // check for the presence of "Checkbox"
                const checkboxSibling = detail.inputs.find(
                  (item) =>
                    item.dataType === "Checkbox" &&
                    item.value === "true" &&
                    item.cmDisabled === false
                );
                if (checkboxSibling) {
                  // if Checkbox present and checked, check for forId and check value, else no need to check
                  const forIdSibling = detail.inputs.find(
                    (item) =>
                      item.forId === checkboxSibling.standardTermDetailId &&
                      item.cmDisabled !== true &&
                      (item.value === null || item.value === "") &&
                      item.dataType !== "Checkbox"
                  );

                  if (forIdSibling) {
                    otherItems.push({
                      label: detail.label,
                      type: detail.lineType,
                    });
                  }
                } else {
                  // checking non-null values for other dataTypes
                  detail.inputs.forEach((item) => {
                    if (
                      item.cmDisabled !== true &&
                      item.isRequired &&
                      (item.value === null || item.value === "") &&
                      item.dataType !== "SelectedCheckbox" &&
                      item.dataType !== "Checkbox" &&
                      !item.forId
                    ) {
                      otherItems.push({
                        label: detail.label,
                        type: detail.lineType,
                      });
                    }
                  });
                }
              }
            }
          });
        }
      });
    }
  });
  return otherItems.length > 0 ? true : false;
};

// To check for 1st 3 Terms in VO side : All fields should be either filled or RNC checked
export const getMandatoryTermsFilledVO = (termsData: any) => {
  // to find null values and revert later false fields
  const yetToFillItems: any = [];
  termsData.forEach((terms) => {
    if (terms.subTerms) {
      let subTermsCounter = 0;
      terms.subTerms.forEach((subTerm) => {
        if (subTerm.isNegotiable === false) {
          subTerm.details.forEach((detail) => {
            if (
              detail.revertLater === false &&
              detail.lineType !== "Description"
            ) {
              detail.inputs.forEach((item) => {
                if (
                  (item.value === null || item.value === "") &&
                  item.isRequired
                ) {
                  yetToFillItems.push({
                    label: detail.label,
                    type: detail.lineType,
                  });
                }
              });
            }
          });
        }
      });
      subTermsCounter++;
      if (subTermsCounter === 3) {
        return;
      }
    }
  });
  const uniqueItems = Array.from(
    new Set(yetToFillItems.map((item: any) => JSON.stringify(item)))
  ).map((item: any) => JSON.parse(item));
  return uniqueItems;
};

export const getBunkerCheckBoxStatus = (
  termsData: any,
  typeOfContract: any
) => {
  // let isErrorKey = true;
  let isErrorKey = typeOfContract ? false : true;
  termsData.forEach((val: any) => {
    val.subTerms.forEach((subtermData: any) => {
      subtermData.details.forEach((detailsData: any) => {
        detailsData.inputs.forEach((inputData: any) => {
          if (
            inputData.value === "true" &&
            inputData.dataType === "SelectedCheckbox"
          ) {
            isErrorKey = false;
            detailsData.inputs.forEach((input: any) => {
              if (
                input.lineType === "Editable" &&
                (input.value === null || input.value === "")
              ) {
                isErrorKey = true;
                return false;
              }
            });
          }
        });
      });
    });
  });
  return isErrorKey;
};

// Bunker validation for extended contract
export const getBunkerCheckBoxStatusForExtension = (
  termsData: any
) => {
  let isErrorKey = true;
  termsData.forEach((val: any) => {
    val.subTerms.forEach((subtermData: any) => {
      subtermData.details.forEach((detailsData: any) => {
        detailsData.inputs.forEach((inputData: any) => {
          if (
            inputData.value === "true" &&
            inputData.dataType === "SelectedCheckbox"
          ) {
            isErrorKey = false;
            detailsData.inputs.forEach((input: any) => {
              if (
                input.lineType === "Editable" &&
                (input.value === null || input.value === "")
              ) {
                isErrorKey = true;
                return false;
              }
            });
          }
        });
      });
    });
  });
  return isErrorKey;
};