import { al } from '../../auth/al-login-config';
import { checkIfUserLoggedIn } from "../../components/Protected/AuthHandler";
import { UserAgentPublicApp } from "@maersk/iam-al";
import { loginCallbackConfig } from '../../auth/al-login-callback-config'

const ForgeRockLoginPage = () => {
  // Check for login user

  const isLoggedIn = checkIfUserLoggedIn();
  if (!isLoggedIn) {
    return al.loginRedirect({
      response_type: 'token id_token',
      entity_type: process.env.REACT_APP_ENTITY_TYPE,
      entity_code: process.env.REACT_APP_ENTITY_CODE,
      /**
      * @type: string[]
      * @default: ["openid", "profile", "email"]
      * @description: the scope of the access in the initial request
      */
      scopes: ["openid", "profile", "email"]
    });
  } else {
    const al = new UserAgentPublicApp(loginCallbackConfig);
    console.log(al.storage);
    return al.login_redirect_cb(al.storage, null)
  }
}

// const LogOutPage = () => {
//   logoutApp() // import it
//   window.location.href = '/home';
// }

export default ForgeRockLoginPage;