import React, { useState, useEffect } from "react";
import "./InitialOfferDetails.scss";
import InitiateOfferDetailsVO from "./InitiateOfferDetailsVO";
import InitiateOfferDetailsCM from "./InitiateOfferDetailsCM";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { useLocation } from "react-router-dom";
import { getUserRole } from "../../../utils/token";
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const InitiateOfferDetails = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const indicationId = params.get("id");
  const location = useLocation();
  const isContractExtended = location?.state?.isExtended;
  const oldNegotiationId = location?.state?.negotiationID;
  const extendedContractId = location?.state?.extendedContractId;
  const indicationStatus = location?.state?.indicationStatus;
  // const location = useLocation();
  // const indicationSection = location.state?.user;
  const [userRoleDetail, setUserRoleDetail] = useState<any>();
  const [indicationIdData, setIndicationIdData] = useState<any>(indicationId);
  // const [initialOfferDetails_section, setInitialOfferDetails_section] = useState<any>(indicationSection);
  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      setUserRoleDetail(userRole);
    };
    getUserRoleAsync();
  }, []);

  return (
    <>
      {userRoleDetail == "CharteringManager" ? (
        <InitiateOfferDetailsCM
          indicateId={indicationIdData}
          isContractExtended={isContractExtended}
          oldNegotiationId={oldNegotiationId}
          extendedContractId={extendedContractId}
          indicationStatus={indicationStatus}
        />
      ) : userRoleDetail == "VesselOwner" ||
        userRoleDetail == "OwnerRepresentative" ? (
        <InitiateOfferDetailsVO indicateId={indicationIdData} />
      ) : (
        <div>...</div>
      )}
    </>
  );
};
export default InitiateOfferDetails;