import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  postAPI,
} from "../../../services/CepApiServices";
interface IPROPS {
  isModalOpen?: boolean;
  onLoadData?: any;
  onCanceled?: any;
}
const InitiateOfferModal = ({
  isModalOpen,
  onLoadData,
  onCanceled,
}: IPROPS) => {
  // const [documents, setDocuments] = useState<any>([]);
  // const [isFixture, setIsFixture] = useState(false);
  // const [isStartNegotiate, setIsStartNegotiate] = useState(false);
  // const [checkeddoc, setCheckeddoc] = useState();
  // const [docToggle, setDocToggle] = useState(true);
  // const [offerType, setOfferType] = useState("");
  // const [isDocLoading, setIsDocLoading] = useState(false);
  const history = useNavigate();

  // const handleSubmit = async (checkeddoc: any) => {
  //   const newArray = {
  //     indicationId: onLoadData.id,
  //     offerType: offerType,
  //     contractDocId: checkeddoc.documentId,
  //   };
  //   console.log(newArray);
  //   const response = await postAPI(`InitialOffer/SaveInitialOffer`, newArray);
  //   if (response) {
  //     history(`/InitiateOfferDetails?id=${onLoadData.id}&user=CM`);
  //   }
  // };

  // const fetchNewFixtureDoc = async () => {
  //   setIsDocLoading(true);
  //   setIsFixture(true);
  //   setDocToggle(true);
  //   const response = await getAPI(`DocumentLibrary/GetAllDocuments`);
  //   if (response) {
  //     const filteredDocuments = response.filter(
  //       (document) =>
  //         document.status === "Reviewed" && document.documentCategoryId === 67
  //     );
  //     setDocuments(filteredDocuments);
  //     setIsDocLoading(false);
  //   }
  //   setOfferType("New Negotiation");
  // };

  // const fetchExistingFixtureDoc = async () => {
  //   setIsDocLoading(true);
  //   setIsFixture(true);
  //   setDocToggle(false);
  //   const response = await getAPI(`DocumentLibrary/GetAllDocuments`);
  //   if (response) {
  //     const filteredDocuments = response.filter(
  //       (document) =>
  //         document.status === "Reviewed" && document.documentCategoryId === 67
  //     );
  //     setDocuments(filteredDocuments);
  //     setIsDocLoading(false);
  //     setOfferType("Extension");
  //   }
  // };
  // const handleDownload = async (fileName: any, categoryName: any) => {
  //   await downloadReportDocuments(
  //     `DocumentLibrary/DownlaodDocument/${fileName}/${categoryName}`
  //   )
  //     .then((data) => data!.blob())
  //     .then((b) => {
  //       const url = window.URL.createObjectURL(b);
  //       let a = document.createElement("a");
  //       let name = fileName;
  //       document.body.appendChild(a);
  //       a.href = url;
  //       a.download = `${name}`;
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch(() => {
  //       console.log("Could not download the file");
  //     });
  // };
  // const downloadFile = (fileName: any, categoryName: any) => {
  //   return handleDownload(fileName, categoryName);
  // };
  // const handleDocumentClick = (id: any) => {
  //   const newArray = documents?.map((element: any) => {
  //     if (element.documentId === id) {
  //       element.checked = true;
  //       setCheckeddoc(element);
  //     } else {
  //       element.checked = false;
  //     }
  //     return element;
  //   });
  //   setDocuments(newArray);
  //   setIsStartNegotiate(true);
  // };
  const handleInitiateClick = async (fixture:string) => {
    const newArray = {
      indicationId: onLoadData.id,
      offerType: fixture,
      contractDocId: null,
    };
    const response = await postAPI(`InitialOffer/SaveInitialOffer`, newArray);
    if ( response === true){
      history(`/InitiateOfferDetails?id=${onLoadData.id}&user=CM`);
    }
  };
  const onCancel = () => {
    // setIsFixture(false);
    // setDocuments([]);
    // setIsStartNegotiate(false);
    onCanceled();
  };

  return (
    <McModal
      height="450px"
      width="900px"
      className="initiate-offer-modal"
      heading={`Initiate Offer for ${onLoadData?.vesselName}`}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="initiate-offer-modal-content">
        {/* {!isFixture ? ( */}
          <div className="select-fixture">
            <div className="row">
              <div className="col-md-6">
                <div className="main-div" data-testid="fixture-button">
                  <div className="upper-div">
                    <span className="icon">
                      <McIcon icon="file"></McIcon>
                    </span>
                    <span className="text">
                      <h5>NEW FIXTURE</h5>
                      <p>
                        Create and Negotiate on a new fixture for a new
                        customer.
                      </p>
                    </span>
                  </div>
                  <div className="btn-div">
                    <button
                      data-testid="new-fixture"
                      className="btn btn-primary"
                      onClick={() => handleInitiateClick('New Negotiation')}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="main-div" data-testid="fixture-button">
                  <div className="upper-div">
                    <span className="icon">
                      <McIcon icon="file"></McIcon>
                    </span>
                    <span className="text">
                      <h5>EXTENSION OF FIXTURE</h5>
                      <p>
                        Create and Negotiate on a extension fixture for a
                        existing customer.
                      </p>
                    </span>
                  </div>
                  <div className="btn-div">
                    <button
                      data-testid="existing-fixture"
                      className="btn btn-primary"
                      onClick={() => handleInitiateClick("Extension")}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* ) : ( */}
          {/* <>
            {docToggle ? (
              <div className="select-documents">
                <div className="header-div">
                  <span className="icon">
                    <McIcon icon="file"></McIcon>
                  </span>
                  <span className="icon-text">Select Contract Documents</span>
                </div>
                <div className="body-div">
                  {documents.length === 0 && isDocLoading && (
                    <div className="loader-div">
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                  {documents.length === 0 && !isDocLoading && (
                    <div className="no-documents no-reviewed-doc">
                      <span>No reviewed documents found</span>
                    </div>
                  )}
                  <div className="row">
                    {documents?.map((data: any) => (
                      <div
                        data-testid="handle-new-doc"
                        className="col-md-4"
                        onClick={() => handleDocumentClick(data.documentId)}
                      >
                        <div
                          className={
                            data?.checked === true
                              ? "download-div active"
                              : "download-div"
                          }
                        >
                          <span className="doc-name">{data?.documentName}</span>
                          <span
                            className="icon-div"
                            onClick={() => {
                              downloadFile(
                                data?.documentName,
                                data?.documentCategoryName
                              );
                            }}
                            data-testid="doc-download"
                          >
                            <McIcon icon="tray-arrow-down"></McIcon>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="select-documents">
                <div className="header-div">
                  <span className="icon">
                    <McIcon icon="file"></McIcon>
                  </span>
                  <span className="icon-text">Select Contract Documents</span>
                </div>
                <div className="body-div">
                  {documents.length === 0 && isDocLoading && (
                    <div className="loader-div">
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                  {documents.length === 0 && !isDocLoading && (
                    <div className="no-documents no-reviewed-doc">
                      <span>No reviewed documents found</span>
                    </div>
                  )}
                  <div className="row">
                    {documents?.map((data: any) => (
                      <div
                        data-testid="handle-existing-doc"
                        className="col-md-4"
                        onClick={() => handleDocumentClick(data.documentId)}
                      >
                        <div
                          className={
                            data?.checked === true
                              ? "download-div active"
                              : "download-div"
                          }
                        >
                          <span className="doc-name">{data?.documentName}</span>
                          <span
                            className="icon-div"
                            data-testid="existing-download"
                            onClick={() => {
                              downloadFile(
                                data?.documentName,
                                data?.documentCategoryName
                              );
                            }}
                          >
                            <McIcon icon="tray-arrow-down"></McIcon>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </> */}
        {/* )} */}
      </div>
      {/* {isStartNegotiate && (
        <McButton
          slot="primaryAction"
          variant="primary"
          fit="small"
          label="Start Negotiation"
          onClick={() => handleSubmit(checkeddoc)}
          data-testid="save-and-update"
        ></McButton>
      )} */}
      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        role="cancel-cp"
        fit="small"
        onClick={() => onCancel()}
      >
        Cancel
      </McButton>
    </McModal>
  );
};
export default InitiateOfferModal;
