import React from "react";
import "./SkeletonLoader.scss";
interface IPROPS {
  isLoader?: any;
}
export const SkeletonLoader = ({ isLoader }: IPROPS) => {
  const loaderCount = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  return (
    <table className={`tg ${!isLoader ? "hideTable" : ""}`}>
      <thead>
        <tr>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
        </tr>
      </thead>
      <tbody>
        {loaderCount.map((val, i) => (
          <tr key={i}>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
            <td className="tg-cly1">
              <div className="line"></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
