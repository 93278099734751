import { useState, useEffect } from "react";
import {
  McModal,
  McButton,
  McIcon,
  McCheckbox,
  McCheckboxGroup,
  McRadio,
} from "@maersk-global/mds-react-wrapper";
import { postAPI, getAPI } from "../../../../services/CepApiServices";
import { useForm, Controller } from "react-hook-form";
import "./MoveToIssueRecapModal.scss";
import moment from "moment";
import DataTable from "react-data-table-component";
import { TableColumn } from "react-data-table-component";
import Nodata from "../../../../components/NoData/NoData";

interface IPROPS {
  isModalOpen?: boolean;
  indicationId?: any;
  onCanceled?: any;
  isPrefilledCondition?: any;
}

const MoveToIssueRecapModal = ({
  isModalOpen,
  indicationId,
  onCanceled,
  isPrefilledCondition,
}: IPROPS) => {
  const arr: any = [];
  const [result, setResult] = useState([]);
  const [selectedMainTermsToMove, setSelectedMainTermsToMove] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { register, handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      mainTermsList: "",
      mainTermsList1: "",
    },
  });

  const onError = (errors, e) => console.log(errors, e);

  const onSubmit = async (data: any) => {
    onCanceled("OK", selectedMainTermsToMove);
  };

  const closeAcceptFunc = () => {
    onCanceled("cancel", null);
  };

  const getMainTermsList = async () => {
    const result = await getAPI(
      "InitialOffer/GetAcceptedTerms?indicationId=" + indicationId
    );
    setResult(result);
    setTableData(result);
  };

  useEffect(() => {
    getMainTermsList();
  }, []);
  type DataRow = {
    termName: any;
    dateRunningRecapEnabled: any;
    runningRecapEnabled: any;
  };
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Term Name",
      selector: (row) => (row.termName ? row.termName : ""),
      cell: (row) =>
        row.runningRecapEnabled
          ? termStatus(row.termName)
          : row.termName == "Laycan" && isPrefilledCondition
          ? "New Time and Period"
          : row.termName,
      sortable: true,
      width: "50%",
    },
    {
      name: "Running Recap Enabled Date",
      selector: (row) =>
        row.dateRunningRecapEnabled
          ? moment(row?.dateRunningRecapEnabled).format("DD-MMM-YYYY HH:mm:ss")
          : "",
      sortable: true,
      width: "50%",
    },
  ];

  const termStatus = (termName: any) => {
    return (
      <span className="accepted-terms">
        {termName == "Laycan" && isPrefilledCondition
          ? "New Time and Period"
          : termName}
      </span>
    );
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedMainTermsToMove(selectedRows);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="submit">
      <McModal
        width="670px"
        height="450px"
        open={isModalOpen}
        heading="Please select below accepted main terms to move to running recap"
      >
        <div className="mainTermsSelectedTable">
          {tableData?.length == 0 ? (
            <div className="no-data-section">
              <Nodata text={"No Data Found.."} size={"small"} />
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={tableData}
              selectableRows
              selectableRowDisabled={(row) => row.runningRecapEnabled == true}
              // clearSelectedRows={clearChecked}
              onSelectedRowsChange={handleChange}
              // selectableRowSelected={(row) => row.runningRecapEnabled == true}
            />
          )}
        </div>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          click={closeAcceptFunc}
          dialogAction="cancel"
          data-testid="cancel"
        >
          Cancel
        </McButton>
        {tableData?.length !== 0 && (
          <McButton
            slot="primaryAction"
            variant="primary"
            type="submit"
            label="Submit"
            fit="small"
          ></McButton>
        )}
      </McModal>
    </form>
  );
};

export default MoveToIssueRecapModal;