// MDS
import { TableColumn } from "@maersk-global/mds-components-community/mc-c-table/types";
import { html, TemplateResult } from "lit";
import { fetchTimeFormat } from "../../utils/customDateTime";

export const AISColumns: Array<TableColumn> = [
  {
    id: "port",
    label: "Ports",
    width: "auto",
    disableSorting: true,
  },
  {
    id: "entryDate",
    label: "Entry date",
    width: "auto",
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span>${fetchTimeFormat(details.value)}</span>`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "outDate",
    label: "Exit date",
    align: "center",
    disableSorting: true,
    cellTemplate: (details): TemplateResult => {
      if (details.value && details.value !== "-" && details.value !== null) {
        return html`<span>${fetchTimeFormat(details.value)}</span>`;
      } else {
        return html`<span>-</span>`;
      }
    },
  },
  {
    id: "portStayValue",
    label: "Stayed",
    width: "auto",
    disableSorting: true,
  },
  {
    id: "outDraft",
    label: "Out Draft",
    width: "auto",
    disableSorting: true,
  },
  {
    id: "serviceName",
    label: "Service",
    width: "auto",
    disableSorting: true,
  },
  {
    id: "nextDestination",
    label: "Next Destination",
    width: "auto",
    disableSorting: true,
  },
];
