// React
import React from "react";
import { McIcon } from "@maersk-global/mds-react-wrapper";

// styles
import "./SideBarData.scss";

const SideBarData = [
  {
    title: "Dashboard",
    path: "/Dashboard",
    icon: "layout-2x2",
    cName: "nav-text",
    page: "cep",
    id: "menu-dashboardPage",
    accessTo: ["CharteringManager"],
  },
  {
    title: "Charter Requests",
    path: "/CharterRequest",
    icon: "file",
    cName: "nav-text",
    page: "cep",
    id: "menu-charterRequestPage",
    accessTo: ["CharteringManager"],
    subNav: [
      {
        text: "Charter Details",
        path: "/CharterDetails",
        page: "cep",
        id: "menu-charterDetailsPage",
        accessTo: ["CharteringManager"],
      },
      {
        text: "Vessel Compare",
        path: "/VesselCompare",
        page: "cep",
        id: "menu-vslComparePage",
        accessTo: ["CharteringManager"],
      },
    ],
  },
  {
    title: "Indication Requests",
    path: "/IndicationRequest",
    icon: "browser",
    cName: "nav-text",
    page: "cep",
    id: "menu-indicationRequestPage",
    accessTo: ["CharteringManager"],
    subNav: [
      {
        text: "Indication Request Details",
        path: "/GetIndicationRequestDataAsync",
        page: "cep",
        id: "menu-indicationReqDataAsyncPage",
        accessTo: ["CharteringManager"],
      },
    ],
  },
  {
    title: "Indication Requests",
    path: "/IndicationRequestVO",
    icon: "browser",
    cName: "nav-text",
    page: "cep",
    id: "menu-indicationRequestVO",
    accessTo: ["VesselOwner", "OwnerRepresentative"],
    subNav: [
      {
        text: "Indication Request Details",
        path: "/GetIndicationRequestDataAsyncVO",
        page: "cep",
        id: "menu-indicationReqDataAsyncVOPage",
        accessTo: ["VesselOwner", "OwnerRepresentative"],
      },
    ],
  },
  {
    title: "Vessel SupplyData",
    path: "/",
    icon: "vessel-front",
    cName: "nav-text",
    page: "vessel",
    id: "menu-vslSupplyDataPage",
    subNav: [
      {
        text: "Vessel Details",
        path: "/vessel-details",
        page: "vessel",
        id: "menu-vslDetailsPage",
      },
    ],
  },
  {
    title: "Negotiation",
    path: "/Negotiation",
    icon: "handshake",
    cName: "nav-text",
    page: "cep",
    id: "menu-negotiationPage",
    accessTo: [
      "CharteringManager",
      "VesselOwner",
      "OwnerRepresentative",
      "Deploymentmanager",
    ],
    subNav: [
      {
        text: "Intiate Offer Details",
        path: "/InitiateOfferDetails",
        page: "cep",
        id: "menu-initiateOfferDetailsPage",
        accessTo: [
          "CharteringManager",
          "VesselOwner",
          "OwnerRepresentative",
          "Deploymentmanager",
        ],
      },
    ],
  },
  {
    title: "Document Verification",
    path: "/DocumentVerification",
    icon: "file-magnifying-glass",
    cName: "nav-text",
    page: "cep",
    id: "menu-documentVerification",
    accessTo: ["CharteringManager", "CEPDocumentReviewer"],
    subNav: [
      {
        text: "Document Verification Page",
        path: "/DocumentVerificationPage",
        page: "cep",
        id: "menu-documentVerificationPage",
        accessTo: ["CharteringManager", "CEPDocumentReviewer"],
      },
    ],
  },
  {
    title: "Template Repository",
    path: "/DocumentLibrary",
    icon: "file-copy",
    cName: "nav-text",
    page: "cep",
    id: "menu-templateRepositoryPage",
    accessTo: ["CharteringManager"],
  },
  {
    title: "Template Repository",
    path: "/DocumentLibraryVO",
    icon: "file-copy",
    cName: "nav-text",
    page: "cep",
    id: "menu-templateRepositoryPageVO",
    accessTo: ["VesselOwner", "OwnerRepresentative"],
  },
  {
    title: "Indication Request Documents",
    path: "/IndicationRequestDocumentsDownloads",
    icon: "file-copy",
    cName: "nav-text",
    page: "cep",
    id: "menu-indicationRequestDocPage",
    accessTo: ["CharteringManager", "Postfixtureteam"],
  },
  {
    title: "Approvals",
    path: "/Approvals",
    icon: "file-check",
    cName: "nav-text",
  },
  {
    title: "Dictionary",
    path: "/Dictionary",
    icon: "book-open",
    cName: "nav-text",
  },
  {
    title: "Manage Contracts",
    path: "/ManageContracts",
    icon: "file-check-stamp",
    cName: "nav-text",
  },
  {
    title: "Access & Permissions",
    path: "/AccessPermissions",
    icon: "people",
    cName: "nav-text",
  },
];
export default SideBarData;
