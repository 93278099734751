import React, { useState, useEffect } from "react";
import "./VesselCompare.scss";
import { McButton, McModal } from "@maersk-global/mds-react-wrapper";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import {RejectMail} from '../../../components/Icons/Icons'
interface IPROPS {
  isCompareModal?:any;
  compareDataValue?:any;
  closeFunc?:any;
}
const VesselCompare = ({isCompareModal, compareDataValue, closeFunc}:IPROPS) => {
  const [isCmpBtn, setIsCmpBtn] = useState(true);
  const [compareData, setCompareData] = useState([]);
  const [sendIndicationData, setSendIndicationData] = useState('')
  useEffect(() => {
    setCompareData(compareDataValue)
  }, [isCompareModal]);
  
  const selectToSend = (imo:any) =>{
    const activeCompare:any = compareData?.map((data:any) => 
      data?.imo === imo ? data?.isSeleceted === true ? {...data, isSeleceted: false } : {...data, isSeleceted: true } : data
    )
    setCompareData(activeCompare)
    const result = activeCompare.filter(res=>res.isSeleceted);
    if(result.length > 0){
      result?.find(e => e.workflowStageId === 2) ? setIsCmpBtn(true) : setIsCmpBtn(false)
    }else{
      setIsCmpBtn(true)
    }
    
    setSendIndicationData(result)
  }
  const clickOnClose = () =>{
    closeFunc('close',sendIndicationData)
  }
  const submitIndication =() =>{
    closeFunc('submitCompare',sendIndicationData)
  }
  const handleStatusIcon = (row: any) => {
    switch (row.workflowStageId) {
      case 2:
        return <li className="icon" title="Send Indication"><McIcon size='24' icon="envelope" color="#42b0d5"></McIcon></li>;
      case 3:
        return <li className="icon" title="Indication Cancelled"><RejectMail/></li>;
      case 4:
        return <li className="icon" title="VO Rejected"><McIcon size='24' color="#c00" icon="times-circle"></McIcon></li>;
      case 5:
        return <li className="icon" title="Indication Received"><McIcon size='24' icon="file-check" color="#07cb07"></McIcon></li>;
      case 6:
        return <li className="icon" title="Indication Accepted"><McIcon size='24' icon="check-circle" color="#07cb07"></McIcon></li>;
      case 7:
        return <li className="icon" title="Response Rejected"><McIcon size='24' color="#c00" icon="times"></McIcon></li>;
      case 8:
        return <li className="icon" title="Indication Terminated"><McIcon size='24' color="#f09800" icon="times-circle"></McIcon></li>;
      case 9:
        return <li  className="icon" title="Offer Initiated"><McIcon size='24' icon="tag" color="#42b0d5"></McIcon></li>;
    }
  };
  return (<>
    <McModal
        width="auto"
        height="auto"
        open={isCompareModal}
        heading="Vessel Comparison"
        backdropcloseactiondisabled
      >
    <div className="VesselCompare-Section" data-testid="compare-modal">
      <div className="table-section">
        <ul className="compare-section">
          <li className="list-main headerlist">
            <ul className="subList ">
              <li className="subitem header-colom heading-name">Vessel</li>
              <li className="subitem heading-name">Design</li>
              <li className="subitem">Broker Design</li>
              <li className="subitem">Fuel Class</li>
              <li className="subitem heading-name">TCO</li>
              <li className="subitem">Slot Cost (forecast)</li>
              <li className="subitem">Slot Cost (vessel max)</li>
              <li className="subitem">Vessel Rate</li>
              <li className="subitem">Market Rate</li>
              <li className="subitem heading-name">TEU</li>
              <li className="subitem heading-name">Vessel Score</li>
            </ul>
          </li>
          <li className="list-main empty-list"></li>
          {compareData?.map((item: any, index: any) => (
            <li className={`list-main data-list ${item.isSeleceted && "selected"}`}>
              <ul className="subList">
                <li className="subitem header-colom heading-name">
                  <button data-testid='select' onClick={()=> selectToSend(item?.imo)} className={`button ${item.isSeleceted && "selectedbtn"}`}>
                    {item?.vesselName}
                  </button>
                  <span className="vessel-compare-icon" >{handleStatusIcon(item)}</span>
                </li>
                <li className="subitem heading-name"></li>
                <li className="subitem">{item?.design}</li>
                <li className="subitem">{item?.fuelClass}</li>
                <li className="subitem heading-name"></li>
                <li className="subitem">NA</li>
                <li className="subitem">NA</li>
                <li className="subitem"></li>
                <li className="subitem">{item?.marketRate}</li>
                <li className="subitem heading-name">{item?.teu}</li>
                <li className="subitem heading-name">{item?.vesselScore}</li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <McButton
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
          click={clickOnClose}
          role ='cancel-cp'
        >
          Cancel
        </McButton>
        <McButton
            slot="primaryAction"
            variant="primary"
            type="submit"
            disabled={isCmpBtn}
            data-testid="save-button"
            click={submitIndication}
          >
            Send Indication Request
          </McButton>
      </McModal>
      </>
  );
};
export default VesselCompare;
