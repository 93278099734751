import pdfIcon from "../../assets/pdf_icon.png";
import docIcon from "../../assets/doc_icon.png";
import docxIcon from "../../assets/docx_icon.png";
import xlsIcon from "../../assets/xls_icon.png";
import xlsxIcon from "../../assets/xlsx_icon.png";
interface IPROPS{
  nameOfDoc?: any;
}
export const IconPdfDL = (nameOfDoc: IPROPS) => {
    if(nameOfDoc?.nameOfDoc == 'pdf'){
      return ( <img src={pdfIcon}  height={"18px"}></img>
      );
    }
   else if(nameOfDoc?.nameOfDoc == 'doc'){
    return ( <img src={docIcon}  height={"18px"}></img>
    );
   }
   else if(nameOfDoc?.nameOfDoc == 'docx'){
    return ( <img src={docxIcon}  height={"18px"}></img>
    );
  }
  else if(nameOfDoc?.nameOfDoc == 'xls'){
    return ( <img src={xlsIcon} height={"18px"}></img>
    );
  }
    else if(nameOfDoc?.nameOfDoc == 'xlsx'){
      return ( <img src={xlsxIcon}  height={"18px"}></img>
      );
    }
    else{
      return ( <img src={""}  height={"18px"}></img>
      );
    }
  };
  