import React, { useEffect, useState } from "react";
import RequestDocumentCM from "../OfferNegotiationCM/RequestDocumentCM";
import "../AdditionalDocuments.scss";
import RequestDocumentVO from "../OfferNegotiationVO/RequestDocumentVO";
import UploadedDocumentSection from "./UploadedDocumentSection";
import { getAPI } from "../../../../services/CepApiServices";
import laderImage from "../../../../assets/loader-image.png";

interface IPROPS {
  userDetails?: any;
  indicationId?: any;
  accessType?: any;
}
const AdditionalDocumentModal = ({
  userDetails,
  indicationId,
  accessType,
}: IPROPS) => {
  const [indicationDocs, setIndicationDocs] = useState<any>([]);
  const [indicationDocsForVO, setIndicationDocsForVO] = useState<any>([]);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const getAllDocuments = async () => {
    setIsApiLoading(true);
    const documents = await getAPI(
      `IndicationRequest/GetIndicationDocuments?indicationId=${indicationId}`
    );
    if (documents) {
      setIndicationDocs(documents);
      const VOdoc = documents.filter(
        (e) => e.documentName !== "Additional Owner Document"
      );
      setIndicationDocsForVO(VOdoc);
    }
    setIsApiLoading(false);
  };
  useEffect(() => {
    getAllDocuments();
  }, []);
  return (
    <div className="add-document-modal">
      <div className="row document-modal-section">
        <div className="col-md-12">
          <div className="row add-document-list">
            {isApiLoading && (
              <div className="loader-div rider-clause">
                <div className="width-div">
                  <div className="image-div shipLoader" id="pot">
                    <img src={laderImage} alt="" />
                  </div>
                </div>
              </div>
            )}
            {userDetails === "CM" && (
              <div>
                <RequestDocumentCM
                  getAllDocumentFunc={getAllDocuments}
                  indicationDocs={indicationDocs}
                  indicationId={indicationId}
                  accessType={accessType}
                />
                <UploadedDocumentSection
                  getAllDocumentFunc={getAllDocuments}
                  indicationDocs={indicationDocs}
                  indicationId={indicationId}
                  accessStatus={accessType}
                />
              </div>
            )}
            {userDetails === "VO" && (
              <div>
                <RequestDocumentVO
                  requestedAdditionalDoc={indicationDocsForVO}
                ></RequestDocumentVO>
                <UploadedDocumentSection
                  getAllDocumentFunc={getAllDocuments}
                  indicationDocs={indicationDocs}
                  indicationId={indicationId}
                  accessStatus={accessType}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdditionalDocumentModal;
