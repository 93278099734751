import { SalesAndPurchaseModel } from "./model";
import { TableColumn } from "react-data-table-component";
import moment from "moment";

const columns: TableColumn<SalesAndPurchaseModel>[] = [
    {
      name: "Price Guidance",
      selector: (row) => row.priceGuidance !== null ? row.priceGuidance.toLowerCase() : "",
      cell: (row) => row.priceGuidance !== null ? row.priceGuidance : "",
      sortable: true,
      width:'11%'
    },
    {
      name: "Date of status",
      selector: (row) => (row.date !== null ? row.date : ""),
      sortable: true,
      width:'10%',
      format: (row) =>
        row.date !== null ? moment(row.date).format("DD-MMM-YYYY") : "",
    },
    {
      name: "Price",
      selector: (row) => (row.price !== null ? row.price : ""),
      sortable: true,
      width:'7%'
    },
    {
      name: "Currency",
      selector: (row) => row.currency !== null ? row.currency : "",
      sortable: true,
      width:'7%'
    },
    {
      name: "Charterer",
      selector: (row) => row.chartererName !== null ? row.chartererName : "",
      sortable: true,
      width:'8%'
    },
    {
      name: "Owner",
      selector: (row) => (row.owner !== null ? row.owner : ""),
      sortable: true,
      width:'9%'
    },
    {
      name: "Status",
      selector: (row) => (row.status !== null ? row.status : ""),
      sortable: true,
      width:'7%'
    },
    {
      name: "Design",
      selector: (row) => row.design !== null ? row.design.toLowerCase() : "",
      cell: (row) => row.design !== null ? row.design : "",
      sortable: true,
      width:'8%'
    },
    {
      name: "TEU",
      selector: (row) => (row.teu !== null ? row.teu : ""),
      sortable: true,
      width:'6%'
    },
    {
      name: "Build",
      selector: (row) => (row.build !== null ? row.build : ""),
      sortable: true,
      width:'6%'
    },
    {
      name: "IMO",
      selector: (row) => (row.imo !== null ? row.imo : ""),
      sortable: true,
      width:'6%'
    },
    {
      name: "Vessel Name",
      selector: (row) => (row.vesselName !== null ? row.vesselName.toLowerCase() : ""),
      cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
      sortable: true,
      width:'12%'
    },
  ];

export default columns;