import {
  McModal,
  McButton,
  McIcon,
  McInput,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import react, { useState } from "react";

import { toast } from "react-toastify";
import { postAPI } from "../../../../services/CepApiServices";
interface IPROPS {
  indicateRequestId?: any;
  isModalOpen?: boolean;
  onLoadData?: any;
  onCloseFunc?: any;
  submitData?: any;
  reloadApi?: any;
}
const EditNewRiderClause = ({
  indicateRequestId,
  isModalOpen,
  onLoadData,
  onCloseFunc,
  reloadApi,
}: IPROPS) => {
  const [riderClauseTitle, setRiderClauseTitle] = useState<string>(onLoadData?.title);
  const [riderClauseValue, setRiderClauseValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);

  const handleSubmit = async () => {
    if (riderClauseTitle.trim() === "") {
      setTitleError(true);
      return;
    }
    setIsSubmitting(true);
    const callSaveAPI = await postAPI(
      `Negotiation/EditNewriderclauseAsync?id=${onLoadData?.titleId}&indicationId=${indicateRequestId}&title=${riderClauseTitle}`,
    );
    if (callSaveAPI.StatusCode) {
      toast.error(`Failed Updating New Rider clause`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      onCloseFunc();
    } else {
      toast.success(`New Rider clause updated`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      onCloseFunc();
    }

    setIsSubmitting(false);
  };

  const onCancelData = () => {
    onCloseFunc('close');
  };
  return (
    <McModal
      height="300px"
      width="650px"
      // className="add-new-rider-clause"
      heading={"Update New Rider Clause"}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="header-modal">
        <McTextarea
          data-testid="rider-clause-title"
          label="Title"
          input={(e: any) => {
            setTitleError(false);
            setRiderClauseTitle(e.target.value);
          }}
          value={riderClauseTitle}
          rows={5}
          invalid={titleError}
          errormessage="Please enter title"
        ></McTextarea>
      </div>
      <McButton
        slot="primaryAction"
        variant="primary"
        fit="small"
        label={"Update"}
        onClick={handleSubmit}
        disabled={isSubmitting}
        data-testid="submit-button"
      ></McButton>
      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        onClick={onCancelData}
        role="cancel-button"
        fit="small"
      >
        Cancel
      </McButton>
    </McModal>
  );
};
export default EditNewRiderClause;
