import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  isModalOpen?: boolean;
  indicationId?: any;
  onCanceled?: any;
}

const AcceptClauseWarningModal = ({
  isModalOpen,
  indicationId,
  onCanceled,
}: IPROPS) => {
  const onOKClick = () => {
    onCanceled("OK", true);
  };

  const closeAcceptFunc = () => {
    onCanceled("cancel", false);
  };

  return (
    <div>
      <McModal
        width="620px"
        height="auto"
        open={isModalOpen}
        heading="Confirm Action"
        className="accept-clause-modal"
      >
        <p className="accept-alert">
          * You have pending terms to be accepted. You will lose Accept option
          once click on edit negotiation button.
        </p>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          click={closeAcceptFunc}
          dialogAction="cancel"
          data-testid='cancel'
        >
          Cancel
        </McButton>
        <McButton
          slot="primaryAction"
          variant="primary"
          click={onOKClick}
          dialogAction="ok"
        >
          OK
        </McButton>
      </McModal>
    </div>
  );
};

export default AcceptClauseWarningModal;
