import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import "./SendIndicationModal.scss";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import {
  McButton,
  McIcon,
  McModal,
  McInput,
  McRadio,
  McSelect,
  McLoadingIndicator,
} from "@maersk-global/mds-react-wrapper";
import {
  downloadReportDocuments,
  getAPI,
  postAPI,
} from "../../../services/CepApiServices";
import VesselInput from "./VesselInput";
interface IPROPS {
  isSIRModal?: any;
  onLoadData?: any;
  closeFunc?: any;
  charterId?: any;
  extendContract?: any;
}
const DirectIndication = ({
  isSIRModal,
  onLoadData,
  closeFunc,
  charterId,
  extendContract,
}: IPROPS) => {
  const history = useNavigate();
  const [documents, setDocuments] = useState<any>([]);
  const [isType, setIsType] = useState<any>(false);
  const [offerType, setOfferType] = useState<any>("New Fixture");
  const [ownersInput, setOwnersInput] = useState<any>("");
  const [ownerEmailError, setOwnerEmailError] = useState(false);
  const [representativeInput, setRepresentativeInput] = useState<any>("");
  const [representativeEmailError, setRepresentativeEmailError] =
    useState(false);
  const [hasAccess, setHasAccess] = useState<any>("owner");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDocSelected, setIsDocSelected] = useState<any>(false);
  const [docSelectionError, setDocSelectionError] = useState<any>("");
  const ownerEmailRef = useRef<any>(null);
  const representativeEmailRef = useRef<any>(null);
  const [isLoaderDiv, setIsLoaderDiv] = useState(false);
  // const [negotiationTypes, setNegotiationTypes] = useState([]);
  const [negotiationType, setNegotiationType] = useState<any>("New Fixture");
  const [searchVslNameText, setVslNameText] = useState<string>("");
  const [ownersEmailOnLoad,setOwnersEmailOnLoad]=useState<string>("");

  const negotiationTypes: any[] = [
    { value: 1, label: "New Fixture" },
    { value: 2, label: "Extension Fixture" },
  ];
  const emailRegex =
    /^(?!.*@(?:maersk\.com|MAERSK\.COM)$)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const fetchNewFixtureDoc = async () => {
    const response = await getAPI(`DocumentLibrary/GetAllDocuments`);
    if (response) {
      const filteredDocuments = response.filter(
        (document) => document.statusId === 62
      );
      setDocuments(filteredDocuments);
      setIsLoaderDiv(false);
    }
  };
  // const downloadDocument = async (fileName: any, categoryName: any) => {
  //   await downloadReportDocuments(
  //     `DocumentLibrary/DownlaodDocument/${fileName}/${categoryName}`
  //   )
  //     .then((data) => data!.blob())
  //     .then((b) => {
  //       const url = window.URL.createObjectURL(b);
  //       var a = document.createElement("a");
  //       var name = fileName;
  //       document.body.appendChild(a);
  //       a.href = url;
  //       a.download = `${name}`;
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch(() => {
  //       console.log("Could not download the file");
  //     });
  // };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if(!extendContract){
      if (hasAccess === "owner" && ownersInput === "") {
        setOwnerEmailError(true);
        setIsSubmitting(false);
        return;
      } else if (hasAccess === "representative" && representativeInput === "") {
        setRepresentativeEmailError(true);
        setIsSubmitting(false);
        return;
      }
      if (ownersInput && !emailRegex.test(ownersInput)) {
        setOwnerEmailError(true);
        setIsSubmitting(false);
        return;
      }
      if (representativeInput && !emailRegex.test(representativeInput)) {
        setRepresentativeEmailError(true);
        setIsSubmitting(false);
        return;
      }
    }

    const doc = documents?.filter((data: any) => data.checked === true);
    const dataVal = extendContract ? onLoadData : onLoadData[0];
    const payLoad = {
      imo: extendContract ? onLoadData.imo : dataVal?.imo,
      contractDocId: extendContract
        ? onLoadData.contractDocumentId
        : doc[0]?.documentId,
      charterRequestId: extendContract
        ? onLoadData.charterRequestId
        : charterId,
      offerType: extendContract
        ? "Extension"
        : offerType === "New Fixture"
        ? "New Negotiation"
        : "Extension",
      directNegotiationAssignmentDetails: [
        {
          vesselName: extendContract
            ? onLoadData.vesselName
            : dataVal?.vesselName,
          teu: extendContract ? 0 : dataVal?.teu,
          design: extendContract ? 0 : dataVal?.design,
          imo: extendContract ? 0 : dataVal?.imo,
          vesselRate: 0,
          assigneeTypeId: 53,
          assigneeTypeName: "Vessel Owner",
          emailAddress: extendContract? ownersEmailOnLoad : ownersInput,
          hasFullAccess: hasAccess === "owner" ? true : false,
        },
      ],
      extendedContractId: extendContract ? onLoadData.id : 0,
    };
    if (representativeInput !== "") {
      payLoad.directNegotiationAssignmentDetails.push({
        vesselName: extendContract
          ? onLoadData.vesselName
          : dataVal?.vesselName,
        teu: extendContract ? 0 : dataVal?.teu,
        design: extendContract ? 0 : dataVal?.design,
        imo: extendContract ? 0 : dataVal?.imo,
        vesselRate: 0,
        assigneeTypeId: 54,
        assigneeTypeName: "Representative",
        emailAddress: representativeInput,
        hasFullAccess: hasAccess === "representative" ? true : false,
      });
    }
    const resp = await postAPI(
      "IndicationRequest/InitiateDirectNegotiation",
      payLoad
    );
    if (resp.status) {
      setIsSubmitting(false);
    } else {
      closeFunc();
      history(`/InitiateOfferDetails?id=${resp}`, {
        state: {
          isExtended: extendContract,
          negotiationID: dataVal?.id,
          extendedContractId: dataVal?.extendedContractId,
          indicationStatus: dataVal?.indicationStatus,
        },
      });
      setIsSubmitting(false);
    }
  };
  const handleAccessCheck = (val: any) => {
    setHasAccess(val);
    setOwnerEmailError(false);
    setRepresentativeEmailError(false);
  };
  // const handleDocumentClick = (id: any) => {
  //   const newArray = documents?.map((element: any) => {
  //     if (element.documentId === id) {
  //       element.checked = true;
  //       setIsSubmitting(false);
  //     } else {
  //       element.checked = false;
  //     }
  //     return element;
  //   });
  //   setDocuments(newArray);
  //   const checkedDoc = newArray.filter((data: any) => data.checked === true);
  //   if (checkedDoc.length > 0) {
  //     setIsDocSelected(false);
  //   }
  // };
  // const handleTypeDropdown = () => {
  //   setIsType(!isType);
  // };
  // const slectFixture = (val: any) => {
  //   setOfferType(val);
  // };

  const negotiationTypeSelected = (e?: any) => {
    setNegotiationType(e?.detail?.selectedOptions[0]);
    setIsSubmitting(false);
  };
  useEffect(() => {
    setOwnersEmailOnLoad(onLoadData.responseReceivedFrom);
    setIsLoaderDiv(true);
    fetchNewFixtureDoc();
  }, []);
  const keyDown = (e: any) => {
      setVslNameText(e.target.value);
      setOwnersEmailOnLoad(e.target.value);
  };
  return (
    <>
      <form data-testid="form">
        <McModal
          width="35rem"
          height="25rem"
          open={isSIRModal}
          className="directIndicationModal"
          heading="Direct Negotiation"
          backdropcloseactiondisabled
        >
          <div className="directIndication">
            <div className="input-section">
              {extendContract && (<div className="input-div">
                <label>
                  Owners Email{" "}
                  <span className="radioCheck">
                    {" "}
                    <McRadio
                      name="access"
                      change={(val) => handleAccessCheck("owner")}
                      fit="small"
                      checked={hasAccess === "owner" ? true : false}
                      label="Full Access"
                      data-testid="owner-access"
                    ></McRadio>
                  </span>
                </label>{" "}
                <McInput
                  data-testid="owner-email"
                  ref={ownerEmailRef}
                  label=""
                  required={hasAccess === "owner" ? true : false}
                  // oninput={(val) => {
                  //   const input = (val.target as HTMLInputElement).value;
                  //   console.log("input is"+input);
                  //   setOwnerEmailError(false);
                  //   // setOwnersInput(input);
                  // }}
                  // onChange={(e: any) =>{
                  //   console.log("e"+e.target.value);
                  //   setOwnersInput(e.target.value);
                  // } 
                  // }
                  value={ownersEmailOnLoad}
                  onBlur={keyDown}
                  // disabled={true}
                  // invalid={ownerEmailError}
                  // errormessage={
                  //   hasAccess === "owner"
                  //     ? ownersInput === ""
                  //       ? "Email is required"
                  //       : ownersInput !== "" && !emailRegex.test(ownersInput)
                  //       ? "Enter a valid email"
                  //       : ""
                  //     : ownersInput !== "" && !emailRegex.test(ownersInput)
                  //     ? "Enter a valid email"
                  //     : ""
                  // }
                ></McInput>
              </div>)}

              {!extendContract && (<div className="input-div">
                <label>
                  Owners Email{" "}
                  <span className="radioCheck">
                    {" "}
                    <McRadio
                      name="access"
                      change={(val) => handleAccessCheck("owner")}
                      fit="small"
                      checked={hasAccess === "owner" ? true : false}
                      label="Full Access"
                      data-testid="owner-access"
                    ></McRadio>
                  </span>
                </label>{" "}
                <McInput
                  data-testid="owner-email"
                  ref={ownerEmailRef}
                  label=""
                  required={hasAccess === "owner" ? true : false}
                  oninput={(val) => {
                    const input = (val.target as HTMLInputElement).value;
                    setOwnerEmailError(false);
                    setOwnersInput(input);
                  }}
                  invalid={ownerEmailError}
                  errormessage={
                    hasAccess === "owner"
                      ? ownersInput === ""
                        ? "Email is required"
                        : ownersInput !== "" && !emailRegex.test(ownersInput)
                        ? "Enter a valid email"
                        : ""
                      : ownersInput !== "" && !emailRegex.test(ownersInput)
                      ? "Enter a valid email"
                      : ""
                  }
                ></McInput>
              </div>)}

              <div className="input-div">
                <label>
                  Representative Email{" "}
                  <span className="radioCheck">
                    {" "}
                    <McRadio
                      name="access"
                      change={(val) => handleAccessCheck("representative")}
                      fit="small"
                      label="Full Access"
                      data-testid="representative-access"
                    ></McRadio>
                  </span>
                </label>{" "}
                <McInput
                  data-testid="representative-email"
                  ref={representativeEmailRef}
                  required={hasAccess === "representative" ? true : false}
                  label=""
                  oninput={(val) => {
                    const input = (val.target as HTMLInputElement).value;
                    setRepresentativeEmailError(false);
                    setRepresentativeInput(input);
                  }}
                  invalid={representativeEmailError}
                  errormessage={
                    hasAccess === "representative"
                      ? representativeInput === ""
                        ? "Email is required"
                        : representativeInput !== "" &&
                          !emailRegex.test(representativeInput)
                        ? "Enter a valid email"
                        : ""
                      : representativeInput !== "" &&
                        !emailRegex.test(representativeInput)
                      ? "Enter a valid email"
                      : ""
                  }
                ></McInput>
              </div>

              <div className="input-div">
                <label>
                  Negotiation Type<span className="mandatory">*</span>
                </label>
                <McSelect
                  name="indicationId"
                  fit="medium"
                  label=""
                  options={negotiationTypes}
                  change={negotiationTypeSelected}
                  hiddenlabel={true}
                  ariaPlaceholder=""
                  value={negotiationType}
                  selectedindex={extendContract ? [1] : [0]}
                  disabled={extendContract ? true : false}
                  errormessage="Please select the document type"
                  required={true}
                  data-testid="source-select"
                ></McSelect>
              </div>
            </div>
            {/* <div className="document-section">
              <div className="select-documents">
                <div className="header-div">
                  <span className="icon">
                    <McIcon icon="file"></McIcon>
                  </span>
                  <span className="icon-text">
                    Select Contract Documents of{" "}
                    <span onClick={handleTypeDropdown}>
                      {offerType} <McIcon icon="chevron-down"></McIcon>
                      {isType && (
                        <div className="dropDown-text">
                          <ul>
                            <li onClick={() => slectFixture("New Fixture")}>
                              New Fixture
                            </li>
                            <li
                              onClick={() => slectFixture("Extension Fixture")}
                            >
                              Extension Fixture
                            </li>
                          </ul>
                        </div>
                      )}
                    </span>
                    <span className="mandatory">*</span>
                  </span>
                  {isDocSelected && (
                    <span className="doc-selection-error">
                      <McIcon icon="info-circle" color="red"></McIcon>
                      <span className="doc-error-msg">{docSelectionError}</span>
                    </span>
                  )}
                </div>
                <div className="body-div">
                  {isLoaderDiv && <McLoadingIndicator />}
                  <div className="row">
                    {documents?.map((data: any) => (
                      <div className="col-md-4">
                        <div
                          className={
                            data?.checked === true
                              ? "doc-select-div active-doc"
                              : "doc-select-div"
                          }
                          onClick={() => handleDocumentClick(data.documentId)}
                        >
                          <span
                            className="doc-name"
                            data-testid="handle-doc-select"
                          >
                            {data?.documentName}
                          </span>
                          <span
                            className="icon-div"
                            data-testid="doc-download"
                            onClick={() => {
                              downloadDocument(
                                data?.documentName,
                                data?.documentCategoryName
                              );
                            }}
                          >
                            <McIcon icon="tray-arrow-down"></McIcon>
                          </span>
                        </div>
                      </div>
                    ))}
                    {!isLoaderDiv && documents.length === 0 && (
                      <div className="col-md-12">
                        <div className="no-documents" data-testid="no-files">
                          <span>No Files Found</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <McButton
            data-testid="cancel"
            slot="secondaryAction"
            variant="secondary"
            dialogAction="cancel"
            role="cancel-cp"
            onClick={() => closeFunc()}
          >
            Cancel
          </McButton>
          <McButton
            slot="primaryAction"
            variant="primary"
            onClick={() => {
              // const checkedDoc = documents?.filter(
              //   (doc) => doc.checked === true
              // );
              // if (checkedDoc.length === 0) {
              //   setIsDocSelected(true);
              //   setDocSelectionError("Please select a document");
              // } else if (checkedDoc.length > 0) {
              //   setIsDocSelected(false);
              //   handleSubmit();
              // }
              handleSubmit();
            }}
            data-testid="save-button"
            disabled={isSubmitting}
          >
            Start Negotiation
          </McButton>
        </McModal>
      </form>
    </>
  );
};
export default DirectIndication;
