import "./AccessDenied.scss";
import { useNavigate } from "react-router-dom";
import AccessDenaied from "../../assets/AccessDenied.png";
import { McButton } from "@maersk-global/mds-react-wrapper";
const AccessDenied = () => {
  const history = useNavigate();
  const submitButton = () => {
    history(`/`);
  };
  return (
    <>
      <div className="AccessDeniedPage">
        <div className="heading-text">
          <h2>Access Denied</h2>
        </div>
        <div className="text">
          <p>You dont have permission to access this page</p>
        </div>
        <div className="image">
          <img src={AccessDenaied} alt="img" />
        </div>
        <div className="btn-div">
          <McButton
            fit="small"
            variant="filled"
            onClick={submitButton}
            label="Return to Main"
            data-testid="return-to-main"
          ></McButton>
        </div>
      </div>
    </>
  );
};
export default AccessDenied;
