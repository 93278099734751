import {
  McModal,
  McButton,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import { useForm } from "react-hook-form";
import "./IndicationInvite.scss";

interface IPROPS {
  onModalOpen?: boolean;
  onModalClose?: any;
}

const RejectModal = ({ onModalOpen, onModalClose }: IPROPS) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onCancel = () => {
    setValue("comments", "");
    onModalClose("cancel", null);
  };

  const onSubmit = (data: any) => {
    onModalClose("submit", data);
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  return (
    <div className="reject-section">
      <form
        data-testid="reject-form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <McModal
          width="auto"
          height="auto"
          open={onModalOpen}
          heading="Reject Indication"
        >
          <p>Are you sure you want to reject this indication?</p>

          <div className="col-md-4">
            <div className="input-reject-section">
              <McTextarea
                {...register("comments")}
                name="comments"
                label="Comments"
                ariaPlaceholder="Type"
                rows={3}
                fit="medium"
                data-testid="comments"
              ></McTextarea>
            </div>
          </div>
          <McButton
            data-testid="cancel"
            slot="secondaryAction"
            variant="secondary"
            onClick={() => onCancel()}
            dialogAction="cancel"
            role="cancel-reject-invite"
          >
            No
          </McButton>
          <McButton
            data-testid="reject-submit"
            slot="primaryAction"
            variant="primary"
            type="submit"
            role="reject-indication-invite"
            dialogAction="ok"
          >
            Yes
          </McButton>
        </McModal>
      </form>
    </div>
  );
};

export default RejectModal;
