// import React from "react";
import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  onDeleteOpen?: boolean;
  data?: any;
  setConfirmDelete?: () => void;
  onDeleteModalClose?: any;
  onSnPDeleteOpen?: boolean;
  onSnPDeleteModalClose?: any;
}

const DeleteModal = ({
  onDeleteOpen,
  setConfirmDelete,
  onDeleteModalClose,
  onSnPDeleteOpen,
  onSnPDeleteModalClose,
}: IPROPS) => {
  const onDeleteClick = () => {
    onDeleteModalClose("delete", true);
  };
  const onSnPDeleteClick = () => {
    onSnPDeleteModalClose("delete", true);
  };

  const onCancelActionClick = () => {
    onSnPDeleteModalClose("cancel", false);
  };
  const closeDeleteFunc = () => {
    onDeleteModalClose("cancel", false);
  };

  return (
    <div>
{/* CharterData */}
      <McModal
        width="680px"
        height="280px"
        open={onDeleteOpen}
        heading="Delete Vessel Detail Record"
      >
        <p>
          The data entered against a vessel and associated attachments enclosed
          will be lost permanently.
        </p>
        <p>Are you sure you want to delete this record?</p>
        <McButton
          // focusendanchor
          slot="secondaryAction"
          variant="secondary"
          // appearance="neutral"
          click={closeDeleteFunc}
          dialogAction="cancel"
          role="cancel-charter-data"
        >
          Cancel
        </McButton>
        <McButton
          // focusstartanchor
          slot="primaryAction"
          variant="primary"
          click={onDeleteClick}
          dialogAction="ok"
          role="delete-charter-data"
        >
          Delete record
        </McButton>
      </McModal>
{/* Sales and Purchase Data */}
      <McModal
        width="680px"
        height="280px"
        open={onSnPDeleteOpen}
        heading="Delete Sales And Purchases Detail Record"
      >
        <p>
          The data entered against a vessel and associated attachments enclosed
          will be lost permanently.
        </p>
        <p>Are you sure you want to delete this record?</p>
        <McButton
          // focusendanchor
          slot="secondaryAction"
          variant="secondary"
          // appearance="neutral"
          click={onCancelActionClick}
          dialogAction="cancel"
          role="cancel-salesNpurchase"
        >
          Cancel
        </McButton>
        <McButton
          // focusstartanchor
          slot="primaryAction"
          variant="primary"
          click={onSnPDeleteClick}
          dialogAction="ok"
          role="delete-salesNpurchase"
        >
          Delete record
        </McButton>
      </McModal>
    </div>
  );
};

export default DeleteModal;
