import React, { useState, useEffect } from "react";
import { McIcon, McInput } from "@maersk-global/mds-react-wrapper";
import './VesselInput.scss'
import { getUserMailID } from "../../../utils/token";
interface IPROPS {
  vesselData?: any;
  indexVal?: any;
  register?: any;
  arrayList?:any;
  ismodal?:any;
  setValue?:any;
  errors?:any;
}
const VesselInput = ({ vesselData, indexVal, register, arrayList, ismodal, setValue, errors }: IPROPS) => {
 
  const [representativeFields, setRepresentativeFields] = useState<any>([]);
  const [ownerField, setOwnerField] = useState<any>("");

  const isDuplicateEmail = (email, currentIndex) => {
    if (email === ownerField) {
      return true;
    }
    for (let i = 0; i < representativeFields.length; i++) {
      if (i !== currentIndex && representativeFields[i].email === email) {
        return true;
      }
    }
    return false;
  };
   

  const handleAddRepresentative = (email:any) => {
    setRepresentativeFields((prevFields) => [...prevFields, { email: email, id: '' }]);
  };

  const handleChangeEmail = (index, value) => {
    const updatedRepresentatives = [...representativeFields];
    updatedRepresentatives[index].email = value;
    setRepresentativeFields(updatedRepresentatives);
  };

  const handleDelteRepresntative = (index) => {
    const list = [...representativeFields];
    list.splice(index, 1);
    setRepresentativeFields(list);
  }
  useEffect(()=>{    
    vesselData?.assigneeTypeDetails?.forEach((element:any) => {
      if(element.assigneeTypeId === 53 ){
        if(element.emailAddress !== null && element.emailAddress !== undefined){
          setOwnerField(element.emailAddress);
          setValue(`ownerInput${indexVal}`,element.emailAddress)
        }else{
          setOwnerField('');
          setValue(`ownerInput${indexVal}`,'')
        }
      }
    });
    if(vesselData?.assigneeTypeDetails?.length) {
      let newIndex = 0;
      vesselData?.assigneeTypeDetails?.forEach((element:any) => {
        if(element.assigneeTypeId === 54){
          if(element.emailAddress !== null && element.emailAddress !== undefined){
            handleAddRepresentative(element.emailAddress)
            setValue(`representativeMail-${indexVal}${newIndex}`,element.emailAddress)
            newIndex++;
          }else{
            handleAddRepresentative('')
            setValue(`representativeMail-${indexVal}${newIndex}`,'')
            newIndex++;
          }
        }
      });
    }
  },[ismodal])
  useEffect(()=>{
    arrayList(representativeFields,indexVal)
  },[representativeFields])
  return (
    <div key={indexVal} className="vessel-div">
      <div className="vessel-heading">
        <span>{indexVal + 1}</span>
        <p>{vesselData?.vesselName}</p>
        <p className="addRep" onClick={(e)=>handleAddRepresentative('')}>
          {" "}
          + Add Representative
        </p>
      </div>
      <div className="vessel-fields">
        <p>Owner Details</p>
        <div className="row">
          <div className="col-md-12">
            <McInput
              placeholder="Email"
              role="owner-email-address"
              autocomplete={"nope"}
              fit="small"
              value={ownerField}
              input={(e:any) => setOwnerField(e.target.value)}
              {...register(`ownerInput${indexVal}`, {
                required: "Owner email is required",
                pattern: {
                  value: /^(?!.*@(?:maersk\.com|MAERSK\.COM)$)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Enter valid email address",
                },
                validate: (value) => {
                  const userEmailId = getUserMailID();
                  if(value === userEmailId){
                    return "Cannot enter the logged-in user's email";
                  }
                  return true;
                }
              })}
              label=""
            ></McInput>
              <p className="error-message" data-testid="owner-email-error">
                {errors?.[`ownerInput${indexVal}`] && errors?.[`ownerInput${indexVal}`]?.message}
              </p>
          </div>
        </div>
      </div>
      {representativeFields?.map((v, i) => (
        <div key={i} className="vessel-fields">
          <p>Representative Details</p>
          <div className="row">
            <div className="col-md-11">
              <McInput
                fit="small"
                className="rep-email"
                placeholder="Email"
                data-testid={`rep-email-address-${i}`}
                autocomplete={"nope"}
                value={v?.email}
                input={(e:any) => handleChangeEmail(i, e.target.value)}
                {...register(`representativeMail-${indexVal}${i}`, {
                  required: "Representative email is required",
                  pattern: {
                    value: /^(?!.*@(?:maersk\.com|MAERSK\.COM)$)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter valid email address",
                  },
                  validate: {
                    duplicateEmail: (value) =>
                      isDuplicateEmail(value, i) ? "Email is already in use": true,
                    cannotUseUserEmail: (value) => {
                      const userEmailId = getUserMailID();
                      if (value === userEmailId) {
                        return "Cannot enter the logged-in user's email";
                      }
                      return true;
                    },
                  },
                })}
                label=""
              ></McInput>
                <p className="error-message" style={{ color: '#FF0000', fontSize: '14px' }} data-testid={`rep-email-error-${i}`}>
                  {errors?.[`representativeMail-${indexVal}${i}`] && errors?.[`representativeMail-${indexVal}${i}`]?.message}
                </p>
            </div>
            <div className="col-md-1">
              <span className="delete-icon" data-testid={`delete-icon-${i}`} onClick={()=>handleDelteRepresntative(i)}>
              <McIcon icon="trash" color="#c00"></McIcon>
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default VesselInput;
