import React, { useState, useEffect } from "react";
import {
  McIcon,
  McCheckbox,
  McRadio,
  McButton,
} from "@maersk-global/mds-react-wrapper";
import StandardClauseHistory from "../standClauseHistrory";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { postAPI } from "../../../../services/CepApiServices";
import LumpsumModal from "./LumpsumModal";
import moment from "moment";
interface IPROPS {
  subTermData?: any;
  indicationId?: any;
  handleChangeFunc?: any;
  submitData?: any;
  renderFunc?: any;
  handleChangeRadio?: any;
  checkWriteAccess?: any;
  isEditing?: any;
  mainInfoData?: any;
  isPrefilledCondition?: any;
}
const InputSection = ({
  subTermData,
  indicationId,
  handleChangeFunc,
  submitData,
  handleChangeRadio,
  renderFunc,
  checkWriteAccess,
  isEditing,
  mainInfoData,
  isPrefilledCondition,
}: IPROPS) => {
  const [isedit, setIsEdit] = useState<any>(true);
  const [isLumpsum, setIsLumpsum] = useState<any>(false);
  const [mainData, setmainData] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minDate, setMinDate] = useState<any>();
  const [laycanToResetData, setLaycanToResetData] = useState<any>();
  const [AcceptBtn, setAcceptBtn] = useState(true);
  const [AcceptSubTermBtn, setAcceptSubTermBtn] = useState(true);
  const handleSelectOnchange = (value: any, itemValue: any) => {
    handleChangeFunc(value.label, itemValue);
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    const pastedText = clipboardData.getData("text");
    const sanitizedValue = pastedText.replace("-", "");
    document.execCommand("insertText", false, sanitizedValue);
  };
  // const getDefaultDropdownvalue = (options: any, val: any) => {
  //   const getObj = options?.filter((item: any) => item.label === val)[0];
  //   return getObj;
  // };

  useEffect(() => {
    if (mainData) {
      const updateData = mainData;
      setIsEdit(!isEditing);
      if (isEditing === true) {
        updateData.isDisabled = false;
      } else {
        updateData.isDisabled = true;
      }
      setmainData(updateData);
    }
  }, [isEditing]);

  // const onClickEdit = (type, subId) => {
  //   const updateData = mainData;
  //   if (type === "edit") {
  //     updateData.isDisabled = false;
  //     setIsEdit(false);
  //   } else {
  //     updateData.isDisabled = true;
  //     setIsEdit(true);
  //   }
  //   setmainData(updateData);
  // };
  // const handleSubmitSubtermData = async (data: any) => {
  //   setIsSubmitting(true);
  //   const payLoad = {
  //     standardTemplateMainTerms: [
  //       {
  //         termName: data.termName,
  //         termId: data.termId,
  //         subTerms: [data],
  //       },
  //     ],
  //   };
  //   const response = await postAPI(
  //     `InitialOffer/SaveStandardTermChanges?indicationId=${indicationId}`,
  //     payLoad
  //   );
  //   if (response.status) {
  //     console.log("error", response.status);
  //     toast.error(`Submission of Terms failed`, {
  //       theme: "colored",
  //       icon: false,
  //       autoClose: 3000,
  //     });
  //     setIsSubmitting(false);
  //   } else {
  //     toast.success(`Terms submitted successfully`, {
  //       theme: "colored",
  //       icon: false,
  //       autoClose: 3000,
  //     });
  //     setIsEdit(true);
  //     renderFunc();
  //     setIsSubmitting(false);
  //   }
  // };
  // added new api for accept changes
  const handleAcceptTerms = async (subtermData) => {
    setAcceptBtn(false);
    const response = await postAPI(
      `InitialOffer/StageStandardTermAcceptStatus?indicationId=${indicationId}&termId=${subtermData.subTermId}`
    );
    if (response.StatusCode) {
      console.log("error", response.StatusCod);
      toast.error(`Failed to accept the terms`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setAcceptBtn(true);
    } else {
      toast.success(`Terms Accepted successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsEdit(true);
      renderFunc();
      setAcceptBtn(true);
    }
  };
  // Individual Accept button
  const handleIndividualAcceptTerms = async (subtermData) => {
    // setAcceptSubTermBtn(false);
    const response = await postAPI(
      `InitialOffer/StageStandardTermFieldLevelAcceptStatus?indicationId=${indicationId}&termDetailId=${subtermData.standardTermDetailId}`
    );
    if (response === true) {
      toast.success(`Terms Accepted successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsEdit(true);
      renderFunc();
      setAcceptSubTermBtn(true);
    } else {
      toast.error(`Failed to accept the terms`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setAcceptSubTermBtn(true);
    }
  };
  const getActionBtn = (isStandardTermsSent: any, subtermData: any) => {
    if (isStandardTermsSent) {
      if (subtermData?.isNegotiable) {
        if (
          (subtermData?.workflowStatus === "New" ||
            subtermData?.workflowStatus === "Awaiting") &&
          subTermData?.modifiedUserType === "CharteringManager"
        ) {
          return (
            <>
              {/* {isedit && (
                <>
                  <button
                    className="btn btn-submit"
                    key={subTermData.subTermId}
                    onClick={() => {
                      onClickEdit("edit", subTermData.subTermId);
                    }}
                  >
                    Edit
                  </button>
                </>
              )} */}
              {!isedit && (
                <>
                  {/* <button
                    className="btn btn-submit"
                    onClick={() => handleSubmitSubtermData(subTermData)}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={() => {
                      onClickEdit("cancel", subTermData.subTermId);
                    }}
                  >
                    Cancel
                  </button> */}
                </>
              )}
            </>
          );
        } else if (
          subtermData?.workflowStatus === "Awaiting" &&
          subTermData?.modifiedUserType !== "CharteringManager" &&
          (subTermData?.sectionLevelAccept == true ||
            subTermData?.sectionLevelAccept == null)
        ) {
          return (
            <>
              {mainInfoData?.enableCheckout && !isEditing}
              {mainInfoData?.enableCheckout && !isEditing && AcceptBtn && (
                <>
                  <button
                    data-testid="button-accept"
                    className="btn btn-negotiate"
                    onClick={() => handleAcceptTerms(subTermData)}
                  >
                    Accept
                  </button>
                  {/* <button
                    className="btn btn-submit"
                    key={subTermData.subTermId}
                    onClick={() => {
                      onClickEdit("edit", subTermData.subTermId);
                    }}
                  >
                    Edit
                  </button> */}
                </>
              )}
              {/* {!isedit && (
                <> */}
              {/* <button
                    className="btn btn-submit"
                    onClick={() => handleSubmitSubtermData(subTermData)}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={() => {
                      onClickEdit("cancel", subTermData.subTermId);
                    }}
                  >
                    Cancel
                  </button> */}
              {/* </>
              )} */}
            </>
          );
        }
      }
    }
  };
  // Individual Accept button
  const getIndvslActionBtn = (detailsData) => {
    if (
      mainInfoData?.enableCheckout &&
      detailsData?.fieldWorkflowStatus === "Awaiting" &&
      detailsData?.fieldLevelAccept === true &&
      // detailsData?.label !== "Hire" &&
      !isEditing
    ) {
      return (
        AcceptSubTermBtn && (
          <>
            <button
              data-testid="button-accept"
              className="btn btn-single-accept"
              onClick={() => handleIndividualAcceptTerms(detailsData)}
            >
              Accept
            </button>
          </>
        )
      );
    }
  };

  const getHirePaymentActionBtn = (detailsData) => {
    if (
      mainInfoData?.enableCheckout &&
      detailsData?.fieldWorkflowStatus === "Awaiting" &&
      detailsData?.fieldLevelAccept === true &&
      !isEditing
    ) {
      return (
        AcceptSubTermBtn && (
          <>
            <button
              data-testid="button-accept"
              className="btn btn-single-accept"
              onClick={() => handleIndividualAcceptTerms(detailsData)}
            >
              Accept
            </button>
          </>
        )
      );
    }
  };

  const handleAddLumpsums = () => {
    setIsLumpsum(true);
  };
  const differData = (
    itemData: any,
    isNegotiable: any,
    isPrefilledCondition: any
  ) => {
    if (
      itemData.dataType === "Dropdown" ||
      itemData.dataType === "Dropdown&Input"
    ) {
      return (
        <span className="differData">
          {itemData.value === "" ? "" : itemData.value === "Yes" ? "Yes" : "No"}
        </span>
      );
    }
    const joineddata: any = [];
    if (itemData?.diffs?.length > 0) {
      const newData: any = [];
      const oldData: any = [];
      itemData?.diffs?.forEach((element) => {
        if (element.operation === 2) {
          if (itemData.dataType === "Datetime") {
            const newText = element.text.replace(/T/g, " ");
            newData.push(newText);
            oldData.push(newText);
          } else if (itemData.dataType === "Date") {
            newData.push(element.text);
            oldData.push(element.text);
          } else {
            joineddata.push(element.text);
          }
        } else if (element.operation === 1) {
          if (itemData.dataType === "Datetime") {
            const newText = <b>{element?.text.replace(/T/g, " ")}</b>;
            newData.push(newText);
          } else if (itemData.dataType === "Date") {
            newData.push(<b>{element?.text}</b>);
          } else {
            joineddata.push(<b>{element?.text}</b>);
          }
        } else if (element.operation === 0) {
          if (itemData.dataType === "Datetime") {
            const newText = <s>{element?.text.replace(/T/g, " ")}</s>;
            oldData.push(newText);
          } else if (itemData.dataType === "Date") {
            oldData.push(<s>{element?.text}</s>);
          } else {
            joineddata.push(<s>{element?.text}</s>);
          }
        }
      });
      if (itemData.dataType === "Datetime" || itemData.dataType === "Date") {
        if (itemData?.diffs?.length === 1) {
          return (
            <span
              className={`${
                itemData?.disableField && isNegotiable && "accpeted"
              } differData`}
            >
              {newData}
            </span>
          );
        }
        return (
          <span
            className={`${
              itemData?.disableField && isNegotiable && "accpeted"
            } differData`}
          >
            {newData} <s>{oldData}</s>
          </span>
        );
      }
      return (
        <span
          className={`${
            itemData?.disableField && isNegotiable && "accpeted"
          } differData`}
        >
          {joineddata}
        </span>
      );
    } else {
      if (isPrefilledCondition) {
        return (
          <span
            className={`${
              itemData?.disableField && isNegotiable && "accpeted"
            } differData`}
          >
            {itemData?.value}
          </span>
        );
      } else {
        return (
          <span
            className={`${
              itemData?.disableField && isNegotiable && "accpeted"
            } differData`}
          ></span>
        );
      }
    }
  };

  const differDataNewTerm = (  itemData: any,
    isNegotiable: any,
    isPrefilledCondition: any) => {
      if (
        itemData.dataType === "Dropdown" ||
        itemData.dataType === "Dropdown&Input"
      ) {
        return (
          <span className="differData">
            {itemData.value === "" ? "" : itemData.value === "Yes" ? "Yes" : "No"}
          </span>
        );
      }
      const joineddata: any = [];
      if (itemData?.changeList?.length > 0) {
        const newData: any = [];
        const oldData: any = [];
        itemData?.changeList?.forEach((element) => {
          if (element?.displayFormat === 1) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
            } else {
              joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
            }
          }
          if (element?.displayFormat === 2) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(newArr);
            } else {
              joineddata.push(element?.text);
            }
          }
          if (element?.displayFormat === 0) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(<b className={`${element?.highlight == 1 ? 'heighlightText': ''}`}>{newArr}</b>);
            } else {
              joineddata.push(<b className={`${element?.highlight == 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
            }
          }
          if (element?.displayFormat === 3) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
            } else {
              joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
            }
          }
          // if (element.operation === 2) {
          //   if (itemData.dataType === "Datetime") {
          //     const newText = element.text.replace(/T/g, " ");
          //     newData.push(newText);
          //     oldData.push(newText);
          //   } else if (itemData.dataType === "Date") {
          //     newData.push(element.text);
          //     oldData.push(element.text);
          //   } else {
          //     joineddata.push(element.text);
          //   }
          // } else if (element.operation === 1) {
          //   if (itemData.dataType === "Datetime") {
          //     const newText = <b>{element?.text.replace(/T/g, " ")}</b>;
          //     newData.push(newText);
          //   } else if (itemData.dataType === "Date") {
          //     newData.push(<b>{element?.text}</b>);
          //   } else {
          //     joineddata.push(<b>{element?.text}</b>);
          //   }
          // } else if (element.operation === 0) {
          //   if (itemData.dataType === "Datetime") {
          //     const newText = <s>{element?.text.replace(/T/g, " ")}</s>;
          //     oldData.push(newText);
          //   } else if (itemData.dataType === "Date") {
          //     oldData.push(<s>{element?.text}</s>);
          //   } else {
          //     joineddata.push(<s>{element?.text}</s>);
          //   }
          // }
        });
        if (itemData.dataType === "Datetime" || itemData.dataType === "Date") {
          if (itemData?.changeList?.length === 1) {
            return (
              <span
                className={`${
                  itemData?.disableField && isNegotiable && "accpeted"
                } differData`}
              >
                {newData}
              </span>
            );
          }
          return (
            <span
              className={`${
                itemData?.disableField && isNegotiable && "accpeted"
              } differData`}
            >
              {newData} <s>{oldData}</s>
            </span>
          );
        }
        return (
          <span
            className={`${
              itemData?.disableField && isNegotiable && "accpeted"
            } differDataNewTerm`}
          >
            {joineddata}
          </span>
        );
      } else {
        if (isPrefilledCondition) {
          return (
            <span
              className={`${
                itemData?.disableField && isNegotiable && "accpeted"
              } differData`}
            >
              {itemData?.value}
            </span>
          );
        } else {
          return (
            <span
              className={`${
                itemData?.disableField && isNegotiable && "accpeted"
              } differDataNewTerm`}
            ></span>
          );
        }
      }
  }

  const handleClickOutside = async (
    event: any,
    itemData: any,
    revertLaterDisable: any,
    subTermData:any
  ) => {
    itemData.stagingId = subTermData.stagingId;
    if (event?.type === "blur") {
      itemData.revertLater = revertLaterDisable;
      await postAPI(
        `InitialOffer/StageStandardTermInputChanges?indicationId=${indicationId}`,
        itemData
      );
    }
  };
  const handlingStageChanges = async (itemData: any, revertLaterDisable) => {
    itemData.revertLater = revertLaterDisable;
    // if condition is fix to stop calling stage APi after extension for laycan To as this field is hidden
    if(itemData.standardTermDetailId == 57 && isPrefilledCondition){
      console.log("no stage API called");
    } else {
      await postAPI(
        `InitialOffer/StageStandardTermInputChanges?indicationId=${indicationId}`,
        itemData
      );
    }
  };
  const handleDisableFunc = (
    isStandardTermsSent: boolean,
    isNegotiable,
    subter,
    cmDisabled,
    revertLaterDisable,
    itemData
  ) => {
    if (!isNegotiable) {
      return checkWriteAccess && cmDisabled
        ? true
        : !isNegotiable
        ? true
        : subter.mainTermAccepted
        ? true
        : subTermData?.workflowStatus === "Accept"
        ? true
        : subter.isDisabled
        ? true
        : revertLaterDisable
        ? true
        : !isNegotiable;
    } else {
      return checkWriteAccess && cmDisabled
        ? true
        : !isNegotiable
        ? true
        : subter.mainTermAccepted
        ? true
        : subTermData?.workflowStatus === "Accept"
        ? true
        : subter.isDisabled
        ? true
        : revertLaterDisable
        ? true
        : itemData?.disableField
        ? true
        : !isNegotiable;
    }
  };
  function convertDateFormat(inputDate) {
    if (inputDate && inputDate !== "") {
      const parsedDate = moment(inputDate, "DD/MM/yyyyTHH:mm");
      if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM-DDTHH:mm");
      } else {
        console.error("Invalid date:", inputDate);
        return null;
      }
    } else {
      return null;
    }
  }
  const getInputType = (
    itemData: any,
    subTermData: any,
    detailsData: any,
    inputInd,
    isNegotiable,
    revertLaterDisable
  ) => {
    switch (itemData.dataType) {
      case "Text":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <input
                data-testid="input-text"
                key={itemData.standardTermDetailId}
                type="text"
                maxLength={200}
                value={itemData.value}
                placeholder={itemData?.placeholderText}
                onBlur={(e) =>
                  handleClickOutside(e, itemData, revertLaterDisable,subTermData)
                }
                disabled={handleDisableFunc(
                  submitData.isStandardTermsSent,
                  isNegotiable,
                  subTermData,
                  itemData.cmDisabled,
                  revertLaterDisable,
                  itemData
                )}
                className="inputText"
                onChange={(e) => handleChangeFunc(e.target.value, itemData)}
              />
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              subTermData.newTerm? differDataNewTerm(itemData, isNegotiable, isPrefilledCondition) :differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <textarea
                data-testid="input-textarea"
                maxLength={4000}
                key={itemData.standardTermDetailId}
                value={itemData.value}
                onBlur={(e) =>
                  handleClickOutside(e, itemData, revertLaterDisable,subTermData)
                }
                disabled={isPrefilledCondition && detailsData.label == 'BUNKERS FOR LONGER PERIOD' && 
                  detailsData.inputs.length == 2? true: handleDisableFunc(
                  submitData.isStandardTermsSent,
                  isNegotiable,
                  subTermData,
                  itemData.cmDisabled,
                  revertLaterDisable,
                  itemData
                )}
                className="inputTextArea"
                onChange={(e) => handleChangeFunc(e.target.value, itemData)}
              />
            )}
          </>
        );
      case "SelectedCheckbox":
        return (
          <>
            {/* {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled
            ) ? (
              differData(itemData, isNegotiable)
            ) : ( */}
            <McRadio
              data-testid="input-selectedcheckbox"
              label={itemData.suffix}
              checked={itemData.value === "true" ? true : false}
              change={(val) => {
                handleChangeRadio(val, itemData);
                handlingStageChanges(itemData, revertLaterDisable);
              }}
              name="suffix"
              disabled={handleDisableFunc(
                submitData.isStandardTermsSent,
                isNegotiable,
                subTermData,
                itemData.cmDisabled,
                revertLaterDisable,
                itemData
              )}
            ></McRadio>
            {/* )} */}
          </>
        );
      case "Number":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <input
                data-testid="input-number"
                key={itemData.standardTermDetailId}
                type="number"
                min={0}
                value={itemData.value}
                onBlur={(e) =>
                  handleClickOutside(e, itemData, revertLaterDisable,subTermData)
                }
                disabled={handleDisableFunc(
                  submitData.isStandardTermsSent,
                  isNegotiable,
                  subTermData,
                  itemData.cmDisabled,
                  revertLaterDisable,
                  itemData
                )}
                className="inputText"
                onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                onKeyPress={(e) => e.key === "-" && e.preventDefault()}
                onPaste={(e) => handlePaste(e)}
              />
            )}
          </>
        );
      case "Checkbox":
        return (
          <>
            {
              !submitData.isStandardTermsSent && (
                // handleDisableFunc(
                //   submitData.isStandardTermsSent,
                //   isNegotiable,
                //   subTermData,
                //   itemData.cmDisabled
                // ) ? (
                //   differData(itemData, isNegotiable)
                // ) : (
                <McCheckbox
                  data-testid="input-checkbox"
                  name="disclaimer"
                  checked={itemData.value === "true" ? true : false}
                  // onBlur={(e)=> handleClickOutside(e, itemData)}
                  change={(val) => {
                    handleChangeFunc(
                      String((val.target as HTMLInputElement).checked),
                      itemData
                    );
                    handlingStageChanges(itemData, revertLaterDisable);
                  }}
                  disabled={handleDisableFunc(
                    submitData.isStandardTermsSent,
                    isNegotiable,
                    subTermData,
                    itemData.cmDisabled,
                    revertLaterDisable,
                    itemData
                  )}
                  label="Please select the Checkbox if the below field is required"
                ></McCheckbox>
              )
              // )
            }
          </>
        );
      case "Date":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <DatePicker
                className="inputText"
                dateFormat="dd/MM/yyyy"
                value={itemData.value}
                onChange={(date: any) => {
                  handleChangeFunc(
                    date ? moment(date).format("DD/MM/YYYY") : "",
                    itemData
                  );
                  handlingStageChanges(itemData, revertLaterDisable);
                }}
                disabled={handleDisableFunc(
                  submitData.isStandardTermsSent,
                  isNegotiable,
                  subTermData,
                  itemData.cmDisabled,
                  revertLaterDisable,
                  itemData
                )}
                key={itemData.standardTermDetailId}
                dateFormatCalendar="MMMM"
                showYearDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
              ></DatePicker>
            )}
          </>
        );

      case "Datetime":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <div className="DateTimeChanges">
                <DateTimePicker
                  format="dd/MM/yyyy HH:mm"
                  locale="en-US"
                  // onBlur={(e)=> handleClickOutside(e, itemData)}
                  className={"dateTimeClass"}
                  disabled={handleDisableFunc(
                    submitData.isStandardTermsSent,
                    isNegotiable,
                    subTermData,
                    itemData.cmDisabled,
                    revertLaterDisable,
                    itemData
                  )}
                  onChange={(e: any) => {
                    let formattedDateTime = "";
                    if (e) {
                      const momentDate = moment(e, "DD/MM/YYYYTHH:mm");
                      if (momentDate.isValid()) {
                        const timeComponent = momentDate.format("HH:mm");
                        if (timeComponent === "00:00") {
                          if (detailsData.label === "LayCan From") {
                            formattedDateTime = momentDate
                              .set({ hour: 0, minute: 1 })
                              .format("DD/MM/YYYYTHH:mm");
                          } else if (detailsData.label === "LayCan To") {
                            formattedDateTime = momentDate
                              .set({ hour: 23, minute: 59 })
                              .format("DD/MM/YYYYTHH:mm");
                          }
                        } else {
                          formattedDateTime =
                            momentDate.format("DD/MM/YYYYTHH:mm");
                        }
                      }
                    }
                    handleChangeFunc(formattedDateTime, itemData);
                     handlingStageChanges(itemData, revertLaterDisable);
                    if (itemData.standardTermDetailId === 56) {
                      handlingStageChanges(
                        laycanToResetData,
                        revertLaterDisable
                      );
                      setMinDate(
                        moment(itemData.value, "DD/MM/YYYYTHH:mm").toDate()
                      );
                    }
                  }}
                  minDate={
                    itemData.standardTermDetailId === 57 ? minDate : new Date()
                  }
                  // value={moment(itemData.value, "DD/MM/YYYYTHH:mm").toDate()}
                  value={convertDateFormat(itemData.value)}
                />
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <Select
                defaultValue={
                  itemData.value === ""
                    ? { value: "", label: "" }
                    : itemData.value === "Yes"
                    ? { value: "Yes", label: "Yes" }
                    : { value: "No", label: "No" }
                }
                placeholder={itemData?.placeholderText}
                options={[
                  { value: "", label: "" },
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ]}
                key={itemData.id}
                // onBlur={(e)=> handleClickOutside(e, itemData)}
                isDisabled={handleDisableFunc(
                  submitData.isStandardTermsSent,
                  isNegotiable,
                  subTermData,
                  itemData.cmDisabled,
                  revertLaterDisable,
                  itemData
                )}
                onChange={(value) => {
                  handleSelectOnchange(value, itemData);
                  handlingStageChanges(itemData, revertLaterDisable);
                }}
              />
            )}
          </>
        );
      case "PrefixDropdown":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              <div className="prefixText prefix-differData">
                <span className="preText">{itemData.prefix}</span>
                {differData(itemData, isNegotiable, isPrefilledCondition)}
                <span className="sufText">{itemData.suffix}</span>
              </div>
            ) : (
              <div className="prefixText">
                <span className="preText">{itemData.prefix}</span>
                <input
                  data-testid="input-prefixdropdown"
                  key={itemData.standardTermDetailId}
                  type="text"
                  maxLength={200}
                  value={itemData.value}
                  onBlur={(e) =>
                    handleClickOutside(e, itemData, revertLaterDisable,subTermData)
                  }
                  disabled={isPrefilledCondition && detailsData.label == 'BUNKERS FOR LONGER PERIOD'  && 
                    detailsData.inputs.length == 2? true:handleDisableFunc(
                    submitData.isStandardTermsSent,
                    isNegotiable,
                    subTermData,
                    itemData.cmDisabled,
                    revertLaterDisable,
                    itemData
                  )}
                  className="inputText"
                  onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                />
                <div className="tooltip-section">
                  <McIcon icon="info-circle"></McIcon>
                  <span className="tooltiptext">
                    {itemData?.options?.map((item: any) => (
                      <div>
                        <span className="tooltip-label">{item.label}</span>
                      </div>
                    ))}
                  </span>
                </div>
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "PrefixText":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              <div className="prefixText prefix-differData">
                <span className="preText">{itemData.prefix}</span>
                {differData(itemData, isNegotiable, isPrefilledCondition)}
                <span className="sufText">{itemData.suffix}</span>
              </div>
            ) : (
              <div className="prefixText">
                <span className="preText">{itemData.prefix}</span>
                <input
                  data-testid="input-prefixtext"
                  key={itemData.standardTermDetailId}
                  type="text"
                  value={itemData.value}
                  onBlur={(e) =>
                    handleClickOutside(e, itemData, revertLaterDisable,subTermData)
                  }
                  disabled={handleDisableFunc(
                    submitData.isStandardTermsSent,
                    isNegotiable,
                    subTermData,
                    itemData.cmDisabled,
                    revertLaterDisable,
                    itemData
                  )}
                  className="inputText"
                  onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                />
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "PrefixNumber":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              <div className="prefixText prefix-differData">
                <span className="preText">{itemData.prefix}</span>
                {differData(itemData, isNegotiable, isPrefilledCondition)}
                <span className="sufText">{itemData.suffix}</span>
                {!itemData.disableField && (
                  <span
                    className="accept-btn-hire-section"
                    key={detailsData?.detailId}
                  >
                    {checkWriteAccess && getHirePaymentActionBtn(itemData)}
                  </span>
                )}
                {(itemData?.fieldWorkflowStatus === "Accept" ||
                  itemData?.fieldWorkflowStatus ===
                    "NegotiatedAndAccepted") && (
                  <span
                    className="accept-btn-hire-section"
                    key={detailsData?.detailId}
                  >
                    <span className="agreedSatus">
                      <McIcon
                        icon="check-circle"
                        size="24"
                        color="#33a059"
                      ></McIcon>{" "}
                    </span>
                  </span>
                )}
              </div>
            ) : (
              <div className="prefixText">
                <span className="preText">{itemData.prefix}</span>
                <input
                  data-testid="input-prefixnumber"
                  key={itemData.standardTermDetailId}
                  type="number"
                  min={0}
                  disabled={handleDisableFunc(
                    submitData.isStandardTermsSent,
                    isNegotiable,
                    subTermData,
                    itemData.cmDisabled,
                    revertLaterDisable,
                    itemData
                  )}
                  value={itemData.value}
                  onBlur={(e) =>
                    handleClickOutside(e, itemData, revertLaterDisable,subTermData)
                  }
                  className="inputText"
                  onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                  onKeyPress={(e) => e.key === "-" && e.preventDefault()}
                  onPaste={(e) => handlePaste(e)}
                />
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "Dropdown&Input":
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <>
                <Select
                  defaultValue={
                    itemData.value !== "No"
                      ? { value: "Yes", label: "Yes" }
                      : { value: "No", label: "No" }
                  }
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  isDisabled={handleDisableFunc(
                    submitData.isStandardTermsSent,
                    isNegotiable,
                    subTermData,
                    itemData.cmDisabled,
                    revertLaterDisable,
                    itemData
                  )}
                  key={itemData.standardTermDetailId}
                  // onBlur={(e)=> handleClickOutside(e, itemData)}
                  onChange={(value) => {
                    handleSelectOnchange(value, itemData);
                    handlingStageChanges(itemData, revertLaterDisable);
                  }}
                />
                <div className="input-dropdown">
                  <label> Comment</label>
                  <input
                    key={itemData.standardTermDetailId}
                    type="text"
                    onBlur={(e) =>
                      handleClickOutside(e, itemData, revertLaterDisable,subTermData)
                    }
                    disabled={handleDisableFunc(
                      submitData.isStandardTermsSent,
                      isNegotiable,
                      subTermData,
                      itemData.cmDisabled,
                      revertLaterDisable,
                      itemData
                    )}
                    value={itemData.value2}
                    className="inputText"
                  />
                </div>
              </>
            )}
          </>
        );
      default:
        return (
          <>
            {handleDisableFunc(
              submitData.isStandardTermsSent,
              isNegotiable,
              subTermData,
              itemData.cmDisabled,
              revertLaterDisable,
              itemData
            ) ? (
              differData(itemData, isNegotiable, isPrefilledCondition)
            ) : (
              <input
                data-testid="default"
                key={itemData.standardTermDetailId}
                type="text"
                disabled={handleDisableFunc(
                  submitData.isStandardTermsSent,
                  isNegotiable,
                  subTermData,
                  itemData.cmDisabled,
                  revertLaterDisable,
                  itemData
                )}
                value={itemData.value}
                className="inputText"
                onChange={(e) => handleChangeFunc(e.target.value, itemData)}
              />
            )}
          </>
        );
    }
  };
  useEffect(() => {
    subTermData.isDisabled = !isEditing;
    setmainData(subTermData);
    setIsEdit(!isEditing);
    subTermData?.details?.map((detailsData: any) => {
      return detailsData?.inputs?.map((inputData: any) => {
        if (inputData?.standardTermDetailId === 57) {
          return setLaycanToResetData(inputData);
        }
      });
    });
  }, [subTermData]);
  return (
    <div className="sub-data" key={mainData?.subTermId}>
      {isPrefilledCondition && mainData?.subTermName == "Delivery notices" ? (
        ""
      ) : (
        <div className="sub-header">
          <span className="icon">
            <McIcon icon="vessel-front" />
          </span>
          <span className="action-btn">
            {(subTermData?.workflowStatus === "Accept" ||
              subTermData?.workflowStatus === "NegotiatedAndAccepted") && (
              <span className="agreedSatus">
                <McIcon icon="check-circle" size="24" color="#33a059"></McIcon>{" "}
              </span>
            )}
            {mainData?.newLinesRequired && !submitData.isStandardTermsSent && (
              <span className="agreedSatus">
                {checkWriteAccess && (
                  <McButton
                    data-testid="button-addlumpsum"
                    fit="small"
                    onclick={handleAddLumpsums}
                    label="Add Lumpsums"
                  ></McButton>
                )}

                {isLumpsum && (
                  <LumpsumModal
                    indicationId={indicationId}
                    isModalOpen={isLumpsum}
                    reRender={() => renderFunc()}
                    onAddModalClose={() => setIsLumpsum(false)}
                  />
                )}
              </span>
            )}
            <span className="agree-checkbox" key={mainData?.subTermId}>
              {checkWriteAccess &&
                getActionBtn(submitData?.isStandardTermsSent, mainData)}
            </span>
          </span>
          <span className="text">
            {isPrefilledCondition && mainData?.subTermName == "Laycan"
              ? "New Time and Period"
              : mainData?.subTermName}
            {mainData?.sectionLevelDisplayMandatory && (
              <span className="mandatory">*</span>
            )}
          </span>
        </div>
      )}

      {isPrefilledCondition && mainData?.subTermName == "Delivery notices"
        ? ""
        : mainData?.details.map((detailsData: any, ind: any) => (
            <div
              className={`${
                mainData?.details.length === 1 && "input1"
              } input-each-data`}
            >
              {isPrefilledCondition && detailsData?.label == "LayCan To" ? (
                ""
              ) : (
                <div
                  className="each-data"
                  key={detailsData.standardTermDetailId}
                >
                  <span className="label-div">
                    {mainData?.subTermName !== detailsData.label
                      ? isPrefilledCondition &&
                        detailsData.label == "LayCan From"
                        ? "From"
                        : mainData?.newTerm ? "" : detailsData.label
                      : ""}
                    {detailsData.lineType !== "Description" &&
                      detailsData?.displayMandatory &&
                      mainData?.subTermName !== detailsData.label && (
                        <span className="mandatory">*</span>
                      )}
                    {detailsData.lineType !== "Description" &&
                      detailsData.revertLater && (
                        <span className="checkBox isDisbaled">
                          <McCheckbox
                            fit="small"
                            label="Revert Next Counter"
                            value={detailsData.revertLater}
                            checked={detailsData.revertLater}
                            key={detailsData.standardTermDetailId}
                          ></McCheckbox>
                        </span>
                      )}

                    <span className="action-btn">
                      {(detailsData?.fieldWorkflowStatus === "Accept" ||
                        detailsData?.fieldWorkflowStatus ===
                          "NegotiatedAndAccepted") && (
                        <span className="agreedSatus">
                          <McIcon
                            icon="check-circle"
                            size="24"
                            color="#33a059"
                          ></McIcon>{" "}
                        </span>
                      )}

                      <span
                        className="accept-btn-section"
                        key={detailsData?.detailId}
                      >
                        {checkWriteAccess && getIndvslActionBtn(detailsData)}
                      </span>
                    </span>
                  </span>

                  {detailsData.inputs.map((inputData: any, inputInd: any) => (
                    <>
                      {" "}
                      <span className="content-div">
                        {detailsData.labelDescription}
                      </span>
                      <div
                        className="input-section"
                        data-testid="input-type"
                        key={inputData.standardTermDetailId}
                      >
                        {inputData.lineType !== "Description" &&
                          getInputType(
                            inputData,
                            mainData,
                            detailsData,
                            inputInd,
                            mainData.isNegotiable,
                            detailsData.revertLater
                          )}
                        {inputData.lineType !== "Description" &&
                          inputData.dataType !== "Checkbox" && (
                            <StandardClauseHistory
                              itemdata={inputData}
                              indicationId={indicationId}
                            />
                          )}
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}
      {/* ))} */}
    </div>
  );
};
export default InputSection;