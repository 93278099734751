import React, { useState } from "react";
import ReactSlider from "react-slider";
import "./rangeSlider.scss";
interface IPROPS {
  name?: any;
  min?: any;
  max?: any;
  intialMin?: any;
  intialMax?: any;
  stepName?:any;
  onRRChange?: any;
}
const RangeSilder = ({ min, max, onRRChange,intialMax,intialMin,stepName }: IPROPS) => {
  const [value, setValue] = useState({});
  const [minDate, setMinDate] = useState(min);
  const [maxDate, setMaxDate] = useState(max);

  return (
    <div>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={[min, max]}
        max={intialMax}
        min={intialMin}
        step={stepName == 'decimal' ? 0.1 : 1}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        onChange={(value, index) => {
          setValue(value);
          onRRChange(value);
          return value;
        }}
      />
    </div>
  );
};

export default RangeSilder;
