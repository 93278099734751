import { McButton, McModal } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
    isModalOpen?: boolean;
    onCloseFunc?: any;
}
const MandatoryNotifyModal = ({isModalOpen, onCloseFunc} : IPROPS) => {
    const handleClose = () => {
        onCloseFunc();
    }
    return (
        <McModal
            width="auto"
            height="auto"
            className="mandatory-notify-modal"
            data-testid="mandatory-notify-modal"
            heading="Please fill all Main Terms"
            open={isModalOpen}
            backdropcloseactiondisabled
        >
            <p className="mandatory-notify-message" data-testid="mandatory-text">
            <span className="mandatory">*</span>Its mandatory to fill all the fields in <span className="message-alert">Main Terms</span>. Please fill to Submit Counter.
            </p>
            <McButton
                slot="secondaryAction"
                variant="secondary"
                fit="small"
                label={"Cancel"}
                data-testid="cancel-button"
                onClick={handleClose}
            ></McButton>
        </McModal>
    )
}

export default MandatoryNotifyModal;