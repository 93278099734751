import React, { useEffect } from "react";
import { useState } from "react";
import {
  McModal,
  McButton,
  McSelect,
  McIcon,
  McLoadingIndicator,
} from "@maersk-global/mds-react-wrapper";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FileUploader } from "react-drag-drop-files";
import {
  getAPI,
  postAPI,
  fileAPI,
  fileApiAxios,
} from "../../services/ApiServices";
import "./UploadModal.scss";

interface IPROPS {
  onOpen?: boolean;
  closeUpload?: any;
}

const UploadModal = ({ onOpen, closeUpload }: IPROPS) => {
  const fileTypes = ["xls", "xlsx"];
  const [loaderDiv, setLoaderDiv] = useState(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [modalHieght, setModalHieght] = useState("54vh");
  const [progressStatus, setProgressStatus] = useState("info");
  const [fileValue, setFile] = useState<any>();
  const [source, setSource] = useState<any>();
  const [isUploadSection, setIsUploadSection] = useState(true);
  const [progress, setProgress] = useState(0);
  const sourceType: any[] = [
    {
      value: 9,
      label: "Broker Availability List",
    },
    {
      value: 10,
      label: "Alphaliner",
    },
    {
      value: 11,
      label: "Maersk Broker",
    },
  ];
  function formatBytes(bytes:any) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;
  
    while (bytes >= 1024 && i < units.length - 1) {
      bytes /= 1024;
      i++;
    }
  
    return bytes.toFixed(2) + units[i];
  }  
  const handleChange = (file: any) => {
    setFile(file);
    setIsUploadSection(false);
    setModalHieght("34vh");
  };
  useEffect(() => {
    handleSubmit();
  }, [fileValue]);
  const handleSubmit = async () => {
    let apiLink = "";
    if (source) {
      switch (source?.value) {
        case 9:
          apiLink = `Charter/ImportAvailabilityList`;
          break;
        case 10:
          apiLink = `Charter/ImportAlphaLinerExcel`;
          break;
        case 11:
          apiLink = `Charter/ImportBrokerFixture`;
          break;
      }
      console.log(fileValue);
      const formData = new FormData();
      formData.append("formFile", fileValue);
      const resp = await fileApiAxios(apiLink, formData, setProgress);
      if (resp.status == "400") {
        setProgressStatus("danger");
      } else if (resp.status == "200") {
        setProgressStatus("success");
        setTimeout(function () {
            onCancel({type:"submit",source: source?.label}, resp.data);
        }, 1000);
      }
    }
  };
  const onCancel = (act: any, data: any) => {
    closeUpload(act, data);
    setLoaderDiv(false)
    setProgressStatus("info");
    setIsUploadSection(true)
    setProgress(0)
    setModalHieght("54vh");
  };

  const sourceSelected = (e:any) => {
    if(e.detail?.selectedOptions[0]){
      setIsUploadDisabled(false);
    }else{
      setIsUploadDisabled(true);
    }
    setSource(e.detail?.selectedOptions[0]);
  };
  const onReload = () => {
    setIsUploadSection(true);
    setProgressStatus("info");
    setModalHieght("54vh");
    setProgress(0);
  };
  return (
    <McModal
      open={onOpen}
      heading="Upload Documents"
      width="580px"
      backdropcloseactiondisabled
      height={modalHieght}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="modal-section">
            <div className="subColoum">
              <div className="header-section">
                <div
                  className="closeIcon"
                  onClick={() => onCancel({type:"close"}, null)}
                  data-testid="close-icon-al"
                >
                  <McIcon icon="times"></McIcon>
                </div>
                <div className="row">
                  <div className="col-md-12 row-divider">
                    <div className="input-section">
                      <label>Source</label>
                      <McSelect
                        name="chartererId"
                        // selectedindex={[0]}
                        fit="medium"
                        label=""
                        options={sourceType}
                        change={sourceSelected}
                        data-testid="source-select"
                      ></McSelect>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {isUploadSection && (
                      <div className="uploader-div" data-testid="file-uploader">
                        <FileUploader
                          handleChange={handleChange}
                          name="File"
                          disabled={isUploadDisabled}
                          types={fileTypes}
                        >
                          <div className="dropZone" data-testid="file-input">
                            <div className="drop-text">
                              <p>
                                <McIcon icon="folder-arrow-up"></McIcon>
                                <span>Select file </span> or Drag here
                              </p>
                            </div>
                          </div>
                        </FileUploader>
                        <p className="file-size">
                          Supported formats: Xls, Xml (Maximum file size: 5mb)
                        </p>
                      </div>
                    )}
                    {!isUploadSection && (
                      <div className="attach-div">
                        <p className="file-name" data-testid="file-name">{fileValue?.name}</p>
                        <p className="file-size" data-testid="file-size">
                          File size : {fileValue ? formatBytes(fileValue.size) : "N/A"}{" "}
                        </p>
                        <div className="progress-section">
                          {progressStatus == "info" && (
                            <span className="status info" data-testid="progress-status-info">
                             {progress < 100  && progress} {progress < 100 ? ' % remaining': <span className="loading-text">Record Is being validated</span>}
                            </span>
                          )}
                          {progressStatus == "success" && (
                            <span className="status success" data-testid="progress-status-sucess">
                              <span className="icon">
                                <McIcon icon="check-circle"></McIcon>
                              </span>{" "}
                              Upload success
                            </span>
                          )}
                          {progressStatus == "danger" && (
                            <span className="status danger" data-testid="progress-status-danger">
                              <span className="icon">
                                {" "}
                                <McIcon icon="exclamation-circle"></McIcon>
                              </span>{" "}
                              Upload failed{" - "} Invalid File
                              <span className="reload" data-testid="reload-button" onClick={onReload}>
                                <McIcon icon="arrow-clockwise"></McIcon>
                              </span>
                            </span>
                          )}
                          <ProgressBar
                            variant={progressStatus}
                            now={progress}
                          />
                        </div>
                      </div>
                    )}
                    {/* {loaderDiv && (
                      <div className="loader">
                        <span>
                          <McLoadingIndicator
                            label="Record Is being validated..."
                            fit="large"
                          ></McLoadingIndicator>
                        </span>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </McModal>
  );
};

export default UploadModal;
