import  { TableColumn } from "react-data-table-component";
import { McIcon } from "@maersk-global/mds-react-wrapper/components-core";
import moment from "moment";

type DataRow = {
  isLocked: boolean;
  min: string;
  max: string;
  delivery: string;
  redeliveryPortName: string;
  redeliveryRangeName: string;
  estRedelivery: string;
  isEstRedlUpdatedByNextFixture: string;
  rate: number;
  chartererName: string;
  teu: string;
  imo: string;
  vesselName: string;
  status: string;
  title: string;
  director: string;
  year: string;
};

const columns: TableColumn<DataRow>[] = [
  {
    name: "",
    selector: (row) => row.isLocked,
    cell: (row) => row.isLocked && <McIcon icon="lock"></McIcon>,
    sortable: true,
    width: "2%",
  },
  {
    name: "Max",
    selector: (row) => (row.max !== null ? row.max : ""),
    sortable: true,
    format: (row) =>
      row.max !== null ? moment(row.max).format("DD-MMM-YYYY") : "",
    width: "8%",
  },
  {
    name: "Min",
    selector: (row) => (row.min !== null ? row.min : ""),
    sortable: true,
    format: (row) =>
      row.min !== null ? moment(row.min).format("DD-MMM-YYYY") : "",
    width: "8%",
  },
  {
    name: "Delivery",
    selector: (row) => (row.delivery !== null ? row.delivery : ""),
    sortable: true,
    format: (row) =>
      row.delivery !== null ? moment(row.delivery).format("DD-MMM-YYYY") : "",
    width: "8%",
  },
  {
    name: "Rdl.Port",
    selector: (row) => row.redeliveryPortName !== null ? row.redeliveryPortName : "",
    sortable: true,
    width: "8%",
  },
  {
    name: "Rdl.Range",
    selector: (row) => row.redeliveryRangeName !== null ? row.redeliveryRangeName : "",
    sortable: true,
    width: "8%",
  },

  {
    name: "Est.Redelivery",
    selector: (row) => row.estRedelivery !== null ? row.estRedelivery : "",
    sortable: true,
    width: "10%",
    format: (row) =>
      row.estRedelivery !== null
        ? moment(row.estRedelivery).format("DD-MMM-YYYY")
        : "",
    cell: (row) =>
      row.estRedelivery !== null ? (
        row.isEstRedlUpdatedByNextFixture ? (
          <span style={{ color: "#2c75ff" }}>
            {moment(row.estRedelivery).format("DD-MMM-YYYY")}
          </span>
        ) : (
          <span>{moment(row.estRedelivery).format("DD-MMM-YYYY")}</span>
        )
      ) : (
        <span>{""}</span>
      ),
  },
  {
    name: "Rate",
    selector: (row) => (row.rate !== null ? row.rate : ""),
    sortable: true,
    width: "6%",
  },
  {
    name: "Charterer",
    selector: (row) => (row.chartererName !== null ? row.chartererName.toLowerCase() : ""),
    cell: (row) => (row.chartererName !== null ? row.chartererName : ""),
    sortable: true,
    width: "8%",
  },
  {
    name: "Status",
    selector: (row) => (row.status !== null ? row.status : ""),
    sortable: true,
    width: "7%",
  },
  {
    name: "TEU",
    selector: (row) => (row.teu !== null ? row.teu : ""),
    sortable: true,
    width: "5%",
  },
  {
    name: "IMO",
    selector: (row) => (row.imo !== null ? row.imo : ""),
    sortable: true,
    width: "7%",
  },
  {
    name: "Vessel Name",
    selector: (row) => (row.vesselName !== null ? row.vesselName.toLowerCase() : ""),
    cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
    sortable: true,
    width: "12%",
  },
];

export default columns;