import { useState, useEffect } from "react";
import "./InitialOfferDetails.scss";
import moment from "moment";
import {
  McButton,
  McTabBar,
  McTab,
  McCheckbox,
  McIcon,
  McLoadingIndicator,
} from "@maersk-global/mds-react-wrapper";
import { getAPI, postAPI } from "../../../services/CepApiServices";
import SubmitNegotiationModal from "../StandardOffer/SubmitNegotiationModal";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import StandardOfferCM from "../StandardOffer/OfferNegotiationCM/StandardOfferCM";
import IndexHomePage from "../BaseCharterParty/IndexHomePage";
import IssueRecapHomePage from "../../IssueRecap/IssueRecapHomePage";
import ChangeOfferTimeModal from "./ChangeOfferTimeModal";
import { toast } from "react-toastify";
import messageSendIcon from "../../../assets/paper-plane.png";
import closeIcon from "../../../assets/close.png";
import ExtensionModal from "../StandardOffer/OfferNegotiationVO/ExtensionModal";
import StepIndicatorFlow from "../../../components/StepIndicationFlow/StepIndicatorFlow";
import ConfirmationModal from "../../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import { AppString } from "../../../utils/AppString";
import laderImage from "../../../assets/loader-image.png";
import { useNavigate } from "react-router-dom";
import ChangeDocBCPModal from "../OfferDetails/ChangeDocBCPModal";
import AcceptClauseWarningModal from "../OfferDetails/AcceptClauseWarningModal";
import MandatoryNotifyModal from "./MandatoryNotifyModal";
import {
  getAllTermsFilledCM,
  getBunkerCheckBoxStatus,
  getBunkerCheckBoxStatusForExtension,
  getInitialNegotiationTermsFilledCM,
} from "../../../components/NegotiationFunctions/NegotiationFunc";
import { getExpiryTime } from "../../../utils/token";
import TokenExpiryPop from "../../../components/TokenExpiryPopup/TokenExpiryPopup";
import FeedBackPrompt from "../../../components/FeedBackPrompt/FeedBackPrompt";
import {
  LAST_FEEDBACK_TIMESTAMP_KEY,
  MdsConstants,
} from "../../../utils/Constants";
interface IPROPS {
  indicateId: any;
  isContractExtended: any;
  oldNegotiationId: any;
  extendedContractId: any;
  indicationStatus: any;
}
const InitiateOfferDetailsCM = ({
  indicateId,
  isContractExtended,
  oldNegotiationId,
  extendedContractId,
  indicationStatus,
}: IPROPS) => {
  const history = useNavigate();
  const [isConfrmModal, setIsConfrmModal] = useState(false);
  const [mianInfoData, setMainInfoData] = useState<any>();
  const [timerSecond, setTimerSecond] = useState<any>(0);
  const [isSubmitNegotiation, setSubmitNegotiation] = useState<any>(false);
  const [offerdata, setOfferData] = useState<any>([]);
  const [isExtensionNotify, setIsExtensionNotify] = useState(false);
  const [submitData, setSubmitData] = useState<any>([]);
  const [BCPData, setBCPData] = useState<any>([]);
  const [runningRecapData, setRunningRecapData] = useState<any>([]);
  const [runningRecapStatus, setRunningRecapStatus] = useState<any>([]);
  const [isInitialRunningRecap, setIsInitialRunningRecap] = useState(false);
  const [timerInitial, setTimerInitial] = useState(0);
  const [remainingTime, setRemainingTime] = useState<any>(0);
  const [tab, setTab] = useState(0);
  const [prevTab, setPrevTab] = useState<any>(null);
  const [acceptTimeSO, setAcceptTimeSO] = useState<any>(null);
  const [acceptTimeRR, setAcceptTimeRR] = useState<any>(null);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const indicationRequestId = params.get("id");
  const [extensionModalOpen, setIsExtensionModalOpen] = useState(false);
  const [extensiontimeLineSO, setExtensionTimeLineSO] = useState<any>();
  const [extensiontimeLineBC, setExtensionTimeLineBC] = useState<any>();
  const [negotiationTypeValue, setNegotiationTypeValue] = useState<any>();
  const [isComment, setIsComment] = useState(false);
  const [enableNegotiation, setEnableNegotiation] = useState(false);
  const [enableAddTerms, setEnableAddTerms] = useState(false);
  const [canAttachBCP, setCanAttachBCP] = useState(false);
  const [isCommentText, setIsCommentText] = useState("");
  const [isExtensionNotifyBaseCharty, setIsExtensionNotifyBaseCharty] =
    useState(false);
  const [tabTermsAcceptTimeline, setTabTermsAcceptTimeline] = useState<any>();
  const [isExtensionModal, setIsExtensionModal] = useState(false);
  const [negotiationType, setNegotiationType] = useState<any>(null);
  const [accessType, setAccessType] = useState<any>();
  const [stageType, setStageType] = useState<any>("");
  const [isMainLoader, setIsMainLoader] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isChangeDocModal, setIsChangeDocModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [commentData, setCommentData] = useState<any>([]);
  const [commentMainData, setCommentMainData] = useState<any>([]);
  const [isAttachBCP, setIsAttachBCP] = useState(false);
  const [isStageTerminate, stageTerminate] = useState(false);
  const [standardOfferFilledData, setStandardOfferFilledData] = useState<any>(
    []
  );
  const [displayAcceptRejectChngeMsg, setDisplayAcceptRejectChngeMsg] =
    useState(false);
  const [acceptClauseWrngModal, setAcceptClauseWrngModal] = useState(false);
  const [timerExpiry, setTimerExpiry] = useState(false);
  const [isMandatoryNotifyModal, setIsMandatoryNotifyModal] = useState(false);
  const [isCharterPartyTab, setIsCharterPartyTab] = useState(false);
  const [isDocSelected, setIsDocSelected] = useState(false);
  const [isChatSendDisabled, setIsChatSendDisabled] = useState(false);
  const [disableEditBtn, setDisableEditBtn] = useState(false);
  const [cmLiftSubTimer, setCmLiftSubTimer] = useState<any>();
  const [cmLiftSubRemainingTime, setCmLiftSubRemainingTime] = useState<any>();
  const [voLiftSubExtensionTime, setVoLiftSubExtensionTime] = useState<any>();
  const [willTokenExpire, setWillTokenExpire] = useState(false);
  const [tokenExpiryMessage, setTokenExpiryMessage] = useState("");
  const [voTimeForExtension, setVoTimeForExtension] = useState<any>({
    userType: "CM",
    timeValue: "",
  });
  const [standardOfferApiLoad, setStandardOfferApiLoad] = useState(false);
  const [showFeedbackPrompt, setShowFeedbackPrompt] = useState(false);
  const [isExtended, setIsExtended] = useState(false);
  const [isPrefilledCondition, setIsPrefilledCondition] = useState(false);
  const [isPrefilledBCPCondition, setIsPrefilledBCPCondition] = useState(false);
  const [extendedContractIDBCP, setExtendedContractIDBCP] = useState<any>();
  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);

    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    let hDisplay = h > 0 ? h + " hr, " : "";
    let mDisplay = m > 0 ? m + " m" : "";
    return dDisplay + hDisplay + mDisplay;
  };

  const secondsToDhm = (seconds) => {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    // let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    let hDisplay = h > 0 ? h + " hr, " : "";
    let mDisplay = m > 0 ? m + " m" : "";
    // let sDisplay = s > 0 ? s + (s == 1 ? " S" : " S") : "";
    return dDisplay + hDisplay + mDisplay;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">...</div>;
    }

    return (
      <div className="timer">
        <div className="text"></div>
        <div className="value">{secondsToDhms(remainingTime)}</div>
      </div>
    );
  };
  const timerProps = {
    isPlaying: true,
    size: 110,
    strokeWidth: 6,
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      calculateRemainingTime(cmLiftSubTimer);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [cmLiftSubTimer]);

  const calculateRemainingTime = (targetTime) => {
    const currentTime = moment()
      .subtract(0, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
    const duration = moment.duration(moment(targetTime).diff(currentTime));
    const seconds = duration.asSeconds();
    if (seconds < 59 && cmLiftSubTimer) {
      setCmLiftSubRemainingTime("Time Expired");
    } else {
      setCmLiftSubRemainingTime(secondsToDhm(seconds));
    }
  };
  const submitDataFun = async (
    responseDate: any,
    comment: any,
    submitCheck
  ) => {
    if (submitCheck == "yes") {
      if (negotiationTypeValue == "Lift Subs") {
        const payLoad = {
          indicationId: Number(indicateId),
          timeLine: responseDate,
          status: "accept",
          comment: comment,
        };
        const response = await postAPI(
          `Negotiation/LiftSubTimelineExtenstionResponse`,
          payLoad
        );
        if (response.StatusCode) {
          toast.error(`Extension of timeline failed`, {
            theme: "colored",
            icon: false,
            autoClose: 3000,
          });
        } else {
          toast.success(`New Extension Time has been changed`, {
            theme: "colored",
            icon: false,
            autoClose: 3000,
          });
          reRenderFuncRR();
        }
      } else {
        const payLoad = {
          indicationId: indicateId,
          timeLine: responseDate,
          negotiationType: negotiationTypeValue,
          comment: comment,
          status: "accept",
        };
        const response = await postAPI(
          `InitialOffer/ProcessTimelineExtensionResponse`,
          payLoad
        );
        if (response.StatusCode) {
          toast.error(`Extension of timeline failed`, {
            theme: "colored",
            icon: false,
            autoClose: 3000,
          });
        } else {
          toast.success(`New Extension Time has been changed`, {
            theme: "colored",
            icon: false,
            autoClose: 3000,
          });
          reRenderFunc();
          reRenderFuncBCP();
        }
      }
    }
    setDisplayAcceptRejectChngeMsg(false);
    getMainSectionData("no");
  };
  const terminateIndicationRequest = async (response: string) => {
    if (response === "Yes") {
      let res = await getAPI(
        `IndicationRequest/UpdateIndicationRequestStatus/` +
          `?indicationId=${indicateId}` +
          `&action=Terminate`
      );
      if (res === true) {
        notify("Indication Request Terminated Successfully", true);
        history("/Negotiation");
      } else {
        notify("Indication Request Termination Failed", false);
      }
      setIsConfrmModal(false);
    } else {
      setIsConfrmModal(false);
    }
  };
  const handleTerminate = async () => {
    setIsConfrmModal(true);
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const handleTimer = (time: any) => {
    let currentdateTime = moment()
      .subtract(0, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
    let seconds = moment(time).diff(currentdateTime, "seconds");
    setTimerInitial(seconds);
    setRemainingTime(seconds);
  };

  const getStandardTermData = async () => {
    setIsMainLoader(true);
    let response: any;
    response = await getAPI(
      `InitialOffer/GetStandardOfferTermsTransactionDetail/${indicateId}`
    );
    if (response) {
      setSubmitData(response);
      setOfferData(response?.standardTemplateMainTerms);
      setStandardOfferFilledData(response?.standardTemplateMainTerms);
      const isExtension = response?.negotiationTimelineDetail
        ?.extensionRequestTimeline
        ? true
        : false;
      setExtensionTimeLineSO(
        response?.negotiationTimelineDetail?.extensionRequestTimeline
      );
      setIsExtensionNotify(isExtension);
      setIsMainLoader(false);
      setStandardOfferApiLoad(true);
    }
  };

  // call only on first time when extended before submission of counter
  const getPrefilledData = async (extendedContractId: number) => {
    setIsMainLoader(true);
    let response: any;
    response = await getAPI(
      `Negotiation/GetNegotiationPrefilledData/${extendedContractId}`
    );
    if (response) {
      setSubmitData(response);
      setOfferData(response?.standardTemplateMainTerms);
      setStandardOfferFilledData(response?.standardTemplateMainTerms);
      const isExtension = response?.negotiationTimelineDetail
        ?.extensionRequestTimeline
        ? true
        : false;
      setExtensionTimeLineSO(
        response?.negotiationTimelineDetail?.extensionRequestTimeline
      );
      setIsExtensionNotify(isExtension);
      setIsMainLoader(false);
      setStandardOfferApiLoad(true);
    }
  };

  const getNegotiationStatusFlow = (currentStage: any) => {
    if (
      currentStage === "Clean Recap - CM" ||
      currentStage === "Terminate" ||
      currentStage === "Request Completed" ||
      currentStage === "Clean Recap - VO"
    ) {
      setStageType(true);
      if (currentStage === "Request Completed") {
        stageTerminate(true);
      } else {
        stageTerminate(false);
      }
    } else {
      setStageType(false);
    }
  };

  const BCPNewAPIResponse = async () => {
    setIsMainLoader(true);
    let response:any;
    if(isPrefilledCondition) {
      response = await getAPI(
        `Negotiation/GetBaseCharterPartyMainClauseContent/` + extendedContractIDBCP + `/1`+`/`+ false+`/`+false+`/`+true+`/`+false
      );
    } else {
      response = await getAPI(
        `Negotiation/GetBaseCharterPartyMainClauseContent/` + indicateId + `/1`+`/`+ true+`/`+false+`/`+false+`/`+false
      );
    }

    if (response) {
      setBCPData(response);
      const isExtension = response?.negotiationTimelineDto
        ?.extensionRequestTimeline
        ? true
        : false;
      setIsExtensionNotifyBaseCharty(isExtension);
      setExtensionTimeLineBC(
        response?.negotiationTimelineDto?.extensionRequestTimeline
      );
      setIsMainLoader(false);
    }
  }

  useEffect(() => {
    if (submitData.length !== 0 && BCPData.length !== 0) {
      setIsInitialLoad(false);
    }
  }, [submitData, BCPData]);

  const getRecapStatus = async (eventType?: any) => {
    setIsMainLoader(true);
    const response = await getAPI(
      `Negotiation/GetRecapStatusAsync/${indicationRequestId}`
    );

    if (response) {
      setRunningRecapStatus(response);
      setAcceptTimeRR(response?.liftSubTimeline);
      // handleTimer(response?.liftSubTimeline);
      const isExtension = response?.liftSubExtensionTimeline ? true : false;
      if (isInitialLoad) {
        setIsMainLoader(false);
      } else {
        if (response?.isCleanRecapCompleted) {
          if (eventType !== "tabChange") {
            getStandardTermData();
          } else {
            setIsMainLoader(false);
          }
        } else {
          setIsMainLoader(false);
        }
      }
    }
  };

  const extentionAccept = (type: any, negotiationType: any) => async () => {
    const timeLineValue =
      negotiationType == "Standard Offer Document"
        ? extensiontimeLineSO
        : extensiontimeLineBC;
    const termsTimeValue = acceptTimeSO;
    const payLoad = {
      indicationId: indicateId,
      timeLine: type === "accept" ? timeLineValue : termsTimeValue,
      status: type,
      negotiationType: negotiationType,
    };

    const response = await postAPI(
      `InitialOffer/ProcessTimelineExtensionResponse`,
      payLoad
    );
    if (response.StatusCode) {
      toast.error(`Extending TimeLine failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      toast.success(`New Extension Time has been ${type}ed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      if (negotiationType == "Standard Offer Document") {
        reRenderFunc();
      } else if (negotiationType == "Contract Document") {
        reRenderFuncBCP();
      } else {
        reRenderFuncRR();
      }
      negotiationType === "Standard Offer Document"
        ? setIsExtensionNotify(false)
        : setIsExtensionNotifyBaseCharty(false);
    }
    setDisplayAcceptRejectChngeMsg(false);
    getMainSectionData("no");
  };
  const liftSubExtentionAccept = (type: any) => async () => {
    const payLoad = {
      indicationId: Number(indicateId),
      timeLine:
        type === "accept"
          ? voLiftSubExtensionTime
          : mianInfoData?.voLiftSubRequestDate,
      status: type,
      comment: "",
    };
    const response = await postAPI(
      `Negotiation/LiftSubTimelineExtenstionResponse`,
      payLoad
    );
    if (response.status) {
      toast.error(`Extension of Timeline failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      toast.success(`New Extension Time For Lift Subs has ${type}ed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      getMainSectionData("no");
    }
  };
  const openOfferExtensionModal = (val: any) => {
    setVoTimeForExtension({
      userType: "CM",
      timeValue: mianInfoData?.cmLiftSubRequestDate,
    });
    setNegotiationTypeValue(val);
    setIsExtensionModalOpen(true);
  };
  const onClosePopUp = () => {
    setIsExtensionModalOpen(false);
  };
  const reRenderFunc = () => {
    getStandardTermData();
  };
  const reRenderNegotiationDetail = () => {
    getMainSectionData("no");
  };
  const reRenderFuncBCP = () => {
    BCPNewAPIResponse();
  };
  const handleRunningTimeOnEdit = (time) => {
    const dateA = new Date(time);
    dateA.setHours(dateA.getHours() + 5);
    dateA.setMinutes(dateA.getMinutes() + 30);
    let timeA = moment(dateA);
    let timeB = moment();
    let timeC = timeB.diff(timeA, "seconds");
    const initialTime =
      timeC > 0 ? moment.utc(timeC * 1000).format("HH:mm:ss") : "00:00:00";
    setTimerSecond(initialTime);

    if (timeC >= 3600) {
      setTimerExpiry(true);
    } else {
      setTimerExpiry(false);
    }
    const intervalId = setInterval(() => {
      setTimerSecond((prevTime) => {
        const nextTime = moment
          .utc(moment.duration(prevTime).as("milliseconds") + 1000)
          .format("HH:mm:ss");
        if (moment.duration(nextTime).as("seconds") >= 3600) {
          setTimerExpiry(true);
        }
        return nextTime;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  };
  const getMainSectionData = async (type: any) => {
    const response = await getAPI(
      `Negotiation/GetNegotiationTimelineDetail/${indicateId}`
    );
    handleTimer(response?.termAcceptTimeline);
    setAcceptTimeSO(response?.termAcceptTimeline);
    setMainInfoData(response);
    setCanAttachBCP(response?.attachedBcp);
    setEnableNegotiation(response?.bcpEdit);
    setIsExtended(response?.isExtended);
    setExtendedContractIDBCP(response?.extendedContractId);
    // To check if contract extended, call prefill API only till counter is submitted for the 1st time
    if (
      response?.initiatedNegotiation == false &&
      response?.counterNumber == 0 &&
      response?.isExtended == true
    ) {
      setIsPrefilledBCPCondition(true);
      getPrefilledData(response?.extendedContractId);
    } else {
      setIsPrefilledBCPCondition(false);
      getStandardTermData();
    }
    setIsPrefilledCondition(response?.isExtended);

    // const dateA = new Date(response?.cmLiftSubRequestDate);
    // dateA.setHours(dateA.getHours() + 5);
    // dateA.setMinutes(dateA.getMinutes() + 30);
    setCmLiftSubTimer(response?.cmLiftSubRequestDate);
    setVoLiftSubExtensionTime(response?.voLiftSubExtensionRequestTimeline);
    if (response?.contractDocId !== null && response?.contractDocId !== 0) {
      setIsCharterPartyTab(true);
      setIsDocSelected(true);
    }
    if (response?.accessType === "Write") {
      getComments();
    }
    setDisplayAcceptRejectChngeMsg(response?.showExtensionNotification);
    if (response?.enableCheckout === false) {
      setIsEditing(false);
    }
    if (
      response?.retailEditControl === true &&
      (response?.checkoutTime === null || response?.checkoutTime === "")
    ) {
      setIsEditing(false);
    } else if (type === "yes" || response?.checkoutTime !== null) {
      handleRunningTimeOnEdit(response?.checkoutTime);
      if (response?.accessType === "Write" && response?.enableCheckout) {
        setIsEditing(true);
        // getComments();
      } else {
        setIsEditing(false);
      }
    } else {
      setTimerSecond(0);
      setTimerExpiry(false);
    }
    setIsLocked(response?.islocked);
    setAccessType(response?.accessType);
    setIsAttachBCP(response?.attachedBcp);
  };

  const trackExpiryTime = async () => {
    const expiryTime = await getExpiryTime();
    if (expiryTime) {
      const currentTime = new Date();
      const expiryDate = new Date(expiryTime);
      const timeDifference = expiryDate.getTime() - currentTime.getTime();
      const settedTime1 = 60 * 60 * 1000; // 1 hour before expiry
      const settedTime2 = 30 * 60 * 1000; // 30 minutes before expiry
      const settedTime3 = 15 * 60 * 1000; // 15 minutes before expiry
      if (
        timeDifference > settedTime1 &&
        timeDifference < settedTime1 + 60 * 1000
      ) {
        console.warn("Warning: Token will expire in less than 1 hour!");
        setWillTokenExpire(true);
        setTokenExpiryMessage(
          `Warning: Token will expire in 1 hour! Please save your changes.`
        );
      }
      if (
        timeDifference > settedTime2 &&
        timeDifference < settedTime2 + 60 * 1000
      ) {
        console.warn("Warning: Token will expire in less than 30 minutes!");
        setWillTokenExpire(true);
        setTokenExpiryMessage(
          `Warning: Token will expire in 30 minutes! Please save your changes.`
        );
      }
      if (
        timeDifference > settedTime3 &&
        timeDifference < settedTime3 + 60 * 1000
      ) {
        console.warn("Warning: Token will expire in less than 15 minutes!");
        setWillTokenExpire(true);
        setTokenExpiryMessage(
          `Warning: Token will expire in 15 minutes! Please save your changes.`
        );
      }
    }
  };

  const scheduleExpiryCheck = async () => {
    await trackExpiryTime();
    setInterval(trackExpiryTime, 60000);
  };

  useEffect(() => {
    getMainSectionData("no");
    getRecapStatus();
    //Not calling this API parallely on page load, its been called inside getMainSectionData API
    // getStandardTermData();
    scheduleExpiryCheck();
  }, []);
  const changeHandler = (item: any) => {
    setPrevTab(tab);
    if (item.detail !== undefined) {
      if (item.detail !== null) {
        setTab(item.detail);
        handleTime(item.detail);
      }
    }
    if (item.detail === 0) {
      isPrefilledBCPCondition ? getPrefilledData(extendedContractIDBCP) : getStandardTermData();
      setIsInitialRunningRecap(false);
    }
    if (item.detail === 1) {
      BCPNewAPIResponse();
      setIsInitialRunningRecap(false);
    }
    if (item.detail == 2) {
      getRecapStatus("tabChange");
      setIsInitialRunningRecap(true);
    }
  };

  const handleTime = (tabNo: any) => {
    if (tabNo === 0 || tabNo === 1 || tabNo === 2) {
      handleTimer(acceptTimeSO);
    } else handleTimer(0);
  };

  const openExtensionModal = () => {
    // if (tab === 2) {
    //   setNegotiationType("Lift Subs");
    //   setTabTermsAcceptTimeline(runningRecapStatus?.liftSubTimeline);
    // }
    setTabTermsAcceptTimeline(mianInfoData?.termAcceptTimeline);
    setIsExtensionModal(true);
  };
  const openLiftSubExtensionModal = () => {
    setNegotiationType("Lift Subs");
    setVoTimeForExtension({
      userType: "CM",
      timeValue: mianInfoData?.voLiftSubRequestDate,
    });
    // setTabTermsAcceptTimeline(runningRecapStatus?.liftSubTimeline);
    setIsExtensionModal(true);
  };
  const closeExtensionModal = () => {
    setIsExtensionModal(false);
  };

  const getReRenderFunc = () => {
    if (tab === 0) {
      getStandardTermData();
    } else if (tab === 1) {
      BCPNewAPIResponse();

    } else if (tab === 2) {
      getRecapStatus();
    }
    getMainSectionData("no");
    getRecapStatus();
  };
  const getDataFromStandardOffer = (data) => {
    setStandardOfferFilledData(data);
  };
  const SubmitNegotiationModalFunc = () => {
    if (!mianInfoData?.initiatedNegotiation) {
      let isErrorKey = getBunkerCheckBoxStatus(
        standardOfferFilledData,
        isExtended
      );
      const fuelReqSelected = standardOfferFilledData ? getSelectedStatus(standardOfferFilledData):false;
      
      // Enable validation only when contract is extended and shorter period was selected
      if (isErrorKey || (isExtended == true && fuelReqSelected == true)) {
        toast.error(`Please Select Bunkers Period in Fuel Requirements`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        return;
      } else {
        const resultFlag = getInitialNegotiationTermsFilledCM(
          standardOfferFilledData
        );
        if (resultFlag) {
          setIsMandatoryNotifyModal(true);
        } else {
          setSubmitNegotiation(true);
        }
      }
    } else {
      const resultFlag = getAllTermsFilledCM(standardOfferFilledData, isPrefilledCondition);
      if (resultFlag) {
        setIsMandatoryNotifyModal(true);
      } else {
        setSubmitNegotiation(true);
      }
    }
  };
  // checking if fuel requirements has both longer and shorter period available
  const getSelectedStatus = (subTermsData1:any) => {
    const fuelReq = subTermsData1.find((e)=>e.termName == 'Fuel Requirements');

    const result=fuelReq.subTerms;
   let errorKey = false;
    // for (let [key, value] of Object.entries(result)) {
    //   console.log(key);
    //   console.log(result[key][0]);
    //   if(key == 'subTerms') {
    //     if(result[key][0].details.length == 6){
    //       errorKey = true;
    //     } else {
    //       errorKey = false;
    //     }
    //   }
    //   console.log("errorKey here is"+errorKey);
    //   return errorKey;
    // }  
    if(result[0].details.length == 6 && getBunkerCheckBoxStatusForExtension(subTermsData1))
      {
      errorKey = true;
      } 
      else 
      {
        errorKey = false;
      }
      return errorKey;
  }
  const reRenderFuncRR = () => {
    getRecapStatus();
    getMainSectionData("no");
  };

  const openModalDocChange = () => {
    setIsChangeDocModal(true);
  };
  const closeModalDocChange = (type: any) => {
    setIsChangeDocModal(false);
    if (type === "yes") {
      reLoadAllApis();
    }
  };
  const handleSave = () => {
    toast.success(`Data Saved Successfully`, {
      theme: "colored",
      icon: false,
      autoClose: 3000,
    });
  };
  const handleEditClick = () => {
    if (
      mianInfoData?.initiatedNegotiation &&
      mianInfoData?.showAcceptClausePopup
    ) {
      setAcceptClauseWrngModal(true);
    } else {
      setTab(0);
      setTimerAPI();
    }
  };

  const setTimerAPI = async () => {
    setDisableEditBtn(true);
    const response = await postAPI(
      `IndicationRequest/LockOrUnlockIndication?indicationId=${indicateId}&locked=true`
    );
    if (response) {
      getMainSectionData("yes");
      // getComments();
      setIsEditing(true);
      setDisableEditBtn(false);
    } else {
      setIsEditing(false);
      setDisableEditBtn(false);
      toast.error(`Edit Negotiation failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
  };
  const getComments = async () => {
    const resp: any = await getAPI(
      `Negotiation/GetNegotiationComments?indicationId=${indicateId}`
    );
    setCommentMainData(resp);
    const newArray: any = [];
    if (resp?.length > 0) {
      resp?.forEach((element: any) => {
        element?.counterGroupByUserTypes?.forEach((group: any) => {
          group?.negotiationComments?.forEach((comments: any) => {
            newArray.push(comments);
          });
        });
      });
    }
    setCommentData(newArray);
  };
  const submitComment = async () => {
    if (isCommentText !== "") {
      setIsChatSendDisabled(true);
      const data: any = {
        indicationId: Number(indicateId),
        comments: isCommentText,
      };
      const resp = await postAPI(`Negotiation/StageNegotiationComment`, data);
      if (resp === true) {
        getComments();
        setIsCommentText("");
        setIsChatSendDisabled(false);
      }
    }
  };
  const reLoadApis = () => {
    if (tab === 0) {
      getStandardTermData();
    } else if (tab === 1) {
      BCPNewAPIResponse();

    } else if (tab === 2) {
      getRecapStatus();
    }
    getMainSectionData("no");
    getRecapStatus();
    getNegotiationStatusFlow(mianInfoData?.currentStage);
    setTimeout(() => {
      //call the NPS feedback method
      shouldPromptFeedback();
    }, 4000);
  };

  const reLoadAllApis = () => {
    if (tab === 0) {
      getStandardTermData();
    } else if (tab === 1) {
      getMainSectionData("no");
    } else if (tab === 2) {
      getRecapStatus();
    }
    getMainSectionData("no");
    getRecapStatus();
    getNegotiationStatusFlow(mianInfoData?.currentStage);
  }

  const closeAcceptWarngModal = (type: string) => {
    if (type === "OK") {
      setAcceptClauseWrngModal(false);
      setIsEditing(true);
      setTimerAPI();
      setTab(0);
    } else {
      setAcceptClauseWrngModal(false);
    }
  };
  const disableNegotiation = (e) => {
    if (e.target.checked === false) {
      setEnableNegotiation(false);
    }
  };
  // Function to check if feedback prompt should be shown
  const shouldPromptFeedback = (): any => {
    const lastTimestamp: any = localStorage.getItem(
      LAST_FEEDBACK_TIMESTAMP_KEY
    );
    // Check if the last timestamp is more than two months old
    const lastDate = new Date(lastTimestamp);
    const now = new Date();
    const twoMonthsAgo = new Date(
      now.getFullYear(),
      now.getMonth() - 2,
      now.getDate()
    );

    if (mianInfoData?.counterNumber == 0) {
      setShowFeedbackPrompt(true);
    } else {
      if (mianInfoData?.counterNumber == 1) {
        if (lastTimestamp) {
          setShowFeedbackPrompt(false);
        } else {
          setShowFeedbackPrompt(true);
        }
      } else {
        if (lastTimestamp) {
          setShowFeedbackPrompt(false);
        } else {
          lastDate < twoMonthsAgo
            ? setShowFeedbackPrompt(false)
            : setShowFeedbackPrompt(true);
        }
      }
    }
  };

  const handleCloseFeedbackPrompt = (type: string) => {
    if (type == "no") {
      localStorage.removeItem(LAST_FEEDBACK_TIMESTAMP_KEY);
      localStorage.removeItem("last_feedback_timestamp");
    }
    setShowFeedbackPrompt(false);
  };

  return (
    <div className="initialOfferDetails_main">
      <div className="initialOfferDetails_offer__breadcrumbs">
        <span> Negotiate / Initiate Offer </span>
      </div>
      {mianInfoData?.showConfidentialMessage && (
        <div className="confidential-info">
          <McIcon icon="shield-check"></McIcon> ALL NEGOTIATIONS AND EVENTUAL
          FIXTURE TO BE KEPT STRICTLY PRIVATE AND CONFIDENTIAL
        </div>
      )}

      <div className="initialOfferDetails_section">
        <div className="initialOfferDetails_section__body">
          <div className="parentdiv">
            <div className="nestedDiv">
              {!stageType && displayAcceptRejectChngeMsg == true && (
                <div className={`extensionIndication indication-extension`}>
                  <span className="icon-notification">
                    <McIcon icon="bell"></McIcon>
                  </span>
                  <label>
                    New Extension Time{" "}
                    <b>
                      {moment(extensiontimeLineSO).format("DD-MMM-YYYY HH:mm")}
                    </b>{" "}
                    has been requested by Vessel owner, <br />
                    Do you want to{" "}
                    <span className="extensionIndication_button">
                      <button
                        className="btn btn-primary"
                        data-testid="extension-accept"
                        onClick={extentionAccept(
                          "accept",
                          "Standard Offer Document"
                        )}
                      >
                        Accept
                      </button>{" "}
                      <button
                        className="btn btn-primary"
                        onClick={extentionAccept(
                          "reject",
                          "Standard Offer Document"
                        )}
                      >
                        Reject
                      </button>{" "}
                      or
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          openOfferExtensionModal("Standard Offer Document")
                        }
                      >
                        Change
                      </button>
                    </span>
                  </label>
                </div>
              )}
              {!stageType &&
                mianInfoData?.accessType === "Write" &&
                mianInfoData?.voLiftSubExtensionRequestTimeline &&
                (mianInfoData?.voLiftedSubsOn === null ||
                  mianInfoData?.voLiftedSubsOn === "") && (
                  <div className={`extensionIndication indication-extension`}>
                    <span className="icon-notification">
                      <McIcon icon="bell"></McIcon>
                    </span>
                    <label>
                      New Extension Time{" "}
                      <b>
                        {moment(voLiftSubExtensionTime).format(
                          "DD-MMM-YYYY HH:mm"
                        )}
                      </b>{" "}
                      For <b>Lift Subs</b> has been requested by Vessel Owner,{" "}
                      <br />
                      Do you want to{" "}
                      <span className="extensionIndication_button">
                        <button
                          className="btn btn-primary"
                          data-testid="liftsub-extension-accept"
                          onClick={liftSubExtentionAccept("accept")}
                        >
                          Accept
                        </button>{" "}
                        <button
                          className="btn btn-primary"
                          data-testid="liftsub-extension-reject"
                          onClick={liftSubExtentionAccept("reject")}
                        >
                          Reject
                        </button>{" "}
                        or
                        <button
                          className="btn btn-primary"
                          data-testid="liftsub-extension-change"
                          onClick={() => openOfferExtensionModal("Lift Subs")}
                        >
                          Change
                        </button>
                      </span>
                    </label>
                  </div>
                )}
            </div>
          </div>
          <div className="row rowDiv initiate-offer-tabs">
            <div className="col-md-1">
              {!stageType && (
                <div className="timer-wrapper">
                  <div className="negotiation-type">
                    <div>Main Terms</div>
                    {/* {tab === 0 ? (
                    <div>Main Terms</div>
                  ) : tab === 1 ? (
                    <div>Charter Party</div>
                  ) : tab === 2 ? (
                    <div>Running Recap</div>
                  ) : null} */}
                  </div>
                  <CountdownCircleTimer
                    {...timerProps}
                    duration={timerInitial}
                    initialRemainingTime={remainingTime}
                    colors={["#42b0d5", "#F7B801", "#A30000", "#bcbcbc"]}
                    colorsTime={[90, 50, 20, 0]}
                  >
                    {renderTime}
                  </CountdownCircleTimer>

                  {!stageType &&
                    mianInfoData &&
                    mianInfoData?.accessType === "Write" &&
                    !stageType &&
                    mianInfoData?.showExtensionRequestLink === true &&
                    mianInfoData?.extensionTimeline == null && (
                      <div
                        className="extension-div"
                        onClick={openExtensionModal}
                      >
                        <label>Request for Extension</label>
                      </div>
                    )}

                  {!stageType &&
                    mianInfoData &&
                    mianInfoData?.accessType === "Write" &&
                    !stageType &&
                    mianInfoData?.enableCheckout &&
                    mianInfoData?.showExtensionRequestLink === false &&
                    mianInfoData?.extensionTimeline !== null && (
                      <div className="extension-div">
                        <label>Request Sent</label>
                      </div>
                    )}

                  {/* {tab === 2 &&
                  runningRecapStatus &&
                  runningRecapStatus?.liftSubTimeline !== null &&
                  runningRecapStatus?.liftSubExtensionTimeline === null && (
                    <div className="extension-div" onClick={openExtensionModal}>
                      <label>Request for Extension</label>
                    </div>
                  )}
                {tab === 2 &&
                  runningRecapStatus &&
                  runningRecapStatus?.liftSubExtensionTimeline !== null && (
                    <div className="extension-div">
                      <label>Request Sent</label>
                    </div>
                  )} */}
                </div>
              )}
              {stageType && (
                <div className="empty-timer-section">
                  <div className="empty-timer"></div>
                </div>
              )}
            </div>
            <div className="col-md-11">
              <div className="step-indicator">
                <div className="terminate-section">
                  {accessType === "Write" && !isStageTerminate && (
                    <span>
                      {" "}
                      <button
                        className="btn btn-primary"
                        onClick={handleTerminate}
                      >
                        Terminate
                      </button>
                    </span>
                  )}
                  <span className="counter">
                    <span className="counter-number">
                      {mianInfoData?.counterNumber}
                    </span>
                    <span className="counter-text">Counter</span>
                  </span>
                </div>

                <StepIndicatorFlow
                  type={"Negotiation"}
                  indicationID={indicateId}
                  updateApiProps={isMainLoader}
                  getStatusFlow={getNegotiationStatusFlow}
                />
              </div>
            </div>
          </div>
          <div className="negotiation-mainInfo">
            <div className="row">
              <div className="col">
                <div className="info-div">
                  <div className="info-label">Vessel Name</div>
                  <div className="info-value">{mianInfoData?.vesselName}</div>
                </div>
              </div>
              <div className="col">
                <div className="info-div">
                  <div className="info-label">IMO</div>
                  <div className="info-value">{mianInfoData?.imo}</div>
                </div>
              </div>
              {/* <div className="col">
                <div className="counter-info">
                  <div className="counter-label">Counter</div>
                  <div className="counter-value">
                    {mianInfoData?.counterNumber}
                  </div>
                </div>
              </div> */}
              <div className="col">
                <div className="info-div">
                  <div className="info-label">Access Type</div>
                  <div className="info-value">{mianInfoData?.accessType}</div>
                </div>
              </div>
              {accessType === "Write" && (
                <>
                  {mianInfoData?.cmLiftSubRequestDate && (
                    <div className="col lift-sub-timer-div">
                      <div className="info-div">
                        <div className="info-label">CM Lift Sub</div>
                        {mianInfoData?.cmLiftedSubsOn !== null &&
                        mianInfoData?.cmLiftedSubsOn !== "" ? (
                          <div className="ls-value-completed">
                            Completed{" "}
                            <span className="timerShow">
                              {moment(mianInfoData?.cmLiftedSubsOn).format(
                                "DD-MMM-YYYY HH:mm"
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className="ls-value">
                            {cmLiftSubRemainingTime}{" "}
                            <span className="date-display">
                              (
                              {moment(
                                mianInfoData?.cmLiftSubRequestDate
                              ).format("DD-MMM-YYYY HH:mm")}
                              )
                            </span>
                          </div>
                        )}
                      </div>
                      {mianInfoData?.cmLiftSubRequestDate &&
                        mianInfoData?.cmLiftSubExtensionRequestTimeline &&
                        mianInfoData?.accessType === "Write" &&
                        (mianInfoData?.cmLiftedSubsOn === null ||
                          mianInfoData?.cmLiftedSubsOn === "") && (
                          <div className="extension-div-sent">
                            <label>Request Sent</label>
                          </div>
                        )}
                      {mianInfoData?.cmLiftSubRequestDate &&
                        !mianInfoData?.cmLiftSubExtensionRequestTimeline &&
                        mianInfoData?.accessType === "Write" &&
                        (mianInfoData?.cmLiftedSubsOn === null ||
                          mianInfoData?.cmLiftedSubsOn === "") && (
                          <div
                            className="extension-div"
                            onClick={openLiftSubExtensionModal}
                          >
                            <label>Request for Extension</label>
                          </div>
                        )}
                    </div>
                  )}
                  {mianInfoData?.initiatedNegotiation && (
                    <div className="col">
                      <div className="islocked">
                        <div className="info-label">Negotiation</div>
                        <div className="icon-div">
                          <McIcon
                            icon={
                              mianInfoData?.enableCheckout === false
                                ? "lock"
                                : "lock-unlocked"
                            }
                            size="20"
                          ></McIcon>
                        </div>
                      </div>
                    </div>
                  )}

                  {mianInfoData?.enableCheckout && !stageType && (
                    <div className="col-md-2">
                      <div className="button">
                        {!isEditing && (
                          <div>
                            <McButton
                              data-testid="edit-button"
                              fit="small"
                              label="Edit Negotiation"
                              className="edit-button"
                              onClick={handleEditClick}
                              disabled={disableEditBtn}
                            ></McButton>
                            {disableEditBtn && (
                              <div className="loader-area">
                                <McLoadingIndicator
                                  hiddenlabel
                                ></McLoadingIndicator>
                              </div>
                            )}
                          </div>
                        )}
                        {isEditing && (
                          <div>
                            <div className="action-buttons">
                              <McButton
                                fit="small"
                                label="Submit Counter/Offer"
                                onclick={() => SubmitNegotiationModalFunc()}
                                className="submit-button"
                                data-testid="SubmitNegotiationModalFunc"
                              ></McButton>
                              <McButton
                                data-testid="save-button"
                                fit="small"
                                label="Save"
                                className="save-button"
                                onClick={handleSave}
                              ></McButton>
                            </div>
                            {/* <div className="timer-value">{timerSecond}</div> */}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {!isAttachBCP &&
                    mianInfoData?.enableCheckout &&
                    !stageType && (
                      <div className="col-md-1">
                        <div
                          className={isPrefilledCondition ? "disable-change-doc":"change-doc" }
                          data-testid="change-doc"
                          onClick={isPrefilledCondition ? undefined: () => {
                            openModalDocChange();
                          }}
                        >
                          Select Document
                        </div>
                        <div
                          className="documentName"
                          title={mianInfoData?.documentName}
                        >
                          {mianInfoData?.documentName}
                        </div>
                      </div>
                    )}
                  <div className="checkbox-container">
                    {mianInfoData?.accessType == "Write" && (
                      <div className="attach-bcp-negotiation">
                        <McCheckbox
                          data-testid="attach-bcp"
                          fit="small"
                          checked={canAttachBCP}
                          change={(e) => {
                            setCanAttachBCP(!canAttachBCP);
                            disableNegotiation(e);
                          }}
                          label="Attach Charter party"
                          disabled={
                            !isEditing ||
                            (isEditing && mianInfoData?.contractDocId == 0) ||
                            mianInfoData?.attachedBcp
                          }
                        ></McCheckbox>
                      </div>
                    )}
                    {mianInfoData?.accessType == "Write" && (
                      <div className="enable-negotiation">
                        <McCheckbox
                          data-testid="enable-bcp-edit"
                          fit="small"
                          checked={enableNegotiation}
                          change={() =>
                            setEnableNegotiation(!enableNegotiation)
                          }
                          label="Enable Negotiation for Charter party ?"
                          disabled={
                            !isEditing ||
                            (isEditing &&
                              mianInfoData?.attachedBcp &&
                              mianInfoData?.bcpEdit) ||
                            (isEditing && !canAttachBCP)
                          }
                        ></McCheckbox>
                      </div>
                    )}
                    {mianInfoData?.accessType == "Write" &&
                      enableNegotiation &&
                      !(
                        !isEditing ||
                        (isEditing &&
                          mianInfoData?.attachedBcp &&
                          mianInfoData?.bcpEdit) ||
                        (isEditing && !canAttachBCP)
                      ) && (
                        <span className="enable-error-msg">
                          Disable Negotiation will discard all the changes made
                          in Charter Party.
                        </span>
                      )}
                  </div>
                </>
              )}
              {/* {(mianInfoData?.documentName !== null && (isAttachBCP &&
                    !mianInfoData?.enableCheckout &&
                    stageType)) || (!isPrefilledCondition && mianInfoData?.documentName == null) && ( */}
                   {/* {mianInfoData?.documentName !== null && ( original */}
                        
                {((mianInfoData?.documentName !== null && mianInfoData?.isExtended == false && isAttachBCP) || (mianInfoData?.isExtended == true && isAttachBCP)) && ( 
                <div className={accessType === "Write" ? !mianInfoData?.initiatedNegotiation ? "doc-not-visible" : "col-md-1" : "col-md-2"}>
                  <div
                    className="documentName"
                    title={mianInfoData?.documentName}
                  >
                    {mianInfoData?.documentName}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`comment-section active`}>
            <div
              data-testid="chat-circle"
              id="chat-circle"
              className="btn btn-raised"
              onClick={() => setIsComment(true)}
            >
              <div id="chat-overlay"></div>
              <McIcon icon="comment"></McIcon>
            </div>

            <div className={`chat-box ${isComment ? "show-chatbox" : ""} `}>
              <div className="chat-box-header">
                Comment Section
                <span
                  data-testid="chat-box-toggle"
                  className="chat-box-toggle"
                  onClick={() => setIsComment(false)}
                >
                  <img
                    className="close-msg-icon"
                    src={closeIcon}
                    alt="close"
                  ></img>
                  {/* <i className="material-icons">close</i> */}
                </span>
              </div>
              <div className="chat-box-body">
                <div className="chat-box-overlay"></div>
                <div className="chat-logs">
                  {commentData?.map((item) => (
                    <div className="chat-msg">
                      <div className="chat-text-div">
                        <span
                          className={`user-role  ${
                            item?.userType === "CharteringManager" ? "cm" : "vo"
                          }`}
                        >
                          {item?.userType === "CharteringManager" ? "CM" : "VO"}
                        </span>
                        <span className="user-chat">{item?.comments}</span>
                      </div>
                      <span className="details">
                        <span className="counter">
                          Counter - <span>{item?.counterNumber}</span>
                        </span>
                        <span className="time">
                          {moment(item?.createdOn).format("DD-MMM-YYYY HH:mm")}
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {isEditing && (
                <div className="chat-input">
                  <input
                    type="text"
                    onChange={(e) => setIsCommentText(e.target.value)}
                    id="chat-input"
                    data-testid="chat-input-text"
                    value={isCommentText}
                    placeholder="Comment..."
                  />
                  <button
                    data-testid="chat-submit"
                    onClick={submitComment}
                    className={`chat-submit ${
                      isChatSendDisabled ? "disabled" : ""
                    }`}
                    disabled={isChatSendDisabled}
                    id="chat-submit"
                  >
                    <img
                      className="message-send-icon"
                      src={messageSendIcon}
                      alt="send"
                    ></img>
                    {/* <i className="material-icons">send</i> */}
                  </button>
                </div>
              )}
            </div>
          </div>
          {mianInfoData?.enableCheckout && mianInfoData?.counterNumber > 0 && (
            <span className="time-expiry">
              <div className="expiry-information">
                <McIcon
                  icon="info-circle"
                  className="information-icon"
                ></McIcon>
                <span>
                  Accepted main terms will be confirmed upon submission of the
                  counter.
                </span>
              </div>
            </span>
          )}

          {/* <div className="checkbox-container">
            {mianInfoData?.accessType == "Write" && (!mianInfoData?.attachedBcp || !mianInfoData?.bcpEdit) && (
              <div className="attach-bcp-negotiation">
                <McCheckbox
                      data-testid="attach-bcp"
                      fit="small"
                      checked={canAttachBCP}
                      change={() => setCanAttachBCP(!canAttachBCP)}
                      label="Attach Charter party"
                      disabled={!isEditing || (isEditing && mianInfoData?.contractDocId == 0 ) || mianInfoData?.attachedBcp}
                    ></McCheckbox>
              </div>
            )}
            {isEditing && !mianInfoData?.bcpEdit && canAttachBCP && (
              <div className="enable-negotiation">
              <McCheckbox
                      data-testid="enable-bcp-edit"
                      fit="small"
                      checked={enableNegotiation}
                      change={() => setEnableNegotiation(!enableNegotiation)}
                      label="Enable Negotiation for Charter party ?"
                    ></McCheckbox>
              </div>
            )}
          </div> */}

          <div className="doucumentsType-section">
            {isMainLoader && (
              <div className="loader-div  ">
                <div className="width-div">
                  <div className="image-div shipLoader" id="pot">
                    <img src={laderImage} alt="" />
                  </div>
                </div>
              </div>
            )}
            <McTabBar
              data-testid="change-handler"
              variant="stretched"
              currentindex={tab}
              change={changeHandler}
            >
              <McTab
                slot="tab"
                label="Main Terms"
                data-testid="main-terms"
                icon="file"
              ></McTab>
              <div slot="panel">
                <StandardOfferCM
                  data={offerdata}
                  indicationId={indicateId}
                  submitData={submitData}
                  reRenderApiFunc={reRenderFunc}
                  accessType={accessType}
                  stageType={stageType}
                  isEditing={isEditing}
                  dataBack={getDataFromStandardOffer}
                  mainInfoData={mianInfoData}
                  standardDataLoaded={standardOfferApiLoad}
                  reRenderNegotiationDetail={reRenderNegotiationDetail}
                  isPrefilledCondition={isPrefilledCondition}
                  recapStatus={runningRecapStatus}
                />
              </div>
              <McTab
                slot="tab"
                label="Charter Party"
                icon="file"
                data-testid="charter-party"
                className={`${!isCharterPartyTab ? "disbaleTab" : ""}`}
              ></McTab>

              <div slot="panel">
                <IndexHomePage
                  indicationRequestId={indicationRequestId}
                  reRenderApiFunc={reRenderFuncBCP}
                  BCPData={BCPData}
                  accessType={accessType}
                  stageType={stageType}
                  SOData={submitData}
                  isEditing={isEditing && enableNegotiation}
                  mainInfoData={mianInfoData}
                  isPrefilledCondition={isPrefilledCondition}
                  extendedContractIDBCP={extendedContractIDBCP}
                />
              </div>

              <McTab
                slot="tab"
                label="Running Recap"
                icon="file"
                data-testid="running-recap"
              ></McTab>
              <div slot="panel">
                <IssueRecapHomePage
                  tabDetails={tab === 2 && tab}
                  indicationRequestId={indicationRequestId}
                  reRenderApiFunc={reRenderFuncRR}
                  data={runningRecapData}
                  recapStatus={runningRecapStatus}
                  accessType={accessType}
                  isEditing={isEditing}
                  isInitialRunningRecap={isInitialRunningRecap}
                  mainInfoData={mianInfoData}
                  isPrefilledCondition={isPrefilledCondition}
                  extendedContractIDBCP={extendedContractIDBCP}
                />
              </div>
            </McTabBar>
          </div>
        </div>
        <ChangeOfferTimeModal
          isModalOpen={extensionModalOpen}
          onLoadData={
            negotiationTypeValue == "Standard Offer Document"
              ? extensiontimeLineSO
              : extensiontimeLineBC
          }
          onCloseFunc={onClosePopUp}
          submitData={submitDataFun}
          liftSubExtensionTime={voTimeForExtension}
          negotiationType={negotiationTypeValue}
        />

        <ConfirmationModal
          onModalOpen={isConfrmModal}
          onModalClose={terminateIndicationRequest}
          modalTxt={AppString.terminateModalTxt}
        />
        {willTokenExpire && (
          <TokenExpiryPop
            onModalOpen={willTokenExpire}
            onModalClose={() => setWillTokenExpire(false)}
            modalTxt={tokenExpiryMessage}
          />
        )}
        <SubmitNegotiationModal
          indicationId={indicateId}
          onCloseFunc={() => setSubmitNegotiation(false)}
          isModalOpen={isSubmitNegotiation}
          reLoadApis={reLoadApis}
          mainInfoData={mianInfoData}
          enableNegotiation={enableNegotiation}
          userDetail={"CM"}
          AttachBCPFromApi={isAttachBCP}
          attachCharterPartyCBox={canAttachBCP}
          isDocSelected={isDocSelected}
        />
        <ExtensionModal
          isModalOpen={isExtensionModal}
          indicationId={indicateId}
          termsAcceptTimeline={tabTermsAcceptTimeline}
          negotiationType={negotiationType}
          onCloseFunc={closeExtensionModal}
          reRenderApiFunc={getReRenderFunc}
          liftSubTimeForExtension={voTimeForExtension}
        />
        {isChangeDocModal && (
          <ChangeDocBCPModal
            isModalOpen={isChangeDocModal}
            indicationId={indicateId}
            onCanceled={closeModalDocChange}
            selectedDocId={mianInfoData?.contractDocId}
            reRenderApiFunc={getReRenderFunc}
          ></ChangeDocBCPModal>
        )}
        {acceptClauseWrngModal && (
          <AcceptClauseWarningModal
            isModalOpen={acceptClauseWrngModal}
            indicationId={indicateId}
            onCanceled={closeAcceptWarngModal}
          ></AcceptClauseWarningModal>
        )}
        {isMandatoryNotifyModal && (
          <MandatoryNotifyModal
            isModalOpen={isMandatoryNotifyModal}
            onCloseFunc={setIsMandatoryNotifyModal}
          />
        )}
        <FeedBackPrompt
          showModal={showFeedbackPrompt}
          onClose={handleCloseFeedbackPrompt}
        />
      </div>
    </div>
  );
};
export default InitiateOfferDetailsCM;