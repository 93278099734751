import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  isModalOpen?: boolean;
  onLoadData?: any;
  onCanceled?: any;
}

const ExtendContractModal = ({
  isModalOpen,
  onLoadData,
  onCanceled,
}: IPROPS) => {
  const onStartBtnClick = () => {
    onCanceled("Submit");
  };

  const closeAcceptFunc = () => {
    onCanceled("Cancel");
  };

  return (
    <div>
      <McModal
        width="520px"
        height="180px"
        open={isModalOpen}
        heading="Confirm Action"
      >
        <p className="extend-clause-modal">
          Do you want to extend this Contract?
        </p>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          click={closeAcceptFunc}
          dialogAction="cancel"
          data-testid="cancel"
        >
          Cancel
        </McButton>
        <McButton
          slot="primaryAction"
          variant="primary"
          click={onStartBtnClick}
          dialogAction="ok"
          data-testid="start-extend"
        >
          Start
        </McButton>
      </McModal>
    </div>
  );
};

export default ExtendContractModal;
