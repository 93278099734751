import { McButton, McModal } from "@maersk-global/mds-react-wrapper";
import { useEffect, useState } from "react";

interface IPROPS {
    isModalOpen?: boolean;
    onCloseFunc?: any;
    termsData?: any;
    indicationId?: any;
    submitType?: any;
  }
const UpdateUnfilledFieldsModal = ({isModalOpen, onCloseFunc, termsData, indicationId, submitType} : IPROPS) => {
    const [revertData, setRevertData] = useState([]);
    const handleClose = () => {
        onCloseFunc();
    }
    useEffect(()=> {
        setRevertData(termsData);
    }, [isModalOpen])
    return (
        <McModal
            height="500px"
            width="700px"
            className="terms-update-modal"
            data-testid="terms-update-modal"
            heading="Please Fill the Terms or Revert Later"
            open={isModalOpen}
            backdropcloseactiondisabled
        >
            <div className="unfilled-terms-section" data-testid="unfilled-labels-content">
            <p>Please provide the below details or select Revert next counter to submit this term</p>
            <ul>
                {revertData?.map((item: any) => (
                <li className="list-of-terms">{item.label}</li>
                ))}
            </ul>
            </div>
            <McButton
                slot="secondaryAction"
                variant="secondary"
                fit="small"
                label={"Cancel"}
                data-testid="cancel-button"
                onClick={handleClose}
            ></McButton>
        </McModal>
    )
}
export default UpdateUnfilledFieldsModal;