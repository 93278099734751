// MDS
import {
  TableColumn,
  TableTBodyTemplateDetails,
} from "@maersk-global/mds-components-community/mc-c-table/types";
import { McCTable } from "@maersk-global/mds-react-wrapper";

export interface TableProps<T> {
  data?: any;
  columns?: TableColumn[];
  dataLoading?: boolean;
  onSorting?: (e: CustomEvent<any>) => void;
  rowclickhandler?: (e: CustomEvent<any>) => void;
}

export const MdsTable = <T extends unknown>({
  data,
  columns,
  dataLoading,
  onSorting,
  rowclickhandler,
}: TableProps<T>) => {
  let loadTemplateBody = false;
  if (dataLoading || !data || data.length === 0) {
    loadTemplateBody = true;
  }

  const tbodyTemplateNoData = (details: TableTBodyTemplateDetails) => {
    if (dataLoading) {
      return details.html`
        <tbody>
          <tr>
            <td colspan=${columns?.length} style="padding: 64px;border-bottom:1px solid #DBDBDB;">
              <mc-loading-indicator label="Loading">
              </mc-loading-indicator>
            </td>
          </tr>
        </tbody>`;
    }
    return details.html`
     <tbody>
       <tr>
         <td colspan=${columns?.length} style="padding: 64px;">
           <mc-notification
             heading="No data found"
             appearance="warning"
             icon="exclamation-triangle"
           >
           </mc-notification>
         </td>
       </tr>
     </tbody>`;
  };

  return (
    <section className="layout layout--center paginated-data-wrapper">
      <div className="layout--s layout--margin-32">
        <McCTable
          data={data}
          columns={columns}
          stickyheader
          height="auto"
          fit="medium"
          recordsperpage={10}
          sorted={onSorting}
          rowclick={rowclickhandler}
          tbodyTemplate={
            !loadTemplateBody
              ? undefined
              : (details: TableTBodyTemplateDetails) =>
                  tbodyTemplateNoData(details)
          }
        ></McCTable>
      </div>
    </section>
  );
};
