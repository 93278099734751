// React
import React, { useState, useEffect } from "react";
import { useForm, Controller, useFormContext } from "react-hook-form";

//MDS
import "@maersk-global/mds-components-core";
import {
  McSelect,
  McCAccordionItem,
  McInputDate,
  McCheckboxGroup,
  McCheckbox,
  McButton,
  McIcon,
  McCTypeahead,
} from "@maersk-global/mds-react-wrapper";

//Pages
import CharterMarket from "../../pages/charter-market/CharterMarket";

//Service
import { getAPI } from "../../services/ApiServices";
//CSS
import "./CharterMarketFilters.scss";

interface IPROPS {
  closeFilterFunc?: (close: any) => void;
  onDataFunc?: (data: any) => void;
}
interface FormValues {
  statusList: string;
  owner: string | null;
  chartererId: number | null;
  buyer: string;
  teuVal: number | null;
  fromDate: string;
  toDate: string;
  FromTeu: any;
  ToTeu: any;
}

function CharterMarketFiltersSNP({ closeFilterFunc, onDataFunc }: IPROPS) {
  const [filters, setFilters] = useState<any>([]);
  const [charterOptions, setCharterOptions] = useState([]);
  const [isOwner, setIsOwner] = useState([]);
  const [isBuyer, setIsBuyer] = useState([]);
  const [isTeuFrom, setIsTeuFrom] = useState([]);
  const [isTeuTo, setIsTeuTo] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      statusList: "",
      owner: null,
      chartererId: null,
      buyer: "",
      ToTeu: 0,
      FromTeu:0,
      fromDate: "",
      toDate: "",
    },
  });
  let formData = {};

  const clearFilter = () => {
    reset();
    setFilters("");
    setIsOwner([]);
    setCharterOptions([]);
    setIsBuyer([]);
    if(onDataFunc){
      onDataFunc([]);
    }
    if(closeFilterFunc){
      closeFilterFunc(true);
    }
  }
  const onSubmit = (data: any) => {
    if (onDataFunc) {
      onDataFunc(data);
    }
    if (closeFilterFunc) {
      closeFilterFunc(true);
    }
  };
  useEffect(() => {
    fetchFiltersForCharterMrkt();
  }, []);

  const fetchFiltersForCharterMrkt = async () => {
    const filtersData = await getAPI(`Filter/GetSalesAndPurchaseFilter`);
    if(filtersData){
      // setIsTeuFrom(filtersData?.teuItems.map((a: any) => a.label));
      setFilters(filtersData);
      setCharterOptions(filtersData?.chartererItems)
      setIsOwner(filtersData?.vesselOwnerItems.map((a: any) => a.label));
      setIsBuyer(filtersData?.buyerItems.map((a: any) => a.label));
    }
  };
  const closeFilter = () => {
    if(closeFilterFunc){
      closeFilterFunc(true);
    }
  };

  return (
    <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="filterModal">
        <div>
          <div className="input-mainSection">
            <div className="filter-section">
            {filters.length === 0 &&  (
                <div className="loader-div loader-div-filterSNP">
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
              <div className="header-section">
                <h5 className="header-text">Apply Filter</h5>
                <span className="filter-note">** Previously applied filters have been removed. Reapply your filters. **</span>
                <div className="closeIcon" data-testid="close-icon" onClick={closeFilter}>
                  <McIcon icon="times"></McIcon>
                </div>
              </div>
              <div className="filters-subsection">
                <McCAccordionItem
                  title="Status"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <Controller
                    name="statusList"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <McCheckboxGroup
                        name="statusList"
                        legend="Please select options"
                        change={(e) => onChange(e.detail)}
                        data-testid="status-list"
                      >
                        {filters?.salesAndPurchaseStatusItems?.map((item: any, index:any) => (
                          <McCheckbox
                            key={index}
                            name="statusList"
                            value={item.value}
                            label={item.label}
                            data-testid={`status-list-items-${index}`}
                          ></McCheckbox>
                        ))}
                      </McCheckboxGroup>
                    )}
                  />
                </McCAccordionItem>
              </div>
              <div className="filters-subsection accordian-div">

                  <div className="input-section">
                    <Controller
                      name="owner"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <McCTypeahead
                          ariaPlaceholder="Owner"
                          name="owner"
                          label=""
                          optionselected={(e) => onChange(e.detail.option.value)}
                          options={isOwner}
                          data-testid="owner"
                        ></McCTypeahead>
                      )}
                    />
                  </div>
                  <div className="input-section">
                  <McSelect
                    {...register("chartererId")}
                    name="chartererId"
                    ariaPlaceholder="Charterer"
                    label=""
                    selectedindex={charterOptions ? [0] : []}
                    options={charterOptions}
                    data-testid="charterer"
                  ></McSelect>

                  </div>
                  <div className="input-section">
                    <Controller
                      name="buyer"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <McCTypeahead
                          ariaPlaceholder="Buyer"
                          name="buyer"
                          label=""
                          optionselected={(e:any) => onChange(e?.detail?.option?.value)}
                          options={isBuyer}
                          maxlistheight="120px"
                          data-testid="buyer"
                        ></McCTypeahead>
                      )}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                        <input type="number" {...register("FromTeu")} placeholder="From TEU" className="inputText" data-testid="from-teu"/>
                    </div>
                    <div className="col-md-6">
                    <input type="number" {...register("ToTeu")} placeholder="To TEU" className="inputText" data-testid="to-teu"/>
                    </div>
                  </div>
              </div>
              <div className="filters-subsection">
                <McCAccordionItem
                  title="Date"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <div className="input-section">
                    <McInputDate
                      {...register("fromDate")}
                      name="fromDate"
                      label="From date"
                      ariaPlaceholder="yyyy/mm/dd"
                      data-testid="from-date"
                    ></McInputDate>
                  </div>
                  <div className="input-section">
                    <McInputDate
                      {...register("toDate")}
                      name="toDate"
                      label="To date"
                      ariaPlaceholder="yyyy/mm/dd"
                      data-testid="to-date"
                    ></McInputDate>
                  </div>
                </McCAccordionItem>
              </div>

              <div className="footer-fixed">
                <div className="button-apply">
                  <div className="input-section">
                    <McButton
                      type="submit"
                      label="Apply filter"
                      data-testid="apply-filter-button"
                      width="full-width"
                      fit="small"
                    ></McButton>
                  </div>
                </div>
                <div className="button-apply">
                  <div className="input-section">
                    {/* <McButton
                      label="Clear filter"
                      width="full-width"
                      appearance="filled"
                      fit="small"
                      click="clearFilterHandler()"
                    ></McButton> */}
                    <button
                      data-testid="clear-filter"
                      className="reset-btn"
                      type="button"
                      onClick={() => {
                        clearFilter();
                      }}
                    >
                      Clear filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CharterMarketFiltersSNP;
