import React, { useEffect } from "react";
import { useState } from "react";
import {
  McModal,
  McButton,
  McSelect,
  McIcon,
  McCheckbox,
  McLoadingIndicator,
} from "@maersk-global/mds-react-wrapper";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import { getAPI, postAPI } from "../../services/ApiServices";
import { toast } from "react-toastify";
import "./UploadModal.scss";
import { DataRow } from "./model";
import { excelDownload } from "../../utils/excelDownload";
import { Row } from "react-bootstrap";
import { async } from "q";
import moment from "moment";

interface IPROPS {
  onOpen?: boolean;
  closeValid?: any;
  pageData?: any;
  uploadFile?: any;
}

const ValidateModal = ({
  onOpen,
  closeValid,
  pageData,
  uploadFile,
}: IPROPS) => {
  const [updateData, setUpdateData] = useState<any>();
  const [isUpdateButton, setIsUpdateButton] = useState(false);
  useEffect(() => {
    if (pageData) {
      setUpdateData(pageData?.fileUploadUserActions);
    }
  }, [pageData]);
  const onCancel = () => {
    closeValid();
  };
  const rowSelectCritera = (row: any) => {
    let val = updateData?.map((item: any) =>
      item.imo == row.target.name
        ? (row.target.label === 'New' ? { ...item, userAction: row.target.checked, misMatched: false } : { ...item, misMatched: row.target.checked, userAction: false })
        : item
    );
    // console.log(val);
    setUpdateData(val);
  };
  const excelDownloadFile = () => {
    const header_alphaliener = [
      "Vessel Name",
      "Teu",
      "Teu 14t",
      "Built",
      "IMO",
      "Design",
      "Rate",
      "Fix charterer",
      "Delivery date",
      "Fix Period",
      "Currency",
      "Nat",
      "Vessel Type",
      "Fix status",
      "Managing Owner",
      "Redelivery date",
      "Validation Summary",
    ];
    const modified_rows_alphaliner = pageData?.validationMessages.map(
      (val: any) => {
        return {
          "Vessel Name": val.vesselName,
          Teu: val.teu,
          "Teu 14t": val.teu14t,
          Built: val.built,
          IMO: val.imo,
          Design: val.design,
          Rate: val.rate,
          "Fix charterer": val.fixCharterer,
          "Delivery date": val.deliveryDate,
          "Fix Period": val.fixPeriod,
          Currency: val.currency,
          Nat: val.nat,
          "Vessel Type": val.vesselType,
          "Fix status": val.fixStatus,
          "Managing Owner": val.managingOwner,
          "Redelivery date": val.redeliveryDate,
          "Validation Summary": val.message,
        };
      }
    );

    const modified_rows_availabilityList = pageData?.validationMessages.map(
      (val: any) => {
        return {
          Name: val.vesselName,
          "IMO No.": val.imo,
          "Ship Design": val.shipDesign,
          Teu: val.teu,
          "@14T": val.teu14T,
          Plugs: val.plugs,
          Dwt: val.dwt,
          Gear: val.gear,
          LOA: val.loa,
          Draft: val.draft,
          Beam: val.beam,
          Speed: val.speed,
          Built: val.built,
          "Flag Country": val.flagCountry,
          "Open From": val.openFrom,
          "Open To": val.openTo,
          Zone: val.zone,
          Open: val.open,
          "Managing Company": val.managingCompany,
          "Ice Class": val.iceClass,
          Charterer: val.charterer,
          "Open Date Range": val.openDateRange,
          Geared: val.geared,
          "Vessel Type": val.vesselType,
          Cellular: val.cellular,
          Parent: val.parent,
          "Marked Source": val.markedSource,
          "Trading Status": val.tradingStatus,
          "Validation Summary": val.message,
        };
      }
    );
    const header_availablilty = [
      "Name",
      "IMO No.",
      "Ship Design",
      "Teu",
      "@14T",
      "Plugs",
      "Dwt",
      "Gear",
      "LOA",
      "Draft",
      "Beam",
      "Speed",
      "Built",
      "Flag Country",
      "Open From",
      "Open To",
      "Zone",
      "Open",
      "Managing Company",
      "Ice Class",
      "Charterer",
      "Open Date Range",
      "Geared",
      "Vessel Type",
      "Cellular",
      "Parent",
      "Marked Source",
      "Trading Status",
      "Validation Summary",
    ];
    const modified_rows_broker = pageData?.validationMessages.map(
      (val: any) => {
        return {
          "CP Date": val.cpDate,
          Vessel: val.vesselName,
          IMO: val.imo,
          TEU: val.teu,
          "@14T": val.teu14t,
          Geared: val.geared,
          Beam: val.beam,
          Laycan: val.laycan,
          Where: val.toWhere,
          Period: val.period,
          Curr: val.curr,
          Rate: val.rate,
          Charterer: val.charterer,
          "Ship Design": val.shipDesign,
          Owners: val.owners,
          Reefers: val.reefers,
          "Built Year": val.builtYear,
          "Premium Class": val.premiumClass,
          "Validation Summary": val.message,
        };
      }
    );
    const header_broker = [
      "CP Date",
      "Vessel",
      "IMO",
      "TEU",
      "@14T",
      "Geared",
      "Beam",
      "Laycan",
      "Where",
      "Period",
      "Curr",
      "Rate",
      "Charterer",
      "Ship Design",
      "Owners",
      "Reefers",
      "Built Year",
      "Premium Class",
      "Validation Summary",
    ];
    if (uploadFile ==='Broker Availability List') {
      excelDownload("Invalid_Records_AvailibiltyList", modified_rows_availabilityList, header_availablilty);
    } else if (uploadFile === 'Alphaliner') {
      excelDownload("Invalid_Records_Alphaliner", modified_rows_alphaliner, header_alphaliener);
    } else if (uploadFile === 'Maersk Broker'){
      excelDownload("Invalid_Records_Broker", modified_rows_broker, header_broker);
    }
    
  };
  const onSubmitData = async () => {
    setIsUpdateButton(true);
    const invalidRecord = pageData.validationMessages.length;
    const resp = await postAPI(
      `Charter/UpdateCharterDetailsForUploadedRecords/${uploadFile}/${invalidRecord}`,
      updateData
    );
    closeValid();
    setIsUpdateButton(false);
    resp?.forEach((item: any) => {
      if (item.failedRecordCount >= 1) {
        if (item.message == "Updated Records") {
          toast.success(
            <div className="toast-success">
              <p className="header"> Record uploaded Successfully!</p>
              <p className="msg">
                <span>{item.failedRecordCount}</span> New commercial details
                replaced to the existing records.
              </p>
            </div>,
            {
              theme: "colored",
              icon: false,
              autoClose: 3000,
            }
          );
        } else if (item.message == "Invalid Records") {
          toast.error(
            <div className="toast-success">
              <p className="header"> Unsuccessful!</p>
              <p className="msg">
                <span>Upload Failed</span> for {item.failedRecordCount} invalid
                records
              </p>
            </div>,
            {
              theme: "colored",
              icon: false,
              autoClose: 4000,
            }
          );
        } else {
          toast.warn(
            <div className="toast-success">
              <p className="header"> Old data retained Successfully!!</p>
              <p className="msg">
                <span>{item.failedRecordCount}</span> Old commercial details
                retained to the list.
              </p>
            </div>,
            {
              theme: "colored",
              icon: false,
              autoClose: 4000,
            }
          );
        }
      }
    });
  };
  const columns: TableColumn<DataRow>[] = [
    {
      id:"vessel-name",
      name: "Vessel Name",
      selector: (row) => row.vesselName,
    },
    {
      id:"vessel-name-critera",
      name: "",
      selector: (row) => row.vesselName,
      grow: 2,
      cell: (row) => (
        <McCheckbox
          data-testid='check-box'
          onchange={rowSelectCritera}
          name={row.imo}
          label="New"
          checked={row.userAction}
          value={row.userAction}
        ></McCheckbox>
      ),
      reorder: true,
    },
    {
      id:"mismatch",
      name: "",
      selector: (row) => row.misMatched,
      grow: 3,
      cell: (row) => (
        <McCheckbox
          onchange={rowSelectCritera}
          name={row.imo}
          label="MisMatch"
          checked={row.misMatched}
          value={row.misMatched}
        ></McCheckbox>
      ),
      reorder: true,
    },
    {
      id:"source",
      name: "Source",
      selector: (row) => row.source,
    },
    {
      id:"last-updated-date",
      name: "Last Updated Date",
      grow: 3,
      selector: (row:any) =>
        row.lastUpdatedDate != null
          ? moment(row.lastUpdatedDate).format("DD-MMM-YYYY")
          : row.lastUpdatedDate,
    },
    {
      id:"charterer",
      name: "Charterer",
      selector: (row) => row.chartererName,
      cell: (row) => (
        <div
          className={`${
            row.chartererName != row.old?.[0]?.chartererName && "errordata"
          }`}
        >
          {row.chartererName}
        </div>
      ),
    },
    {
      id:"currency",
      name: "Currency",
      selector: (row) => row.currencyName,
      cell: (row) => (
        <div
          className={`${
            row.currencyName != row.old?.[0]?.currencyName && "errordata"
          }`}
        >
          {row.currencyName}
        </div>
      ),
    },
    {
      id:"rate",
      name: "Rate",
      selector: (row) => row.rate,
      cell: (row) => (
        <div className={`${row.rate != row.old?.[0]?.rate && "errordata"}`}>
          {row.rate}
        </div>
      ),
    },
    {
      id:"delivery",
      name: "Delivery",
      selector: (row) => row.delivery,
      cell: (row) => (
        <div
          className={`${row.delivery != row.old?.[0]?.delivery && "errordata"}`}
        >
          {row.delivery != null
            ? moment(row.delivery).format("DD-MMM-YYYY")
            : row.delivery}
        </div>
      ),
    },
    {
      id:"min",
      name: "Min",
      selector: (row) => row.min,
      cell: (row) => (
        <div className={`${row.min != row.old?.[0]?.min && "errordata"}`}>
          {row.min != null ? moment(row.min).format("DD-MMM-YYYY") : row.min}
        </div>
      ),
    },
    {
      id:"max",
      name: "Max",
      selector: (row) => row.max,
      cell: (row) => (
        <div className={`${row.max != row.old?.[0]?.max && "errordata"}`}>
          {row.max != null ? moment(row.max).format("DD-MMM-YYYY") : row.max}
        </div>
      ),
    },
    {
      id:"type",
      name: "Type",
      selector: (row) => row.charterTypeName,
      cell: (row) => (
        <div
          className={`${
            row.charterTypeName != row.old?.[0]?.charterTypeName && "errordata"
          }`}
        >
          {row.charterTypeName}
        </div>
      ),
    },
  ];
  const subcolumns: TableColumn<DataRow>[] = [
    {
      name: "Vessel Name",
      selector: (row) => row.vesselName,
    },
    {
      name: "",
      selector: (row) => row.vesselName,
      grow: 2,
      cell: (row) => (
        <McCheckbox name="disclaimer" label="New" value={row.imo}></McCheckbox>
      ),
      reorder: true,
    },
    {
      name: "",
      selector: (row) => row.vesselName,
      grow: 3,
      cell: (row) => (
        <McCheckbox name="disclaimer" label="pending" value={row.imo}></McCheckbox>
      ),
      reorder: true,
    },
    {
      name: "Source",
      selector: (row) => row.source,
    },
    {
      name: "Last Updated Date",
      grow: 3,
      selector: (row:any) =>
        row.lastUpdatedDate != null
          ? moment(row.lastUpdatedDate).format("DD-MMM-YYYY")
          : row.lastUpdatedDate,
    },
    {
      name: "Charterer",
      selector: (row) => row.chartererName,
    },
    {
      name: "Currency",
      selector: (row) => row.currencyName,
    },
    {
      name: "Rate",
      selector: (row) => row.rate,
    },
    {
      name: "Delivery",
      selector: (row) =>
        row.delivery != null
          ? moment(row.delivery).format("DD-MMM-YYYY")
          : row.delivery,
    },
    {
      name: "Min",
      selector: (row) =>
        row.min != null ? moment(row.min).format("DD-MMM-YYYY") : row.min,
    },
    {
      name: "Max",
      selector: (row) =>
        row.max != null ? moment(row.max).format("DD-MMM-YYYY") : row.max,
    },
    {
      name: "Type",
      selector: (row) => row.charterTypeName,
    },
  ];
  const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({
    data,
  }) => {
    return (
      <>
        <DataTable columns={subcolumns} data={data?.old} />
      </>
    );
  };
  return (
    <McModal
      data-testid="validate-modal"
      open={onOpen}
      heading={`${
        uploadFile != "Broker Availability List"
          ? "File Upload has duplicates, review and confirm"
          : ""
      }`}
      width="70vw"
      backdropcloseactiondisabled
      height="60vh"
    >
      <div className="modalValidation-section">
        <div className="subColoum">
          <div className="closeIcon"  data-testid="cancel" onClick={() => onCancel()}>
            <McIcon icon="times"></McIcon>
          </div>
          {uploadFile != "Broker Availability List" && (
            <>
              <div className="header-section">
                <div className="note-section">
                  {uploadFile} data uploaded file records already exist. Check
                  which data record you want to maintain against the same vessel
                  and confirm by clicking on retain old or save or update newly
                  uploaded data.{" "}
                  <span>
                    If any invalid data is uploaded, it will not be processed.
                  </span>
                </div>
              </div>

              <div className="table-section">
                <DataTable
                  columns={columns}
                  data={updateData}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  expandableRowExpanded={(row) => true}
                  // onColumnOrderChange={(cols) => console.log("c", cols)}
                />
              </div>
            </>
          )}
          <div className="invalid-section">
            <h5>Invalid Records</h5>
            <div className="data-section">
              <h5>Invalid Records</h5>
              <button className="download" data-testid="download-report" onClick={excelDownloadFile}>
                {" "}
                <span>
                  <McIcon icon="tray-arrow-down"></McIcon>
                </span>{" "}
                Download Report
              </button>
              {pageData?.validationMessages?.map((val: any, i: any) => (
                <p>
                  IMO: <span className="count">{val.imo}</span>, Validation
                  Summary : <span className="record">{val.message}</span>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      {pageData?.fileUploadUserActions?.length > 0 && (
        <>
          <McButton
            data-testid="update-button"
            slot="primaryAction"
            onClick={onSubmitData}
            variant="primary"
            disabled={isUpdateButton}
          >
            Update
          </McButton>
          <McButton
            data-testid="cancel"
            slot="secondaryAction"
            variant="secondary"
            onClick={onCancel}
            dialogAction="cancel"
          >
            Cancel
          </McButton>
        </>
      )}
    </McModal>
  );
};

export default ValidateModal;
