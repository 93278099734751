// React
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

//MDS
import "@maersk-global/mds-components-core";
import {
  McSelect,
  McCAccordionItem,
  McInput,
  McCheckboxGroup,
  McCheckbox,
  McButton,
  McIcon,
  McCTypeahead,
  McInputDate,
} from "@maersk-global/mds-react-wrapper";
import { McRadioGroup } from "@maersk-global/mds-react-wrapper/components-core";
import { McRadio } from "@maersk-global/mds-react-wrapper/components-core";

//Service
import { getAPI } from "../../services/ApiServices";
import Multiselect from "multiselect-react-dropdown";
//CSS
import "./CharterMarketFilters.scss";
interface IPROPS {
  closeFilterFunc?: (close: any) => void;
  onDataFunc?: any;
}
interface FormValues{
  statusList: any;
  imo: any;
  design: any;
  chartererId: any;
  charterTypeId: any;
  currencysItems: any;
  rate: any;
  redeliveryPortId: any;
  redeliveryRangeId: any;
  purchaseObligation: any;
  deliveryDate: any;
  estRedeliveryDate: any;
  teuFrom: any;
  teuTo: any;
  fixtureOption: any;
  purchaseOption: any;
  isLocked: any;
  source: any;
  managingOwner: any;
}
function CharterMarketFilters({ closeFilterFunc, onDataFunc }: IPROPS) {
  const [filters, setFilters] = useState<any>([]);
  const [purchaseObligationOptions, setPurchaseObligationOptions] = useState([]);
  const [mangOwner, setMangOwner] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [isIMOdata, setIsIMOdata] = useState([]);
  const [isDesignData, setIsDesignData] = useState([]);
  const [design, setdesign] = useState("");
  const [imoVal, setImoVal] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      statusList: "",
      imo: null,
      design: null,
      chartererId: "",
      charterTypeId: "",
      currencysItems: "",
      rate: "",
      redeliveryPortId: "",
      redeliveryRangeId: "",
      purchaseObligation: "",
      deliveryDate: "",
      estRedeliveryDate: "",
    },
  });
  let formData = {};

  const clearFilter = () => {
    reset();
    setdesign("");
    setImoVal("");
    if(onDataFunc){
      onDataFunc('clear',[]);
    }
    if(closeFilterFunc){
      closeFilterFunc(true);
    }
  };

  const onSubmit = (data:any) => {
    if(onDataFunc){
      onDataFunc('submit',data);
    }
    if(closeFilterFunc){
      closeFilterFunc(true);
    }
  };
  useEffect(() => {
    fetchFiltersForCharterMrkt();
    fetchManagingOwner();
    fetchSources();
  }, []);

  const fetchSources = async () => {
    const sourcesData = await getAPI(`Filter/GetAllSources`);
    setSourceData(sourcesData);
  };

  const fetchManagingOwner = async () => {
    const mangOwnerData = await getAPI(`Charter/GetManagingOwner`);
    setMangOwner(mangOwnerData);
  };

  const fetchFiltersForCharterMrkt = async () => {
    const filtersData = await getAPI(`Filter/GetCharterFilter`);
    if(filtersData){
      setFilters(filtersData);
      setPurchaseObligationOptions(filtersData?.purchaseObligationItems)
      setIsIMOdata(filtersData?.imoItems.map((a:any) => a.label));
      setIsDesignData(filtersData?.designItems);
    }
  };
  const closeFilter = () => {
    if(closeFilterFunc){
      closeFilterFunc(true);
    }
  };

  return (
    <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="filterModal">
        <div>
          <div className="input-mainSection">
            <div className="filter-section">
              {isDesignData?.length === 0 && isIMOdata?.length === 0 && sourceData?.length === 0 && mangOwner?.length === 0 &&  (
                <div className="loader-div">
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )} 
              <div className="header-section">
                <h5 className="header-text">Apply Filter</h5>
                <span className="filter-note">** Previously applied filters have been removed. Reapply your filters. **</span>
                <div className="closeIcon" data-testid="close-icon" onClick={closeFilter}>
                  <McIcon icon="times"></McIcon>
                </div>
              </div>
              <div className="filters-subsection">
                <McCAccordionItem
                  title="Status"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <Controller
                    name="statusList"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <McCheckboxGroup
                        name="statusList"
                        legend="Please select options"
                        change={(e) => onChange(e.detail)}
                        data-testid="status-list"
                      >
                        {filters?.charterStatus?.map((item:any, index:any) => (
                          <McCheckbox
                            key={index}
                            name="statusList"
                            value={item}
                            label={item}
                            data-testid={`status-list-items-${index}`}
                          ></McCheckbox>
                        ))}
                      </McCheckboxGroup>
                    )}
                  />
                </McCAccordionItem>
              </div>
              <div className="input-section">
                <div className="parent">
                  <div className="left">
                    <McInput
                      {...register("teuFrom")}
                      name="teuFrom"
                      width={50}
                      ariaPlaceholder="Type"
                      label="teuFrom"
                      data-testid="teu-from"
                    ></McInput>
                  </div>
                  <div className="right">
                    <McInput
                      {...register("teuTo")}
                      width={50}
                      name="teuTo"
                      ariaPlaceholder="Type"
                      label="teuTo"
                      data-testid="teu-to"
                    ></McInput>
                  </div>
                </div>
              </div>
              <div className="filters-subsection">
                <McCAccordionItem
                  title="Column Elements"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <div className="input-section">
                    <Controller
                      name="imo"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <McCTypeahead
                          ariaPlaceholder="IMO"
                          name="imo"
                          label=""
                          clearbutton
                          value={imoVal}
                          optionselected={(e) => {
                            onChange(e.detail.option.value);
                            setImoVal(e.detail.option.value);
                          }}
                          options={isIMOdata}
                          data-testid="imo"
                        ></McCTypeahead>
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <Controller
                      name="design"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          id="design-items"
                          placeholder="Design"
                          // name="design"
                          options={isDesignData}
                          onSelect={(e) => {
                            onChange(e);
                            setdesign(e.label);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <Controller
                      name="chartererId"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          id="charterer-id"
                          placeholder="Charterer"
                          // name="chartererId"
                          options={filters?.chartererItems}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <Controller
                      name="charterTypeId"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          id="charter-type-id"
                          placeholder="Charter Type"
                          // name="charterTypeId"
                          options={filters?.charterTypeItems}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <McSelect
                      {...register("currencysItems")}
                      name="currencysItems"
                      label=""
                      ariaPlaceholder="Currency"
                      options={filters?.currencysItems}
                      data-testid="currencys-items"
                    ></McSelect>
                  </div>
                  <div className="input-section">
                    <Controller
                      name="source"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          id="source"
                          placeholder="Source"
                          // name="source"
                          options={sourceData}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <Controller
                      name="managingOwner"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          id="managing-owner"
                          placeholder="Managing Owner"
                          // name="managingOwner"
                          options={filters?.managingOwnerItems}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                  </div>
                  <div className="input-section">
                    <McSelect
                      {...register("redeliveryPortId")}
                      name="redeliveryPortId"
                      label=""
                      ariaPlaceholder="Redelivery Port"
                      options={filters?.redeliveryPortItems}
                      data-testid="redelivery-port-id"
                    ></McSelect>
                  </div>
                  <div className="input-section">
                    <McSelect
                      {...register("redeliveryRangeId")}
                      name="redeliveryRangeId"
                      label=""
                      ariaPlaceholder="Redelivery Range"
                      options={filters?.redeliveryRangeItems}
                      data-testid="redelivery-range-id"
                    ></McSelect>
                  </div>
                  <div className="input-section">
                    <McSelect
                      {...register("purchaseObligation")}
                      name="purchaseObligation"
                      label=""
                      ariaPlaceholder="Purchase Obligation"
                      options={purchaseObligationOptions}
                      selectedindex={purchaseObligationOptions ? [0] : []}
                      data-testid="purchase-obligation"
                    ></McSelect>
                  </div>

                  <div className="input-section">
                    <McSelect
                      {...register("fixtureOption")}
                      name="fixtureOption"
                      label=""
                      ariaPlaceholder="Fixture Option"
                      options={purchaseObligationOptions}
                      selectedindex={purchaseObligationOptions ? [0] : []}
                      data-testid="fixture-option"
                    ></McSelect>
                  </div>

                  <div className="input-section">
                    <McSelect
                      {...register("purchaseOption")}
                      name="purchaseOption"
                      label=""
                      ariaPlaceholder="Purchase Option"
                      options={purchaseObligationOptions}
                      selectedindex={purchaseObligationOptions ? [0] : []}
                      data-testid="purchase-option"
                    ></McSelect>
                  </div>
                </McCAccordionItem>
              </div>
              <div className="filters-subsection">
                <McCAccordionItem
                  title="Date"
                  id="1"
                  expanded={true}
                  fit="medium"
                >
                  <div className="input-section">
                    <McInputDate
                      {...register("deliveryDate")}
                      name="deliveryDate"
                      label="Delivery Date"
                      ariaPlaceholder="yyyy/mm/dd"
                      data-testid="delivery-date"
                    ></McInputDate>
                  </div>
                  <div className="input-section">
                    <McInputDate
                      {...register("estRedeliveryDate")}
                      name="estRedeliveryDate"
                      label="Est. Redelivery Date"
                      ariaPlaceholder="yyyy/mm/dd"
                      data-testid="est-redelivery-date"
                    ></McInputDate>
                  </div>

                  <McRadioGroup
                    {...register("isLocked")}
                    name="isLocked"
                    legend="Source Status"
                    data-testid="isLocked"
                  >
                    <McRadio
                      name="isLocked"
                      value="true"
                      label="Locked"
                      data-testid="isLocked-true"
                    ></McRadio>
                    <McRadio
                      name="isLocked"
                      value="false"
                      label="UnLocked"
                      data-testid="isLocked-false"
                    ></McRadio>
                  </McRadioGroup>
                </McCAccordionItem>
              </div>
            
              <div className="footer-fixed">
                <div className="button-apply">
                  <div className="input-section">
                    <McButton
                      data-testid="apply-filter-button"
                      type="submit"
                      label="Apply filter"
                      width="full-width"
                      fit="small"
                    ></McButton>
                  </div>
                </div>
                <div className="button-apply">
                  <div className="input-section">
                    <button
                      data-testid="clear-filter"
                      className="reset-btn"
                      type="button"
                      onClick={() => {
                        clearFilter();
                      }}
                    >
                      Clear filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CharterMarketFilters;
