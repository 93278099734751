// React
import React, { useState, useEffect } from "react";

// Model
import { aisdetailsModel, aisdetails } from "./model";
//Components
import { getAPI } from "../../services/ApiServices";
import { McCAccordionItem } from "@maersk-global/mds-react-wrapper";
import { MdsTable } from "../../components/table/MdsTable";
import { AISColumns } from "../../pages/aisDetails/Columns";
import moment from "moment";

const AISDetails = ({ selectedRow, data }: any) => {
  const [aisdetails, setAISDetailsData] = React.useState<aisdetailsModel>();
  const [aisdetailsNestedData, setaisdetailsNestedData] =
    useState<aisdetails>();
  const [aisPortDetails, setAISPortDetails] = useState([]);

  const fetchAISDetails = async (data: any) => {
    setAISDetailsData(data);
    setaisdetailsNestedData(data?.locationServiceDetail?.vesselLocation);
    const data1 = data?.locationServiceDetail?.portCallsHistories?.sort(
      (a: any, b: any) => a.entryDate - b.entryDate
    );
    setAISPortDetails(data1);
  };

  useEffect(() => {
    fetchAISDetails(data);
  }, [data]);

  const columnSortHandler = (event: CustomEvent): void => {
    event.preventDefault();
  };

  return (
    <>
      <div>
        <div className="vsl-details-overview">
          <div className="row vsl-details-data">
            <div className="col">
              <label className="vsl-details-header">Vessel name</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-vessel-name"
              >
                {aisdetails?.vesselName}
              </div>
            </div>
            <div className="col">
              <label className="vsl-details-header">IMO No.</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-imo-no"
              >
                {aisdetails?.imoNumber}
              </div>
            </div>
            <div className="col ">
              <label className="vsl-details-header">Managing Owner</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-managing-owner"
              >
                {aisdetails?.managingOwner}
              </div>
            </div>
            <div className="col">
              <label className="vsl-details-header">Operator</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-operator"
              >
                {aisdetails?.operator}
              </div>
            </div>
            <div className="col">
              <label className="vsl-details-header ">Trade</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-trade"
              >
                {aisdetails?.trade}
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row vsl-details-data">
            <div className="col">
              <label className="vsl-details-header">Route</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-route"
              >
                {aisdetailsNestedData?.route}
              </div>
            </div>
            <div className="col">
              <label className="vsl-details-header">Rotation</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-rotation"
              >
                {aisdetailsNestedData?.duration}
              </div>
            </div>
            <div className="col">
              <label className="vsl-details-header">Previous Call</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-previous-call"
              >
                {aisdetailsNestedData?.previousCall}
              </div>
            </div>
            <div className="col">
              <label className="vsl-details-header">Destination</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-destination"
              >
                {aisdetailsNestedData?.destination}
              </div>
            </div>
            <div className="col">
              <label className="vsl-details-header">Target ETA</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-target-eta"
              >
                {aisdetailsNestedData?.targetETA}
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row vsl-details-data">
            <div className="col">
              <label className="vsl-details-header">Last Signal</label>
              <div
                className="vsl-details-value font-bold"
                data-testid="ais-last-signal"
              >
                {aisdetailsNestedData?.lastSignal !== null
                  ? moment(aisdetailsNestedData?.lastSignal).format(
                      "DD-MMM-YYYY HH:MM:SS"
                    )
                  : ""}
              </div>
            </div>
            <br />
          </div>
        </div>
        <br />
        <div className="div-liner"></div>
        <div className="port-details">
          <McCAccordionItem
            title="Estimation to reach and Port call details"
            id="1"
            expanded={true}
            fit="small"
          >
            <MdsTable
              aria-sort="descending"
              data={aisPortDetails?.sort(
                (a: any, b: any) => b.entryDate - a.entryDate
              )}
              columns={AISColumns}
              onSorting={columnSortHandler}
            />
          </McCAccordionItem>
        </div>
      </div>
      <br />
    </>
  );
};

export default AISDetails;
