import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./DetailsTab.scss";
import { McStepIndicator } from "@maersk-global/mds-react-wrapper/components-core";
import { McTab, McTabBar, McButton } from "@maersk-global/mds-react-wrapper";
import CharterRequest from "../../CharterRequest";
import VesselRequirements from "../VesselRequirements/VesselRequirements";
import { toast } from "react-toastify";
//Component
import VesselsList from "../VesselsList/VesselsList";
import DetailsFilter from "../DetailsFilter/DetailsFilter";
import { McIcon } from "@maersk-global/mds-react-wrapper/components-core";
import { McInput } from "@maersk-global/mds-react-wrapper";
import { getAPI } from "../../../services/CepApiServices";
import VesselCompare from "../VesselCompare/VesselCompare";
import SendIndicationModal from "../SendIndicationModal/SendIndicationModal";
import DirectIndication from "../SendIndicationModal/SendDirectIndication";
const DetailsTab = () => {
  const search = window.location.search;
  const history = useNavigate();

  const params = new URLSearchParams(search);
  const id = params.get("id");

  const [filters, setFilters] = useState([]);
  const [isCmpBtn, setIsCmpBtn] = useState(true);
  const [isSendIndicationBtn, setIsSendIndicationBtn] = useState(true);
  const [isSIRModal, setIsSIRModal] = useState(false);
  const [isDirectModal, setIsDirectModal] = useState(false);
  const [isCompareModal, setIsCompareModal] = useState(false);
  const [isFilterPanel, setIsFilterPanel] = useState(false);
  const [crDetailsAPI, setCRDetailsAPI] = useState(1);
  const [submitType, setSubmitType] = useState("submitIndication");
  const [imoList, setImoList] = useState([]);
  const [isCheckedTable, setIsCheckedTable] = useState(false);
  const [imoListCompare, setImoListCompare] = useState([]);
  const [isDirect, setIsDirect] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchVslNameText, setVslNameText] = useState<string>("");
  const [maintainStatus, setMaintainStatus] = useState("");
  const handleUpdateStatus = (status: any) => {
    setMaintainStatus(status);
  };
  const onFilterClose = (close: string) => {
    setIsFilterPanel(false);
    if (close == "save") {
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsFilterPanel(false);
    }
  };
  const onCRDetailsFilterClick = () => {
    setIsFilterPanel(!isFilterPanel);
  };

  const fetchFiltersForCRDetails = async (crNo: any) => {
    const filtersData = await getAPI(
      `CharterRequest/GetCharterRequestDetailFilter/` + crNo
    );
    setFilters(filtersData);
  };

  useEffect(() => {
    fetchFiltersForCRDetails(id);
  }, [isFilterPanel]);
  const compareVessel = () => {
    setIsCompareModal(true);
  };
  const sendIndicationModal = () => {
    setIsSIRModal(true);
    setSubmitType("submitIndication");
  };
  const sendDirectIndication = () => {
    if (isDirect) {
      toast.error(
        <div>
          Initiate Negotiation cannot be proceed for Accepted/Negototiation
          stage Charter request
        </div>,
        {
          theme: "colored",
          icon: false,
          autoClose: 5000,
        }
      );
    } else {
      setIsDirectModal(true);
    }
  };
  const directNegotiationFunc = (status) => {
    setIsDirect(status);
  };
  const closeDirectIndicationModal = () => {
    setIsDirectModal(false);
  };
  const filterAppliedFunc = (applied: any) => {
    setIsFilterApplied(applied);
  };
  const closeSendIndication = (val: any) => {
    if (val == "send") {
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsCheckedTable(true);
      setIsSendIndicationBtn(true);
      setIsCmpBtn(true);
      setImoList([]);
      toast.success(<div> Indication Request Sent</div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else if (val === "failed") {
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsCheckedTable(true);
      setIsSendIndicationBtn(true);
      setIsCmpBtn(true);
      setImoList([]);
      toast.error(<div> Indication Request Failed</div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
    setIsSIRModal(false);
  };
  const closeCompareModal = (type: any, data: any) => {
    setIsCompareModal(false);
    if (type === "submitCompare") {
      setIsSIRModal(true);
      setSubmitType("submitCompare");
      setImoListCompare(data);
    }
  };
  const handleSendIndicationBtn = (row: any) => {
    row?.find((e) => e.workflowStageId === 2)
      ? setIsSendIndicationBtn(true)
      : setIsSendIndicationBtn(false);
    row?.length === 0 && setIsSendIndicationBtn(true);
  };
  const callCompareImoFunc = (selectedRows: any) => {
    setIsCheckedTable(false);
    setImoList(selectedRows);
    handleSendIndicationBtn(selectedRows);
    if (selectedRows.length <= 10 && selectedRows.length >= 2) {
      setIsCmpBtn(false);
    } else {
      setIsCmpBtn(true);
      if (selectedRows.length > 10) {
        toast.error(<div> Please select only 10 vessel to compare</div>, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      }
    }
  };
  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      setVslNameText(e.target.value);
    }
  };
  const onSearchVslNameCancel = () => {
    setVslNameText("");
  };
  return (
    <div className="charter-requestMain">
      <div>Charter Request Details</div>
      <div className="button-group">
        <div className="buttons">
          <McButton
            data-testid="sendIndication"
            label="Send Indication Request"
            disabled={isSendIndicationBtn}
            onclick={() => {
              sendIndicationModal();
            }}
            fit="small"
          ></McButton>
        </div>
        {isCmpBtn && !isSendIndicationBtn && (
          <div className="buttons">
            <McButton
              data-testid="sendIndication"
              label="Initiate Negotiation"
              disabled={isSendIndicationBtn}
              onclick={() => {
                sendDirectIndication();
              }}
              fit="small"
            ></McButton>
          </div>
        )}
        <div className="buttons">
          <McButton
            data-testid="compare"
            label="Compare"
            disabled={isCmpBtn}
            onClick={() => compareVessel()}
            fit="small"
          ></McButton>
        </div>
      </div>
      {isSIRModal && (
        <SendIndicationModal
          detailsId={id}
          isSIRModal={isSIRModal}
          vessellist={
            submitType == "submitIndication" ? imoList : imoListCompare
          }
          closeFunc={closeSendIndication}
        ></SendIndicationModal>
      )}
      {isCompareModal && (
        <VesselCompare
          isCompareModal={isCompareModal}
          closeFunc={closeCompareModal}
          compareDataValue={imoList}
        ></VesselCompare>
      )}
      {isDirectModal && (
        <DirectIndication
          onLoadData={imoList}
          isSIRModal={isDirectModal}
          charterId={id}
          closeFunc={closeDirectIndicationModal}
          extendContract={false}
        />
      )}
      <div className="cr-rectangle">
        <McTabBar currentindex={0}>
          <McTab
            className="custom-height"
            slot="tab"
            label="Vessel Requirements"
          ></McTab>
          <div className="tab-row tab-section" slot="panel">
            <VesselRequirements crId={id} />
          </div>
        </McTabBar>
      </div>

      <div className="table-label">
        Select vessels to compare
        <div className="sub-section-cr">
          <div className="col-md-4">
            <div className="global-search-cr">
              <McInput
                className="search-box"
                icon="magnifying-glass"
                iconposition="left"
                label=""
                ariaPlaceholder="Search Vessel Name"
                fit="medium"
                onChange={(e: any) => setVslNameText(e.target.value)}
                value={searchVslNameText}
                onKeyDown={keyDown}
              ></McInput>
              <div
                className="closeIcon"
                onClick={() => onSearchVslNameCancel()}
                data-testid="close-icon"
              >
                <McIcon icon="times"></McIcon>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <button
              onClick={onCRDetailsFilterClick}
              data-testid="filter-button"
              className={`filter-button ${
                maintainStatus === "Completed" ? "no-filter" : ""
              }`}
            >
              <span>
                <McIcon icon="sliders-horizontal"></McIcon>
              </span>
              {isFilterApplied && <span className="heighlight"></span>}
              <span>Filter</span>
            </button>
          </div>
          {isFilterPanel && (
            <DetailsFilter
              closeFilterFunc={onFilterClose}
              filterDataCalled={filters}
              crId={id}
            />
          )}
        </div>
      </div>
      <div style={{ paddingTop: "15px" }}>
        {/* <div className="checkbox-table"> */}

        <VesselsList
          compareImoFunc={callCompareImoFunc}
          loadData={crDetailsAPI}
          crId={id}
          filterApplied={filterAppliedFunc}
          clearChecked={isCheckedTable}
          isDirectNegotiation={directNegotiationFunc}
          resetCheckBox={() => {
            setIsCheckedTable(false);
          }}
          searchVesselNameText={searchVslNameText}
          handleUpdateStatus={handleUpdateStatus}
        />

        {/* </div> */}
      </div>
    </div>
  );
};
export default DetailsTab;
