export const RejectMail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#c00"
    >
      <path
        d="M1 5C1 4.44772 1.44772 4 2 4H16C16.5523 4 17 4.44771 17 5V8H16V5.64603L10.9548 9.77396C10.7761 9.92014 10.5524 10 10.3215 10H7.67848C7.44764 10 7.22391 9.92014 7.04524 9.77396L2 5.64603V15H8V16H2C1.44772 16 1 15.5523 1 15V5ZM2.78959 5L7.67848 9H10.3215L15.2104 5H2.78959ZM14.5 11C12.567 11 11 12.567 11 14.5C11 16.433 12.567 18 14.5 18C16.433 18 18 16.433 18 14.5C18 12.567 16.433 11 14.5 11ZM10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5ZM13.7929 14.5L12.6464 13.3536L13.3536 12.6464L14.5 13.7929L15.6464 12.6464L16.3536 13.3536L15.2071 14.5L16.3536 15.6464L15.6464 16.3536L14.5 15.2071L13.3536 16.3535L12.6465 15.6464L13.7929 14.5Z"
        fill="#c00"
      />
    </svg>
  );
};
