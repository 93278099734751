import React, { useState, useEffect } from "react";
import { downloadReportDocuments, getAPI } from "../../../../services/CepApiServices";
import { McIcon, McLoadingIndicator } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
    requestedAdditionalDoc?: any;
}
const RequestDocumentVO = ({requestedAdditionalDoc}: IPROPS) => {
    const [contentLoader, setContentLoader] = useState(true);

    const downloadDocument = async (fileName: any, categoryName: any) => {
        await downloadReportDocuments(`DocumentLibrary/DownlaodDocument/${fileName}/${categoryName}`)
        .then((data =>
            data!.blob()
        ))
        .then((b) => {
            const url = window.URL.createObjectURL(b);
            var a = document.createElement("a");
            var name = fileName;
            document.body.appendChild(a);
            a.href = url;
            a.download = `${name}`;
            a.click()
            window.URL.revokeObjectURL(url);
        })
        .catch(() => {
            console.log("Could not download the file");
        });
    }
    useEffect(()=>{
        setContentLoader(false);
    }, [requestedAdditionalDoc]);
    return(
        <div>
            <div className="col-md-12">
                <div className="select-documents">
                <div className="header-div">
                    <div className="left-content">
                        <span className="headingIcon">
                            <McIcon icon="file"></McIcon>
                        </span>
                        <span className="icon-text">Requested Documents</span>
                    </div>
                </div>
                <div className="body-div">
                    <div className="row">
                            <div className="col-md-12">
                                {!contentLoader && requestedAdditionalDoc?.length === 0 && 
                                <div className="no-documents" data-testid="no-files">
                                    <span>
                                    No Files Found
                                    </span>
                                </div>}
                            </div>
                        {!contentLoader && requestedAdditionalDoc.length > 0 && requestedAdditionalDoc?.map((data: any) => (
                        <div className="col-md-4">
                            <div className="doc-select-div vo-documents">
                            <span className="document-name" >{data?.documentName}</span>
                            <span className="icon-div" data-testid="downloadDocument-rdvo" onClick={() => {downloadDocument(data?.documentName, "Other Document")}}>
                                <McIcon icon="tray-arrow-down"></McIcon>
                            </span>
                            </div>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default RequestDocumentVO;