import React from "react";
import DataTable from "react-data-table-component";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import "./DocumentVerificationTable.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IconPdfDL } from "../../../components/Icons/pdfIcon";
import { DocumentVerificationModal } from "./DocumentVerificationModal";
import { TableColumn } from "react-data-table-component";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  tableData: any;
  isTableLoader?: any;
  isDocumentListChanges?: any;
}
const DocumentVerificationTable = ({
  tableData,
  isTableLoader,
  isDocumentListChanges,
}: IPROPS) => {
  const history = useNavigate();
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const tableRowClick = (row: any) => {
    history(
      `/DocumentVerificationPage?id=${row.documentId}&name=${row.documentName}`
    );
  };
  const negotiantionStatus = (statusId: any, statusName: any) => {
    switch (statusId) {
      case 60:
        return (
          <span className="status-success status-label">{statusName}</span>
        );
      case 62:
        return <span className="status-sent status-label">{statusName}</span>;
      default:
        return <span className="status-label">{statusName}</span>;
    }
  };
  const documentNameWithIcon = (docName: any) => {
    const ext = docName.substr(docName?.lastIndexOf(".") + 1);
    return (
      <span>
        <span style={{ paddingRight: "8px" }}>
          <IconPdfDL nameOfDoc={ext} />
        </span>
        <span>{docName}</span>
      </span>
    );
  };
  const columns: TableColumn<DocumentVerificationModal>[] = [
    {
      name: "Document Name",
      selector: (row) =>
        row.documentName !== null ? row.documentName.toLowerCase() : "",
      cell: (row) => (
        <div
          onClick={() => {
            tableRowClick(row);
          }}
        >
          {documentNameWithIcon(row.documentName)}
        </div>
      ),
      sortable: true,
      width: "25%",
    },
    {
      name: "Document Category",
      selector: (row) => row.documentCategoryName,
      sortable: true,
      width: "15%",
    },
    {
      name: "Uploaded By",
      selector: (row) =>
        row.createdBy !== null ? row.createdBy.toLowerCase() : "",
      cell: (row) => (row.createdBy !== null ? row.createdBy : ""),
      sortable: true,
      width: "15%",
    },
    {
      name: "Uploaded On",
      selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
      cell: (row) => moment(row.createdOn).format("DD-MMM-YYYY"),
      sortable: true,
      width: "10%",
    },
    {
      name: "Reviewed By",
      selector: (row) =>
        row.reviewedBy !== null ? row.reviewedBy.toLowerCase() : "",
      cell: (row) => (row.reviewedBy !== null ? row.reviewedBy : ""),
      sortable: true,
      width: "15%",
    },
    {
      name: "Reviewed On",
      selector: (row) => (row.reviewedOn ? row.reviewedOn : ""),
      cell: (row) =>
        row.reviewedOn ? moment(row.reviewedOn).format("DD-MMM-YYYY") : "",
      sortable: true,
      width: "10%",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => negotiantionStatus(row.statusId, row.status),
      sortable: true,
      width: "10%",
    },
  ];

  return (
    <div className="Document-Verification-Landing-Page-Table ir-table-main table-margin2" data-testid="document-verification-table">
      <DataTable
        columns={columns}
        data={tableData}
        progressPending={isTableLoader}
        progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        onRowClicked={tableRowClick}
        clearSelectedRows={isDocumentListChanges}
      />
    </div>
  );
};

export default DocumentVerificationTable;
