import { McButton, McIcon, McLoadingIndicator } from "@maersk-global/mds-react-wrapper";
import React, { useState, useEffect } from "react";
import { downloadReportDocuments, getAPI, postAPI } from "../../../../services/CepApiServices";
import { toast } from "react-toastify";

interface IPROPS {
    indicationId?: any;
    accessType?: any;
    getAllDocumentFunc?: any;
    indicationDocs?: any;
}
const RequestDocumentCM = ({indicationId, accessType, getAllDocumentFunc, indicationDocs}: IPROPS) => {
    const [initialOtherDocs, setInitialOtherDocs] = useState<any>([]);
    const [additionalDocuments, setAdditionalDocuments] = useState<any>([]);
    const [checkeddoc, setCheckeddoc] = useState<any>([]);
    const [contentLoader, setContentLoader] = useState(true);
    const [isSubmitButton, setIsSubmitButton] = useState(true);
    const [isWriteAccess, setIsWriteAccess] = useState(false);
    const getAllDocuments = async() => {
        const allDocuments = await getAPI(`DocumentLibrary/GetAllDocuments`);
        if(allDocuments){
            setInitialOtherDocs(allDocuments.filter((document) => document.documentCategoryId === 68));
        } 
    }
    useEffect(()=> {
        const requestedDocumentIds = new Set(indicationDocs.map((doc) => doc.documentId));
        const filteredDocuments = initialOtherDocs.map((document) => ({
            ...document,
            status: requestedDocumentIds.has(document.documentId) ? "requested" : "avail",
            }));
        setAdditionalDocuments(filteredDocuments);
        setContentLoader(false);
    }, [initialOtherDocs, indicationDocs])

    const handleSubmit = async () => {
        setIsSubmitButton(true);
        const resp = await postAPI(
            `IndicationRequest/SaveIndicationDocumentsFromNegotiation?indicationId=${indicationId}`,
            checkeddoc
        );
        if(resp === true){
            notify("Document request submitted successfully", true)
            setContentLoader(true);
            getAllDocumentFunc();
            setCheckeddoc([]);
        }else{
            notify("Document request failed", false)
        }
    }
    const handleDocumentSelect = (documentId:any, status: any) => {
        if(status === "avail"){
            setCheckeddoc((prevCheckedDocuments:any) => {
                if (prevCheckedDocuments.includes(documentId)) {
                    return prevCheckedDocuments.filter((id) => id !== documentId);
                } else {
                    return [...prevCheckedDocuments, documentId];
                }
            });
        }
    }
    const downloadDocument = async (fileName: any, categoryName: any) => {
        await downloadReportDocuments(`DocumentLibrary/DownlaodDocument/${fileName}/${categoryName}`)
        .then((data =>
            data!.blob()
        ))
        .then((b) => {
            const url = window.URL.createObjectURL(b);
            var a = document.createElement("a");
            var name = fileName;
            document.body.appendChild(a);
            a.href = url;
            a.download = `${name}`;
            a.click()
            window.URL.revokeObjectURL(url);
        })
        .catch(() => {
            console.log("Could not download the file");
        });
    }
    const notify = (msg: any, flag: any) => {
        if (flag) {
            toast.success(msg, {
            theme: "colored",
            icon: false,
            });
        } else {
            toast.error(msg, {
            theme: "colored",
            icon: false,
            });
        }
    };
    useEffect(()=>{
        if(checkeddoc.length === 0){
            setIsSubmitButton(true);
        }else{
            setIsSubmitButton(false);
        }
    }, [checkeddoc])
    useEffect(()=>{
        setIsWriteAccess(accessType);
        getAllDocuments();
    }, []);
    return (
        <div>
            <div className="col-md-12">
                <div className="select-documents">
                <div className="header-div">
                    <div className="left-content">
                        <span className="headingIcon">
                            <McIcon icon="file"></McIcon>
                        </span>
                        <span className="icon-text">Request for Documents</span>
                    </div>
                    {isWriteAccess && (
                        <div className="right-content">
                            <McButton fit="small" className="submit-document" onClick={handleSubmit} data-testid="selected-document" disabled={isSubmitButton}>Submit Document</McButton>
                        </div>
                    )}
                </div>
                <div className="body-div">
                    <div className="row">
                        <div className="col-md-12">
                            {!contentLoader && additionalDocuments?.length === 0 && 
                            <div className="no-documents" data-testid="no-files">
                                <span>
                                No Files Found
                                </span>
                            </div>}
                        </div>
                        {!contentLoader && additionalDocuments?.map((data: any) => (
                        <div className="col-md-4">
                            <div className={`doc-select-div ${!isWriteAccess ? "no-access" : data?.status === "requested" ? "unactive-doc" : data?.status === "avail" && checkeddoc.includes(data?.documentId) ? "active-doc" : ""}`}>
                            <span className="document-name" data-testid='document-select' onClick={() => handleDocumentSelect(data.documentId, data.status)}>{data?.documentName}</span>
                            <span className="icon-div" data-testid='download-document' onClick={() => {downloadDocument(data?.documentName, data?.documentCategoryName)}}>
                                <McIcon icon="tray-arrow-down"></McIcon>
                            </span>
                            </div>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequestDocumentCM;