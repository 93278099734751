import { CharterRequestModel } from "./model";
import { TableColumn } from "react-data-table-component";
import moment from "moment";

const negotiantionStatus = (status: any) => {
  switch (status) {
    case "New":
      return <span className="status-success status-label">{status}</span>;
    case "Initiated":
      return <span className="status-warning status-label">{status}</span>;
    case "Canceled":
      return <span className="status-danger status-label">{status}</span>;
    case "InProgress":
      return <span className="status-negotiation status-label">{status}</span>;
    case "Completed":
      return <span className="status-accepted status-label">{status}</span>;
  }
};

const columns: TableColumn<CharterRequestModel>[] = [
  {
    name: "Status",
    selector: (row) => row.requestStatus,
    cell: (row) => negotiantionStatus(row.requestStatus),
    sortable: true,
    width: "11%",
  },
  {
    name: "Current Vessel",
    selector: (row) =>
      row.requestName !== null ? row.requestName.toLowerCase() : "",
    cell: (row) => (row.requestName !== null ? row.requestName : ""),
    sortable: true,
    width: "16%",
  },
  {
    name: "Requested By",
    selector: (row) => (row.requestedBy !== null ? row.requestedBy : ""),
    sortable: true,
    width: "12%",
  },
  {
    name: "Date",
    selector: (row) => row.startDate,
    sortable: true,
    format: (row) =>
      row.startDate !== null ? moment(row.startDate).format("DD-MMM-YYYY") : "",
    width: "10%",
  },
  {
    name: "Gear",
    selector: (row) => (row.gearValue !== null ? row.gearValue : ""),
    sortable: true,
    width: "10%",
  },
  {
    name: "Phase In Port",
    selector: (row) => (row.startPort !== null ? row.startPort : ""),
    sortable: true,
    width: "15%",
  },
  {
    name: "TEU",
    selector: (row) => (row.teu !== null ? row.teu : ""),
    sortable: true,
    width: "8%",
  },
  {
    name: "Plugs",
    selector: (row) => (row.reeferPlugs !== null ? row.reeferPlugs : ""),
    sortable: true,
    width: "8%",
  },
  {
    name: "Total Days",
    selector: (row) => (row.totalDays !== null ? row.totalDays : ""),
    sortable: true,
    width: "5%",
  },
];

export default columns;
