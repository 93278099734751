// import { HttpClientHandler } from '../httpHandler/HttpClientHandler';
import { getToken } from '../utils/token';
import { protectedResources } from "../authConfig";
export const VesselDetailsService = async(imoNumber:any) => {
    try
    {
            const accessToken = await getToken(protectedResources.apiTodoList.scopes.read);    
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;
        
            headers.append("Authorization", bearer);
        
            const options = {
                method: "GET",
                headers: headers
            };
        
            return fetch(protectedResources.apiTodoList.endpoint1 + `${imoNumber}`, options)
                .then(response => response.json())
                .catch(error => console.log(error));
    }
    catch(error) {
        console.log('error is ', error);
    }

}