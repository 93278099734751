import React from "react";
import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  onMismatchPopupOpen?: boolean;
  onMismatchedPopupClose?: any;
  data?: any;
}

const MismatchPopup = ({
  onMismatchPopupOpen,
  onMismatchedPopupClose,
  data,
}: IPROPS) => {
  const closePopupFunc = () => {
    onMismatchedPopupClose("cancel", null);
  };

  const onProceedStatusChngFunc = () => {
    onMismatchedPopupClose("proceed", data);
  };
  return (
    <div>
      <McModal
        width="420px"
        height="180px"
        open={onMismatchPopupOpen}
        heading=""
      >
        <p>Mismatch status will be changed</p>
        <McButton
          // focusendanchor
          slot="secondaryAction"
          variant="secondary"
          // appearance="neutral"
          click={closePopupFunc}
          dialogAction="cancel"
          data-testid="cancel"
        >
          Cancel
        </McButton>
        <McButton
          // focusstartanchor
          slot="primaryAction"
          variant="primary"
          click={onProceedStatusChngFunc}
          dialogAction="ok"
          data-testid="continue"
        >
          Continue
        </McButton>
      </McModal>
    </div>
  );
};

export default MismatchPopup;
