import React, { useEffect, useState } from "react";
import "./VesselSupplyDetails.scss";
import { useForm, Controller } from "react-hook-form";
import {
  McIcon,
  McButton,
  McCheckbox,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import { toast } from "react-toastify";
import { getAPI, postAPI } from "../../../services/CepApiServices";

interface IPROPS {
  IndicationId?:any;
  renderFunc?:any;
  maindata?:any;
  accessType?:any;
  stageType?:any;
}

const VesselSupplyDetails = ({IndicationId, renderFunc, maindata, accessType, stageType}:IPROPS) => {
  const {
    register,
    handleSubmit,
    control,
    formState
  } = useForm({defaultValues: maindata});
  const isWriteAccess = accessType === "Write" ? stageType ? false : true : false;
  const onSubmit = async (data: any) => {
    const payLoad= {
      shipbroker: data.shipbroker? data.shipbroker : maindata?.shipbroker,
      placeDate: data.placeDate? data.placeDate : maindata?.placeDate,
      ownerContactInfo: data.ownerContactInfo? data.ownerContactInfo : maindata?.ownerContactInfo,
      chartererContactInfo: data.chartererContactInfo? data.chartererContactInfo : maindata?.chartererContactInfo,
      vesselName: data.vesselName? data.vesselName : maindata?.vesselName,
      callSign: data.callSign? data.callSign : maindata?.callSign,
      grt: data.grt? data.grt : maindata?.grt,
      dwt: data.dwt? data.dwt : maindata?.dwt,
      teu: data.teu? data.teu : maindata?.teu,
      class: data.class? data.class : maindata?.class,
      flag: data.flag? data.flag : maindata?.flag,
      serviceSpeed: data.serviceSpeed? data.serviceSpeed : maindata?.serviceSpeed,
      fuelConsumption: data.fuelConsumption? data.fuelConsumption : maindata?.fuelConsumption,
      fuelType: data.fuelType? data.fuelType : maindata?.fuelType,
      maximumBunkerCapacity: data.maximumBunkerCapacity? data.maximumBunkerCapacity : maindata?.maximumBunkerCapacity,
      bunkerPriceOnDelivery: data.bunkerPriceOnDelivery? data.bunkerPriceOnDelivery : maindata?.bunkerPriceOnDelivery,
      bunkerPriceOnReDelivery: data.bunkerPriceOnReDelivery? data.bunkerPriceOnReDelivery : maindata?.bunkerPriceOnReDelivery,
      deliveryPlace: data.deliveryPlace? data.deliveryPlace : maindata?.deliveryPlace,
      earliestDeliveryDate: data.earliestDeliveryDate? data.earliestDeliveryDate : maindata?.earliestDeliveryDate,
      latestDeliveryDate: data.latestDeliveryDate? data.latestDeliveryDate : maindata?.latestDeliveryDate,
      redeliveryPlace: data.redeliveryPlace? data.redeliveryPlace : maindata?.redeliveryPlace,
      tadingLimits: data.tadingLimits? data.tadingLimits : maindata?.tadingLimits,
      charterOptionPeriod: data.charterOptionPeriod? data.charterOptionPeriod : maindata?.charterOptionPeriod,
      optionPeriod: data.optionPeriod? data.optionPeriod : maindata?.optionPeriod,
      hireRate: data.hireRate? data.hireRate : maindata?.hireRate,
      hazardousGoodsAllowedQuantity: data.hazardousGoodsAllowedQuantity? data.hazardousGoodsAllowedQuantity : maindata?.hazardousGoodsAllowedQuantity,
      vesselInsuredValue: data.vesselInsuredValue? data.vesselInsuredValue : maindata?.vesselInsuredValue,
      superCargoDailyRate: data.superCargoDailyRate? data.superCargoDailyRate : maindata?.superCargoDailyRate,
      victuallingRate: data.victuallingRate? data.victuallingRate : maindata?.victuallingRate,
      ownersPLClubName: data.ownersPLClubName? data.ownersPLClubName : maindata?.ownersPLClubName,
      charterersPLClubName: data.charterersPLClubName? data.charterersPLClubName : maindata?.charterersPLClubName,
      charteresMaximumClaim: data.charteresMaximumClaim? data.charteresMaximumClaim : maindata?.charteresMaximumClaim,
      adjustedGeneralAverage: data.adjustedGeneralAverage? data.adjustedGeneralAverage : maindata?.adjustedGeneralAverage,
      lawAndArbitration: data.lawAndArbitration? data.lawAndArbitration : maindata?.lawAndArbitration,
      brokerageCommission: data.brokerageCommission? data.brokerageCommission : maindata?.brokerageCommission,
      additionalClauseNumbers: data.additionalClauseNumbers? data.additionalClauseNumbers : maindata?.additionalClauseNumbers,
    }
    // const payLoad= data;
    const response = await postAPI(`InitialOffer/SaveVesselContractDetails?indicationId=${IndicationId}`, payLoad);
    if(!response.status){
      renderFunc();
      toast.success(`Vessel Supply Details submitted successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }else{
      toast.error(`Vessel Supply Details submission failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
  };
  return (
    <div className="VesselDetailsCapture">
      <div className="VesselDetailsCapture__header">
        <div className="left-content">
          <span className="icon">
            {" "}
            <McIcon icon="info-circle" />
          </span>
          <span className="text">
            Fill out all the vessel supply details.
          </span>
        </div>
      </div>
      <form
        data-testid="form"
        className="charterMarketEditModal"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="VesselDetailsCapture__body">
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                <label className="label">Shipbroker</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.shipbroker}
                  {...register("shipbroker")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Place and date</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.placeDate}
                  {...register("placeDate")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Owners/Disponent Owners & Place of Business, Telephone, Telex
                  and Telefax Number
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.ownerContactInfo}
                  {...register("ownerContactInfo")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Charterers & Place of Business, Telephone, Telex and Telefax
                  Number
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.chartererContactInfo}
                  {...register("chartererContactInfo")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Vessel name</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.vesselName}
                  {...register("vesselName")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Call Sign/Telex Number</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.callSign}
                  {...register("callSign")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">GRT/NRT</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.grt}
                  {...register("grt")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">DWT on Summer Freeboard</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.dwt}
                  {...register("dwt")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">TEU Capacity (Maximum)</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.teu}
                  {...register("teu")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Class (Cl. 5)</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.class}
                  {...register("class")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Flag</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.flag}
                  {...register("flag")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Service Speed (See Part III)</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.serviceSpeed}
                  {...register("serviceSpeed")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Fuel Consumption (See Part III)</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.fuelConsumption}
                  {...register("fuelConsumption")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Type(s) of Fuel(s) (Cl. 12 (d))</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.fuelType}
                  {...register("fuelType")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Maximum Bunker Capacity</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.maximumBunkerCapacity}
                  {...register("maximumBunkerCapacity")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Bunkers/Price on Delivery (Min. - Max.) (Cl. 12 (a) and (c))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.bunkerPriceOnDelivery}
                  {...register("bunkerPriceOnDelivery")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Bunkers/Price on Redelivery (Min. - Max.) (Cl. 12 (a) and (c))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.bunkerPriceOnReDelivery}
                  {...register("bunkerPriceOnReDelivery")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Place of Delivery (Cl. 1 (b))</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.deliveryPlace}
                  {...register("deliveryPlace")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Earliest Date of Delivery (local time) (Cl. 1 (b))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.earliestDeliveryDate}
                  {...register("earliestDeliveryDate")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Latest Date of Delivery (local time) (Cl. 1 (b))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.latestDeliveryDate}
                  {...register("latestDeliveryDate")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">Place of Redelivery (Cl. 6 (m))</label>
                <input
                  className="inputText"
                  defaultValue={maindata?.redeliveryPlace}
                  {...register("redeliveryPlace")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Trading Limits (Cl. 3 and Cl. 5 (c))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.tadingLimits}
                  {...register("tadingLimits")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Period of Charter and Options if any (Cl. 1 (a), Cl. 6 (m) and
                  Cl. 7 (f))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.charterOptionPeriod}
                  {...register("charterOptionPeriod")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  State number of Days Options have to be declared after
                  commencement of Charter Period (Cl. 1 (a))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.optionPeriod}
                  {...register("optionPeriod")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Rate of Hire per Day and to whom payable (Cl. 1 (a), Cl. 7 (a)
                  and (b)
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.hireRate}
                  {...register("hireRate")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Quantity of Hazardous Goods allowed (Cl. 4 (b))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.hazardousGoodsAllowedQuantity}
                  {...register("hazardousGoodsAllowedQuantity")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Insured Value of Vessel (Cl. 18 (a))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.vesselInsuredValue}
                  {...register("vesselInsuredValue")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  "Daily Rate for Supercargo (Cl. 13 (h))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.superCargoDailyRate}
                  {...register("superCargoDailyRate")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Victualling Rate per Meal for other Charterers Servants etc.
                  (Cl. 13 (j))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.victuallingRate}
                  {...register("victuallingRate")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  "Name of Owners P & I Club (Cl. 18 (b))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.ownersPLClubName}
                  {...register("ownersPLClubName")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Name of Charterers P & I Club (Cl. 18 (b))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.charterersPLClubName}
                  {...register("charterersPLClubName")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Charterers maximum Claim settlement authority (Cl. 16 (h))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.charteresMaximumClaim}
                  {...register("charteresMaximumClaim")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  General Average to be adjusted at (Cl. 14 (c))
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.adjustedGeneralAverage}
                  {...register("adjustedGeneralAverage")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Law and Arbitration (state a, b, or c of Cl. 20, as agreed; if
                  c agreed also state Place of Arbitration) (Cl. 20)
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.lawAndArbitration}
                  {...register("lawAndArbitration")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Brokerage Commission and to whom payable (Cl. 21)
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.brokerageCommission}
                  {...register("brokerageCommission")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-div">
                {" "}
                <label className="label">
                  Number of Additional Clauses covering special Provisions
                </label>
                <input
                  className="inputText"
                  defaultValue={maindata?.additionalClauseNumbers}
                  {...register("additionalClauseNumbers")}
                />
              </div>
            </div>
          </div>
          <div className="submitbtn">
            {isWriteAccess && (
            <McButton label="Save and Submit" variant="primary"
            type="submit"></McButton>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default VesselSupplyDetails;
