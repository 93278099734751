// React
import React, { useState, useEffect } from "react";

// Service
import { getAPI } from "../../../services/CepApiServices";
import "./VesselRequirements.scss";
import { fetchDateFormat } from "../../../utils/customDateTime";
interface IPROPS {
  crId?: any;
}
const VesselRequirements = (crId: IPROPS) => {
  const [vslreqdetails, setVslReqDetailsData] = useState<any>([]);

  const fetchVesselRequirements = async (crId: any) => {
    const crSearchRes = await getAPI(`CharterRequest/Detail/` + crId);
    setVslReqDetailsData(crSearchRes);
  };
  useEffect(() => {
    fetchVesselRequirements(crId.crId);
  }, []);

  return (
    <div>
      <div className="vsl-details-overview">
        <div className="row vsl-details-data">
          <div className="col">
            <label className="cr-vsl-details-header">Request Name</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.requestName}
            </div>
          </div>
          <div className="col">
            <label className="cr-vsl-details-header">Status</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.requestStatus}
            </div>
          </div>
          <div className="col ">
            <label className="cr-vsl-details-header">Date</label>
            <div className="vsl-details-value font-bold">
              {fetchDateFormat(vslreqdetails?.startDate)}
            </div>
          </div>
          <div className="col">
            <label className="cr-vsl-details-header ">Phase in Port</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.startPort}
            </div>
          </div>
          <div className="col">
            <label className="cr-vsl-details-header">TEU</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.teu}
            </div>
          </div>
        </div>

        <div className="row vsl-details-data">
          <div className="col">
            <label className="cr-vsl-details-header">Request Deleted From Source</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.isRequestDeletedFromSource ? "Yes" : "No"}
            </div>
          </div>
          <div className="col">
            <label className="cr-vsl-details-header">Reefer Plugs</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.reeferPlugs}
            </div>
          </div>
          <div className="col">
            <label className="cr-vsl-details-header">Gear</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.gearValue}
            </div>
          </div>

          <div className="col">
            <label className="cr-vsl-details-header">Total Days Deployed</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.totalDays}
            </div>
          </div>

          <div className="col">
            <label className="cr-vsl-details-header">Requested By</label>
            <div className="vsl-details-value font-bold">
              {vslreqdetails?.requestedBy}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VesselRequirements;
