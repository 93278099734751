import DocumentLibraryTable from "./DocumentLibraryTable/DocumentLibraryTable";
import "./DocumentLibraryCm.scss";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import UploadDocumentLibrary from "./UploadDocumentLibrary";
import { useEffect, useRef, useState } from "react";
import { fileApiAxios, getAPI } from "../services/CepApiServices";
import { getUserRole } from "../utils/token";
import FilterComponent from "../components/FilterComponent/FilterComponent";
const DocumentLibraryCM = () => {
  const [fileUploaded, setFileUploaded] = useState<File>();
  const [documentCategoryId, setDocumentCategoryId] = useState();
  const [documentCategoryName, setDocumentCategoryName] = useState();
  const [uploadModalValueDl, setUploadModalValueDl] = useState(false);
  const [progressStatus, setProgressStatus] = useState("");
  const [tableData, setTableData] = useState([]);
  const [mainData, setMainData] = useState<any>([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [pageReload, setPageReload] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [dataObj, setDataObj] = useState<any>();
  const [userRole, setUserRole] = useState<any>("");
  const pdfStartPage = useRef<string>("");
  const receiveUploadedFile = (file: File, source: any, startPage: string) => {
    setFileUploaded(file);
    setDocumentCategoryId(source?.value);
    setDocumentCategoryName(source?.label);
    pdfStartPage.current = startPage;
  };
  const onLoadDataFetchDocumentLibrary = async () => {
    setIsTableLoader(true);
    const getDocumentLibraryList = await getAPI(
      `DocumentLibrary/GetAllDocuments`
    );
    setTableData(getDocumentLibraryList);
    setMainData(getDocumentLibraryList);
    setIsTableLoader(false);
  };
  const onFilterClick = () => {
    setIsFilter(true);
  };
  const clearFilterFunc = () => {
    setDataObj({});
  };
  const filterDataFunc = (obj: any) => {
    const formData = {
      docStatus: obj?.docStatus ? obj?.docStatus : null,
      docCategory: obj?.docCategory
        ? obj?.docCategory.map((item) => item.label)
        : null,
      docNames: obj?.docNames ? obj?.docNames.map((item) => item.label) : null,
    };
    setDataObj(formData);
  };
  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      setUserRole(userRole);
    };
    getUserRoleAsync();
  }, []);
  useEffect(() => {
    if (dataObj) {
      if (Object.keys(dataObj).length > 0) {
        const newTableData = mainData?.filter(function (item: any) {
          if (
            dataObj?.docStatus?.length > 0 &&
            dataObj?.docCategory?.length > 0 &&
            dataObj?.docNames?.length > 0
          ) {
            return (
              dataObj?.docStatus?.indexOf(item.statusId) !== -1 &&
              dataObj?.docCategory?.indexOf(item.documentCategoryName) !== -1 &&
              dataObj?.docNames?.indexOf(item.documentName) !== -1
            );
          } else if (
            dataObj?.docStatus.length > 0 &&
            dataObj?.docCategory.length > 0
          ) {
            return (
              dataObj?.docStatus?.indexOf(item.statusId) !== -1 &&
              dataObj?.docCategory?.indexOf(item.documentCategoryName) !== -1
            );
          } else if (
            dataObj?.docStatus.length > 0 &&
            dataObj?.docNames.length > 0
          ) {
            return (
              dataObj?.docStatus?.indexOf(item.statusId) !== -1 &&
              dataObj?.docNames?.indexOf(item.documentName) !== -1
            );
          } else if (
            dataObj?.docCategory.length > 0 &&
            dataObj?.docNames.length > 0
          ) {
            return (
              dataObj?.docCategory?.indexOf(item.documentCategoryName) !== -1 &&
              dataObj?.docNames?.indexOf(item.documentName) !== -1
            );
          } else if (dataObj?.docStatus.length > 0) {
            return dataObj?.docStatus?.indexOf(item.statusId) !== -1;
          } else if (dataObj?.docCategory.length > 0) {
            return (
              dataObj?.docCategory?.indexOf(item.documentCategoryName) !== -1
            );
          } else if (dataObj?.docNames.length > 0) {
            return dataObj?.docNames?.indexOf(item.documentName) !== -1;
          } else {
            return item;
          }
        });
        setTableData(newTableData);
      } else {
        onLoadDataFetchDocumentLibrary();
      }
    }
  }, [dataObj]);
  const saveUploadedDocuments = async () => {
    const UploadedDocumentsFiles = {
      DocumentId: 0,
      DocumentName: fileUploaded?.name,
      DocumentCategoryId: documentCategoryId,
      Checked: false,
      Status: null,
      DocumentCategoryName: documentCategoryName,
      FormFile: fileUploaded,
      StartPage: pdfStartPage.current,
    };
    let formData: any = new FormData();
    let index = 0;
    formData.append("DocumentId", UploadedDocumentsFiles.DocumentId);
    formData.append("DocumentName", UploadedDocumentsFiles.DocumentName);
    formData.append(
      "DocumentCategoryId",
      UploadedDocumentsFiles.DocumentCategoryId
    );
    formData.append("Checked", UploadedDocumentsFiles.Checked);
    formData.append("Status", UploadedDocumentsFiles.Status);
    formData.append(
      "DocumentCategoryName",
      UploadedDocumentsFiles.DocumentCategoryName
    );
    formData.append("FormFile", UploadedDocumentsFiles.FormFile);
    formData.append("StartPage", UploadedDocumentsFiles.StartPage);

    if (fileUploaded) {
      await fileApiAxios("DocumentLibrary/SaveDocument", formData).then(
        (result) => {
          if (result.data == true) {
            setProgressStatus("success");
            setTimeout(function () {
              setUploadModalValueDl(false);
            }, 1500);
            onLoadDataFetchDocumentLibrary();
          } else {
            setProgressStatus("danger");
            setTimeout(function () {}, 1000);
          }
        }
      );
      setPageReload(true);
    }
  };
  const DocumentListChanges = (res: any) => {
    if (res === "No") {
      setPageReload(false);
    }
  };
  const UploadFun = () => {
    setUploadModalValueDl(true);
  };
  const closingUpload = () => {
    setUploadModalValueDl(false);
  };
  useEffect(() => {
    saveUploadedDocuments();
  }, [fileUploaded]);

  useEffect(() => {
    onLoadDataFetchDocumentLibrary();
  }, [pageReload]);
  return (
    <div className="document-library-cm">
      <div className="container-fluid">
        <div className="header-div-dl">
          <div className="row">
            <div className="col-md-3">
              <h5>Document Library</h5>
            </div>
            <div className="col-md-2">
              <div className="uploadButtonDocumentLibrary">
                <McButton fit="small" icon="tray-arrow-up" data-testid="upload-button" onClick={UploadFun}>
                  Upload Document
                </McButton>
              </div>
            </div>
            {userRole == "CharteringManager" && (
              <div className="col-md-2 filter-buttons">
                <button className="clear-button" data-testId="clear-button" onClick={clearFilterFunc}>
                  Clear
                </button>
                <button
                  onClick={onFilterClick}
                  data-testid="apply-filter"
                  className="negotiation-filter-button"
                >
                  <span>
                    <McIcon icon="sliders-horizontal"></McIcon>
                  </span>
                  <span>Filter</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          <DocumentLibraryTable
            tableData={tableData}
            isTableLoader={isTableLoader}
            isDocumentListChanges={DocumentListChanges}
            rerenderFunc={onLoadDataFetchDocumentLibrary}
          />
        </div>
        <div className="row">
          <div className="col-md-12">
            <span className="user-info-div">
              ** Please do not upload PDFs with images in Content..
            </span>
          </div>
        </div>
        <div className="negotiation-filter-section">
          {isFilter && (
            <FilterComponent
              filterFor={"DocumentVerification"}
              onDataFunc={filterDataFunc}
              closeFilterFunc={() => setIsFilter(false)}
              clearFilter={clearFilterFunc}
            />
          )}
        </div>
        <div data-testid="upload-file">
          <UploadDocumentLibrary
            onOpen={uploadModalValueDl}
            closeUpload={closingUpload}
            fileUpload={receiveUploadedFile}
            progressStatusRes={progressStatus}
            documentList={tableData}
          />
        </div>
      </div>
    </div>
  );
};
export default DocumentLibraryCM;
