import React, { useState } from "react";

import { McIcon } from "@maersk-global/mds-react-wrapper";
import IndicationRequestTable from "./IndicationRequestPages/IndicationRequestTable/IndicationRequestTable";
import IndicationRequestFilter from "./IndicationRequestPages/IndicationRequestFilter/IndicationRequestFilter";
import "./IndicationRequest.scss";
const IndicationRequest = () => {
  const [isFilter, setIsFilter] = useState(false);
  const onloadObj = {
    StatusList: [],
    RequestId: [],
  };
  const [dataObj, setDataObj] = useState<any>(onloadObj);
  const onFilterClick = () => {
    setIsFilter(true);
  };
  const onFilterClose = () => {
    setIsFilter(false);
  };
  const clearFilterFunc = () => {
    setDataObj(onloadObj);
  };

  const filterDataFunc = (obj: any) => {
    const formData = {
      RequestId: obj?.requestItems
        ? obj?.requestItems.map((i: any) => i?.value)
        : null,
      StatusList: obj?.requestStatus ? obj?.requestStatus : null,
    };
    setDataObj(formData);
  };
  return (
    <div className="indication-requestMain">
      <div className="container-fluid">
        <div className="header-div">
          <div className="row">
            <div className="col-md-3">
              <h5>Indication request</h5>
            </div>
          </div>
          <div className="col-md-7"></div>
          <div className="col-md-2 filter-buttons">
            <button
              className="clear-button"
              data-testid="clear-filter-button"
              onClick={clearFilterFunc}
            >
              Clear
            </button>
            <button
              onClick={onFilterClick}
              data-testid="apply-filter"
              className="ir-filter-button"
            >
              <span>
                <McIcon icon="sliders-horizontal"></McIcon>
              </span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <div className="table-section">
          <IndicationRequestTable pageData={dataObj} />
        </div>
        {isFilter && (
          <IndicationRequestFilter
            closeFilterFunc={onFilterClose}
            onDataFunc={filterDataFunc}
            clearFilter={clearFilterFunc}
          />
        )}
      </div>
    </div>
  );
};
export default IndicationRequest;
