import { useState, useEffect, useRef } from 'react';
import PDFViewer from "./PDF/PDFViewer";
import { McButton, McIcon } from '@maersk-global/mds-react-wrapper';
import '../DocumentVerificationHome.scss'
import './DocumentVerificationPage.scss'
import { getAPI, patchAPI, putAPI, postAPI } from "../../services/CepApiServices";
import { TContractLine, TRiderClause, contentType, TDocument } from './model';
import TextEditor from './ContractLinesEditor/TextEditor';
import ConfirmationModal from '../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal';
import { AppString } from '../../utils/AppString';
import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper';
import { toast } from "react-toastify";
import DocumentEditor from './ContractLinesEditor/DocumentEditor';
import loaderImage from "../../assets/loader-image.png";

const DocumentVerificationPage = () => {
    const search = window.location.search;
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [contractLines, setContractLines] = useState<TContractLine[]>([]);
    const [contractLinesOriginal, setContractLinesOriginal] = useState<TContractLine[]>([]);
    const [riderClauses, setRiderClauses] = useState<TRiderClause[]>([]);
    const [riderOriginalClauses, setRiderOriginalClauses] = useState<TRiderClause[]>([]);
    const navigation = useRef('next');
    const [showConfrmModal, setShowConfrmModal] = useState(false);
    const [showReviewConfrmModal, setShowReviewConfrmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPDFLoading, setIsPDFLoading] = useState(true);
    const [isReviewed, setIsReviewed] = useState(false);
    const [docName, setDocumentName] = useState();
    const isSaved = useRef(true);
    const [isMainLoader, setIsMainLoader] = useState(false);
    const startPage = useRef<number>(1);
    const goToPrevPage = () => {
        if (!isSaved.current) {
            navigation.current = 'prev';
            setShowConfrmModal(true);
        }
        else
            setPageNumber(pageNumber - 1 <= startPage.current ? startPage.current : pageNumber - 1);
    }
    const goToNextPage = () => {
        if (!isSaved.current) {
            navigation.current = 'next';
            setShowConfrmModal(true);
        }
        else
            setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
    }
    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
    const modifiedContractlines = useRef<TContractLine[]>([]);
    const modifiedRiderClauses = useRef<TRiderClause[]>([]);
    const params = new URLSearchParams(search);
    const id = params.get("id");
    const name = params.get("name");

    useEffect(() => {
        setIsPDFLoading(true);
        getAllDocuments();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getContractLinesPerPage();
        // getAllDocuments();
        getRiderClausesPerPage();
    }, [pageNumber]);

    const getContractLinesPerPage = async () => {
        const responseValue = await getAPI(
            `DocumentLibrary/GetDocumentContent/${pageNumber}/${id}`
        );
        if (responseValue) {
            setContractLinesOriginal(structuredClone(responseValue));
            setContractLines(structuredClone(responseValue));
            setIsLoading(false);
        }
    }

    const getRiderClausesPerPage = async () => {
        const responseValue = await getAPI(
            `DocumentLibrary/GetRiderClauseDocumentContent/${pageNumber}/${id}`
        );

        if (responseValue) {
            setRiderClauses(structuredClone(responseValue));
            setRiderOriginalClauses(structuredClone(responseValue));
            setIsLoading(false);
        }
    }

    const getAllDocuments = async () => {
        const documents = await getAPI(`DocumentLibrary/GetAllDocuments`);
        if (documents) {
            const filteredDocuments = documents.filter((document) => document.documentCategoryName.toLowerCase() === "contract document" && document.status.toLowerCase() === "reviewed" && document.documentId == id);
            setIsReviewed(filteredDocuments.length > 0);
            const document = documents.find(document => document.documentId == id && document.documentCategoryName.toLowerCase() === "contract document");
            setDocumentName(document?.documentName);
            setIsPDFLoading(false);
            setPageNumber(document?.startPage);
            startPage.current = document?.startPage;
        }
    }

    const handleSave = (doc: TDocument) => {
        let condition;
        if ('lineNumber' in doc) {
            condition = 'ContractLine';
        }
        else {
            condition = 'RiderClause';
        }
        switch (condition) {
            case 'ContractLine':
                saveContractLine(doc as TContractLine);
                break;
            case 'RiderClause':
                saveRiderClause(doc as TRiderClause);
                break;
            default:
                // Handle default case if necessary
                break;
        }

        isSaved.current = false;
    };

    const saveContractLine = async (contractLine: TContractLine) => {
        if (contractLine.content.includes('<strong>') || contractLine.content.includes('<del>')) {
            updateNegotiationHistories(contractLine);
            updateContractLines(contractLine);
        }
        else {
            updateContractLines(contractLine);
            conditionalDeleteContractLinesHistory(contractLine);
        }
    };

    const saveRiderClause = async (clause: TRiderClause) => {
        if ((clause.content || '').includes('<strong>') || (clause.content || '').includes('<del>')) {
            updateRiderHistories(clause);
            updateRiderClauses(clause);
        }
        else {
            updateRiderClauses(clause);
        }
    };

    const updateContractLines = async (contractLine: TContractLine) => {
        const index = modifiedContractlines.current.findIndex(line => line.lineNumber === contractLine.lineNumber && line.isContractLine);
        let content = contractLine.content;
        content = contractLine.content.replace(/<strong>.*?<\/strong>(<br>)?|<\/?del>/g, '');
        if (index === -1) {
            modifiedContractlines.current.push({
                lineNumber: contractLine.lineNumber,
                content: content,
                isContractLine: true
            });
        } else {
            modifiedContractlines.current[index].content = content;
        }
    }

    const updateRiderClauses = async (clause: TRiderClause) => {
        const index = modifiedRiderClauses.current.findIndex(clauses => clauses.id === clause.id && clauses.contentType == contentType.Content);
        let content = clause.content;
        content = (clause.content || '').replace(/<strong>.*?<\/strong>(<br>)?|<\/?del>/g, '').replace(/(<br>)+/g, '<br>');
        if (index === -1) {
            modifiedRiderClauses.current.push({
                id: clause.id,
                content: content,
                contentType: contentType.Content
            });
            conditionalDeleteRiderHistory(clause);
        } else {
            modifiedRiderClauses.current[index].content = content;
            conditionalDeleteRiderHistory(clause);
        }


    }
    const conditionalDeleteContractLinesHistory = async (contractLine: TContractLine) => {
        if (!(contractLine.content || '').includes('<strong>') && !(contractLine.content || '').includes('<del>')) {
            const matchingLine = contractLinesOriginal.find(cls =>  cls.lineNumber === contractLine.lineNumber);
            if (matchingLine?.content && (matchingLine?.content.includes('<strong>') || matchingLine?.content.includes('<del>'))) {
                contractLine.content = '';
                updateNegotiationHistories(contractLine);
            }
        }
    }
    const conditionalDeleteRiderHistory = async (clause: TRiderClause) => {
        if (!(clause.content || '').includes('<strong>') && !(clause.content || '').includes('<del>')) {
            const matchingRiderClause = riderOriginalClauses.find(clauses => clauses.contentType === contentType.Content && clauses.id === clause.id);
            if (matchingRiderClause?.content && (matchingRiderClause?.content.includes('<strong>') || matchingRiderClause?.content.includes('<del>'))) {
                clause.content = '';
                updateRiderHistories(clause);
            }
        }
    }

    const updateNegotiationHistories = async (contractLine: TContractLine) => {
        const index = modifiedContractlines.current.findIndex(line => line.lineNumber === contractLine.lineNumber && !line.isContractLine);
        if (index === -1) {
            modifiedContractlines.current.push({
                lineNumber: contractLine.lineNumber,
                content: contractLine.content.replace(/(<br\s*\/?>\s*){2,}/g, '<br>'),
                isContractLine: false
            });
        } else {
            modifiedContractlines.current[index].content = contractLine.content.replace(/(<br\s*\/?>\s*){2,}/g, '<br>');
        }
    }

    const updateRiderHistories = async (history: TRiderClause) => {
        const index = modifiedRiderClauses.current.findIndex(clauses => clauses.id === history.id && clauses.contentType == contentType.History);
        if (index === -1) {
            modifiedRiderClauses.current.push({
                id: history.id,
                content: (history.content || '').replace(/(<br\s*\/?>\s*){2,}/g, '<br>'),
                contentType: contentType.History
            });
        } else {
            modifiedRiderClauses.current[index].content = (history.content || '').replace(/(<br\s*\/?>\s*){2,}/g, '<br>');
        }
    }

    const saveCancel = async (response: string) => {
        if (response == "Yes") {
            await saveDocument();
        }
        else {
            isSaved.current = true;
            navigation.current === 'next' ? goToNextPage() : goToPrevPage();
        }
        setShowConfrmModal(false);
    }

    const approveCancel = async (response: string) => {
        if (response == "Yes") {
            setIsMainLoader(true);
            await updateDocumentStatus();           
        }
        setShowReviewConfrmModal(false);
    }

    const saveDocument = async () => {
        if (modifiedContractlines.current.length > 0) {
            setIsMainLoader(true);
            saveContractLines();
        }
        else if (modifiedRiderClauses.current.length > 0) {
            setIsMainLoader(true);
            saveRiderClauses();
        }
    }

    const saveContractLines = async () => {
        const res = await putAPI(
            `DocumentLibrary/UpdateContractContent/` + id, modifiedContractlines.current
        );
        if (res) {
            setIsMainLoader(false);
            toast.success(<div> Contract lines have been saved</div>, {
                theme: "colored",
                icon: false,
                autoClose: 3000,
            });
            modifiedContractlines.current = []; // or null, depending on your needs
        } else {
            setIsMainLoader(false);
            toast.error(<div> Contract lines have not been saved!</div>, {
                theme: "colored",
                icon: false,
                autoClose: 3000,
            });
        }

        isSaved.current = true;
    };

    const saveRiderClauses = async () => {
        const res = await postAPI(
            `DocumentLibrary/UpdateRiderClauseContentAsync/`, modifiedRiderClauses.current
        );
        if (res) {
            setIsMainLoader(false);
            toast.success(<div> Rider Clauses have been saved</div>, {
                theme: "colored",
                icon: false,
                autoClose: 3000,
            });
            modifiedRiderClauses.current = []; // or null, depending on your needs
        } else {
            setIsMainLoader(false);
            toast.error(<div> Rider Clauses have not been saved!</div>, {
                theme: "colored",
                icon: false,
                autoClose: 3000,
            });
        }
        isSaved.current = true;
    };

    const updateDocumentStatus = async () => {
        patchAPI(
            `DocumentLibrary/UpdateDocumentStatus/` +
            id ,
            62
        ).then((resp) => {
            setIsMainLoader(false);
            if (resp) {
                toast.success(<div> Document status has been updated to Reviewed</div>, {
                    theme: "colored",
                    icon: false,
                    autoClose: 3000,
                });
                setIsReviewed(true);
            } else {
                toast.error(<div> Document status has not been updated!</div>, {
                    theme: "colored",
                    icon: false,
                    autoClose: 3000,
                });
            }
        });

    };

    const handleTitleChange = async (clause: TRiderClause, newValue: string) => {
        const index = modifiedRiderClauses.current.findIndex(clauses => clauses.id === clause.id && clause.contentType == contentType.Title);
        const titleIndex = riderClauses.findIndex(c => c.id === clause.id);
        if (index === -1) {
            modifiedRiderClauses.current.push({
                id: clause.id,
                content: (newValue || '').replace(/(<br\s*\/?>\s*){2,}/g, '<br>'),
                contentType: contentType.Title
            });
        } else {
            modifiedRiderClauses.current[index].content = newValue.replace(/(<br\s*\/?>\s*){2,}/g, '<br>');
        }
        const newClauses = [...riderClauses];
        newClauses[titleIndex] = { ...clause, content: newValue };

        // Update your state
        setRiderClauses(newClauses);
        isSaved.current = false;

    }

    return (
        <div className="contract-negotiation-home-Main">
            {isMainLoader && (
                <div className="loader-div  ">
                    <div className="width-div">
                        <div className="image-div shipLoader" id="pot">
                            <img src={loaderImage} alt="" />
                        </div>
                    </div>
                </div>
            )}
            <div className='document-verification-header'>              
                <div className="page-controls">
                    <button data-testid="prev-button" onClick={goToPrevPage} disabled={pageNumber <= 1}>
                        <span className='prevIcon'><McIcon icon="caret-left" color='#42b0b5' /></span>
                    </button>
                    <span data-testid="page-number" className='pageNumber'>
                        {pageNumber} of {numPages}
                    </span>
                    <button data-testid="next-button" onClick={goToNextPage}><span className='prevIcon'><McIcon icon="caret-right" color='#42b0b5' /></span></button>
                </div>
                {!isReviewed && !isLoading && (
                    <div className="contract-button-group button-group">
                        <div className='button'>
                            <McButton
                                label="Save"
                                fit="small"
                                click={saveDocument}
                                data-testid="save-button"
                            ></McButton>
                        </div>
                        <div className='button'>
                            <McButton
                                label="Review & Approve"
                                fit="small"
                                click={() => setShowReviewConfrmModal(true)}
                                data-testid="review-button"
                            ></McButton>
                        </div>
                    </div>
                )}
                {isReviewed && (
                    <div className="reviewed-contract-message">
                        This contract has been reviewed and approved.
                    </div>
                )}
            </div>
            <div className='row'>
                <div className="col-md-7">
                    <div className="pdf-viewer">
                        {isPDFLoading ? (
                            <McLoadingIndicator variant="bar" label="Loading" data-testId="loading" />
                        ) : (
                            <>
                                {docName && <PDFViewer pageNumber={pageNumber} documentLoad={onDocumentLoadSuccess} pdfName={docName!} />}</>)
                        }
                    </div>
                </div>
                <div className="col-md-5">
                    <div className='contract-lines'>
                        {isLoading ? (
                            <McLoadingIndicator variant="bar" label="Loading" />
                        ) : (
                            <>
                                {contractLines.map((line: TContractLine, index: number) => (
                                    <div key={index} className='d-flex contract-line'>
                                        <div className={`flex-grow-0 flex-shrink-0 line-number  ${isReviewed ? 'reviewed' : ''} `}>
                                            {line.lineNumber}
                                        </div>
                                        <div className="flex-grow-1">
                                            <DocumentEditor key={line.lineNumber} contractLine={line} onSave={handleSave} isReadOnly={isReviewed} />
                                        </div>
                                    </div>
                                ))}
                                {riderClauses.map((clause: TRiderClause, index: number) => (
                                    <div key={index} className='rider-clause'>
                                        {clause.contentType === contentType.Title && (
                                            <div className="flex-grow-1 title">
                                                <input
                                                    key={clause.id}
                                                    value={clause.content || ''}
                                                    disabled={isReviewed}
                                                    data-testid="handle-title-change"
                                                    onChange={(event) => handleTitleChange(clause, event.target.value)}
                                                ></input>
                                            </div>
                                        )}
                                        {clause.contentType === contentType.Content && (
                                            <div className="flex-grow-1 mb-2">
                                                <DocumentEditor key={clause.id} contractLine={clause} onSave={handleSave} isReadOnly={isReviewed} />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                onModalOpen={showConfrmModal}
                onModalClose={saveCancel}
                modalTxt={AppString.saveModalTxt}
            />
            <ConfirmationModal
                onModalOpen={showReviewConfrmModal}
                onModalClose={approveCancel}
                modalTxt={AppString.reviewModalTxt}
            />
        </div>
    );
}

export default DocumentVerificationPage;