import noData from "../../assets/nodataFound.png";
interface IPROPS {
  text?: any;
  size?: any;
}
const Nodata = ({ text, size }: IPROPS) => {
  return (
    <div className={size ? "no-data-modal" : "no-data"} data-testid="no-data">
      <div className="image">
        <img src={noData} alt="noData" />
        <div className="text">{text}</div>
      </div>
    </div>
  );
};
export default Nodata;
