import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { useState } from "react";
import { downloadReportDocuments } from "../services/CepApiServices";
import "./IndicationDocumentsDownload.scss";
interface IPROPS {
  indicationId?: any;
  documents?: any;
  isModalOpen?: boolean;
  onLoadData?: any;
  onCanceled?: any;
}
const SelectDocumentModal = ({
  indicationId,
  documents,
  isModalOpen,
  onLoadData,
  onCanceled,
}: IPROPS) => {
  const [checkeddoc, setCheckeddoc] = useState();

  const handleDownload = async (id: any, fileName: any, categoryName: any) => {
    const file = id + "_" + fileName;
    await downloadReportDocuments(
      `IndicationRequest/DownlaodIndicationRequestDocument/${file}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("Could not download the file");
      });
  };
  const downloadFile = (id: any, fileName: any, categoryName: any) => {
    return handleDownload(id, fileName, categoryName);
  };
  const handleDocumentClick = (id: any) => {
    const newArray = documents?.map((element: any) => {
      if (element.documentId === id) {
        element.checked = true;
        setCheckeddoc(element);
      } else {
        element.checked = false;
      }
      return element;
    });
  };
  const onClose = () => {
    onCanceled();
  };

  return (
    <McModal
      height="350px"
      width="600px"
      className="download-doc-modal"
      heading={`Indication Request Documents`}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="download-doc-modal-content">
        <>
          <div className="select-documents">
            {documents?.length > 0 && (
              <div className="header-div">
                <span className="icon">
                  <McIcon icon="file"></McIcon>
                </span>
                <span className="icon-text">Select Indication Documents</span>
              </div>
            )}
            <div className="body-div">
              <div className="row">
                {documents?.length == 0 && (
                  <div>
                    <span>No uploaded documents!</span>
                  </div>
                )}
                {documents?.map((data: any) => (
                  <div
                    data-testid="handle-new-doc"
                    className="col-md-4"
                    onClick={() => handleDocumentClick(data.documentId)}
                  >
                    <div className="download-div">
                      <span className="doc-name">{data?.documentName}</span>
                      <span
                        className="icon-div"
                        onClick={() => {
                          downloadFile(
                            data?.id,
                            data?.uploadedFileName,
                            data?.documentCategoryName
                          );
                        }}
                        data-testid="doc-download"
                      >
                        <McIcon icon="tray-arrow-down"></McIcon>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      </div>

      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        role="cancel-cp"
        fit="small"
        onClick={() => onClose()}
      >
        Close
      </McButton>
    </McModal>
  );
};
export default SelectDocumentModal;
