import TopMenu from "../TopMenuName/TopMenu";

const pageName = (location: any) => {
  const checkSubNavMenu = (item: any) => {
    if (item.path == location.pathname) {
      return true;
    } else if (
      item?.subNav &&
      item.subNav?.find((i: any) => i?.path == location.pathname)
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {TopMenu.map((item, index) => {
        if (checkSubNavMenu(item)) {
          return item?.displayName;
        }
      })}
    </>
  );
};
export default pageName;
