import { McIcon } from "@maersk-global/mds-react-wrapper";
import './StepIndicatorFlow.scss';
import { getAPI } from "../../services/CepApiServices";
import { useEffect, useState } from "react";
import check from '../../assets/check_white.png'
interface IPROPS {
  steps?:any;
  type?:any;
  indicationID?:any;
  updateApiProps?:boolean;
  getStatusFlow?:any;
}

const StepIndicatorFlow = ({steps, type, indicationID , updateApiProps, getStatusFlow}: IPROPS) => {
  const [indicationSteps, setIndicationSteps] = useState<any>([]);
  const getStatus = (step:any) =>{
    switch(step){
      case 'Completed':
        return 'completed';
      case 'Cancelled':
        return 'cancelled';
      case 'New':
        return 'new';
      case 'Current':
        return 'current';
      default:
        return 'new'
    }
  }
  const getIcon = (step:any) =>{
    switch(step){
      case 'Completed':
        return <img src={check} alt="check"  height="11"/>;
      case 'Cancelled':
        return <McIcon icon="times" color="#000" size="20"></McIcon>;
      case 'New':
        return <img src={check} alt="check"  height="11"/>;
      case 'Current':
        return '';
      default:
        return <img src={check} alt="check"  height="11"/>;
    }
  }
  const onLoadAPI = async() =>{
    const response = await getAPI(`IndicationRequest/GetIndicationStageDetail?indicationId=${indicationID}&stageType=${type}`);
    setIndicationSteps(response?.indicationStages);
    if(getStatusFlow){
    getStatusFlow(response?.currentStage)
    }
    // if(response?.currentStage === 'Terminate'){
    //   return window.location.href = "/AccessDenied";
    // }
  }
  useEffect(()=>{
    if(updateApiProps){
      onLoadAPI()
    }
  },[updateApiProps])
  return (
    <section className="step-indicatorFlow">
      { indicationSteps && indicationSteps.map((item:any, index:any) => {
        return( <><div key={`${item.label}${index}`} className={`step ${getStatus(item.status)}`}>
        <div className="step-icon">
          {
            getIcon(item.status)
          }
          </div>
        <p>{item.label}</p>
      </div>
      <div className="indicator-line active"></div></>)
      })}
    </section>
  );
};
export default StepIndicatorFlow;
