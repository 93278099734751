import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  isModalOpen?: boolean;
  indicationId?: any;
  onCanceled?: any;
}

const WarningModal = ({
  isModalOpen,
  indicationId,
  onCanceled,
}: IPROPS) => {
  const onOKClick = () => {
    onCanceled("Submit");
  };

  const closeAcceptFunc = () => {
    onCanceled("Cancel");
  };

  return (
    <div>
      <McModal
        width="620px"
        height="180px"
        open={isModalOpen}
        heading="Confirm Action"
        className="accept-clause-modal"
      >
        <p className="accept-alert">
        Reset Filter will delete previously applied filter.Do you want to reset ?
        </p>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          click={closeAcceptFunc}
          dialogAction="cancel"
          data-testid='cancel'
        >
          Cancel
        </McButton>
        <McButton
          slot="primaryAction"
          variant="primary"
          click={onOKClick}
          dialogAction="ok"
        >
          OK
        </McButton>
      </McModal>
    </div>
  );
};

export default WarningModal;
