interface IPROPS {
    toolTipList?: any;
}
const ToolTipFormatter = ({toolTipList}: IPROPS) => {
    const toolTipListFunc = (row:any) => {
        return (
            <>
            {row?.multipleServiceDescription?.filter(item => item !== undefined && item !== null)?.length > 0 && (
                <div className="list-header">{`${row.multipleServiceDescription.length} Service${row.multipleServiceDescription.length > 1 ? 's' : ''}`}</div>
            )}
          <ul>
             
            {row?.multipleServiceDescription.map((items, index) => {
                if(items !== undefined && items !== null){
                    return <li key={index}>{items}</li>;
                }
            })}
          </ul>
          </>
        );
      }
    return (
        <div className="tooltip-container">
            <div className="tooltip-content">
                {toolTipList.multipleServiceDescription && toolTipListFunc(toolTipList)}
            </div>
        </div>
    )
}
export default ToolTipFormatter;