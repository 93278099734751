// React

const TopMenu = [
  {
    title: "Dashboard",
    path: "/Dashboard",
    displayName: "Dashboard",
  },
  {
    title: "Charter Request",
    path: "/CharterRequest",
    displayName: "Chartering Execution Platform",
    subNav: [
      {
        text: "Charter Details",
        path: "/CharterDetails",
      },
    ],
  },
  {
    title: "Indication Request",
    path: "/IndicationRequest",
    displayName: "Chartering Execution Platform",
    subNav: [
      {
        text: "Indication Invite Page",
        path: "/IndicationRequestPage",
      },
      {
        text: "Indication Request Details",
        path: "/GetIndicationRequestDataAsync",
      },
    ],
  },
  {
    title: "Indication Request",
    path: "/IndicationRequestVO",
    displayName: "Chartering Execution Platform",
    subNav: [
      {
        text: "Indication Request Details",
        path: "/GetIndicationRequestDataAsyncVO",
      },
    ],
  },
  {
    title: "Vessel SupplyData",
    path: "/",
    displayName: "Availability list",
    subNav: [
      {
        text: "Vessel Details",
        path: "/vessel-details",
      },
    ],
  },
  {
    title: "Document Verification",
    path: "/DocumentVerification",
    displayName: "Chartering Execution Platform",
  },
  {
    title: "Negotiation",
    path: "/Negotiation",
    displayName: "Chartering Execution Platform",
    subNav: [
      {
        text: "Initiate Offer",
        path: "/InitiateOfferDetails",
      },
    ],
  },
  {
    title: "Template Repository",
    path: "/DocumentLibrary",
    displayName: "Chartering Execution Platform",
  },
  {
    title: "Template Repository",
    path: "/DocumentLibraryVO",
    displayName: "Chartering Execution Platform",
  },
  {
    title: "Indication Request Documents",
    path: "/IndicationRequestDocumentsDownloads",
    displayName: "Chartering Execution Platform",
  },
  {
    title: "Approvals",
    path: "/Approvals",
    displayName: "Chartering Execution Platform",
  },
  {
    title: "Dictionary",
    path: "/Dictionary",
    displayName: "Chartering Execution Platform",
  },
  {
    title: "Manage Contracts",
    path: "/ManageContracts",
    displayName: "Chartering Execution Platform",
  },
  {
    title: "Access & Permissions",
    path: "/AccessPermissions",
    displayName: "Chartering Execution Platform",
  },
];
export default TopMenu;
