import React, { useState, useEffect } from "react";
import { McIcon, McCheckbox, McRadio } from "@maersk-global/mds-react-wrapper";
import StandardClauseHistory from "../standClauseHistrory";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { postAPI } from "../../../../services/CepApiServices";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import OfferSubmitModal from "./offerSubmitModal";
import moment from "moment";
interface IPROPS {
  subTermData?: any;
  indicationId?: any;
  handleChangeFunc?: any;
  revertChange?: any;
  submitData?: any;
  renderFunc?: any;
  handleChangeRadio?: any;
  checkWriteAccess?: any;
  isEditing?: any;
  mainInfoData?: any;
  isPrefilledCondition?: any;
}
const InputSectionVO = ({
  subTermData,
  indicationId,
  handleChangeFunc,
  handleChangeRadio,
  revertChange,
  submitData,
  renderFunc,
  checkWriteAccess,
  isEditing,
  mainInfoData,
  isPrefilledCondition,
}: IPROPS) => {
  const [isedit, setIsEdit] = useState<any>(true);
  const [submitType, setSubmitType] = useState<any>();
  const [submitPayLoad, setSubmitPayLoad] = useState<any>();
  const [mainData, setmainData] = useState<any>();
  const [isSubmitModal, setIsSubmitModal] = useState<any>(false);
  const [minDate, setMinDate] = useState<any>();
  const [laycanToResetData, setLaycanToResetData] = useState<any>();
  const [AcceptBtn, setAcceptBtn] = useState(true);
  const [AcceptSubTermBtn, setAcceptSubTermBtn] = useState(true);
  const handleSelectOnchange = (value: any, itemValue: any) => {
    handleChangeFunc(value.label, itemValue);
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    const pastedText = clipboardData.getData("text");
    const sanitizedValue = pastedText.replace("-", "");
    document.execCommand("insertText", false, sanitizedValue);
  };

  // const getDefaultDropdownvalue = (options: any, val: any) => {
  //   const getObj = options?.filter((item: any) => item.label === val)[0];
  //   return getObj;
  // };
  useEffect(() => {
    if (mainData) {
      const updateData = mainData;
      setIsEdit(!isEditing);
      if (isEditing === true) {
        updateData.isDisabled = false;
      } else {
        updateData.isDisabled = true;
      }
      setmainData(updateData);
    }
  }, [isEditing]);
  // const onClickEdit = (type, subId) => {
  //   const updateData = mainData;
  //   if (type === "edit") {
  //     updateData.isDisabled = false;
  //     setIsEdit(false);
  //   } else {
  //     updateData.isDisabled = true;
  //     setIsEdit(true);
  //   }
  //   setmainData(updateData);
  // };
  // const handleSubmitSubtermData = async (data: any, type?: any) => {
  //   const payLoad = {
  //     standardTemplateMainTerms: [
  //       {
  //         termName: data.termName,
  //         termId: data.termId,
  //         subTerms: [data],
  //       },
  //     ],
  //   };
  //   if (type === "Submit") {
  //     setSubmitType(type);
  //     setIsSubmitModal(true);
  //     setSubmitPayLoad(payLoad);
  //   } else {
  //     const response = await postAPI(
  //       `InitialOffer/SaveStandardTermChanges?indicationId=${indicationId}`,
  //       payLoad
  //     );
  //     if (response.StatusCode) {
  //       console.log("error", response.StatusCod);
  //     } else {
  //       toast.success(`Terms submitted successfully`, {
  //         theme: "colored",
  //         icon: false,
  //         autoClose: 3000,
  //       });
  //       setIsEdit(true);
  //       renderFunc();
  //     }
  //   }
  // };
  const handleAcceptTerms = async (subtermData: any, type: any) => {
    setAcceptBtn(false);
    if (type === "Accept") {
      const response = await postAPI(
        `InitialOffer/StageStandardTermAcceptStatus?indicationId=${indicationId}&termId=${subtermData.subTermId}`
      );
      if (response.StatusCode) {
        console.log("error", response.StatusCod);
        setAcceptBtn(true);
      } else {
        toast.success(`Terms Accepted successfully`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        setIsEdit(true);
        renderFunc();
        setAcceptBtn(true);
      }
    } else if (type === "Acknowledge") {
      setSubmitType(type);
      setIsSubmitModal(true);
      setAcceptBtn(true);
    }
  };
  // Individual Accept button
  const handleIndividualAcceptTerms = async (subtermData) => {
    // setAcceptSubTermBtn(false);
    const response = await postAPI(
      `InitialOffer/StageStandardTermFieldLevelAcceptStatus?indicationId=${indicationId}&termDetailId=${subtermData.standardTermDetailId}`
    );
    if (response === true) {
      toast.success(`Terms Accepted successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsEdit(true);
      renderFunc();
      setAcceptSubTermBtn(true);
    } else {
      toast.error(`Failed to accept the terms`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setAcceptSubTermBtn(true);
    }
  };
  function convertDateFormat(inputDate) {
    if (inputDate && inputDate !== "") {
      const parsedDate = moment(inputDate, "DD/MM/yyyyTHH:mm");
      if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM-DDTHH:mm");
      } else {
        console.error("Invalid date:", inputDate);
        return null;
      }
    } else {
      return null;
    }
  }
  const getActionBtn = (isStandardTermsSent: any, subtermData: any) => {
    if (isStandardTermsSent) {
      if (!subTermData?.isNegotiable) {
        if (
          subTermData?.termName === "Vessel Details" ||
          subTermData?.termName === "File Upload"
        ) {
          if (
            subTermData?.workflowStatus === "Awaiting" &&
            subTermData?.modifiedUserType === "CharteringManager"
          ) {
            return (
              <>
                {mainInfoData?.enableCheckout && !isEditing && AcceptBtn && (
                  <>
                    <button
                      data-testid="button-accept"
                      className="btn btn-negotiate"
                      onClick={() => handleAcceptTerms(subTermData, "Accept")}
                    >
                      Accept
                    </button>
                  </>
                )}
              </>
            );
          } else {
            return <></>;
          }
          // Hide Accept button when revert next counter is enabled
        } else if (subTermData?.termName === "Ownership Structure" || subTermData?.termName === "Technical Details") 
          {
            console.log("else if");
            if(subTermData?.workflowStatus === "Awaiting" && subTermData?.revertLaterCounterEnabled) {
              console.log("condition 1");
              return <></>;
          } else if(subTermData?.workflowStatus === "Accept") {
            console.log("condition 2");
            return <></>;
          }  
          else {
            console.log("condition 3");
            return (
              <>
                {mainInfoData?.enableCheckout &&
                  !isEditing &&
                  mainInfoData?.counterNumber !== 0 && (
                    <>
                      <button
                        data-testid="button-acknowledge"
                        className="btn btn-negotiate"
                        onClick={() =>
                          handleAcceptTerms(subTermData, "Acknowledge")
                        }
                      >
                        Accept
                      </button>
                    </>
                  )}
              </>
            );
          }
        } else if (subTermData?.workflowStatus !== "Accept") {
          return (
            <>
              {mainInfoData?.enableCheckout &&
                !isEditing &&
                mainInfoData?.counterNumber !== 0 && (
                  <>
                    <button
                      data-testid="button-acknowledge"
                      className="btn btn-negotiate"
                      onClick={() =>
                        handleAcceptTerms(subTermData, "Acknowledge")
                      }
                    >
                      Accept
                    </button>
                  </>
                )}
            </>
          );
        }
      } else if (
        subtermData?.workflowStatus === "Awaiting" &&
        subTermData?.modifiedUserType === "CharteringManager" &&
        (subTermData?.sectionLevelAccept == true ||
          subTermData?.sectionLevelAccept == null)
      ) {
        return (
          <>
            {mainInfoData?.enableCheckout && !isEditing && AcceptBtn && (
              <>
                <button
                  data-testid="button-accept"
                  className="btn btn-negotiate"
                  onClick={() => handleAcceptTerms(subTermData, "Accept")}
                >
                  Accept
                </button>
              </>
            )}
          </>
        );
      }
    }
  };

  // Individual Accept button
  const getIndvslActionBtn = (detailsData) => {
    if (
      mainInfoData?.enableCheckout &&
      detailsData?.fieldWorkflowStatus === "Awaiting" &&
      detailsData?.fieldLevelAccept === true &&
      // detailsData?.label !== "Hire" &&
      !isEditing
    ) {
      return (
        AcceptSubTermBtn && (
          <>
            <button
              data-testid="button-accept"
              className="btn btn-single-accept"
              onClick={() => handleIndividualAcceptTerms(detailsData)}
            >
              Accept
            </button>
          </>
        )
      );
    }
  };
  const getHirePaymentActionBtn = (detailsData) => {
    if (
      mainInfoData?.enableCheckout &&
      detailsData?.fieldWorkflowStatus === "Awaiting" &&
      detailsData?.fieldLevelAccept === true &&
      !isEditing
    ) {
      return (
        AcceptSubTermBtn && (
          <>
            <button
              data-testid="button-accept"
              className="btn btn-single-accept"
              onClick={() => handleIndividualAcceptTerms(detailsData)}
            >
              Accept
            </button>
          </>
        )
      );
    }
  };

  const differData = (itemData: any, isPrefilledCondition: any) => {
    // if (
    //   itemData.dataType === "Dropdown" ||
    //   itemData.dataType === "Dropdown&Input"
    // ) {
    //   return (
    //     <span className={`${itemData?.disableField && "accpeted"} differData`}>
    //       {itemData.value === "" ? "" : itemData.value === "Yes" ? "Yes" : "No"}
    //     </span>
    //   );
    // }
    const joineddata: any = [];
    if (itemData?.diffs?.length > 0) {
      const newData: any = [];
      const oldData: any = [];
      itemData?.diffs?.forEach((element) => {
        if (element.operation === 2) {
          if (itemData.dataType === "Datetime") {
            const newText = element.text.replace(/T/g, " ");
            newData.push(newText);
            oldData.push(newText);
          } else if (itemData.dataType === "Date") {
            newData.push(element.text);
            oldData.push(element.text);
          } else {
            joineddata.push(element.text);
          }
        } else if (element.operation === 1) {
          if (itemData.dataType === "Datetime") {
            const newText = <b>{element?.text.replace(/T/g, " ")}</b>;
            newData.push(newText);
          } else if (itemData.dataType === "Date") {
            newData.push(<b>{element?.text}</b>);
          } else {
            joineddata.push(<b>{element?.text}</b>);
          }
        } else if (element.operation === 0) {
          if (itemData.dataType === "Datetime") {
            const newText = <s>{element?.text.replace(/T/g, " ")}</s>;
            oldData.push(newText);
          } else if (itemData.dataType === "Date") {
            oldData.push(<s>{element?.text}</s>);
          } else {
            joineddata.push(<s>{element?.text}</s>);
          }
        }
      });
      if (itemData.dataType === "Datetime" || itemData.dataType === "Date") {
        if (itemData?.diffs?.length === 1) {
          return (
            <span
              className={`${itemData?.disableField && "accpeted"} differData`}
            >
              {newData}
            </span>
          );
        }
        return (
          <span
            className={`${itemData?.disableField && "accpeted"} differData`}
          >
            {newData} <s>{oldData}</s>
          </span>
        );
      }
      return (
        <span className={`${itemData?.disableField && "accpeted"} differData`}>
          {joineddata}
        </span>
      );
    } else {
      if (isPrefilledCondition) {
        return (
          <span
            className={`${itemData?.disableField && "accpeted"} differData`}
          >
            {itemData?.value}
          </span>
        );
      } else {
        return (
          <span
            className={`${itemData?.disableField && "accpeted"} differData`}
          ></span>
        );
      }
    }
  };

  const differDataNewTerm = (  itemData: any,
    isNegotiable: any,
    isPrefilledCondition: any) => {
      if (
        itemData.dataType === "Dropdown" ||
        itemData.dataType === "Dropdown&Input"
      ) {
        return (
          <span className="differData">
            {itemData.value === "" ? "" : itemData.value === "Yes" ? "Yes" : "No"}
          </span>
        );
      }
      const joineddata: any = [];
      if (itemData?.changeList?.length > 0) {
        const newData: any = [];
        const oldData: any = [];
        itemData?.changeList?.forEach((element) => {
          if (element?.displayFormat === 1) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
            } else {
              joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
            }
          }
          if (element?.displayFormat === 2) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(newArr);
            } else {
              joineddata.push(element?.text);
            }
          }
          if (element?.displayFormat === 0) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</b>);
            } else {
              joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
            }
          }
          if (element?.displayFormat === 3) {
            if (element?.text?.indexOf("<br>") > -1) {
              let newArr: any = [];
              newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
              joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
            } else {
              joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
            }
          }
        });
        if (itemData.dataType === "Datetime" || itemData.dataType === "Date") {
          if (itemData?.changeList?.length === 1) {
            return (
              <span
                className={`${
                  itemData?.disableField && isNegotiable && "accpeted"
                } differData`}
              >
                {newData}
              </span>
            );
          }
          return (
            <span
              className={`${
                itemData?.disableField && isNegotiable && "accpeted"
              } differData`}
            >
              {newData} <s>{oldData}</s>
            </span>
          );
        }
        return (
          <span
            className={`${
              itemData?.disableField && isNegotiable && "accpeted"
            } differDataNewTerm`}
          >
            {joineddata}
          </span>
        );
      } else {
        if (isPrefilledCondition) {
          return (
            <span
              className={`${
                itemData?.disableField && isNegotiable && "accpeted"
              } differData`}
            >
              {itemData?.value}
            </span>
          );
        } else {
          return (
            <span
              className={`${
                itemData?.disableField && isNegotiable && "accpeted"
              } differDataNewTerm`}
            ></span>
          );
        }
      }
  }
  const handleClickOutside = async (
    event: any,
    itemData: any,
    revertKey: any,
    subTermData:any
  ) => {
    itemData.stagingId = subTermData.stagingId;
    if (event?.type === "blur") {
      itemData.revertLater = revertKey;
      await postAPI(
        `InitialOffer/StageStandardTermInputChanges?indicationId=${indicationId}`,
        itemData
      );
    }
  };
  const handleStageOnClick = async (itemData: any, revertKey: any) => {
    itemData.revertLater = revertKey;
    // if condition is fix to stop calling stage APi after extension for laycan To as this field is hidden
    if(itemData.standardTermDetailId == 57 && isPrefilledCondition){
      console.log("no stage API called");
    } else {
    await postAPI(
      `InitialOffer/StageStandardTermInputChanges?indicationId=${indicationId}`,
      itemData
    );
  }
  };
  const handlingRevert = async (event: any, itemData: any) => {
    await postAPI(
      `InitialOffer/StageStandardTermRevertNextCounterChanges?indicationId=${indicationId}`,
      itemData
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  const handleDisableFunc = (
    subTermData: any,
    itemData?: any,
    typeVal?: any,
    revertLater?: any
  ) => {
    if (typeVal === "input") {
      return subTermData?.isDisabled
        ? true
        : subTermData?.mainTermAccepted
        ? true
        : subTermData?.workflowStatus === "Accept"
        ? true
        : itemData?.disableField
        ? true
        : revertLater
        ? true
        : false;
    } else {
      return subTermData?.isDisabled
        ? true
        : subTermData?.mainTermAccepted
        ? true
        : subTermData?.workflowStatus === "Accept"
        ? true
        : itemData?.disableField
        ? true
        : false;
    }
  };
  const getInputType = (
    itemData: any,
    subTermData: any,
    detailsData: any,
    inputInd,
    isNegotiable,
    revertLater
  ) => {
    switch (itemData.dataType) {
      case "Text":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              differData(itemData, isPrefilledCondition)
            ) : (
              <input
                data-testid="input-text"
                maxLength={200}
                key={itemData.standardTermDetailId}
                type="text"
                placeholder={itemData?.placeholderText}
                value={itemData.value}
                onBlur={(e) => handleClickOutside(e, itemData, revertLater,subTermData)}
                disabled={handleDisableFunc(
                  subTermData,
                  itemData,
                  "input",
                  revertLater
                )}
                className="inputText"
                onChange={(e) => handleChangeFunc(e.target.value, itemData)}
              />
            )}
          </>
        );
      case "Number":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              differData(itemData, isPrefilledCondition)
            ) : (
              <input
                data-testid="input-number"
                key={itemData.standardTermDetailId}
                type="number"
                value={itemData.value}
                onBlur={(e) => handleClickOutside(e, itemData, revertLater, subTermData)}
                disabled={handleDisableFunc(
                  subTermData,
                  itemData,
                  "input",
                  revertLater
                )}
                className="inputText"
                onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                min={0}
                onKeyPress={(e) => e.key === "-" && e.preventDefault()}
                onPaste={(e) => handlePaste(e)}
              />
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              subTermData.newTerm? differDataNewTerm(itemData, isNegotiable, isPrefilledCondition) : differData(itemData, isPrefilledCondition)
            ) : (
              <textarea
                data-testid="input-textarea"
                maxLength={4000}
                key={itemData.standardTermDetailId}
                value={itemData.value}
                onBlur={(e) => handleClickOutside(e, itemData, revertLater, subTermData)}
                disabled={handleDisableFunc(
                  subTermData,
                  itemData,
                  "input",
                  revertLater
                )}
                className="inputTextArea"
                onChange={(e) => handleChangeFunc(e.target.value, itemData)}
              />
            )}
          </>
        );
      case "Date":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              differData(itemData, isPrefilledCondition)
            ) : (
              <DatePicker
                data-testid="input-date"
                className="inputText"
                dateFormat="dd/MM/yyyy"
                value={itemData.value}
                onChange={(date: any) => {
                  handleChangeFunc(
                    date ? moment(date).format("DD/MM/YYYY") : "",
                    itemData
                  );
                  handleStageOnClick(itemData, revertLater);
                }}
                disabled={handleDisableFunc(
                  subTermData,
                  itemData,
                  "input",
                  revertLater
                )}
                key={itemData.standardTermDetailId}
                onChangeRaw={handleDateChangeRaw}
                dateFormatCalendar="MMMM"
                showYearDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
              ></DatePicker>
            )}
          </>
        );
      case "Datetime":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              differData(itemData, isPrefilledCondition)
            ) : (
              <div className="DateTimeChanges">
                <DateTimePicker
                  data-testid="input-datetime"
                  format="dd/MM/yyyy HH:mm"
                  locale="en-US"
                  // onBlur={(e)=> handleClickOutside(e, itemData, revertLater)}
                  className={"dateTimeClass"}
                  disabled={handleDisableFunc(
                    subTermData,
                    itemData,
                    "input",
                    revertLater
                  )}
                  onChange={(e) => {
                    let formattedDateTime = "";
                    if (e) {
                      const momentDate = moment(e, "DD/MM/YYYYTHH:mm");
                      if (momentDate.isValid()) {
                        const timeComponent = momentDate.format("HH:mm");
                        if (timeComponent === "00:00") {
                          if (detailsData.label === "LayCan From") {
                            formattedDateTime = momentDate
                              .set({ hour: 0, minute: 1 })
                              .format("DD/MM/YYYYTHH:mm");
                          } else if (detailsData.label === "LayCan To") {
                            formattedDateTime = momentDate
                              .set({ hour: 23, minute: 59 })
                              .format("DD/MM/YYYYTHH:mm");
                          }
                        } else {
                          formattedDateTime =
                            momentDate.format("DD/MM/YYYYTHH:mm");
                        }
                      }
                    }
                    handleChangeFunc(formattedDateTime, itemData);
                    handleStageOnClick(itemData, revertLater);
                    if (itemData.standardTermDetailId === 56) {
                      handleStageOnClick(laycanToResetData, revertLater);
                      setMinDate(
                        moment(itemData.value, "DD/MM/YYYYTHH:mm").toDate()
                      );
                    }
                  }}
                  minDate={
                    itemData.standardTermDetailId === 57 ? minDate : new Date()
                  }
                  value={convertDateFormat(itemData.value)}
                  // value={moment(itemData.value, "DD/MM/YYYYTHH:mm").toDate()}
                />
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              differData(itemData, isPrefilledCondition)
            ) : (
              <Select
                data-testid="input-dropdown"
                defaultValue={
                  itemData.value === ""
                    ? { value: "", label: "" }
                    : itemData.value === "Yes"
                    ? { value: "Yes", label: "Yes" }
                    : { value: "No", label: "No" }
                }
                options={[
                  { value: "", label: "" },
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ]}
                key={itemData.id}
                // onBlur={(e)=> handleClickOutside(e, itemData, revertLater)}
                isDisabled={handleDisableFunc(
                  subTermData,
                  itemData,
                  "input",
                  revertLater
                )}
                onChange={(value) => {
                  handleSelectOnchange(value, itemData);
                  handleStageOnClick(itemData, revertLater);
                }}
              />
            )}
          </>
        );
      case "PrefixDropdown":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              <div className="prefixText prefix-differData">
                <span className="preText">{itemData.prefix}</span>
                {differData(itemData, isPrefilledCondition)}
                <span className="sufText">{itemData.suffix}</span>
              </div>
            ) : (
              <div className="prefixText">
                <span className="preText">{itemData.prefix}</span>
                <input
                  data-testid="input-prefixdropdown"
                  key={itemData.standardTermDetailId}
                  type="text"
                  maxLength={200}
                  value={itemData.value}
                  onBlur={(e) => handleClickOutside(e, itemData, revertLater, subTermData)}
                  disabled={handleDisableFunc(
                    subTermData,
                    itemData,
                    "input",
                    revertLater
                  )}
                  className="inputText"
                  onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                />
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "PrefixText":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              <div className="prefixText prefix-differData">
                <span className="preText">{itemData.prefix}</span>
                {differData(itemData, isPrefilledCondition)}
                <span className="sufText">{itemData.suffix}</span>
              </div>
            ) : (
              <div className="prefixText">
                <span className="preText">{itemData.prefix}</span>
                <input
                  data-testid="input-prefixtext"
                  key={itemData.standardTermDetailId}
                  type="text"
                  value={itemData.value}
                  onBlur={(e) => handleClickOutside(e, itemData, revertLater, subTermData)}
                  disabled={handleDisableFunc(
                    subTermData,
                    itemData,
                    "input",
                    revertLater
                  )}
                  className="inputText"
                  onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                />
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "PrefixNumber":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              <div className="prefixText prefix-differData">
                <span className="preText">{itemData.prefix}</span>
                {differData(itemData, isPrefilledCondition)}
                <span className="sufText">{itemData.suffix}</span>
                {!itemData.disableField && (
                  <span
                    className="accept-btn-hire-section"
                    key={detailsData?.detailId}
                  >
                    {checkWriteAccess && getHirePaymentActionBtn(itemData)}
                  </span>
                )}
                {(itemData?.fieldWorkflowStatus === "Accept" ||
                  itemData?.fieldWorkflowStatus ===
                    "NegotiatedAndAccepted") && (
                  <span
                    className="accept-btn-hire-section"
                    key={detailsData?.detailId}
                  >
                    <span className="agreedSatus">
                      <McIcon
                        icon="check-circle"
                        size="24"
                        color="#33a059"
                      ></McIcon>{" "}
                    </span>
                  </span>
                )}
              </div>
            ) : (
              <div className="prefixText">
                <span className="preText">{itemData.prefix}</span>
                <input
                  data-testid="input-prefixnumber"
                  key={itemData.standardTermDetailId}
                  type="number"
                  min={0}
                  disabled={handleDisableFunc(
                    subTermData,
                    itemData,
                    "input",
                    revertLater
                  )}
                  value={itemData.value}
                  onBlur={(e) => handleClickOutside(e, itemData, revertLater, subTermData)}
                  className="inputText"
                  onChange={(e) => handleChangeFunc(e.target.value, itemData)}
                  onKeyPress={(e) => e.key === "-" && e.preventDefault()}
                  onPaste={(e) => handlePaste(e)}
                />
                <span className="sufText">{itemData.suffix}</span>
              </div>
            )}
          </>
        );
      case "Dropdown&Input":
        return (
          <>
            {handleDisableFunc(subTermData, itemData) ? (
              differData(itemData, isPrefilledCondition)
            ) : (
              <>
                <Select
                  data-testid="input-dropdown&input"
                  defaultValue={
                    itemData.value !== "No"
                      ? { value: "Yes", label: "Yes" }
                      : { value: "No", label: "No" }
                  }
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  isDisabled={
                    !isNegotiable
                      ? true
                      : handleDisableFunc(
                          subTermData,
                          itemData,
                          "input",
                          revertLater
                        )
                  }
                  key={itemData.standardTermDetailId}
                  // onBlur={(e)=> handleClickOutside(e, itemData, revertLater)}
                  onChange={(value) => {
                    handleSelectOnchange(value, itemData);
                    handleStageOnClick(itemData, revertLater);
                  }}
                />
                <div className="input-dropdown">
                  <label> Comment</label>
                  <input
                    key={itemData.standardTermDetailId}
                    type="text"
                    disabled={
                      !isNegotiable
                        ? true
                        : handleDisableFunc(
                            subTermData,
                            itemData,
                            "input",
                            revertLater
                          )
                    }
                    value={itemData.value2}
                    onBlur={(e) => handleClickOutside(e, itemData, revertLater, subTermData)}
                    className="inputText"
                  />
                </div>
              </>
            )}
          </>
        );
      default:
        return <div data-testid="null"></div>;
    }
  };
  const reRenderApi = () => {
    setIsEdit(true);
    renderFunc();
    setIsSubmitModal(false);
  };
  const closeModal = () => {
    setIsSubmitModal(false);
  };
  useEffect(() => {
    subTermData.isDisabled = !isEditing;
    setmainData(subTermData);
    setIsEdit(!isEditing);
    subTermData?.details?.map((detailsData: any) => {
      return detailsData?.inputs?.map((inputData: any) => {
        if (inputData?.standardTermDetailId === 57) {
          return setLaycanToResetData(inputData);
        }
      });
    });
  }, [subTermData]);
  return (
    <div className="sub-data" key={mainData?.subTermId}>
      {checkWriteAccess && (
        <OfferSubmitModal
          subTermData={subTermData}
          submitType={submitType}
          payLoadData={submitPayLoad}
          onCloseFunc={closeModal}
          reRenderApiFunc={reRenderApi}
          isModalOpen={isSubmitModal}
          indicationId={indicationId}
          checkWriteAccess={checkWriteAccess}
        ></OfferSubmitModal>
      )}

      {isPrefilledCondition && mainData?.subTermName == "Delivery notices" ? (
        ""
      ) : (
        <div className="sub-header">
          <span className="icon">
            <McIcon icon="vessel-front" />
          </span>
          <span className="action-btn">
            {(subTermData?.workflowStatus === "Accept" ||
              subTermData?.workflowStatus === "NegotiatedAndAccepted") && (
              <span className="agreedSatus">
                <McIcon icon="check-circle" size="24" color="#33a059"></McIcon>{" "}
              </span>
            )}

            <span className="agree-checkbox" key={mainData?.subTermId}>
              {checkWriteAccess &&
                getActionBtn(submitData?.isStandardTermsSent, mainData)}
            </span>
          </span>
          <span className="text">
            {isPrefilledCondition && mainData?.subTermName == "Laycan"
              ? "New Time and Period"
              : mainData?.subTermName}
            {mainData?.sectionLevelDisplayMandatory && (
              <span className="mandatory"> *</span>
            )}
          </span>
        </div>
      )}
      {isPrefilledCondition && mainData?.subTermName == "Delivery notices"
        ? ""
        : mainData?.details.map((detailsData: any, ind: any) => (
            <div
              className={`${
                mainData?.details.length === 1 && "input1"
              } input-each-data`}
            >
              {isPrefilledCondition && detailsData?.label == "LayCan To" ? (
                ""
              ) : (
                <div
                  className="each-data"
                  key={detailsData.standardTermDetailId}
                >
                  <span className="label-div">
                    {mainData?.subTermName !== detailsData.label
                      ? isPrefilledCondition &&
                        detailsData.label == "LayCan From"
                        ? "From"
                        : mainData?.newTerm ? "" : detailsData.label
                      : ""}
                    {detailsData.lineType !== "Description" &&
                      detailsData?.displayMandatory &&
                      mainData?.subTermName !== detailsData.label && (
                        <span className="mandatory">*</span>
                      )}
                    {detailsData.lineType !== "Description" &&
                      detailsData.revertNextCounterRequired && (
                        // !subTermData?.isNegotiable &&
                        <span className="checkBox">
                          <McCheckbox
                            data-testid="handling-revert"
                            fit="small"
                            label="Revert Next Counter"
                            value={detailsData.revertLater}
                            checked={detailsData.revertLater}
                            key={detailsData.standardTermDetailId}
                            change={(e) => {
                              revertChange(e, detailsData);
                              handlingRevert(e, detailsData);
                            }}
                            disabled={handleDisableFunc(subTermData)}
                          ></McCheckbox>
                        </span>
                      )}
                    <span className="action-btn">
                      {(detailsData?.fieldWorkflowStatus === "Accept" ||
                        detailsData?.fieldWorkflowStatus ===
                          "NegotiatedAndAccepted") && (
                        <span className="agreedSatus">
                          <McIcon
                            icon="check-circle"
                            size="24"
                            color="#33a059"
                          ></McIcon>{" "}
                        </span>
                      )}

                      <span
                        className="accept-btn-section"
                        key={detailsData?.detailId}
                      >
                        {checkWriteAccess && getIndvslActionBtn(detailsData)}
                      </span>
                    </span>
                  </span>

                  {detailsData.inputs?.map((inputData: any, inputInd: any) => (
                    <>
                      <span className="content-div">
                        {detailsData.labelDescription}
                      </span>
                      <div
                        className="input-section"
                        data-testid="input-type"
                        key={inputData.standardTermDetailId}
                      >
                        {inputData.lineType !== "Description" &&
                          getInputType(
                            inputData,
                            mainData,
                            detailsData,
                            inputInd,
                            mainData.isNegotiable,
                            detailsData.revertLater
                          )}
                        {inputData.lineType !== "Description" &&
                          inputData.dataType !== "Checkbox" && (
                            <StandardClauseHistory
                              itemdata={inputData}
                              indicationId={indicationId}
                            />
                          )}
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}
    </div>
  );
};
export default InputSectionVO;