import { useState } from "react";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import { getAPI } from "../../../services/CepApiServices";
import moment from "moment";
import "./InitiateOfferAccept.scss";
interface IPROPS {
  index?: any;
  itemdata?: any;
  indicationId?: any;
}
const StandardClauseHistory = ({ index, itemdata, indicationId }: IPROPS) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [historyData, setHistoryData] = useState<any>([]);
  const [isLoader, setIsLoader] = useState(false);
  const handleHistoryClick = async () => {
    setIsHistoryOpen(!isHistoryOpen);
    setIsLoader(true);
    if (!isHistoryOpen) {
      const response = await getAPI(
        `InitialOffer/GetStandardTermDetailLog/${indicationId}/${itemdata.standardTermDetailId}`
      );
      if (response) {
        setHistoryData(response);
        setIsLoader(false);
      }
    }
  };
  const returnJoinData = (val: any) => {
    const joineddata: any = [];
    val?.forEach((element: any) => {
      if (element?.operation == 0) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<s>{newArr}</s>);
        } else {
          joineddata.push(<s>{element?.text}</s>);
        }
      }
      if (element?.operation == 2) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(newArr);
        } else {
          joineddata.push(element?.text);
        }
      }
      if (element?.operation == 1) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<b>{newArr}</b>);
        } else {
          joineddata.push(<b>{element?.text}</b>);
        }
      }
    });
    return joineddata;
  };

  const displayStandTermsHistoryContent = (val) => {
    return (
      <>
        <div className="dataValue">
          {val?.diffs?.length == 0 ? val?.value : returnJoinData(val?.diffs)}
        </div>
      </>
    );
  };

  return (
    <span
      data-testid="history-icon"
      className="historyicon-div"
      
      key={`history-${index}`}
    >
      <span className="history-icon-area" data-testid='history-icon-area' onClick={(e) => handleHistoryClick()}><McIcon icon="arrow-anti-clockwise-clock" color="#42b0d5" ></McIcon></span>
      {isHistoryOpen && (
        <div className="tooltip-section" key={`historyDiv${index}`}>
          <div className="closeIconDiv" data-testid='history-icon-circle' key={`historySection-${index}`} onClick={(e) => handleHistoryClick()}>
            <McIcon icon="times-circle"></McIcon>
          </div>
          <div className="tooltipData-div">
            <div className="tooltip-div-border">
              {isLoader && (
                <div className="loader-div">
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
              {historyData?.length === 0 && (
                <div className="no-data">No Data Available</div>
              )}
              {historyData?.map((item: any, index: any) => (
                <div
                  className="tooltip-div"
                  key={item.standardTermDetailHistoryId}
                >
                  <div className="header-section">
                    {item?.userType === "VesselOwner" ? (
                      <div className="voDiv">
                        <span className="icon">
                          <McIcon icon="user" color="#ff8766"></McIcon>
                        </span>
                        <span className="text">Vessel Owner</span>
                        <span className="date-div">
                          {moment(item?.createdOn)
                            .format("DD-MMM-YYYY HH:mm:ss")}
                        </span>
                      </div>
                    ) : (
                      <div className="cmDiv">
                        <span className="icon">
                          <McIcon icon="user" color="#43afd5"></McIcon>
                        </span>
                        <span className="text">Chartering Manager</span>
                        <span className="date-div">
                          {moment(item?.createdOn)
                            .format("DD-MMM-YYYY HH:mm:ss")}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="body-section">
                    <span className="main-content">{item?.description}</span>
                    <span className="description">
                      {item.descriptionDetail}
                    </span>
                    <span className="highlight-changes">
                      {displayStandTermsHistoryContent(item)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </span>
  );
};
export default StandardClauseHistory;
