// React
import { useState, useRef, useEffect } from "react";
import { McCheckbox, McIcon } from "@maersk-global/mds-react-wrapper";
import { postAPI, getAPI } from "../../../../services/CepApiServices";
import "./RiderClausePage.scss";
//Config
import { getUID } from "../../../../utils/token";
import RiderHistoryPage from "./RiderClauseHistory";
import { toast } from "react-toastify";
import addLogIcon from "../../../../assets/image-gallery.png";
import closeLogIcon from "../../../../assets/minimize.png";

interface IPROPS {
  indicationId?: any;
  cluaseData?: any;
  onAPiLoadNew?: any;
  userRole?: any;
  checkNegotiation?: any;
  isWriteAccess?: any;
  isEditing?: any;
  mainInfoData?: any;
  handleRevertCheck?: any;
  fromIssueRecap?: any;
  isPrefilledCondition?:any;
  extendedContractIDBCP?:any;
}

const RiderClause = ({
  indicationId,
  cluaseData,
  onAPiLoadNew,
  userRole,
  checkNegotiation,
  isWriteAccess,
  isEditing,
  mainInfoData,
  handleRevertCheck,
  fromIssueRecap,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const inputRef: any = useRef([]);
  const inputRef2 = useRef([]);
  const [textareaDiv, setTextareaDiv] = useState(false);
  const [acceptBtn, setAcceptBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(true);
  const [historyBtn, setHistoryBtn] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [paraId, setparaId] = useState<any>([]);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isLogDivOpen, setIsLogDivOpen] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const UID = getUID();

  useEffect(() => {
    if (isEditing) {
      if (!mainInfoData?.attachedBcp) {
        setTextareaDiv(true);
      } else if (
        cluaseData?.allowEdit === true &&
        cluaseData?.status !== "Accept"
      ) {
        setTextareaDiv(true);
      } else {
        setTextareaDiv(false);
      }
    } else {
      setTextareaDiv(false);
    }
  }, [isEditing]);

  useEffect(() => {
    fetchHistoryBtnCondition();
    fetchEditBtnCondition();
  });

  const fetchHistoryBtnCondition = () => {
    // if (
    //   (cluaseData?.riderClauseHistoryId != null || cluaseData?.lastNewClause == true) &&
    //   (cluaseData?.status === "NegotiatedAndAccepted" ||
    //     cluaseData?.status === "Awaiting")
    // ) {
    //   setHistoryBtn(true);
    // } else {
    //   setHistoryBtn(false);
    // }

      if (
      (cluaseData?.status === "NegotiatedAndAccepted" ||
        cluaseData?.status === "Awaiting")
    ) {
      setHistoryBtn(true);
    } else {
      setHistoryBtn(false);
    }

    // if(cluaseData?.riderClauseHistoryId!=0 && cluaseData?.newriderClause == false && cluaseData?.lastNewClause == false 
    //   && cluaseData?.status == "NegotiatedAndAccepted")
    // {
    //     setHistoryBtn(true);
    // } 
    // else if(cluaseData?.riderClauseHistoryId ==  null && cluaseData?.newriderClause == false && cluaseData?.lastNewClause== true 
    //   && cluaseData?.status == "NegotiatedAndAccepted")
    // {
    //   setHistoryBtn(true);
    // }
    // else if(cluaseData?.riderClauseHistoryId !=0 && cluaseData?.newriderClause == false && cluaseData?.lastNewClause == false 
    //   && cluaseData?.status == "Awaiting")
    // {
    //   setHistoryBtn(true);
    // } 
    // else if(cluaseData?.riderClauseHistoryId == null && cluaseData?.newriderClause == false && cluaseData?.lastNewClause == true
    //    && cluaseData?.status == "Awaiting")
    // {
    //   setHistoryBtn(true);
    // }
    // else if(cluaseData?.riderClauseHistoryId != 0 && cluaseData?.newriderClause == false && cluaseData?.lastNewClause == true 
    //   && cluaseData?.status == "Awaiting") {
    //   setHistoryBtn(true);
    // }
    // else if(cluaseData?.riderClauseHistoryId == 0 && cluaseData?.newriderClause == true && cluaseData?.lastNewClause == false 
    //   && cluaseData?.status == "Awaiting")
    // {
    //   setHistoryBtn(false);
    // } 
    // else if(cluaseData?.riderClauseHistoryId == null && cluaseData?.lastNewClause == false && cluaseData?.newriderClause == false 
    //   && cluaseData?.status == "Accept")
    // {
    //     setHistoryBtn(false);
    // }  
    // else if(cluaseData?.riderClauseHistoryId == null && cluaseData?.lastNewClause == false && cluaseData?.newriderClause  == false 
    //   && cluaseData?.status == "New")
    // {
    //     setHistoryBtn(false);
    // }
    // else 
    // {
    //   setHistoryBtn(false);
    // }
  };

  const fetchEditBtnCondition = () => {
    if (cluaseData?.status == "New") {
      setEditBtn(true);
    } else if (
      (userRole == "VesselOwner" || userRole == "OwnerRepresentative") &&
      cluaseData?.userType == "CharteringManager" &&
      cluaseData?.status == "Awaiting"
    ) {
      setEditBtn(true);
      setAcceptBtn(true);
    } else if (
      userRole == "CharteringManager" &&
      (cluaseData?.userType == "VesselOwner" ||
        cluaseData?.userType == "OwnerRepresentative") &&
      cluaseData?.status == "Awaiting"
    ) {
      setEditBtn(true);
      setAcceptBtn(true);
    } else if (cluaseData?.status == "Accept") {
      setEditBtn(false);
      setAcceptBtn(false);
    } else if (
      userRole == "CharteringManager" &&
      cluaseData?.userType == "CharteringManager" &&
      cluaseData?.status == "Awaiting"
    ) {
      setEditBtn(true);
      setAcceptBtn(false);
    } else if (
      userRole == "VesselOwner" &&
      cluaseData?.userType == "VesselOwner" &&
      cluaseData?.status == "Awaiting"
    ) {
      setEditBtn(true);
      setAcceptBtn(false);
    } else if (
      userRole == "OwnerRepresentative" &&
      cluaseData?.userType == "OwnerRepresentative" &&
      cluaseData?.status == "Awaiting"
    ) {
      setEditBtn(true);
      setAcceptBtn(false);
    } else {
      setEditBtn(false);
      setAcceptBtn(false);
    }
  };

  // const onAccept = async (obj: any) => {
  //   const acceptChanges = await getAPI(
  //     `Negotiation/AcceptRiderClauseAsync/${cluaseData?.riderClauseContentMasterId}`
  //   );
  //   if (acceptChanges) {
  //     onAPiLoadNew();
  //   }
  // };

  const onHistory = () => {
    setHistoryModalOpen(true);
    // isPrefilledCondition ? setparaId(cluaseData?.riderClauseContentMasterID) : setparaId(cluaseData?.riderClauseContentMasterId);
    setparaId(cluaseData?.riderClauseContentMasterId);
  };

  const closehistory = () => {
    setHistoryModalOpen(false);
  };

  const returnTextData = (val: any) => {
    const joineddata: any = [];
    val?.forEach((element: any) => {
      if (element?.displayFormat !== 1 && element?.displayFormat !== 3) {
        if (element?.text?.indexOf("<br>") > -1) {
          joineddata.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
        } else {
          joineddata.push(element?.text);
        }
      }
    });
    return joineddata.join("").replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' ');
  };
  const returnDataWithBr = (val: any) => {
    const value = val == "" ? 0 : val?.split("<br>");
    if (value?.length > 0) {
      const joineddata: any = [];
      let newArr: any = [];
      newArr.push(val?.replace(/<br\s*\\?>/g, "\r\n"));
      joineddata.push(newArr);
      return joineddata;
    } else {
      let newArr: any = [];
      newArr.push(val);
      return newArr;
    }
  };
  const returnJoinData = (val: any) => {
    const joineddata: any = [];
    // const highlightClasses = ['highlightBlack', 'highlightRed', 'highlightGreen'];
    // className={`${highlightClasses[element.highlight]}`}
    val?.forEach((element: any) => {
      // if (!fromIssueRecap && element?.operation == 0) {
      if (element?.displayFormat === 1) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<s className={`${element.highlight === 1 ? 'highlightText': ''}`}>{newArr}</s>);
        } else {
          joineddata.push(<s className={`${element.highlight === 1 ? 'highlightText': ''}`}>{element?.text}</s>);
        }
      }
      if (element?.displayFormat === 2) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(newArr);
        } else {
          joineddata.push(element?.text);
        }
      }
      if (element?.displayFormat == 0) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<b className={`${element.highlight === 1 ? 'highlightText': ''}`}>{newArr}</b>);
        } else {
          joineddata.push(<b className={`${element.highlight === 1 ? 'highlightText': ''}`}>{element?.text}</b>);
        }
      }
      if (element?.displayFormat === 3) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<b className={`${element.highlight === 1 ? 'highlightText': ''}`}><s>{newArr}</s></b>);
        } else {
          joineddata.push(<b className={`${element.highlight === 1 ? 'highlightText': ''}`}><s>{element?.text}</s></b>);
        }
      }
    });
    return joineddata;
  };
  const getContent = (val: any) => {
    const data = val?.content ? val?.content : "";
    return (
      <>
        <span>
          <span>{data}</span>;
        </span>
      </>
    );
  };
  const displayContent = (val: any) => {
    return (
      <>
        <table className="riderClauseBaseContent">
          <tbody>
            <tr>
              {/* {isPrefilledCondition ? (
                <td className="dataValue">
                {val?.change?.length ==0 || val?.change == null
                  ? returnDataWithBr(val?.content)
                  : returnJoinData(val?.change)}
              </td>
              ):
              ( */}
              <td className="dataValue">
              {val?.changeLists?.length ==0 || val?.changeLists == null
                ? returnDataWithBr(val?.content)
                : returnJoinData(val?.changeLists)}
            </td>
            {/* ) */}
              {/* } */}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const getCollapseContent = (val: any) => {
    return (
      <>
        {val?.changeLists?.length ==0 || val?.changeLists == null
                  ? returnDataWithBr(val?.content)
                  : returnJoinData(val?.changeLists)}
      </>
    );
  }

  const displayLogContent = (val: any) => {
    return (
      <>
        <table className="riderClauseBaseContent">
          <tbody>
            <tr>
              <td className="dataValue">
                {val?.historyDiffs == null
                  ? returnDataWithBr(val?.modifiedContent)
                  : returnJoinData(val?.historyDiffs)}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, pastedText);
  };
  const handlingRevert = async (event: any) => {
    if (mainInfoData?.attachedBcp) {
      handleRevertCheck(event, cluaseData);
      console.log(">>> ", indicationId, cluaseData?.titleId, event);
      await postAPI(
        `Negotiation/StageRCRevertNextCounter?indicationId=${indicationId}&titleId=${cluaseData?.titleId}&isChecked=${event}`
      );
    }
  };

  const handleBlur = async (canUpt: any) => {
    const content = canUpt?.current?.innerText;
    const newObjData = {
      indicationRequestId: Number(id),
      rideClauseContentMasterId: cluaseData?.riderClauseContentMasterId,
      content: content !== "\n" ? canUpt?.current?.innerText.replaceAll("\r\n", "<br>").replaceAll("\n", "<br>") : "",
      newriderClause: cluaseData?.newRiderClauseAddedButNotSubmitted,
    };
    const callSaveAPI = await postAPI(
      `Negotiation/AddRiderClauseHistory`,
      newObjData
    );
  };

  return (
    <>
      {cluaseData?.title !== "" && (
        <>
          <div className="cluase-heading">
            <div className="icons-div">
              {
                // !textareaDiv &&
                ((historyBtn && checkNegotiation) ||
                  fromIssueRecap === true) && (
                  <button
                    data-testid="on-history"
                    className={`rider-clause-btn ${
                      !textareaDiv ? "" : "upper"
                    } ${
                      cluaseData?.status == "Accept" ? "accept-position" : ""
                    }`}
                    onClick={() => onHistory()}
                  >
                    <McIcon
                      style={{ margin: "0px 0px" }}
                      icon="arrow-anti-clockwise-clock"
                      color="#fff"
                    ></McIcon>
                  </button>
                )
              }
              {textareaDiv && (
                <img
                  src={!isLogDivOpen ? addLogIcon : closeLogIcon}
                  className={`${
                    !isLogDivOpen ? " add-log-icon" : "close-log-icon"
                  }`}
                  alt="pencil-icon"
                  onClick={() => setIsLogDivOpen(!isLogDivOpen)}
                ></img>
              )}
            </div>
          </div>
          {historyModalOpen && (
            <div className="history-page-container">
              <RiderHistoryPage
                isModalOpen={historyModalOpen}
                riderClauseContentMasterId={paraId}
                indicationRequestId={id}
                onClose={closehistory}
                isPrefilledCondition={isPrefilledCondition}
                extendedContractIDBCP={extendedContractIDBCP}
              />
            </div>
          )}
          <div className="rider-clause-body">
            {!textareaDiv &&
              mainInfoData?.attachedBcp &&
              mainInfoData?.bcpEdit &&
              cluaseData?.status !== "Accept" && (
                <div className="label-div above-clause-body">
                  <span className="checkBox">
                    <McCheckbox
                      fit="small"
                      label="Revert Next Counter"
                      value={cluaseData?.checked ? "true" : "false"}
                      checked={cluaseData?.checked}
                      key={paraId}
                      disabled={true}
                    ></McCheckbox>
                  </span>
                </div>
              )}

            {cluaseData?.status == "Accept" && (
              <div className="collapsable-div">
                {!isCollapse && (
                  <div className="headerDisplay">
                    <span className="text text-overflow">{getCollapseContent(cluaseData)}</span>
                    <span
                      className="icon-to"
                      onClick={() => setIsCollapse(true)}
                    >
                      <McIcon icon="chevron-down" color="#42b0d5"></McIcon>
                    </span>
                  </div>
                )}
                {isCollapse && (
                  <div className="contentDisplay">
                    <div
                      className={`riderContentbody${
                        isEditing ? " main-copy" : ""
                      }`}
                    >
                      <pre>{displayContent(cluaseData)}</pre>
                      <span
                        className="icon-to"
                        onClick={() => setIsCollapse(false)}
                      >
                        <McIcon icon="chevron-up" color="#42b0d5"></McIcon>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {cluaseData?.status !== "Accept" && (
              // <div className={`riderContentbody${isEditing ? " main-copy" : ""}`}>
              <>
                <div className="riderContentbody main-copy">
                  <pre>{displayContent(cluaseData)}</pre>
                </div>
                {/* {textareaDiv && isLogDivOpen && cluaseData?.modifiedContent && (
                  <div className="riderContentbody log-copy">
                    <pre>{displayLogContent(cluaseData)}</pre>
                  </div>
                )} */}
              </>
            )}
            <div className={`rider-editor-div ${isLogDivOpen ? 'show-editor': ''}`}>
              {/* If terms are accepted, if allowParaEdit: true = dont show editor */}
              {textareaDiv && (
                <>
                  <h6>Editor</h6>
                  {mainInfoData?.attachedBcp && mainInfoData?.bcpEdit && (
                    <div className="label-div">
                      <span className="checkBox">
                        <McCheckbox
                          data-testid="handling-revert"
                          fit="small"
                          label="Revert Next Counter"
                          value={cluaseData?.checked ? "true" : "false"}
                          checked={cluaseData?.checked}
                          key={paraId}
                          change={(e: any) => {
                            handlingRevert(e.target.checked);
                          }}
                        ></McCheckbox>
                      </span>
                    </div>
                  )}

                  <div
                    className={`riderEditorBody ${
                      cluaseData?.checked ? "checked-content" : ""
                    }`}
                  >
                    <div
                      data-testid="handle-blur"
                      className="divtext"
                      ref={(el) => (inputRef.current = el)}
                      id={cluaseData.titleId}
                      contentEditable
                      suppressContentEditableWarning={true}
                      onBlur={() => {
                        handleBlur(inputRef);
                      }}
                      onPaste={handlePaste}
                    >
                      {cluaseData?.changeLists?.length == 0 || cluaseData?.changeLists == null ? (
                        <pre>{displayContent(cluaseData)}</pre>
                      ) : (
                        <pre>{returnTextData(cluaseData?.changeLists)}</pre>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <></>
    </>
  );
};
export default RiderClause;
