import React, { Component, useEffect, useState } from 'react';
import { EditorState,ContentState,convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { TDocument } from '../model';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


interface IPROPS {
    contractLine: TDocument;
    isReadOnly: boolean;
    onSave: (value: string) => void;
}

const DocumentEditor = ({
    contractLine,
    onSave,
    isReadOnly
}) => {
    const toolbarConfig = {
        options: ['inline'], // this line is important
        inline: {
            inDropdown: false,
            options: ['bold', 'strikethrough'],
        },
    };
    const [editorState, setEditorState] = useState('');
    useEffect(() => {
        const contentBlock = htmlToDraft(contractLine.content || '');
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [contractLine.content]);
 
    const handleChange = (value) => {
        setEditorState(value);
         const html = draftToHtml(convertToRaw(value.getCurrentContent()));
         const regex = /&nbsp;|<p>|<(\s*\/?(?!strong|del|br|p)\w+)[^>]*>/gi;
         let modifiedContent = html.replace(regex, '');
         modifiedContent = formatHtml(html);
        if (modifiedContent !== (contractLine.content|| '').trim()) {
            // contractLine.content = html;
            const newContractLine = { ...contractLine, content: modifiedContent };
            onSave(newContractLine);
        }
       
    };
  
    const formatHtml = (inputString) => {
        inputString = inputString.replace(/<p>/g, '').replace(/<\/p>/g, '');
        inputString = inputString.replace(/\n/g, '<br>');
        inputString = inputString.replace(/<\/p>/g, '<br>');
        inputString = inputString.replace(/&amp;/g, '&');
        inputString = inputString.replace(/&nbsp;/g, '');
        let lastIndex = inputString.lastIndexOf('<br>');
        if (lastIndex !== -1) {
            const firstPart = inputString.substring(0, lastIndex);
            const lastPart = inputString.substring(lastIndex).replace('<br>', '');
            inputString = firstPart + lastPart;
        }
        return inputString;
    };


    return (
        <div>
            <Editor
            readOnly={isReadOnly}
            editorState={editorState}
            toolbar={toolbarConfig}
            onEditorStateChange={handleChange}
            toolbarHidden = {isReadOnly}
            handlePastedText={() => false} 
        />
        </div>
    );
}

export default DocumentEditor;