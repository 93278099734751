import { useState, useEffect } from "react";
import { McIcon, McButton } from "@maersk-global/mds-react-wrapper";
import "./IssueRecapSOT.scss";
import "react-datepicker/dist/react-datepicker.css";
import InputSection from "./InputSection";
import { toast } from "react-toastify";
import { postAPI } from "../../../services/CepApiServices";
import ConfirmationModal from "../../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import { AppString } from "../../../utils/AppString";

import NoData from "../../../components/NoData/NoData";

import { getUserRole } from "../../../utils/token";
interface IPROPS {
  data?: any;
  submitData?: any;
  indicationId?: any;
  reRenderApiFunc?: any;
  leftmenu?: any;
  isPrefilledCondition?: any;
}
const IssueRecapStandardOffer = ({
  data,
  submitData,
  indicationId,
  reRenderApiFunc,
  isPrefilledCondition,
}: IPROPS) => {
  const [mainData, setMainData] = useState<any>([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [keyData, setKeyData] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>(1);
  const [leftList, setLeftList] = useState<any>([]);
  const [userRole, setUserRole] = useState();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userTypeFromURL = params.get("user");

  const fetchToken = async () => {
    const role = await getUserRole();
    setUserRole(role);
  };
  useEffect(() => {
    fetchToken();
  }, []);
  const handleLeftMenu = (id: any, response: any, leftMenu: any) => {
    const updatedList = leftMenu.map((item) => {
      if (item.id === id) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setLeftList(updatedList);
    setKeyData(response?.filter((item: any) => item.termId === id));
    setSelectedId(id);
  };
  const handleOnTextchange = (value: any, itemValue: any) => {
    const newArray = mainData.map((val: any) => {
      if (val.termId === itemValue.termId) {
        val.subTerms.map((subtermData: any) => {
          if (subtermData.subTermId === itemValue.subTermId) {
            subtermData.details.map((detailsData: any) => {
              if (detailsData.detailId === itemValue.detailId) {
                detailsData.inputs.map((inputData: any) => {
                  if (
                    inputData.standardTermDetailId ===
                    itemValue.standardTermDetailId
                  ) {
                    inputData.value = value;
                  }
                  return inputData;
                });
              }
              return detailsData;
            });
          }
          return subtermData;
        });
      }
      return val;
    });
    setKeyData(newArray?.filter((item: any) => item.termId === selectedId));
    setMainData(newArray);
  };

  const radioChange = (checkedVal: any, itemValue: any) => {
    const newArray = mainData.map((val: any) => {
      if (val.termId === itemValue.termId) {
        val.subTerms.map((subtermData: any) => {
          if (subtermData.subTermId === itemValue.subTermId) {
            subtermData.details.map((detailsData: any) => {
              const otherInputObj = detailsData?.inputs?.filter(
                (inputData: any) =>
                  inputData.dataType === "SelectedCheckbox" &&
                  inputData.detailId !== itemValue.detailId
              )[0];

              if (detailsData.detailId === itemValue.detailId) {
                detailsData.inputs.map((inputData: any) => {
                  if (
                    inputData.standardTermDetailId ===
                    itemValue.standardTermDetailId
                  ) {
                    inputData.value = checkedVal.target.checked;
                  }
                  return inputData;
                });
              }
              if (otherInputObj) {
                if (detailsData.detailId === otherInputObj?.detailId) {
                  detailsData.inputs.map((inputData: any) => {
                    if (
                      inputData.standardTermDetailId ===
                      otherInputObj.standardTermDetailId
                    ) {
                      inputData.value = !checkedVal.target.checked;
                    }
                    return inputData;
                  });
                }
              }
              return detailsData;
            });
          }
          return subtermData;
        });
      }
      return val;
    });
    setKeyData(newArray?.filter((item: any) => item.termId === selectedId));
    setMainData(newArray);
  };
  const handleIssueRecap = async () => {
    const payload = {
      indicationRequestId: Number(indicationId),
      negotiationDocumentCategory: "Standard Offer Document",
      recapStatus: "Issue Recap",
      initiatedByCM: userRole == "CharteringManager" ? true : false,
    };
    const response = await postAPI(`Negotiation/SubmitRecap`, payload);
    if (response.StatusCode) {
      console.log("error", response.StatusCode);
    } else {
      toast.success(`Issue Recap successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      reRenderApiFunc();
    }
  };
  useEffect(() => {
    if (data) {
      const leftData = data?.map((item: any) => ({
        id: item.termId,
        name: item.termName,
        mainTermAccepted: item.mainTermAccepted,
        isReverted: item.revertLaterCounterEnabled,
        active: item.termId === 1 ? true : false,
      }));
      setLeftList(leftData);
      setMainData(data);
      const leftId = leftData.length > 0 ? leftData[0]?.id : 1;
      handleLeftMenu(leftId, data, leftData);
    }
  }, [data]);

  const displayCnfrmModal = () => {
    setmodalOpen(true);
  };

  const saveOrCancelIssueRecap = (response: string) => {
    if (response == "Yes") {
      handleIssueRecap();
      setmodalOpen(false);
    } else {
      setmodalOpen(false);
    }
  };
  useEffect(() => {
    fetchToken();
  }, []);
  return (
    <div className="IssueRecapstandardOffer-section">
      {submitData?.standardTemplateMainTerms?.length === 0 ? (
        <NoData text={"No Recap Data Found.."} />
      ) : (
        <div className="data-section">
          <div className="left-dataSection">
            <p className="main-heading">Main Terms</p>
            <ul>
              {leftList.map((item) => (
                <li
                  data-testid="handle-left-menu"
                  className={`${item.active && "active"}`}
                  onClick={() => handleLeftMenu(item?.id, mainData, leftList)}
                >
                  {item?.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="right-dataSection">
            {submitData?.recapStatus == null &&
            submitData?.standardTemplateMainTerms?.length > 0 &&
            submitData?.baseCharterPartyRecapDetail?.length > 0 ? (
              <div className="submitBtn">
                <McButton
                  fit="small"
                  label="Issue Recap"
                  data-testid="issueRecapBtn"
                  onclick={() => {
                    displayCnfrmModal();
                  }}
                ></McButton>
              </div>
            ) : (
              submitData?.standardTemplateMainTerms?.length > 0 &&
              submitData?.recapStatus?.isIssueRecaped && (
                <div className="status-msg">
                  <span className="icon">
                    <McIcon icon="check-circle" color="#18c618">
                      {" "}
                    </McIcon>
                  </span>
                  <span className="text">Issue Recaped for Main Terms</span>
                </div>
              )
            )}

            <div className="each-dataSection">
              <div className="header-div">
                <span className="icon"> {keyData && keyData[0]?.termId} </span>
                <span className="text">
                  {keyData && keyData[0]?.termName} Main Terms
                </span>
              </div>
              <div className="data-div">
                {keyData &&
                  keyData[0]?.subTerms.map((subTermData: any, index) => (
                    <InputSection
                      key={subTermData.subTermId}
                      subTermData={subTermData}
                      handleChangeRadio={radioChange}
                      renderFunc={reRenderApiFunc}
                      indicationId={indicationId}
                      submitData={submitData}
                      handleChangeFunc={handleOnTextchange}
                      isPrefilledCondition={isPrefilledCondition}
                    />
                  ))}
                <ConfirmationModal
                  onModalOpen={modalOpen}
                  onModalClose={saveOrCancelIssueRecap}
                  modalTxt={AppString.confirmIssueRecapModalTxt}
                  width={"auto"}
                  height={"140px"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default IssueRecapStandardOffer;