import {
  NPS_FORM_ID,
  NPS_FORM_ID_SIT,
  NPS_FORM_ID_PROD,
} from "../utils/Constants";
import { format } from "date-fns";
import { postAPI } from "../services/CepApiServices";

export const postNPSFeedback = async (data: {
  rating: number;
  comment: string;
  userEmail: string;
  userName: string;
}) => {
  const userEmail = data.userEmail;
  const userName = data.userName;
  let NPSID: any;

  if (process.env.REACT_APP_ENVIRONMENT == "Dev") {
    NPSID = NPS_FORM_ID;
  } else if (process.env.REACT_APP_ENVIRONMENT == "Sit") {
    NPSID = NPS_FORM_ID_SIT;
  } else if (process.env.REACT_APP_ENVIRONMENT == "prod") {
    NPSID = NPS_FORM_ID_PROD;
  }

  const requestBody = {
    formData: {
      rating: data.rating.toString(),
      feedback: data.comment,
    },
    metadata: {
      host: "feedback.maersk-digital.net",
      pathname: `/form/feedback/${NPSID}`,
      pagetitle: "NPS Feedback Form",
    },
    hidden: { email: userEmail, name: userName },
  };
  const LAST_FEEDBACK_TIMESTAMP_KEY = "last_feedback_timestamp";

  try {
    await postAPI(`Feedback/ProxyFeedback?npsFormId=${NPSID}`, requestBody);
    // Save current timestamp to local storage upon successful feedback submission
    const currentTimestamp = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    localStorage.setItem(LAST_FEEDBACK_TIMESTAMP_KEY, currentTimestamp);
    return true;
  } catch (error) {
    throw error;
  }
};
