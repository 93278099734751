import { useEffect, useState } from "react";
import { getAPI, postAPI } from "../../../../services/CepApiServices";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import moment from "moment";
import "../HistoryPage.scss";
import { toast } from "react-toastify";

interface IPROPS {
  isModalOpen: any;
  riderClauseContentMasterId: any;
  indicationRequestId: any;
  onClose: any;
  isPrefilledCondition:any;
  extendedContractIDBCP:any;
}
const RiderHistoryPage = ({
  isModalOpen,
  riderClauseContentMasterId,
  indicationRequestId,
  onClose,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const [riderClauseHistoryData, setRiderClauseHistoryData] = useState<any>([]);
  const [isLoader, setIsLoader] = useState(true);
  const handleRiderClauseHistory = async () => {
    let response:any=[];
    if(isPrefilledCondition){
      response = await getAPI(
        `Negotiation/GetRiderClauseDocumentHistory/${extendedContractIDBCP}/${riderClauseContentMasterId}`+`/`+true
      );
    }else {
      response = await getAPI(
        `Negotiation/GetRiderClauseDocumentHistory/${indicationRequestId}/${riderClauseContentMasterId}`+`/`+false
      );
    }  
    if (!response.StatusCode) {
      setRiderClauseHistoryData(response);
      setIsLoader(false);
    } else {
      // toast.error(`History loading failed`, {
      //   theme: "colored",
      //   icon: false,
      //   autoClose: 3000,
      // });
      onCancel();
    }
  };
  useEffect(() => {
    handleRiderClauseHistory();
  }, []);

  const onCancel = () => {
    onClose();
  };
  const returnDataWithBr = (val: any) => {
    const value = val == "" ? 0 : val?.split("<br>");
    if (value?.length > 0) {
      const joineddata: any = [];
      let newArr: any = [];
      newArr.push(val?.replace(/<br\s*\\?>/g, "\r\n"));
      joineddata.push(newArr);
      return joineddata;
    } else {
      let newArr: any = [];
      newArr.push(val);
      return newArr;
    }
  };

  const returnJoinData = (val: any) => {
    const joineddata: any = [];
    const highlightClasses = ['highlightBlack', 'highlightRed', 'highlightGreen'];
    val.forEach((element: any) => {
      if (element?.displayFormat === 0) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          // joineddata.push(<b className={`${highlightClasses[element.highlight]}`}>{newArr}</b>);
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</b>);
        } else {
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
        }
      }
      if (element?.displayFormat === 1) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
        } else {
          joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
        }
      }
      if (element?.displayFormat === 2) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(newArr);
        } else {
          joineddata.push(element?.text);
        }
      }
      if (element?.displayFormat === 3) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
        } else {
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
        }
      }
    });
    return joineddata;
  };

  const displayContent = (val: any) => {
    return (
      <>
        <table className="riderClauseBaseContent">
          <tbody>
            <tr>
              <td className="dataValue">
                {val?.changes?.length ==0 || val?.changes== null
                  ? returnDataWithBr(val?.content)
                  : returnJoinData(val?.changes)}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="history">
      <div className="closeIconDiv">
        <McIcon
          data-testid="close-icon"
          icon="times-circle"
          className="close-icon"
          onClick={() => onCancel()}
        ></McIcon>
      </div>
      <div className="history-page">
        {isLoader ? (
          <div data-testid="loader" className="loader-div">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <>
            {riderClauseHistoryData?.length === 0 && (
              <div className="no-data">No Data Available</div>
            )}
            {riderClauseHistoryData?.filter(item => item.createdUserType !== "System" && item.createdBy !== "System").map((item: any, index: any) => (
              <div key={index} className="key">
                <div className="row">
                <div className="col history-head">
                    {(item?.createdUserType === "VesselOwner" || item?.createdUserType === "OwnerRepresentative") && (
                      <span className="voDiv">
                        <span className="user-icon">
                          <McIcon
                            icon="user"
                            color="#ff8766"
                            size="20"
                          ></McIcon>
                        </span>
                        <span className="text">Vessel Owner</span>
                      </span>
                    )}
                    {item?.createdUserType === "CharteringManager" && (
                      <div className="cmDiv">
                        <span className="user-icon">
                          <McIcon icon="user" color="#43afd5" size="20"></McIcon>
                        </span>
                        <span className="text">Chartering Manager</span>
                      </div>
                    )}
                  </div>
                  <span className="history-head-right col">
                    {" "}
                    {moment(item?.createdDate)
                      .format("DD-MMM-YYYY HH:mm:ss")}
                  </span>
                </div>
                <span className="createdBy">
                  Changes made by : {item?.createdBy}
                </span>
                <div className="edit-page">
                  {/* <div>{item?.content?.replaceAll("<br>", "")}.</div> */}
                  <div>{displayContent(item)}</div>
                </div>
                <div className="horizontal-line"></div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default RiderHistoryPage;
