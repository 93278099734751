// import { HttpClientHandler } from "../httpHandler/HttpClientHandler";
import { getToken } from "../utils/token";
import { protectedResources } from "../authConfig";
export const VesselSupplyDataListService = async (searchText: any) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      protectedResources.apiTodoList.endpoint + `${searchText}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const CharterMarketService = async (
  pageNumber: number,
  itemsPerPage: number,
  postData: any
) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    // headers.append("Authorization", bearer);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(postData),
    };
    return fetch(protectedResources.apiTodoList.endpoint3, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const CharterMarketFiltersService = async (url: any) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };
    return fetch(
      protectedResources.apiTodoList.endPointCommon + `${url}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const DownloadVesselSupplyDataListService = async (searchText: any) => {
  try {
    const accessToken = await getToken(
      protectedResources.apiTodoList.scopes.read
    );
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      protectedResources.apiTodoList.endpoint4 + `${searchText}`,
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};
