import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import Zoom from './Zoom';
import { useEffect, useState } from 'react';
import { getAPI } from '../../../services/CepApiServices';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
interface IPROPS {
  documentLoad?: any;
  pageNumber?: number;
  pdfName: string;
}

const PDFViewer = ({
  documentLoad,
  pageNumber,
  pdfName
}: IPROPS) => {
  const [scale, setScale] = useState(1.50);
  const handleZoomIn = () => setScale(scale + 0.1);
  const handleZoomOut = () => setScale(scale - 0.1);
  const [pdfContent, setPDFContent] = useState('');
  useEffect(() => {
    getPDFContent();
  }, []);

  const getPDFContent = async () => {
    const responseValue = await getAPI(
      `DocumentLibrary/GetPDFContent/${pdfName}`,"text"
    )
    setPDFContent(responseValue);
  }

  return (
    <div >
      <Zoom onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
      {pdfContent && (
        <Document file={`data:application/pdf;base64,${pdfContent}`} onLoadSuccess={documentLoad}>
          <Page pageNumber={pageNumber} scale={scale} renderAnnotationLayer={false} />
        </Document>
      )}
    </div>
  );
}

export default PDFViewer;