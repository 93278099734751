import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  downloadReportDocuments,
  getAPI,
  postAPI,
} from "../../../services/CepApiServices";
interface IPROPS {
  isModalOpen?: boolean;
  indicationId?: any;
  onCanceled?: any;
  reRenderApiFunc?: any;
  selectedDocId?: any;
}
const ChangeDocBCPModal = ({
  isModalOpen,
  indicationId,
  onCanceled,
  reRenderApiFunc,
  selectedDocId,
}: IPROPS) => {
  const [documents, setDocuments] = useState<any>([]);
  const [documentSelected, setDocumentSelected] = useState(false);
  const [checkeddoc, setCheckeddoc] = useState();
  const [docToggle, setDocToggle] = useState(true);
  const [isDocLoading, setIsDocLoading] = useState(false);
  const history = useNavigate();

  const handleSubmit = async (checkeddoc: any) => {
    const indicationReqId = Number(indicationId);
    const docId = Number(checkeddoc.documentId);

    const response = await postAPI(
      `Negotiation/ChangeContractDocument?indicationId=${indicationReqId}&docId=${docId}`
    );
    if (response) {
      reRenderApiFunc();
    }
    onCanceled("yes");
  };

  const fetchNewFixtureDoc = async () => {
    setIsDocLoading(true);
    setDocToggle(true);
    const response = await getAPI(`DocumentLibrary/GetAllDocuments`);
    if (response) {
      const filteredDocuments = response.filter(
        (document) =>
          document.status === "Reviewed" && document.documentCategoryId === 67
      );
      setDocuments(filteredDocuments);
      setIsDocLoading(false);
    }
  };

  useEffect(() => {
    fetchNewFixtureDoc();
  }, []);

  const handleDownload = async (fileName: any, categoryName: any) => {
    await downloadReportDocuments(
      `DocumentLibrary/DownlaodDocument/${fileName}/${categoryName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("Could not download the file");
      });
  };
  const downloadFile = (fileName: any, categoryName: any) => {
    return handleDownload(fileName, categoryName);
  };
  const handleDocumentClick = (id: any) => {
    const newArray = documents?.map((element: any) => {
      if (element.documentId === id) {
        element.checked = true;
        setCheckeddoc(element);
      } else {
        element.checked = false;
      }
      return element;
    });
    setDocuments(newArray);
    setDocumentSelected(true);
  };
  const onCancel = () => {
    setDocuments([]);
    setDocumentSelected(false);
    onCanceled("no");
  };

  return (
    <McModal
      height="400px"
      width="800px"
      className="initiate-offer-modal"
      data-testid="change-doc-modal"
      // heading="Change Selected Document"

      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="initiate-offer-modal-content">
        <>
          {docToggle ? (
            <div className="select-documents">
              <div className="header-div">
                <span className="icon">
                  <McIcon icon="file"></McIcon>
                </span>
                <span className="icon-text" data-testid="select-document">
                  Select Contract Document
                </span>
              </div>
              <div className="body-div">
                {documents.length === 0 && isDocLoading && (
                  <div className="loader-div">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                {documents.length === 0 && !isDocLoading && (
                  <div className="no-documents no-reviewed-doc">
                    <span>No reviewed documents found</span>
                  </div>
                )}
                <div className="row">
                  {documents?.map((data: any) => (
                    <div
                      data-testid="handle-new-doc"
                      className="col-md-4"
                      onClick={() => {
                        if (data.documentId !== selectedDocId) {
                          handleDocumentClick(data.documentId);
                        }
                      }}
                    >
                      <div
                        className={
                          data?.checked === true
                            ? "download-div active"
                            : selectedDocId === data.documentId
                            ? "download-div already-selected-doc select-active"
                            : "download-div"
                        }
                      >
                        <span className="doc-name" title={data?.documentName}>{data?.documentName}</span>
                        <span
                          className="icon-div"
                          onClick={() => {
                            downloadFile(
                              data?.documentName,
                              data?.documentCategoryName
                            );
                          }}
                          data-testid="doc-download"
                        >
                          <McIcon icon="tray-arrow-down"></McIcon>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="select-documents">
              <div className="no-documents">
                <span>
                  There is no previous contract for the selected vessel please
                  select document from below.
                </span>
              </div>
              <div className="header-div">
                <span className="icon">
                  <McIcon icon="file"></McIcon>
                </span>
                <span className="icon-text">Select Contract Documents</span>
              </div>
              <div className="body-div">
                {documents.length === 0 && isDocLoading && (
                  <div className="loader-div">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                {documents.length === 0 && !isDocLoading && (
                  <div className="no-documents no-reviewed-doc">
                    <span>No reviewed documents found</span>
                  </div>
                )}
                <div className="row">
                  {documents?.map((data: any) => (
                    <div
                      className="col-md-4"
                      onClick={() => handleDocumentClick(data.documentId)}
                    >
                      <div
                        className={
                          data?.checked === true
                            ? "download-div active"
                            : "download-div"
                        }
                      >
                        <span className="doc-name" title={data?.documentName}>{data?.documentName}</span>
                        <span
                          className="icon-div"
                          onClick={() => {
                            downloadFile(
                              data?.documentName,
                              data?.documentCategoryName
                            );
                          }}
                        >
                          <McIcon icon="tray-arrow-down"></McIcon>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      {documentSelected && (
        <McButton
          slot="primaryAction"
          variant="primary"
          fit="small"
          label="Select Document"
          onClick={() => handleSubmit(checkeddoc)}
          data-testid="save-and-update"
        ></McButton>
      )}
      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        role="cancel-button"
        fit="small"
        onClick={() => onCancel()}
      >
        Cancel
      </McButton>
    </McModal>
  );
};
export default ChangeDocBCPModal;
