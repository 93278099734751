// react
import React from "react";
import { useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

// Config
import { loginRequest } from "../authConfig";

// Pages
import HomePage from "../pages/homePage/HomePage";
import VesselSupplyDataList from "../pages/vessel_supply_data_list/VesselSupplyDataList";

// Components
import { NavigationBar } from "../components/navigationBar/NavigationBar";
import SideNavBar from "../components/sideNavBar/SideNavBar";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const authRequest: any = {
    ...loginRequest,
  };
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <div className="App">
          <header className="App-header"></header>
        </div>
        <br />
        <div className="vessel-supply-data">
          <HomePage />
        </div>
        <br />
      </MsalAuthenticationTemplate>
    </>
  );
};
