import React, { Component } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { Container, Row, Col } from "reactstrap";
import "./dateSlider.scss";
import moment from "moment";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
interface IPROPS {
  startDate?: any;
  endDate?: any;
  onRRChange?: any;
}
interface STATES {
  startDate?: any;
  startDateLabel?: any;
  endDate?: any;
  endDateLabel?: any;
  minRange?: any;
  maxRange?: any;
  currentValue?: any;
  flag?:boolean;
}
class DateSlider extends Component<IPROPS, STATES> {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      startDateLabel: "",
      endDate: "",
      endDateLabel: "",
      currentValue: [],
      minRange: 0,
      maxRange: 100,
      flag:false,
    };

    this.tipFormatter = this.tipFormatter.bind(this);
  }

  componentDidMount() {
    this.fakeApiCall();
  }

  fakeApiCall() {
    let startDate = this.props?.startDate;
    let endDate = this.props?.endDate;
    const startDateMinus90 = moment(startDate).subtract(90, 'days');
    const endDatePlus180 = moment(endDate).add(180, 'days');
    this.setState({
      startDate: startDateMinus90.format('YYYY-MM-DD'),
      endDate: endDatePlus180.format('YYYY-MM-DD'),
      startDateLabel: startDate,
      endDateLabel: endDate,
    });
    let range = this.calculateDateRange(startDate, endDate);
    this.setState({
      minRange: 0,
      maxRange: Math.abs(range)+90+180,
      currentValue: [90, Math.abs(range)+90]
    });
  }

  onDateChange: any = ([newStartDate, newEndDate]) => {
    this.setState(
      {
        flag: true,
        currentValue: [newStartDate, newEndDate],
      },
      () => {
        this.updateDates();
      }
    );
    setTimeout(() => {
      this.props?.onRRChange(this.state);
    }, 1000);
  };

  updateDates() {
    let [min, max] = this.state.currentValue;
    let start = moment(this.state.startDate).add(min, "d");
    let end = moment(this.state.endDate).subtract(
      this.state.maxRange - max,
      "d"
    );

    this.setState({
      startDateLabel: start.format('DD-MMM-YYYY'),
      endDateLabel: end.format('DD-MMM-YYYY'),
    });
  }

  calculateDateRange(startDateStr, endDateStr) {
    let startDate = moment(startDateStr, "YYYY-MM-DD");
    let endDate = moment(endDateStr, "YYYY-MM-DD");
    const range = startDate > endDate ? startDate.diff(endDate, "days") : endDate.diff(startDate, "days");
    return range;
  }

  tipFormatter(value) {
    let [min, max] = this.state.currentValue;
    if (min == value) {
      return <div className='p-1 text-center'>{this.state.flag === true ?  this.state.startDateLabel: moment(this.state.startDate).add(91, 'days').utc().format('DD-MMM-YYYY')}</div>;
    } else if (max == value) {
      return <div className='p-1 text-start'>{this.state.flag === true ? this.state.endDateLabel: moment(this.state.endDate).subtract(179, 'days').utc().format('DD-MMM-YYYY')}</div>;
    }
  }

  formatDate(date: any) {
    let day,
      month,
      year = 0;
    day = date.get("date");
    month = date.get("month")+1;
    year = date.get("year");
    return day + "-" + month + "-" + year;
  }

  render() {
    return (
      <div className="date-slider">
        <span className="left-date" data-testid="left-date-label">{this.state.flag == true ?  this.state.startDateLabel: moment(this.state.startDate).add(91, 'days').utc().format('DD-MMM-YYYY') }</span>

        <div className="text-center">
          <Range
            allowCross={false}
            min={this.state.minRange}
            max={this.state.maxRange}
            value={this.state.currentValue}
            tipFormatter={this.tipFormatter}
            onChange={this.onDateChange}
          />
        </div>
        <span className="right-date" data-testid="right-date-label">{this.state.flag == true ?  this.state.endDateLabel: moment(this.state.endDate).subtract(179, 'days').utc().format('DD-MMM-YYYY') }</span>
      </div>
    );
  }
}

export default DateSlider;
