import {
  McModal,
  McButton,
  McCheckbox,
} from "@maersk-global/mds-react-wrapper";
import { useEffect, useState } from "react";
import { postAPI } from "../../../services/CepApiServices";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
import moment from "moment";
import "./InitiateOfferAccept.scss";
import { userInfo } from "os";
interface IPROPS {
  isModalOpen?: any;
  onCloseFunc?: any;
  termsAcceptTimeline?: any;
  indicationId?: any;
  reLoadApis?: any;
  userDetail?: any;
  AttachBCPFromApi?: any;
  attachCharterPartyCBox?: any;
  isDocSelected?: any;
  mainInfoData?: any;
  enableNegotiation?:any;
}
const SubmitNegotiationModal = ({
  isModalOpen,
  onCloseFunc,
  termsAcceptTimeline,
  indicationId,
  reLoadApis,
  userDetail,
  enableNegotiation,
  AttachBCPFromApi,
  attachCharterPartyCBox,
  mainInfoData,
  isDocSelected,
}: IPROPS) => {
  const [valueTime, onChangeDateTime] = useState(new Date());
  const [isAttachBCP, setIsAttachBCP] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [isConfidential, setIsConfidential] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const handleSubmit = async () => {
    if (!isDateSelected) {
      setErrorMsg(true);
      return;
    }
    setIsSubmitting(true);
    const timeValue = moment(valueTime).format("YYYY-MM-DDTHH:mm");
    const enableNegotiationdata = mainInfoData?.bcpEdit !== true ? userDetail === 'CM' ? enableNegotiation : false : true;
    const response = await postAPI(
      `Negotiation/SubmitNegotiationAsync?IndicationRequestId=${indicationId}&timeLine=${timeValue}&attachBcp=${isAttachBCP}&isConfidential=${isConfidential}&isBcpEdit=${enableNegotiationdata}`
    );
    onCloseFunc();
    if (response.StatusCode) {
      toast.error(`Submission Failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsSubmitting(false);
    } else {
      toast.success(`Submitted Successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsSubmitting(false);
    }
    reLoadApis();
  };
  const handleDateChange = (e: any) => {
    onChangeDateTime(e);
    if (e) {
      setIsDateSelected(true);
      setErrorMsg(false);
    } else {
      setIsDateSelected(false);
    }
  };
  const onCancelData = () => {
    onCloseFunc();
  };
  useEffect(() => {
    setIsDateSelected(false);
    setErrorMsg(false);
    // setIsAttachBCP(AttachBCPFromApi);
    onChangeDateTime(termsAcceptTimeline);
    console.log(AttachBCPFromApi);
    if(!AttachBCPFromApi){
      if(userDetail == "CM"){
        setIsAttachBCP(attachCharterPartyCBox);
      }
      else{
        setIsAttachBCP(AttachBCPFromApi)
      }
    }else{
      setIsAttachBCP(AttachBCPFromApi);
    }
  }, [isModalOpen]);
  return (
    <>
      <McModal
        height="450px"
        width="450px"
        className="submit-negotiation-modal"
        heading={`Submit Counter/Offer`}
        open={isModalOpen}
        backdropcloseactiondisabled
      >
        <div className="date-section">
          <label>Select due date/time for your counter response</label>
          <DateTimePicker
            format="MM/dd/yyyy HH:mm"
            onChange={handleDateChange}
            value={valueTime}
            minDate={new Date()}
          />
          {errorMsg && <div className="error-msg">Please Select Date</div>}
          {mainInfoData?.counterNumber === 0 &&
            mainInfoData?.showConfidentialCheckbox && (
              <div className="checkbox-div">
                <McCheckbox
                  data-testid="attach-bcp"
                  checked={isConfidential}
                  change={(e) => setIsConfidential(!isConfidential)}
                  label="is All negotiations and eventual fixture to be kept strictly private and confidential ?"
                ></McCheckbox>
              </div>
            )}

          {/* {userDetail === "CM" && !AttachBCPFromApi && isDocSelected && (
            <div className="checkbox-div">
              <McCheckbox
                data-testid="attach-bcp"
                checked={isAttachBCP}
                change={(e) => setIsAttachBCP(!isAttachBCP)}
                label="Attach Charter Party"
              ></McCheckbox>
            </div>
          )} */}
        </div>

        <McButton
          slot="primaryAction"
          variant="primary"
          fit="small"
          label="Submit"
          onClick={handleSubmit}
          disabled={isSubmitting}
          data-testid="save-and-update"
        ></McButton>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
          onClick={onCancelData}
          role="cancel-cp"
          fit="small"
        >
          Cancel
        </McButton>
      </McModal>
    </>
  );
};
export default SubmitNegotiationModal;
