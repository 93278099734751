import "./IndicationRequest.scss";
import IndicationRequestTableVO from "./IndicationRequestVOPages/IndicationRequestTableVO/IndicationRequestTableVO";
const IndicationRequestVO = () => {
  return (
    <div className="indication-requestMain">
      <div className="container-fluid">
        <div className="header-div">
          <div className="row">
            <div className="col-md-3">
              <h5>Indication request</h5>
            </div>
          </div>
        </div>
        <div className="table-section">
          <IndicationRequestTableVO />
        </div>
      </div>
    </div>
  );
};
export default IndicationRequestVO;
