import React, { useEffect, useState } from "react";
import './Dashboard.scss'
import dashboardImage from "../assets/dashboard-image.png";
import { Area, Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Scatter, Sector, Tooltip, XAxis, YAxis } from "recharts";
import { getAPI } from "../services/CepApiServices";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [dashboardData, setDashboardData] = useState<any>();
    const [pieChartData, setpieChartData] = useState<any>([]);
    const history = useNavigate();
    const data = [
        {
            name: 'Page A',
            uv: 590,
            pv: 800,
            amt: 1400,
        },
        {
            name: 'Page B',
            uv: 868,
            pv: 967,
            amt: 1506,
        },
        {
            name: 'Page C',
            uv: 1397,
            pv: 1098,
            amt: 989,
        },
        {
            name: 'Page D',
            uv: 1480,
            pv: 1200,
            amt: 1228,
        },
        {
            name: 'Page E',
            uv: 1520,
            pv: 1108,
            amt: 1100,
        },
        {
            name: 'Page F',
            uv: 1400,
            pv: 680,
            amt: 1700,
        },
    ];
    const onLoadDataFetchDashboardData = async () => {
        const getDashboardList = await getAPI(`Dashboard/GetDashboardDetails`);
        setDashboardData(getDashboardList);
        setpieChartData(getDashboardList?.indicationStatusCountList);
    };
    const COLORS = ['#0088FE', '#00C49F', '#f00', '#FFBB28', '#FF8042', '#8884d8'];
    const renderActiveShape = (props: any) => {

        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, label } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g style={{ pointerEvents: 'none' }}>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.label}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <div>

                </div>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontSize: "18px", fontWeight: "bold", whiteSpace: "pre-line" }}>{`${value}`}</text>
                {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} style={{fontSize:"18px",fontWeight: "bold"}} fill="#999">
                    {`${value}`}
                </text> */}

            </g>
        );
    };
    const redirectionRequest = (requestName: any) => {
        history(`/${requestName}`);
    }

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };
    useEffect(() => {
        onLoadDataFetchDashboardData();
    }, []);
    return (
        <>

            <div className="dashboard" >
                <img
                    className="ship-bc-img"
                    src={dashboardImage}
                    alt="vessels-img"
                    width={"100%"}
                    height={"250px"}
                />
                <div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row" >
                                    <div className="col-md-3">
                                        <div className="dashboardDetails-section" data-testid="click-request" onClick={() => redirectionRequest("CharterRequest")}>
                                            <div className="heading-request">Charter Request</div>
                                            <div className='headingDiv-dashboard' >
                                                <div className="row">
                                                    <div className="col-md-6 request-value">
                                                        New: {dashboardData?.charterRequestSentCount}
                                                    </div>
                                                    <div className="col-md-6 request-value">
                                                        Initiated: {dashboardData?.charterRequestInitiatedCount}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="dashboardDetails-section" data-testid='redirectionRequest' onClick={() => redirectionRequest("Negotiation")}>
                                            <div className="heading-request"> Negotiation</div>
                                            <div className='headingDiv-dashboard'>
                                                <div className="row">
                                                    <div className="col-md-6 request-value">
                                                        Negotiating: {dashboardData?.negotiationCount}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dashboardDetails-section" data-testid='IndicationRequest' onClick={() => redirectionRequest("IndicationRequest")}>
                                            <div className="heading-request">Indication Request</div>
                                            <div className='headingDiv-dashboard'>
                                                <div className="row">
                                                    <div className="col-md-6 request-value">
                                                        Count: {dashboardData?.indicationRequestCount}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dashboardDetails-section">
                                            <div className="heading-request"> Approved</div>
                                            <div className='headingDiv-dashboard'>
                                                <div className="row">
                                                    <div className="col-md-6 request-value">
                                                        {'Count: 20'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8" >
                                        <div>
                                            <div className="barChart-details-section">
                                                <div className="heading-request left-main-heading">Heading </div>
                                                <ResponsiveContainer width="100%" height="93%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={400}
                                                        data={data}
                                                        margin={{
                                                            top: 20,
                                                            right: 20,
                                                            bottom: 20,
                                                            left: 20,
                                                        }}
                                                    >
                                                        <CartesianGrid stroke="#f5f5f5" />
                                                        <XAxis dataKey="name" scale="band" />
                                                        <YAxis />
                                                        <Legend />
                                                        <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />
                                                        <Bar dataKey="pv" barSize={20} fill="#413ea0" />
                                                        <Scatter dataKey="cnt" fill="red" />
                                                        <Tooltip />
                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ">
                                        <div className="chartDetails-section">
                                            <div className="rightIndicationStatus-header">Indication Status</div>
                                            <ResponsiveContainer width="100%" height="92%">
                                                <PieChart width={800} height={800}>
                                                    <Legend
                                                        payload={
                                                            pieChartData?.map(
                                                                (item: any, index: any) => ({
                                                                    type: "square",
                                                                    value: `${item.label}`,
                                                                    color: COLORS[index % COLORS.length]
                                                                })
                                                            )
                                                        }
                                                    />
                                                    <Pie
                                                        activeIndex={activeIndex}
                                                        activeShape={renderActiveShape}
                                                        data={pieChartData}
                                                        cx="50%"
                                                        cy="50%"
                                                        innerRadius={100}
                                                        outerRadius={140}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                        onMouseEnter={onPieEnter}
                                                    >
                                                        {pieChartData?.map((entry:any, index: any) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

};
export default Dashboard;
