import { checkIfUserLoggedIn, setUserData, keyPrefix } from "../components/Protected/AuthHandler";
import { useNavigate } from 'react-router-dom';
import { getUserRole } from "../utils/token";

console.log("react-app-redirect-url - Testing-20-12-2023", process.env.REACT_APP_REDIRECT_URI);
export const loginCallbackConfig = {
  /**
   * @description: the uri that's whitelisted in AM for OAuth2
   */
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  /**
   * @description: Your application client id
   */
  client_id: process.env.REACT_APP_FORGEROCKCLIENT_ID,
  /**
    * @description: The url of the authority, e.g https://accounts.maersk.com/developer
    */
  authority: process.env.REACT_APP_AUTHORITY,
  storage: {
    /**
     * @default: "localStorage"
     * @description: the type of storage you want to utilize to store the tokens
     * choices are sessionStoragesession lived tokens, and localStorage, for permanent storing (until deleted)
     */
    type: "localStorage"
  },
  /**
   * default: "iamAL"
   * this will give the AL instance name in the global scope (window.iamAL)
   */
  window_namespace: 'maerskAL',
  post_logout_uri: window.location.origin,

  login_redirect_cb: (data, error) => {
    if (!!error || data === null) {
      window.location.href = `${window.origin}/error`;
    }
    // adding this check to avoid dual callbacks happening
    if (!localStorage.getItem('logCount')) {
      localStorage.setItem('logCount', '1');
    }
    if (data) {
      console.log('data in cb ', data);
      loginCallBack(data.access_token, data.id_token, 1);
    } else {
      window.location.href = `${window.origin}/error`;
    }
  }
}

const loginCallBack = (accessToken, idToken, status) => {
  if (status === 1) {
    const back_to = localStorage.getItem("login_back_to");
    localStorage.removeItem("login_back_to");
    //localStorage.setItem("frJwt", idToken);
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ AccessToken: accessToken, IdToken: idToken })
  };
  console.log('Calling auth api')
  const loginApi = `${process.env.REACT_APP_BASE_URL_CEP}Auth/authenticate`;
  fetch(loginApi, requestOptions)
    .then((response) => {
      if ([400, 401, 403, 404].includes(response.status)) {
      throw new Error('API Authentication Failed')
      }
      return response.json();
    })
    .then(async (data) => {
      setUserData(data);
      const userRole = await getUserRole();
      const redirectUri = localStorage.getItem('redirectUri');
      console.log("Dev Test redirect",redirectUri);
      if (!!redirectUri) {
        localStorage.removeItem('redirectUri');
        window.location.href = redirectUri; 
        return;
      }
      if(userRole === "CharteringManager"){
        window.location.href = `/CharterRequest`;
      }else if(userRole === "VesselOwner" || userRole ==="OwnerRepresentative"){
        window.location.href = `/IndicationRequestVO`
      }else if(userRole === "CEPDocumentReviewer"){
        window.location.href = `/DocumentVerification`
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
}
