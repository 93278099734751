import {FC} from 'react'

interface IPROPS {
  data?: any;
}
const ChangeLogDetail : FC<IPROPS> = ({ data}) => {
  return (
    <div>
      {data?.map((item: any, index : any) => (
        <div className="row" key={index}>
          <div className="col-md-2" data-testid='time'>{item.time}</div>
          <div className="col-md-4" data-testid='columnName'>{item.columnName}</div>
          <div className="col-md-3" data-testid='newValue'>{item.newValue}</div>
          <div className="col-md-3" data-testid='oldValue'>{item.oldValue}</div>
        </div>
      ))}
    </div>
  );
};

export default ChangeLogDetail;
