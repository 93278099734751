// React
import React, { useState, useEffect } from "react";
import "./SalesAndPurchase.scss";
import DataTable, { TableColumn } from "react-data-table-component";
import { McIcon } from "@maersk-global/mds-react-wrapper/components-core";
import { MdsConstants } from "../../utils/Constants";
import { getAPI, postAPI, deleteAPI } from "../../services/ApiServices";
import EditModal from "./EditModal";
import DeleteModal from "../delete-modal/DeleteModal";
import { excelDownload } from "../../utils/excelDownload";
import ChangeLogModal from "../change-log/ChangeLogModal";
import { SkeletonLoader } from "../../components/skeletonLoader/SkeletonLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { McSelectOptions } from "@maersk-global/mds-components-core/mc-select/types";
import columns from "./SalesAndPurchaseColumns";

interface IPROPS {
  dataObject?: any;
  isFilterload?: any;
  addObj?: any;
  onAddModalClose?: any;
  ondownloadSnP?: any;
  isDownloadClicked?: any;
  snpAfterDownload?: any;
  globalSearchSelected?: any;
  onSearch?: any;
}
function SalesAndPurchase({
  dataObject,
  isFilterload,
  addObj,
  onAddModalClose,
  ondownloadSnP,
  snpAfterDownload,
  isDownloadClicked,
  globalSearchSelected,
  onSearch,
}: IPROPS) {
  const [tableData, setTableData] = useState<any>([]);
  const [status, setStatus] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [source, setCourse] = useState([]);
  const [editData, setEditData] = useState();
  const [dDlOptions, setTableSizeDdl] = useState<McSelectOptions>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);
  const [selectedNoOfRecords, setSelectedNoOfRecords] = useState(10);
  const [showAddSNPError, setShowAddSNPError] = useState(false);
  const [showAddSNPSuccess, setShowAddSNPSuccess] = useState(false);
  const [isSPDeleteModal, setIsSPDeleteModal] = useState(false);
  const [snpdeleteData, setSNPDeleteData] = useState();
  const [downloadSnPData, setDownloadSnPData] = useState<any>([]);
  const [changeLogModalSnP, setChangeLogModalSnP] = useState(false);
  const [changeLogDataSnP, setChangeLogDataSnP] = useState<any>();
  const [isSalesAndPurchasePage, setSalesAndPurchasePage] = useState();
  const [isTableLoader, setIsTableLoader] = useState(false);
  let numOfRecordsPerPage = 10;
  let numOfRecords = 0;

  const fetchSNPData = async (
    itemsPerPage: number,
    pageNumber: number,
    OnloadData: any
  ): Promise<void> => {
    setIsTableLoader(true);
    setTableSizeDdl(MdsConstants.ItemsPerPageDDl);
    const snpData = await postAPI(`SalesAndPurchase/Get`, OnloadData);
    setDownloadSnPData(snpData);
    if (snpData?.Errors) {
      setIsTableLoader(false);
    } else {
      if (snpData?.length > 0) {
        setTableData(snpData);
        numOfRecords = snpData[0].recordCount ? snpData[0].recordCount : 0;
        setTotalPages(numOfRecords / numOfRecordsPerPage);

        // const startIdx = pageNumber * numOfRecordsPerPage - numOfRecordsPerPage;
        // const endIdx = pageNumber * numOfRecordsPerPage - 1;
        // const recordsToRead = snpData.slice(startIdx, endIdx);
        setIsTableLoader(false);
      } else {
        setTableData([]);
        setIsTableLoader(false);
      }
    }
  };

  const validateSNPStatus = async (obj: any): Promise<void> => {
    const imo = obj?.imo ? obj?.imo : null;
    const typeId = obj?.statusId ? obj?.statusId : null;
    if (imo && typeId) {
      const status = await getAPI(
        `SalesAndPurchase/Validate/` + imo + `/` + typeId
      );
      //Call add API if status is false
      if (!status) {
        setShowAddSNPError(false);
        const addSNP = await postAPI(`SalesAndPurchase/Add`, obj);
        if (addSNP == true) {
          snpNotify("Sales and Purchases details added successfully", true);
          fetchSNPData(10, 1, dataObject);
          onAddModalClose(true);
        } else {
          if (addSNP.StatusCode == 400) {
            snpNotify(addSNP.Message, false);
            setShowAddSNPError(true);
          }
        }
        onAddModalClose(true);
      } else {
        snpNotify(" Record already exist, Please select and update", false);
        setShowAddSNPError(false);
        onAddModalClose(true);
      }
    }
  };

  useEffect(() => {
    if (ondownloadSnP == true) downloadSalesNPurchase();
  }, [ondownloadSnP]);

  const downloadSalesNPurchase = async () => {
    const salesAndPurchaseDownloadData = await fetchSNPData(0, 0, dataObject);
    setDownloadSnPData(salesAndPurchaseDownloadData);
    const modified_rows = tableData.map((e: any) => {
      return {
        "Vessel Name": e.vesselName,
        Build: e.build,
        TEU: e.teu,
        Design: e.design,
        Status: e.status,
        Owner: e.owner,
        Charterer: e.chartererName,
        Currency: e.currency,
        Price: e.price,
        "Date of Status":
          e.updateDate != null
            ? moment(e.updateDate).format("DD-MMM-YYYY")
            : e.updateDate,
        "Price Guidance": e.priceGuidance,
        Buyer: e.buyer,
        Source: e.source,
        UID: e.uid,
        Updated:
          e.lastUpdatedOn != null
            ? moment(e.lastUpdatedOn).format("DD-MMM-YYYY")
            : e.lastUpdatedOn,
        Comments: e.comments,
      };
    });

    // set headers of excel columns
    const header = [
      "Vessel Name",
      "Build",
      "TEU",
      "Design",
      "Status",
      "Owner",
      "Charterer",
      "Currency",
      "Price",
      "Date of Status",
      "Price Guidance",
      "Buyer",
      "Source",
      "UID",
      "Updated",
      "Comments",
    ];

    excelDownload("Sales-And_Purchases_Data", modified_rows, header);
    snpAfterDownload();
    notify("File downloaded successfully", true);
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  useEffect(() => {
    validateSNPStatus(addObj);
  }, [addObj]);

  useEffect(() => {
    if (globalSearchSelected) searchSNPGlobalName(onSearch);
  }, [onSearch]);

  const searchSNPGlobalName = async (input: any) => {
    if (input) {
      setIsTableLoader(true);
      const res: any = await getAPI(
        `SalesAndPurchase/GetSalesAndPurchaseByVessel?searchInput=` + input
      );
      if (res?.Errors || !res || res?.length == 0) {
        setTableData([]);
        setIsTableLoader(false);
      } else {
        setTableData(res);
        numOfRecords = res[0].recordCount ? res[0].recordCount : 0;
        setTotalPages(numOfRecords / 10);
        setIsTableLoader(false);
        // const startIdx = 1 * 10 - 10;
        // const endIdx = 1 * 10 - 1;
        // const recordsToRead = res.slice(startIdx, endIdx);
      }
    }
  };

  const deleteSPFunc = (type: string) => {
    if (type === "delete") {
      deleteAPI(`SalesAndPurchase/Delete` + `/` + snpdeleteData).then(
        (resp) => {
          fetchSNPData(10, 1, dataObject);
          setIsSPDeleteModal(false);
          if (resp?.StatusCode) {
            toast.error("Delete Failed", {
              theme: "colored",
              icon: false,
            });
          } else {
            toast.success("Deleted successfully", {
              theme: "colored",
              icon: false,
            });
          }
        }
      );
    } else {
      setIsSPDeleteModal(false);
    }
  };

  const fetchOnLoad = async () => {
    await getAPI("Filter/GetAllSNPStatus").then((result) => {
      setStatus(result);
    });
    await getAPI("Filter/GetAllCurrency").then((result) => {
      setCurrency(result);
    });
    await getAPI("Charter/GetManagingOwner").then((result) => {
      setBuyer(result);
    });
    await getAPI("Filter/GetSNPSources").then((result) => {
      setCourse(result);
    });
  };
  useEffect(() => {
    fetchOnLoad();
  }, []);
  useEffect(() => {
    fetchSNPData(10, 1, dataObject);
  }, [isFilterload]);

  const modalCloseFuc = (type: string, data: any) => {
    if (type === "submit") {
      postAPI(`SalesAndPurchase/Update`, data).then((resp) => {
        fetchSNPData(10, 1, dataObject);
        setIsEditModal(false);
        if (resp.StatusCode) {
          toast.error("Update Failed", {
            theme: "colored",
            icon: false,
          });
        } else {
          toast.success("Updated successfully", {
            theme: "colored",
            icon: false,
          });
        }
      });
    } else {
      setIsEditModal(false);
    }
  };

  const closeSnPChangeLog = () => {
    setChangeLogModalSnP(false);
    setChangeLogDataSnP(0);
  };

  const ExpandedComponent = ({ data }: any) => {
    const onclickFunc = (data: any) => {
      setEditData(data);
      setIsEditModal(true);
    };

    const onSPDeleteClickFunc = (data: any) => {
      setSNPDeleteData(data.id);
      setIsSPDeleteModal(true);
    };

    const onChangeLogSnP = (data: any) => {
      setChangeLogDataSnP(data.id);
      setChangeLogModalSnP(true);
    };

    return (
      <div className="subColoum">
        <div className="edit-options">
          <ul className="options-list">
            <li>
              <McIcon
                data-testid="edit-option"
                ref={data}
                onClick={() => onclickFunc(data)}
                icon="pencil"
              ></McIcon>
            </li>
            <li>
              <McIcon
                data-testid="change-log-option"
                icon="arrow-clockwise-clock"
                onClick={() => onChangeLogSnP(data)}
              ></McIcon>
            </li>
            <li>
              <McIcon
                data-testid="delete-option"
                icon="trash"
                onClick={() => onSPDeleteClickFunc(data)}
              ></McIcon>
            </li>
          </ul>
        </div>
        <div className="header-section">
          <div className="row">
            <div className="col-md-3">
              <h5>Comments</h5>
            </div>
            <div className="col-md-2">
              <h5>Updated </h5>
            </div>
            <div className="col-md-1">
              <h5>EnBlock </h5>
            </div>
            <div className="col-md-2">
              <h5>UID</h5>
            </div>
            <div className="col-md-2">
              <h5>Source</h5>
            </div>
            <div className="col-md-2">
              <h5>Buyer</h5>
            </div>
          </div>
        </div>
        <div className="data-section">
          <div className="row">
            <div className="col-md-3" data-testid="snp-comments">
              {data.comments}
            </div>
            <div className="col-md-2 ltr">
              {data.updateDate !== null
                ? moment(data.updateDate).format("DD-MMM-YYYY")
                : ""}
            </div>
            <div className="col-md-1" data-testid="snp-enblock">
              {data.enBlockValue}
            </div>
            <div className="col-md-2" data-testid="snp-uid">
              {data.uid}
            </div>
            <div className="col-md-2" data-testid="snp-source">
              {data.source}
            </div>
            <div className="col-md-2" data-testid="snp-buyer">
              {data.buyer}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const snpNotify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  return (
    <>
      <DataTable
        columns={columns}
        data={tableData}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        progressPending={isTableLoader}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
      />
      <EditModal
        onModalClose={modalCloseFuc}
        statusOption={status}
        currencyOption={currency}
        buyerOption={buyer}
        sourceOption={source}
        OpenModal={isEditModal}
        data={editData}
      />
      <DeleteModal
        onSnPDeleteOpen={isSPDeleteModal}
        onSnPDeleteModalClose={deleteSPFunc}
      />

      <ChangeLogModal
        onChangeLogOpenSnP={changeLogModalSnP}
        logIdSnP={changeLogDataSnP}
        onClose={closeSnPChangeLog}
      />
    </>
  );
}
export default SalesAndPurchase;
