// React
import React, { useState, useEffect } from "react";
import { useForm, Controller, useFormContext } from "react-hook-form";

//MDS
import "@maersk-global/mds-components-core";
import {
  McSelect,
  McCAccordionItem,
  McCheckboxGroup,
  McCheckbox,
  McButton,
  McIcon,
} from "@maersk-global/mds-react-wrapper";
//Service
import { getAPI } from "../../services/CepApiServices";
//CSS
import "./FilterComponent.scss";
import Multiselect from "multiselect-react-dropdown";

interface IPROPS {
  closeFilterFunc?: any;
  onDataFunc?: any;
  clearFilter?: any;
  filterFor?: string;
}
const FilterComponent = ({
  closeFilterFunc,
  onDataFunc,
  clearFilter,
  filterFor,
}: IPROPS) => {
  const [filters, setFilters] = useState<any | { requestStatusItems: any }>({});
  const [statusData, setStatusData] = useState([]);
  const [requestItemsData, setRequestItemsData] = useState<any[]>([]);
  // const [requestItemsData, setRequestItemsData] = useState([{}]);
  // const [requestItemsData,setRequestItemsData] = useState([]);
  const [documentStatus, setDocumentStatus] = useState<any[]>([]);
  const [documentCategory, setDocumentCategory] = useState<any[]>([]);
  const [documentNames, setDocumentNames] = useState<any[]>([]);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      statusField: [],
      docStatus: [],
      docCategory: [],
      docNames: [],
      charterRequestList:[]
    },
  });
  const onSubmit = (data: any) => {
    onDataFunc(data);
    closeFilterFunc();
  };
  useEffect(() => {
    fetchFilters();
  }, []);

  const fetchFilters = async () => {
    const filtersData = await getAPI(
      `IndicationRequest/GetIndicationFilter`
    );
    setFilters(filtersData);
    setStatusData(filtersData?.workflowStageList);
    // setRequestItemsData(filtersData?.charterRequestList?.map((a: any) => a));
    // temp fix
    const arr = filtersData?.charterRequestList?.splice(1200);
    console.log(arr?.length);
     // temp fix end
    setRequestItemsData(filtersData?.charterRequestList);
    console.log(typeof requestItemsData);
    setDocumentStatus(filtersData?.docParsingStatusList);
    setDocumentCategory(filtersData?.docCategoryList);
    setDocumentNames(filtersData?.documentList); // same
  };
  const closeFilter = () => {
    closeFilterFunc();
  };
  const clearFunction = () => {
    reset();
    clearFilter();
    closeFilterFunc();
  };
  return (
    <form
      data-testid="form"
      className="filter-common"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="filterModal" data-testid="filter-form">
        <div className="filter-section">
          <div className="input-mainSection">
            <div className="header-section">
              <h5 className="header-text">Apply Filter</h5>
              <div
                className="closeIcon"
                data-testid="close"
                onClick={closeFilter}
              >
                <McIcon icon="times"></McIcon>
              </div>
            </div>
            {(filterFor == "IndicationRequestDocuments" || filterFor == "Negotiation" )&& (
              <div className="filters-subsection">
              <McCAccordionItem
                title="Status"
                id="1"
                expanded={true}
                fit="medium"
              >
                <Controller
                  name="statusField"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <McCheckboxGroup
                      name="statusField"
                      legend=""
                      fit="small"
                      change={(e) => onChange(e)}
                    >
                      {statusData?.map((item: any) => (
                        <McCheckbox
                          name="statusField"
                          value={item.value}
                          label={item.label}
                        ></McCheckbox>
                      ))}
                    </McCheckboxGroup>
                  )}
                />
              </McCAccordionItem>
            </div>
            )}
            {(filterFor == "DocumentVerification" || filterFor == "TemplateRepository" )&& (
              <div className="filters-subsection">
              <McCAccordionItem
                title="Status"
                id="1"
                expanded={true}
                fit="medium"
              >
                <Controller
                  name="docStatus"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <McCheckboxGroup
                      name="docStatus"
                      legend=""
                      fit="small"
                      change={(e) => onChange(e)}
                    >
                      {documentStatus?.map((item: any) => (
                        <McCheckbox
                          name="docStatus"
                          value={item.value}
                          label={item.label}
                        ></McCheckbox>
                      ))}
                    </McCheckboxGroup>
                  )}
                />
              </McCAccordionItem>
            </div>
            )}
            {(filterFor == "IndicationRequestDocuments" || filterFor == "Negotiation" )&& (
              <div className="filters-subsection">
              <McCAccordionItem
                title="Additional filters"
                id="1"
                expanded={true}
                fit="medium"
              >
                {/* <div className="input-section" style={{ height: "180px" }}>
                  <Controller
                    name="requestItems"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Multiselect
                        placeholder="Requested items "
                        options={requestItemsData}
                        onSelect={(e) => {
                          onChange(e);
                        }}
                        displayValue="label"
                        showCheckbox
                      />
                    )}
                  />
                </div> */}
                  <div className="input-section" style={{height:"180px"}}>
                  <Controller
                  name="charterRequestList"
                  control={control}
                
                  render={({ field: { onChange } }) => (
                <Multiselect
                          placeholder="Requested items "
                          options={requestItemsData}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                  )}
                  />
                </div>
              </McCAccordionItem>
            </div>
            )}
            {(filterFor == "DocumentVerification" || filterFor == "TemplateRepository")&& (
              <div className="filters-subsection">
              <McCAccordionItem
                title="Additional filters"
                id="1"
                expanded={true}
                fit="medium"
              >
                <div className="input-section" style={{ height: "auto" }}>
                  <label>Document Category</label>
                  <Controller
                    name="docCategory"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Multiselect
                        placeholder="Select Category"
                        options={documentCategory}
                        onSelect={(e) => {
                          onChange(e);
                        }}
                        displayValue="label"
                        showCheckbox
                      />
                    )}
                  />
                </div>

                <div className="input-section" style={{ height: "220px" }}>
                <label>Document List</label>
                  <Controller
                    name="docNames"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Multiselect
                        placeholder="Select Document"
                        options={documentNames}
                        onSelect={(e) => {
                          onChange(e);
                        }}
                        displayValue="label"
                        showCheckbox
                      />
                    )}
                  />
                </div>
                
              </McCAccordionItem>
            </div>
            )}
            
          </div>
          <div className="footer-fixed">
            <div className="button-apply">
              <div className="input-section">
                <McButton
                  data-testid="filter-component-apply-filter"
                  type="submit"
                  label="Apply filter"
                  width="full-width"
                  fit="small"
                ></McButton>
              </div>
            </div>
            <div className="button-apply">
              <div className="input-section">
                <button
                  data-testid="filter-component-clear-filter"
                  className="reset-btn"
                  type="button"
                  onClick={() => {
                    clearFunction();
                  }}
                >
                  Clear filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default FilterComponent;
