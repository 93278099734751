import React, { useEffect } from "react";
import { useState } from "react";
import { McModal, McIcon, McButton } from "@maersk-global/mds-react-wrapper";
import { McRadioGroup } from "@maersk-global/mds-react-wrapper/components-core";
import { McRadio } from "@maersk-global/mds-react-wrapper/components-core";
import { useForm } from "react-hook-form";
import "./UploadDocConfirmationModal.scss";

interface IPROPS {
  onOpen?: boolean;
  closeConfirmation?: any;
}

interface FormValues {
  isLocked: any;
}

const UploadDocConfirmationModal = ({ onOpen, closeConfirmation }: IPROPS) => {
  const [modalHieght, setModalHieght] = useState("34vh");
  const [isUploadSection, setIsUploadSection] = useState(true);
  const [uploadModalValue, setUploadModalValue] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({});

  useEffect(() => {
    setIsUploadSection(true);
  }, [onOpen]);

  const onSubmit = (data: any) => {
    if (data.isLocked !== null) {
      setUploadModalValue(true);
      closeConfirmation(data.isLocked);
    }
  };

  const onCnfmModalClose = () => {
    closeConfirmation(null);
  };

  return (
    <div>
      <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
        <McModal
          open={onOpen}
          heading=""
          width="520px"
          backdropcloseactiondisabled
          height={modalHieght}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="modal-section">
                <div className="subColoum">
                  <div className="header-section">
                    {
                      <div className="row question-sec">
                        <div className="col-md-12">
                          <McRadioGroup
                            {...register("isLocked")}
                            name="isLocked"
                            legend="Please select the category from which you want to upload the document"
                            data-testid="isLocked"
                          >
                            <McRadio
                              name="isLocked"
                              value="true"
                              label="Document requested by CM"
                              data-testid="isLocked-true"
                            ></McRadio>
                            <McRadio
                              name="isLocked"
                              value="false"
                              label="Vessel owners document"
                              data-testid="isLocked-false"
                            ></McRadio>
                          </McRadioGroup>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <McButton
            data-testid="cancel-button"
            slot="secondaryAction"
            variant="secondary"
            click={onCnfmModalClose}
            dialogAction="cancel"
          >
            Cancel
          </McButton>

          <McButton
            data-testid="save-button"
            slot="primaryAction"
            variant="primary"
            type="submit"
            // disabled={isDisableBtn}
            role="submit"
          >
            Next
          </McButton>
        </McModal>
      </form>
    </div>
  );
};

export default UploadDocConfirmationModal;
